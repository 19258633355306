import React, { Component }  from 'react';


const Bitcoin = props => (<svg {...props} width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.642 8.13086C14.9349 6.13867 13.4232 5.06055 11.3373 4.3457L12.017 1.63867L10.3763 1.22852L9.72009 3.86523C9.2865 3.75977 8.84118 3.6543 8.39587 3.56055L9.05212 0.912109L7.4115 0.501953L6.74353 3.19726C6.38025 3.11523 6.02868 3.0332 5.68884 2.95117V2.93945L3.4154 2.37695L2.98181 4.13476C2.98181 4.13476 4.20056 4.41601 4.17712 4.42773C4.84509 4.5918 4.96228 5.03711 4.93884 5.38867L4.1654 8.4707C4.21228 8.48242 4.27087 8.49414 4.34118 8.5293C4.28259 8.51758 4.224 8.50586 4.1654 8.48242L3.08728 12.7949C3.00525 12.9941 2.79431 13.2988 2.33728 13.1816C2.349 13.2051 1.14197 12.8887 1.14197 12.8887L0.321655 14.7754L2.46618 15.3145C2.86462 15.4199 3.25134 15.5137 3.63806 15.6191L2.95837 18.3496L4.599 18.7598L5.27868 16.0527C5.724 16.1699 6.16931 16.2871 6.59118 16.3926L5.92321 19.0879L7.56384 19.498L8.24353 16.7676C11.056 17.2949 13.1654 17.084 14.0443 14.541C14.7591 12.502 14.0091 11.3184 12.5326 10.5449C13.6224 10.2988 14.431 9.58398 14.642 8.13086ZM10.8802 13.4043C10.3763 15.4434 6.93103 14.3418 5.81775 14.0605L6.72009 10.4395C7.83337 10.7207 11.4193 11.2715 10.8802 13.4043ZM11.3959 8.0957C10.9271 9.95898 8.06774 9.00977 7.14196 8.77539L7.96228 5.49414C8.88806 5.72852 11.8763 6.16211 11.3959 8.0957Z" fill="white"/>
</svg>
)


const BitcoinCircle = props => (
  <div style={{
    backgroundColor:'orange', 
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    width:'50px', 
    height:'50px',
    borderRadius:'50px',
    transform:`scale(${props.scale})`,
  }}>
    <Bitcoin style={{
      width:props.width ? props.width : '',
      height:props.height? props.height : '',
      marginRight:'0px'
    }}/>
  </div>

)

export default BitcoinCircle
