//turn categories into a list
var sortCategories = function(categories){
  //put the categories into a list
  var list = []
  for (var i in categories){
    list.push(categories[i])
  }

  //sort the list by row order
  list.sort(function(a,b){
    return a.row_order - b.row_order
  })
  return list
}

module.exports = {
  sortCategories:sortCategories
}
  
