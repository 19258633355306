import React, { Component } from "react"
import RestContextHOC from "../../Contexts/RestContextHOC"
import UserContextHOC from "../../Contexts/UserContextHOC"
import CartContextHOC from "../../Contexts/CartContextHOC"
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC"
import { injectStripe } from "react-stripe-elements"
import Checkbox from "../../sparkles/Checkbox/Checkbox"
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "react-stripe-elements"

const INPUT_STYLE = {
  base: {
    fontSize: "16px",
    fontWeight: "400",
    padding: "10px 10px 10px 10px",
    textAlign: "left",
    fontFamily: "sans-serif",
    color: "#717171",
    "::placeholder": {
      fontSize: "16px",
      color: "#aab7c4",
      fontWeight: 400,
    },
  },
}

class CreditCardAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saveCard: true,
      funding: null,
      cardNumber: null,
      cardCvc: null,
      cardExpiry: null,
    }
    this.handleCancel = this.handleCancel.bind(this)
    this.handleZipChange = this.handleZipChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.getFundingType = this.getFundingType.bind(this)
    this.zipcodeRef = React.createRef()

    this.inputTypes = ["cardNumber", "cardExpiry", "cardCvc"]
  }

  handleCancel(e) {
    this.props.handleCardAddCancel()
    this.props.updateZipcode("")
  }

  handleZipChange(e) {
    if (
      e.target.value.indexOf(".") === -1 &&
      (e.target.value.match(/^-?\d+$/) || e.target.value === "")
    ) {
      this.props.updateZipcode(e.target.value.substring(0, 5))
    }
  }

  handleInputChange(o) {
    if (!this.inputTypes.includes(o.elementType)) return

    this.setState(
      {
        [o.elementType]: o.complete && !o.error ? true : false,
      },
      () => {
        if (o.error || !o.complete) {
          this.setState({
            funding: null,
          })
        } else {
          this.getFundingType()
        }
      }
    )
  }

  async getFundingType() {
    if (this.state.cardNumber && this.state.cardExpiry && this.state.cardCvc) {
      const types = ["credit", "debit", "prepaid", "unknown"]

      // Create a charge token with Stripe to retrieve the funding type
      let { token, error } = await this.props.stripe.createToken()
      if (!error && token.card) {
        this.setState({
          funding: token.card.funding,
        })
        this.props.cart.selectPaymentNew("newCard", null, token.card)
      }
    }
  }

  render() {
    return (
      <div className="checkout-card-section ">
        <div
          className="flex-row space-between"
          style={{ marginBottom: "15px" }}
        >
          <div style={{ marginBottom: "0px" }} className="add-new-card-text">
            Add New Card
          </div>
          <div className="cancel-card" onClick={this.handleCancel}>
            Cancel
          </div>
        </div>
        <div className="elements-container">
          <div className="element-row">
            <div style={{ width: "100%" }}>
              <div className="checkout-elements-header">Card Number</div>
              <CardNumberElement
                style={INPUT_STYLE}
                classes={{ base: "card-number-input" }}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className="element-row">
            <div style={{ width: "50%" }}>
              <div className="checkout-elements-header">Expiration</div>
              <CardExpiryElement
                style={INPUT_STYLE}
                classes={{ base: "expiration-input" }}
                onChange={this.handleInputChange}
              />
            </div>
            <div style={{ width: "50%" }}>
              <div className="checkout-elements-header">Security Code</div>
              <CardCvcElement
                style={INPUT_STYLE}
                classes={{ base: "cvv-input" }}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className="element-row">
            <div style={{ width: "50%" }}>
              <div className="checkout-elements-header">Zip Code</div>
              <input
                style={{ width: "90%" }}
                ref={this.zipcodeRef}
                className="StripeElement--empty zipcode-input"
                type="number"
                inputMode="decimal"
                placeholder="00000"
                value={this.state.zipcode}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    that.zipcodeRef.current.blur()
                  }
                }}
                onKeyDown={(evt) => evt.key === "." && evt.preventDefault()}
                onChange={this.handleZipChange}
              />
            </div>
            <div
              onClick={() =>
                this.setState({ saveCard: !this.state.saveCard }, () =>
                  this.props.cart.shouldSaveCard(this.state.saveCard)
                )
              }
              className="save-card-container"
            >
              <Checkbox
                checked={this.state.saveCard}
                style={{ marginRight: "10px", transform: "scale(1.2)" }}
              />
              <div className="save-card-text">Save Card</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectStripe(
  RestContextHOC(
    AnalyticsContextHOC(CartContextHOC(UserContextHOC(CreditCardAdd)))
  )
)
