import React from "react";
import "./Checkbox.scss";
import { ReactComponent as Check } from "./check.svg";

const Checkbox = props => {

  return (
    <div className={props.checked ? "checkbox__checked" : "checkbox"} style={props.style}>
      {props.checked && (
        <div
          className="checkbox__inner"
          style={{ backgroundColor: props.fillColor || "#222222" }}
        >
          <Check />
        </div>
      )}
    </div>
  )
}

export default Checkbox;
