const { detect } = require('detect-browser');

var callApi = function(
  endpoint_name,
  success,
  failure = function() {},
  data = {},
  url_params = {},
  resource_id,
  extraArgs,
) {
  var endpoint = ""
  let apiContent = ""
  var method = "post"
  let use_c = false // remove

  if (endpoint_name === "third_party_login") {
    endpoint = "/api/login/third-party";
  } else if (endpoint_name === "clickstream") {
    endpoint = "/clickstream"
    method = "post"
    use_c = true
  } else if (endpoint_name === "verify") {
    endpoint = "/api/order/verify";
    method = "post";
  } else if (endpoint_name === "placement") {
    endpoint = "/api/order/place";
    method = "post";
  } else if (endpoint_name === "uuid-to-token") {
    endpoint = "/api/login/uuid-to-token"
    method = 'post'
  } else if (endpoint_name === 'diner_update_info') {
    endpoint = "/api/login/update"
    method = 'post'
  } else if (endpoint_name === 'diner_update_email') {
    endpoint = "/api/login/set_email"
    method = 'post'
  } else if (endpoint_name === 'diner_password_reset') {
    endpoint = "/api/login/v2/reset"
    method = 'post'
  } else if (endpoint_name === 'anon_password_reset') {
    endpoint = '/api/login/v2/anon_reset'
    method = 'post'
  } else if (endpoint_name === 'anon_password_set_password') {
    endpoint = '/api/login/v2/anon_reset/set_and_login'
    method = 'post'
  } else if (endpoint_name === 'anon_password_code_verify') {
    endpoint = '/api/login/v2/anon_reset/code'
    method = 'post'
  } else if (endpoint_name === "reverse_geocode") {
    endpoint = `/api/reverse-geocode?lat=${url_params.latitude}&lng=${
      url_params.longitude
    }`;
    method = "get";
  } else if (endpoint_name === "geocode") {
    endpoint = `/api/geocode`;
  } else if (endpoint_name === "delivery_zone_verify") {
    endpoint = `/api/delivery-zone/verify`;
  } else if (endpoint_name === "menu_by_id") {
    endpoint = `/api/menu/${resource_id}`;
    method = "get";
  } else if (endpoint_name === "menu") {
    endpoint = `/api/rest/menu/${resource_id}`;
    method = "get";
  } else if (endpoint_name === "menu_category_blackouts") {
    let fulfill_at = ''
    if (url_params && url_params.fulfill_at) {
      fulfill_at = url_params.fulfill_at
    }
    endpoint = `/api/rest/menu-category-blackout/${resource_id}?fulfill_at=${fulfill_at}`;
    method = "get";
  } else if (endpoint_name === "menu_item_blackouts") {
    let fulfill_at = ''
    if (url_params && url_params.fulfill_at) {
      fulfill_at = url_params.fulfill_at
    }
    endpoint = `/api/rest/menu-item-blackout/${resource_id}?fulfill_at=${fulfill_at}`;
    method = "get";
  }  else if (endpoint_name === "create_account") {
    endpoint = "/api/login/create";
  } else if (endpoint_name === "forgot_password") {
    endpoint = "/api/forgot-password";
  } else if (endpoint_name === "email_login") {
    endpoint = "/api/login/v2/email"
    method = 'post'
  } else if (endpoint_name === "email_register") {
    endpoint = "/api/login/v2/register"
    method = "post"
  } else if (endpoint_name === "list_restaurants") {
    endpoint = "/api/restaurants/home.json";
    method = "get";
  } else if (endpoint_name === "rest_basic_info") {
    endpoint = `/api/restaurant/basic-info/${resource_id}`;
    method = "get";
  } else if (endpoint_name === 'font') {
    endpoint = `/api/restaurant/font/${resource_id}`
    method = 'get' 
  } else if (endpoint_name === 'delivery_bounds') { 
    endpoint = `/api/restaurant/delivery-bounds/${resource_id}`
    method = 'get'
  } else if (endpoint_name === "saved_cards") {
    endpoint = `/api/cards/saved/${resource_id}`;
    method = "get";
  } else if (endpoint_name === "delete_card") {
    endpoint = "/api/cards/delete";
  } else if (endpoint_name === "rest_hours") {
    endpoint = `/api/restaurant/hours/${resource_id}`;
    method = "get";
  } else if (endpoint_name === 'future_hours'){
    endpoint = `/api/restaurant/future-hours/${resource_id}`;
    method='get'
  } else if (endpoint_name === "save_address") {
    endpoint = "/api/diner/save_address";
  } else if (endpoint_name === "get_addresses") {
    endpoint = "/api/diner/addresses";
  } else if (endpoint_name === "delete_address") {
    endpoint = "/api/diner/address/delete";
  } else if (endpoint_name === "charge") {
    endpoint = "/api/stripe/charge";
  } else if (endpoint_name === "phone_verification_start") {
    endpoint = "/twilio/start_verification";
    method = "post";
  } else if (endpoint_name === "phone_verification_end") {
    endpoint = "/twilio/end_verification";
    method = "post";
  } else if (endpoint_name === "open_orders") {
    endpoint = "/api/order/open-orders";
    method = "post";
  } else if (endpoint_name === "open_order") {
    endpoint = "/api/order/open-order";
    method = "post";
  } else if (endpoint_name === "order_status") {
    endpoint = "/api/order/order-status";
    method = "post";
  } else if (endpoint_name === "coupon_input") {
    endpoint = "/api/coupons/input";
    method = "post";
  } else if (endpoint_name === "accepting_orders") {
    endpoint = "/api/accepting-orders";
    method = "post";
  } else if (endpoint_name === "duplicate_email") {
    endpoint = "/api/login/dupe-email";
    method = "post";
  } else if (endpoint_name === "recent_orders") {
    endpoint = `/api/order/recent-orders/${resource_id[0]}/${resource_id[1]}`;
    method = "get";
  } else if (endpoint_name === "order_receipt") {
    apiContent = 'blob'
    endpoint = `/api/order/receipt-img-secure/${resource_id[0]}/${resource_id[1]}`
    method = "get"
  } else if (endpoint_name === "get_points") {
    endpoint = `/api/rewards/points/${resource_id[0]}/${resource_id[1]}`;
    method = "get";
  } else if (endpoint_name === "init_rewards_user") {
    endpoint = `/api/rewards/init/${resource_id[0]}/${resource_id[1]}/${resource_id[2]}`;
    method = "post";
  } else if (endpoint_name === 'reorder'){
    endpoint = '/api/order/reorder'
    method = 'post'
  } else if (endpoint_name === 'other_locations'){
    endpoint = `/api/restaurant/other-locations/${url_params.rest_id}`
    method='get'
  } else if (endpoint_name === "brand_page_rests") {
    endpoint = `/api/brand/rests`;
    method = "post";
  } else if (endpoint_name === "brand_page_info") {
    endpoint = `/api/brand/info`;
    method = "post"
  } else if (endpoint_name === "contactless-payment-retrieve") {
    endpoint = `/api/contactless-payment/retrieve`;
    method = "post"
  } else if (endpoint_name === "contactless-payment-pay") {
    endpoint = `/api/contactless-payment/pay`;
    method = "post"
  } else if (endpoint_name === 'contactless-payment-receipt'){
    endpoint = '/api/contactless-payment/send-receipt'
    method = 'post'
  } else if(endpoint_name === 'thankyou-cart'){
    endpoint = "/api/order/cart"
    method = 'post'
  } else if (endpoint_name === 'logrocket_session'){
    endpoint ='/api/logrocket/session'
    method = 'post'
  } else if (endpoint_name === "time"){
    endpoint = '/api/time'
    method = 'get'
  } else if(endpoint_name === 'locations-v2-pickup-search'){
    endpoint = '/api/brand/v2/pickup-rests'
    method = 'post'
  } else if (endpoint_name === 'locations-v2-delivery-search'){
    endpoint = '/api/brand/v2/delivery-rests'
    method='post'
  } else if (endpoint_name === 'brand-info-v2'){
    endpoint = '/api/brand/v2/brand-info'
    method = 'post'
  } else if (endpoint_name === 'brand-v2-rests'){
    endpoint = '/api/brand/v2/brand-rests'
    method = 'post'
  } else if (endpoint_name === 'locations-page-save-email'){
    endpoint = '/api/brand/locations-page-add-email'
    method='post'
  } else if (endpoint_name == 'wait-for-solana-txn-confirm'){
    method = 'post'
    endpoint = '/api/check-if-solana-txn-ready'
  } else if (endpoint_name === "nft-metadata") {
    endpoint = `/api/nft-metadata/${resource_id}`;
    method = "get";
  } else if (endpoint_name === 'solana-price'){
    endpoint = '/api/sol-price'
    method = 'get'
  } else if (endpoint_name === 'check-for-nft'){ 
    endpoint = '/fcsc/ordering/check-if-own-nft'
    method = 'post'
  } else if (endpoint_name == 'post-to-nft-slack'){
    method='post'
    endpoint = '/fcsc/post-to-slack'
  } else if (endpoint_name === 'qr-session-start'){
    method = 'post'
    endpoint = '/api/start-qr-session'
  } else if (endpoint_name === "qr-session-check"){
    method = 'post'
    endpoint = '/api/check-qr-session'
  } else if (endpoint_name === "qr-session-check-txn") {
    method = 'post'
    endpoint = '/api/check-qr-session-txn'
  } else if (endpoint_name === 'accepted_tokens'){
    method = 'post'
    endpoint = '/api/supperclub/accepted-coins'
  } else if (endpoint_name === 'get-supperclub-diner'){
    method = 'post'
    endpoint = '/api/login/get-supperclub-diner'
  } else if (endpoint_name === 'is-tax-exempt'){
    method = 'post'
    endpoint = '/api/is-tax-exempt'
  } else if (endpoint_name === 'kiosk-create-payment-intent'){
    method = 'post'
    endpoint = '/stripe-pos/kiosk/create-payment-intent'
  } else {
    alert(endpoint_name + " is not a valid endpoint");
  }

  var url = `${window.API_HOST}${endpoint}`;
  if (use_c === true) {
    url = `${window.CLICKSTREAM_HOST}${endpoint}`;
  }

  let headers = null
  if (method === "post") {
    headers = {
      "Content-Type": "application/json",
      "Clickstream-Session-Id": window.CLICKSTREAM_SESSION_ID,
      "Hngr-Token": window.HNGR_TOKEN,
      "App-Api": window.API_HOST,
      "App-Version": window.APP_VERSION,
      'X-Debug-Id': window.DEBUG_ID
    };
    fetch(url, { method: "post", headers: headers, body: JSON.stringify(data) })
      .then(res => {
        if (res.ok){
            res.json().then(json => success(json, extraArgs))
        } else{
            res.json().then(json => failure(json))
        }
      })  
      .catch(err => {
        console.log(err);
        if(failure){
          failure(err);
        }
      });
  }

  if (method === "get") {

    headers = {
      "App-Api": window.API_HOST,
      "Hngr-Token": window.HNGR_TOKEN,
      "Clickstream-Session-Id": window.CLICKSTREAM_SESSION_ID,
      'X-Debug-Id': window.DEBUG_ID
    }

    // support for gzip encoding of GET endpoints by adding a header
    // called Accept-Encoding, which tells the backend that it is
    // ok to speak gzip instead of raw text. this is very useful in
    // cases where we are transferring something large (aka a menu)
    // see: https://github.com/blpt-hngr/foodcoin/issues/615
    const browser = detect()
    if (browser) {
      if (browser.name === 'chrome' || browser.name === 'firefox' || 
          browser.name === 'ios' || browser.name === 'edge' ||
          browser.name === 'crios' || browser.name === 'safari' ||
          browser.name === 'edge-chromium' || browser.name === 'fxios' ||
          browser.name === 'ios-webview' || browser.name === 'samsung' ||
          browser.name === 'chromium-webview') {
        headers['Accept-Encoding'] = 'gzip'
      }
    }

    
    if (apiContent === 'blob'){
      fetch(url, { method: 'get', headers: headers })
        .then(res => {
          if (res.ok){
              res.blob().then(blob => success(blob, extraArgs))
          } else{
              res.blob().then(blob => failure(blob))
          }
        })  
        .catch(err => {
          console.log(err);
          if(failure){
            failure(err);
          }
        });
    } else {
    fetch(url, { headers: headers, method: "get" })
      .then(res => {
        if (res.ok){
            res.json().then(json => success(json, extraArgs))
        } else{
            res.json().then(json => failure(json))
        }
      })  
      .catch(err => {
        console.log(err);
        if(failure){
          failure(err);
        }
      });
    }
  }
};

module.exports = {
  callApi: callApi
};
