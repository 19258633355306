//takes 1 and returns monday.... 7 and returns sunday
var dayOfWeekToString = function(dow){
  if (dow === 1){
    return 'Monday'
  }
  if (dow === 2){
    return 'Tuesday'
  }
  if (dow === 3){
    return 'Wednesday'
  }
  if (dow === 4){
    return 'Thursday'
  }
  if (dow === 5){
    return 'Friday'
  }
  if (dow === 6){
    return 'Saturday'
  }
  if (dow === 7){
    return 'Sunday'
  }
}

// takes 14,20 and returns 2:00pm
var formatHoursAndMinutesToString = function(hour, minutes){
  var ampm = "am"
  if(hour >= 12){
    ampm = "pm"
    if(hour > 13){
      hour = hour-12
    }
  }

  //12:30am for example
  if(hour === 0){
    hour = 12
  }

  //zero pad the minutes
  if(minutes < 10){
      minutes = '0' + minutes
  }
  return `${hour}:${minutes}${ampm}`
}


//take a minutesAfterMidnight integer and convert it to "11:00pm"
var intToDisplayedTime = function(minutesAfterMidnight){
  var hour = Math.floor(minutesAfterMidnight/60)
  var minutes = minutesAfterMidnight - hour*60

  return formatHoursAndMinutesToString(hour, minutes)
}


//returns 1 for mon, 7 for sun
var currentDayOfWeek = function(){
  var d = new Date();
  var currentDOW =  d.getDay(); // sun = 0, mon = 1
  var currentAdjustedDay = currentDOW;
  if (currentAdjustedDay === 0) {
    return currentAdjustedDay = 7;
  }   
  return currentAdjustedDay;
}

//get the current time from javascript into minutes-after-midnight form
var currentMinutesAfterMidnight = function(){
  var d = new Date()
  var hours = d.getHours()
  var minutes = d.getMinutes()
  return hours * 60 + minutes
}

var todaysHoursFromSet = function(hours, isBefore4am){
  // assume hours has two days in it
  let today = false
  let tomorrow
  for(let i in hours){
    if(!today){
      today = hours[i]
    } else{
      tomorrow = hours[i]
    }
  }
  console.log("pooptpday", today)
  console.log("pooptmro", tomorrow)
  let hours_string = ''
  let sets = []
  for(let i in today){
    let set = today[i]
    if (set['open_at_min'] == 0 && !isBefore4am){
      continue
    }

    let ret = `${set['open_at_min']} - ${set['close_at_min']}`
    let start = intToDisplayedTime(set['open_at_min'])
    let end = intToDisplayedTime(set['close_at_min'])
    // check to see if open til tomorrow after midnight
    if(set['close_at_min'] == 1439){
      if(tomorrow && tomorrow.length){
        // 24/7 case
        if(tomorrow[0]['close_at_min'] == 1439){
          return []
        }
        end = intToDisplayedTime(tomorrow[0]['close_at_min'])
      }
    }
    sets.push(`${start}-${end}`)
  }
  return sets
}

module.exports = {
  currentDayOfWeek:currentDayOfWeek,
  intToDisplayedTime:intToDisplayedTime,
  currentMinutesAfterMidnight:currentMinutesAfterMidnight,
  dayOfWeekToString:dayOfWeekToString,
  todaysHoursFromSet:todaysHoursFromSet
}
