import React from 'react';
import { Elements } from 'react-stripe-elements';
import Checkout from './Checkout'

const CheckoutWrapper =props  => {
  return (
    <Elements>
      <Checkout match={props.match}/>
    </Elements>
  );
};

export default CheckoutWrapper;
