import React from "react";
import InputComponent from "../../sparkles/InputComponent/InputComponent";
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC";

import styles from "./MenuSearch.module.scss";

const MenuSearch = props => {
  const handleFocus = () => {
    props.analytics.debug("MenuItemSearchFocus");
  };

  const handleBlur = () => {
    props.analytics.info("MenuItemSearch", props.value);
  };

  return (
    <div className='MenuSearch'>
      <InputComponent
        value={props.value}
        className={styles.search}
        onChange={props.onSearchChange}
        placeholder={props.placeholder ? props.placeholder : "Search for an item..."}
        customStyle={props.customStyle}
        onfocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default AnalyticsContextHOC(MenuSearch);
