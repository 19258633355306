import React, { Component } from 'react'
import RestContextHOC from "./Contexts/RestContextHOC"

class Injection extends Component {

  constructor(props){
    super(props)
    this.state = {
      context: props.context,
      injections: []}

    this.htmlReplaceWithProps = this.htmlReplaceWithProps.bind(this)
    this.getInjections = this.getInjections.bind(this)
  }

  // injections can either be passed in directly as a prop
  // or get pulled from the local props, this is to support
  // the locations page type of injection
  // https://github.com/blpt-hngr/foodcoin/issues/3403
  getInjections() {
    let injections = []
    if (this.props.injections) {
      for (let i in this.props.injections) {
        let injection = atob(this.props.injections[i])
        injections.push(injection)
      }
    } else {
      injections = this.props.rest.getInjections(this.props.context)
    }
    return injections
  }

  componentDidMount() {
    this.setState({injections: this.getInjections()})
  }

  componentDidUpdate(props) {
    let injections = this.props.rest.getInjections(this.props.context)
    // dont love this approach but i really dont see much
    // else as on option to compare these things
    if (JSON.stringify(this.state.injections) !== 
        JSON.stringify(this.getInjections())) {
      this.setState({injections: this.getInjections()})
    }
  }

  // helper function which replaces any strings in the 
  // injected code. at the basic level we need to support the
  // {HASH_ID}, {ORDER_AT}, {ITEM_COUNT}, {TOTAL}
  htmlReplaceWithProps(html) {
    if (this.props.hash_id) {
      html = html.replace("{HASH_ID}", this.props.hash_id)
    }
    if (this.props.order_at) {
      html = html.replace("{ORDER_AT}", this.props.order_at)
    }
    if (this.props.total) {
      let total = this.props.total / 100.00
      html = html.replace("{TOTAL}", total)
    }

    return html
  }
  
  render() {
    let iCode = this.state.injections.map((inj) => {
      return <div dangerouslySetInnerHTML={{__html: 
        this.htmlReplaceWithProps(inj)}} />
    })
    return (
      <div>{iCode}</div>
    )
  }
}

export default RestContextHOC(Injection)
