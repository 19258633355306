import React from 'react';
import {GoogleMapsJSLoaderContext} from "./GoogleMapsJSLoaderContext";

export default function GoogleMapsJSLoaderContextHOC(Component){
          return React.forwardRef((props, ref) => (
            <GoogleMapsJSLoaderContext.Consumer>
                {
                    (context) => <Component ref={ref} GoogleMapsJSLoader={context} {...props} /> 
                }
            </GoogleMapsJSLoaderContext.Consumer>
          ))
}

