import React, { Component, useMemo, useState, useEffect} from "react"
import { WalletModalProvider, WalletMultiButton, WalletConnectButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import "./WalletButton.css"
import {
    WalletReadyState,
} from '@solana/wallet-adapter-base';


require('@solana/wallet-adapter-react-ui/styles.css');


const WalletButton = (props) => {

  const { name, readyState, wallet, connect, connecting, connected, publicKey } = useWallet();


  const content = useMemo(() => {
      if (connecting) return 'Connecting ...';
      if (connected) {
        return 'Connected';
      }
      if (wallet) return 'Connect Wallet';
      return 'Connect Wallet';
  }, [connecting, connected, wallet]);

  useEffect(() => {
    if(wallet && window.CLICKED_WALLET){
      connect().catch(() => {}); // Ignore this error because it'll be handled by the provider
    }
    if(wallet && window.CLICKED_WALLET_SIGNUP && props.signUp){ 
      connect().catch(() => {}); // Ignore this error because it'll be handled by the provider
    }
  }, [wallet]);

  
  useEffect(() => {
  }, [publicKey]);




  return (
    <WalletMultiButton>
      <>
        {publicKey && (
          <div className='wallet-button-address' style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
            <div>
              {publicKey.toString().substr(0,4)}
              ...
              {publicKey.toString().substr(publicKey.toString().length-4, publicKey.toString().length)}
            </div>
            <Dot style={{marginLeft:'6px'}}/>
          </div>
        )}
      </>
    </WalletMultiButton>
  )

}

export default WalletButton

const Dot = props => (<svg {...props} width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="5" cy="5.5" r="5" fill="#00CF46"/>
</svg>
)
