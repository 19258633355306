import React, { Component } from "react"
import CartContextHOC from "../../Contexts/CartContextHOC"
import UserContextHOC from "../../Contexts/UserContextHOC"
import RestContextHOC from "../../Contexts/RestContextHOC"
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC"
import styles from "./Coupon.module.scss"
import withErrorHandling from "../../Contexts/withErrorHandling/withErrorHandling"
import { withRouter } from "react-router-dom"

class CouponInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      keycode: this.props.cart.couponKeycode,
      addFlag: false,
      error: this.props.cart.couponFailReason,
      color: props.primary
        ? props.rest.hex_color_primary
        : props.rest.hex_color_secondary,
    }

    if (this.state.error) {
      this.showError()
      this.state.addFlag = true
    }

    this.updateKeycode = this.updateKeycode.bind(this)
    this.submit = this.submit.bind(this)
    this.removeCoupon = this.removeCoupon.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.cart.couponFailReason !== this.state.error &&
      this.state.error !== null
    ) {
      // if coupon has failed, we always want the addFlag open
      this.setState({ error: this.props.cart.couponFailReason, addFlag: true })
      if (this.props.cart.couponFailReason) {
        this.showError()
      } else {
        this.props.updateError(false)
      }
    }

    if (this.props.rest.hex_color_primary && !this.state.color) {
      this.setState({ color: this.props.rest.hex_color_primary })
    }
  }

  showError() {
    // free item coupon
    let couponItem = this.props.cart.couponItem
      ? this.props.cart.couponItem
      : this.props.cart.couponCartConditionItem

    let missingTxt
    let analyticsTxt
    if (this.props.cart.couponItem) {
      missingTxt = "Now Eligible For Item Below"
      analyticsTxt = "ConditionItem"
    } else {
      missingTxt = "Item is missing"
      analyticsTxt = "FreeItem"
    }

    if (couponItem) {
      // if we are on the checkout page
      if (this.props.checkoutPage) {
        let openItem = () => {
          this.props.analytics.debug(
            "OpeningCouponItemFromCheckout",
            analyticsTxt
          )
          let menu_item_id = couponItem.menu_item_id
          let item = this.props.rest.menu.menu_items[menu_item_id]
          let path = `/menu/${menu_item_id}`
          this.props.history.push({
            pathname: path,
            state: {
              item: item,
              modifier_id: couponItem.modifier_id,
              modifier_class_id: couponItem.modifier_class_id,
              isCoupon: true,
            },
          })
        }
        // free item need to add to cart
        let addItemButton = (
          <div style={{ textAlign: "center", width: "100%" }}>
            <div
              style={{
                fontSize: "14pt",
                textAlign: "center",
              }}
            >
              {missingTxt}
            </div>
            <button
              className="hngrButton"
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                backgroundColor: "#4284f4",
                fontSize: "12px",
              }}
              onClick={openItem}
            >
              {this.props.cart.couponFailReason}
            </button>
          </div>
        )
        this.props.updateError(
          true,
          null,
          true,
          null,
          null,
          addItemButton,
          () => {
            this.props.cart.clearCouponFail()
          }
        )

        // if we are in the cart
      } else {
        let openItem = () => {
          this.props.analytics.info("OpeningCouponItemFromCart", analyticsTxt)
          let menu_item_id = couponItem.menu_item_id
          let item = this.props.rest.menu.menu_items[menu_item_id]
          let path = `/menu/${menu_item_id}`
          // if we currently have an item open, replace the route, otherwise push it
          if (
            this.props.location.pathname.indexOf("category") +
              "category".length ===
              this.props.location.pathname.length ||
            this.props.location.pathname.indexOf("category") === -1
          ) {
            this.props.history.push({
              pathname: path,
              state: {
                item: item,
                modifier_id: couponItem.modifier_id,
                modifier_class_id: couponItem.modifier_class_id,
                isCoupon: true,
                closeCart: true,
              },
            })
          } else {
            this.props.history.replace({
              pathname: path,
              state: {
                item: item,
                modifier_id: couponItem.modifier_id,
                modifier_class_id: couponItem.modifier_class_id,
                isCoupon: true,
                closeCart: true,
              },
            })
          }
        }
        // free item need to add to cart
        let addItemButton = (
          <div>
            <div
              style={{
                fontSize: "14pt",
                textAlign: "center",
              }}
            >
              {missingTxt}
            </div>
            <button
              className="hngrButton"
              style={{
                padding: "4px",
                letterSpacing: "0px",
                fontWeight: "700",
                backgroundColor: "#4284f4",
                fontSize: "12px",
              }}
              onClick={openItem}
            >
              {this.props.cart.couponFailReason}
            </button>
          </div>
        )
        this.props.updateError(
          true,
          null,
          true,
          null,
          null,
          addItemButton,
          () => {
            this.props.cart.clearCouponFail()
          }
        )
        this.props.cart.setForceScrollBottom(true)
      }
    } else {
      //not a free item coupon
      this.props.updateError(
        true,
        this.props.cart.couponFailReason,
        true,
        null,
        null,
        null,
        () => {
          this.props.cart.clearCouponFail()
        }
      )
    }
  }

  removeCoupon() {
    this.props.cart.removeCoupon()
    this.setState({ keycode: "" })
  }

  submit() {
    this.setState({loading:true})
    this.props.analytics.info("CouponEntered", this.state.keycode)
    let dinerUUID = this.props.user.user ? this.props.user.user.uniq_uuid : null
    let triggerOpenItem = this.props.checkoutPage ? false : true
    this.props.cart.inputCoupon(
      this.state.keycode.trim(),
      ()=>this.setState({loading:false}),
      dinerUUID,
      false,
      triggerOpenItem
    )
    this.setState({ error: "" })
  }

  updateKeycode(e) {
    if (this.state.error) {
      this.props.updateError(false)
      this.props.cart.clearCouponFail()
      this.setState({ keycode: e.target.value, error: null }, () =>
        console.log(this.state.error)
      )
    } else {
      this.setState({ keycode: e.target.value })
    }
  }

  render() {
    if (this.props.rest.isLocked()) {
      return null
    }
    if (this.props.cart.coupon) {
      return null
      // <div className={styles.yourCode}>
      //   CODE: {this.props.cart.coupon.keycode}
      //   <img
      //     src="/remove.png"
      //     className={styles.removeButton}
      //     alt=""
      //     onClick={this.removeCoupon}
      //   />
      // </div>
    }
    if (this.state.addFlag) {
      let style = {}
      if (this.props.checkoutPage) {
        style = { backgroundColor: "white" }
      }
      return (
        <>
          {this.state.loading && (
            <div style={{textAlign:'center', marginTop:'10px', marginBottom:'5px'}}>
              <img src='https://cdn.hngr.co/crystal-spinner.gif' style={{width:'40px', height:'40px'}}/>
            </div>
          )}      
          
          <div className={styles.couponContainer}>
            <input
              className={styles.inputBody}
              style={style}
              placeholder="Enter code"
              id="couponInput"
              value={this.state.keycode}
              onChange={this.updateKeycode}
              onMouseDown={(e) => e.stopPropagation()}
            />
            <button
              onClick={this.submit}
              className={styles.submitButton}
              id="couponAddButton"
              style={{ background: this.state.color }}
            >
              ADD
            </button>
          </div>
        </>
      )
    }
    
    if(this.props.checkout){
      return (
        <div
          className={styles.addCoupon}
          style={{ color: this.state.color }}
          onClick={() => this.setState({ addFlag: true })}
        >
          add coupon
        </div>
      )
 
    }


    if (!this.state.addFlag) {
      return (
        <div
          className='coupon-tag-container'
          onClick={() => this.setState({ addFlag: true })}
        >
          <CouponTag/>&nbsp;&nbsp;<span>Add a coupon</span>
        </div>
      )
    }
  }
}


const CouponTag = props =>(
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M10.605 0h-10.605v10.609l13.391 13.391 10.609-10.604-13.395-13.396zm-4.191 6.414c-.781.781-2.046.781-2.829.001-.781-.783-.781-2.048 0-2.829.782-.782 2.048-.781 2.829-.001.782.782.781 2.047 0 2.829z"/></svg>
)


export default withRouter(
  AnalyticsContextHOC(
    withErrorHandling(
      RestContextHOC(UserContextHOC(CartContextHOC(CouponInput)))
    )
  )
)
