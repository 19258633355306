import React, { Component } from 'react';
import localforage from 'localforage';
const api = require("../helpers/api")

export const UserContext = React.createContext()

class UserProvider extends Component {

  constructor(props){
    super(props)
    this.state = {
      id: null,
      user: null,
      rest_id: null,
      hngr_token: null,
      loginUser: (user, tok) => this.loginUser(user, tok),
      signOut: () => this.signOut(),
      loadRestId: (callback) => this.loadRestId(callback),
      setRestId: (rest_id) => this.setRestId(rest_id),
      addVerifiedPhone: (p) => this.addVerifiedPhone(p),
      nullifyToken: () => this.nullifyToken(),
    }

    this.saveUser = this.saveUser.bind(this)
    this.saveToken = this.saveToken.bind(this)
    this.saveRestId = this.saveRestId.bind(this)
    this.setRestId = this.setRestId.bind(this)
    this.loadUser = this.loadUser.bind(this)
    this.loadToken = this.loadToken.bind(this)
    this.nullifyToken = this.nullifyToken.bind(this)

    this.loadUser()
    this.loadToken()
  }

  nullifyToken() {
    this.setState({hngr_token: null})
    window.HNGR_TOKEN = null
  }

  signOut(){
    this.saveUser(null)
    this.setState({user: null, hngr_token: null})
  }

  async loadUser() {
    let value = await localforage.getItem('user')
    if(value) {
      this.setState({user:value})
    }
  }

  async loadToken() {
    let value = await localforage.getItem('hngr_token')
    if (value) {
      window.HNGR_TOKEN = value
      this.setState({hngr_token: value})
    }
  }

  saveUser(user){
    localforage.setItem('user', user)
  }

  saveToken(hngr_token) {
    localforage.setItem('hngr_token', hngr_token)
  }

  saveRestId(rest_id) {
    localforage.setItem('user_rest_id', rest_id)
  }

  loadRestId(callback){
    var that = this
    localforage.getItem('user_rest_id').then(function (rest_id) {
      that.setState({rest_id:rest_id})
      callback(rest_id)
    }).catch(function(err) {
      console.error(err);
    })
  }

  async loginUser(user, hngr_token) {
    this.saveUser(user)
    this.saveToken(hngr_token)
    await this.setState({user: user, 
      hngr_token: hngr_token})
    window.HNGR_TOKEN = hngr_token
  }

  addVerifiedPhone(phone_e164) {
    let user = this.state.user
    user['phone_number_e164'] = phone_e164
    this.setState({user: user})
    this.saveUser(user)
  }

  setRestId(rest_id) {
    var that = this
    this.setState({rest_id: rest_id}, 
      that.saveRestId)
  }

  render() {
    return  (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export default UserProvider;
