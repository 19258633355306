import React from "react";

const NoColorSelected = ({ color }) => {
  const fillColor = color || "#CED4DA";

  return (
    <svg
      width="26px"
      height="16px"
      viewBox="0 0 26 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Welcome"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Desktop-HD" transform="translate(-104.000000, -802.000000)">
          <g id="card" transform="translate(104.000000, 802.000000)">
            <path
              d="M2.5,13.5 L5.5,13.5"
              id="Line-7"
              stroke={fillColor}
              strokeLinecap="square"
            />
            <path
              d="M8.5,13.5 L11.5,13.5"
              id="Line-7-Copy"
              stroke={fillColor}
              strokeLinecap="square"
            />
            <rect
              id="Rectangle"
              fill={fillColor}
              x="0"
              y="3"
              width="26"
              height="3"
            />
            <rect
              id="Rectangle"
              stroke={fillColor}
              x="0.5"
              y="0.5"
              width="25"
              height="15"
              rx="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NoColorSelected;
