import React from "react"
import styles from "./InputComponent.module.scss"
const InputComponent = (props) => {
  const {
    hasCustomInput,
    onChange,
    onBlur,
    placeholder,
    subheader,
    value,
    customStyle,
    onfocus,
    className,
    ...rest
  } = props
  if (hasCustomInput)
    return (
      <div className={styles.container}>
        <div className={styles.subheader}>{subheader}</div>
        <div className={styles.childInput}>{props.children}</div>
      </div>
    )

  return (
    <div
      className={styles.container}
      style={customStyle && customStyle.container}
    >
      {subheader && (
        <div
          className={styles.subheader}
          style={customStyle && customStyle.subheader}
        >
          {subheader}
        </div>
      )}
      <input
        // id="inputComponent"
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value ? value : ""}
        className={className ? `${styles.input} ${className}` : styles.input}
        style={customStyle && customStyle.input}
        onFocus={onfocus ? onfocus : undefined}
        {...rest}
      />
    </div>
  )
}

export default InputComponent
