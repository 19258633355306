import React from "react";
import "./HalfToppings.scss";
import { ReactComponent as LeftHalf } from "./LeftHalf.svg";
import { ReactComponent as LeftHalfShadow } from "./LeftHalfShadow.svg";
import { ReactComponent as Both } from "./Both.svg";
import { ReactComponent as BothShadow } from "./BothShadow.svg";
import { ReactComponent as RightHalf } from "./RightHalf.svg";
import { ReactComponent as RightHalfShadow } from "./RightHalfShadow.svg";

class HalfToppings extends React.Component {
  constructor(props) {
    super(props);
    console.log("half modifiers", this.props.editModifiers)
    this.state = {
      selected: "none" //selected is 'none', 'all', 'left' or 'right'
    };
    this.remove = this.remove.bind(this);
    this.left = this.left.bind(this);
    this.right = this.right.bind(this);
    this.all = this.all.bind(this);
  }

  componentDidMount(){
    if(this.props.editModifiers){
      for(var i in this.props.editModifiers){
        if(this.props.editModifiers[i].modifier_id === this.props.modifier.modifier_id){
          if(this.props.editModifiers[i].half_selection){
            if(this.props.editModifiers[i].half_selection === 'left'){
              this.left()
            } else{
              this.right()
            }            
          } else {
            this.all()
          }
        }
      }
    }
  }

  remove() {
    this.props.removeModifier(this.props.modifier);
    this.setState({ selected: "none" });
    this.props.selectNotHalf();
  }

  left() {
    if (this.state.selected === "left") {
      this.remove();
    } else {
      var valid = this.props.addModifier(this.props.modifier, "left");
      if (valid) {
        this.setState({ selected: "left" });
        this.props.selectHalf();
      }
    }
  }

  right() {
    if (this.state.selected === "right") {
      this.remove();
    } else {
      var valid = this.props.addModifier(this.props.modifier, "right");
      if (valid) {
        this.setState({ selected: "right" });
        this.props.selectHalf();
      }
    }
  }

  all() {
    if (this.state.selected === "all") {
      this.remove();
    } else {
      var valid = this.props.addModifier(this.props.modifier);
      if (valid) {
        this.props.selectNotHalf();
        this.setState({ selected: "all" });
      }
    }
  }

  render() {
    const LeftSelected = this.state.selected === "left";
    const BothSelected = this.state.selected === "all";
    const RightSelected = this.state.selected === "right";
    return (
      <div className="toppings__container">
        {/* <div className="toppings__button" onClick={this.remove}>
          <NoToppings />
        </div> */}
        <div
          className={
            LeftSelected ? "toppings__button selected" : "toppings__button"
          }
          onClick={this.left}
        >
          {LeftSelected ? <LeftHalfShadow /> : <LeftHalf />}
        </div>
        <div
          className={
            BothSelected ? "toppings__button selected" : "toppings__button"
          }
          onClick={this.all}
        >
          {BothSelected ? <BothShadow /> : <Both />}
        </div>

        <div
          className={
            RightSelected ? "toppings__button selected" : "toppings__button"
          }
          onClick={this.right}
        >
          {RightSelected ? <RightHalfShadow /> : <RightHalf />}
        </div>
      </div>
    );
  }
}

export default HalfToppings;
