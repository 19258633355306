import React from "react";
import CartContext from "../Contexts/CartContextHOC";
import RestContextHOC from "../Contexts/RestContextHOC";
import UserContextHOC from "../Contexts/UserContextHOC";
import AnalyticsContextHOC from '../Contexts/AnalyticsContextHOC'
import { withRouter } from 'react-router-dom';
import '../css/RecentOrder.css'

const Timestamp = require("../helpers/timestamps");

class RecentOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.order,
      orderTotal: (props.order.total / 100).toFixed(2)
    };
  }

  openTrackOrder(){
    this.props.analytics.info('OpenedOrderHistoryModal', this.state.order.hash_id)
    let url = `/orders/track/?hash_id=${this.state.order.hash_id}&diner_id=${this.props.user.user.uniq_uuid}`
    this.props.history.push({
      pathname: url,
      state:{status_or_receipt:'receipt'}
    });
  }

  formatOrderDate(){
    let dt = this.state.order.localtime_order_at_timestamp
    let dow = Timestamp.getDayOfWeek(dt)
    let mm = Timestamp.getMonthInt(dt);
    let dd = Timestamp.getDayOfMonth(dt);
    let yyyy = Timestamp.getFullYear(dt).toString().slice(2,4);
    return `${dow}, ${mm}/${dd}/${yyyy}`
  }

  formatOrderTime(){
    return Timestamp.getTimeOfDay(this.state.order.localtime_order_at_timestamp)
  }

  renderOrderTitle(){
    if(this.state.order.status_id === 4){
      return(
        <div className='recentOrderTitle'>
            <b>{this.formatOrderDate()}</b> : ORDER CANCELLED
        </div>
        )
    }
    else{
      return(
      <div className='recentOrderTitle'>
          <b>{this.formatOrderDate()}</b> : <span style={{textTransform:'capitalize'}}>{this.state.order.delivery_or_pickup}</span> at {this.formatOrderTime()}
      </div>
      )
    }
  }

  renderOrderBody(){
    if(this.state.order.status_id === 4){
      return(
        <div className='recentOrderBody'>
          Order #<span className='recentOrderHeavyText'>{this.state.order.hash_id}</span> - REFUNDED
        </div>
      )
    }
    else{
      return(
        <div className='recentOrderBody'>
          Order #<span className='recentOrderHeavyText'>{this.state.order.hash_id}</span> - ${this.state.orderTotal}
        </div>
      )
    }
  }

  render() {
    return (
      <span style={{textAlign:'center'}}>
        <div className='recentOrderContainer' onClick={()=>this.openTrackOrder()}>
          {this.renderOrderTitle()}
          <br/>
          {this.renderOrderBody()}
         
          
        </div>
        {!this.props.noBorder && <div className='recentOrderDivider'></div>}
      </span>
    );
  }
}

export default withRouter(AnalyticsContextHOC(RestContextHOC(UserContextHOC(CartContext(RecentOrder)))));
