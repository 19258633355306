import React from 'react';
import {CartContext} from "./CartContext";
import RestContextHOC from './RestContextHOC'

export default function CartContextHOC(Component){
  var C2 = RestContextHOC(Component)
  return React.forwardRef((props, ref) => (
    <CartContext.Consumer>
        {
            (context) => <C2 ref={ref} cart={context} {...props} /> 
        }
    </CartContext.Consumer>
  ))
}

