import React from 'react';
import RestContextHOC from '../Contexts/RestContextHOC';
import AnalyticsContextHOC from '../Contexts/AnalyticsContextHOC';
import RadioModifier from './RadioModifier';

class SelectionFormRadioButtons extends React.Component{

  constructor(props) {
    super(props)
    this.state = {
      selectedKey: null
    }
    this._onChange = this._onChange.bind(this);
  }

  // on change we need to set which key is selected and we also need to add the modifier to the cart
  // for radio buttons, there should only be one radio selected per modifier class, so we need to
  //
  _onChange(selectedKey, modifier, editModifier){
    this.props.analytics.info("RadioModifierSelect", modifier.name)
    this.setState({selectedKey: selectedKey})
    this.addModifier(modifier, editModifier)
  }

  // on radio change, we need to remove any other modifiere_ids
  // that are in this class. We also call displaySubModifiers() to display the
  // neccesssary child modifiers
  addModifier(modifier, editModifier){
    //when we select a radio modifier, we need to hide any sub-modifiers that may of been displayed as a result of
    //selecting an item in this category
    if (this.props.parentModifierClassesCart[modifier.modifier_class_id]['modifiers'].length){
      //don't attempt to remove children of string modifiers since they cant exist
      if(this.props.parentModifierClassesCart[modifier.modifier_class_id]['modifiers'][0].item){
        let dontUpdateState = !!modifier.item // let the state update if we've selected a string modifier
                                              // because displaySubModifiers wont update in that case
        this.props.removeModifierClasses(this.props.parentModifierClassesCart[modifier.modifier_class_id]['modifiers'][0], dontUpdateState)
      }
    }

    modifier['cart_modifier_classes'] = this.props.initializeCartModifierClasses(modifier.item)
    // since for a radio button, the modifier list can only have one element, just set this modifier
    // as the new list!

    if (this.props.couponModifierId){
      // check to see if the coupon modifier is going to be de-selected. If it is
      // then we need to set the state of MenuItemExpanded to tell it that the 
      // coupon is no bueno
      if (this.props.parentModifierClassesCart[modifier.modifier_class_id]['modifiers'].length 
          && this.props.parentModifierClassesCart[modifier.modifier_class_id]['modifiers'][0].modifier_id === this.props.couponModifierId)
        {
          this.props.analytics.info("RadioModifierCouponDeselect", "NotUsingCouponNow")
          this.props.invalidateCoupon()
      } 

      // if they deseleccted the coupon modifier and are now reseleccting it,
      // then revalidate the coupon
      if (modifier.modifier_id === this.props.couponModifierId){
          this.props.analytics.info("RadioModifierCouponReselect", "UsingCouponNow")
        this.props.revalidateCoupon()
      }

    }     
    this.props.parentModifierClassesCart[modifier.modifier_class_id]['modifiers'] = [modifier]
    this.props.displaySubModifiers(modifier, this.props.modifierClass, this.props.parentModifierClassesCart, editModifier)
  }


  render() {
    console.log("SelectionFormEditModifiers", this.props.editModifiers)
    return (
      <div>
        {this.props.modifiers.map(modifier => (
          <RadioModifier
            editModifiers={this.props.editModifiers}
            modifier={modifier}
            key={modifier.modifier_id}
            modifierId={modifier.modifier_id}
            couponModifierId={this.props.couponModifierId}
            selectedKey={this.state.selectedKey}
            onChange={this._onChange}/>
        ))}
      </div>
  );
  }
};


export default AnalyticsContextHOC(
  RestContextHOC(SelectionFormRadioButtons)
)
