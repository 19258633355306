import React from "react";
import Checkbox from "../sparkles/Checkbox/Checkbox.jsx";
import RestContextHOC from "../Contexts/RestContextHOC";

class CheckboxModifier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      selected: false,
      blackedOut:false
    };
    this.toggleHover = this.toggleHover.bind(this);
    this.click = this.click.bind(this);
  }

  //handle is_default here
  componentDidMount() {
    if(this.props.editModifiers){
      for(var i in this.props.editModifiers){
        if(this.props.editModifiers[i].modifier_id === this.props.modifier.modifier_id){
          this.click(null, this.props.editModifiers[i])
        }
      }         
    } else if (this.props.modifier.is_default) {
      this.click()
    }
    this.checkIfBlackedOut()
  }

  componentDidUpdate(prevProps){
    if(
      prevProps.rest.itemBlackouts.length !==
      this.props.rest.itemBlackouts.length
    ){
      this.checkIfBlackedOut()
    }
  }

  checkIfBlackedOut(){
    if(!this.props.modifier.item) return

    let menu_item_id = this.props.modifier.item.menu_item_id
    if(this.props.rest.itemBlackouts.indexOf(menu_item_id) > -1){
      this.setState({blackedOut:true})
    } else{
      console.log("nope", this.props.rest.itemBlackouts)
      this.setState({
        blackedOut:false
      })
    }
  }

  /* toggleHover :: toggles the HOVER state on the component
     so you can do things like change color, etc on hover */
  toggleHover() {
    if (window.innerWidth > 800){
      this.setState({ hover: !this.state.hover });
    }
  }

  // upon click check to see if it's valid (pretty much just checking if it'll put the user over the max
  // num selections ). If it is valid, then flip the checkbox state
  click(e, editModifier) {
    const is_valid_selection = this.props.onChange(
      this.props.modifier,
      !this.state.selected,
      editModifier
    );
    if (is_valid_selection) {
      this.setState({ selected: !this.state.selected });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ selectedKey: props.selectedKey });
  }


  renderPrice(){
    if(this.props.modifier.display_price){
      var string_price = this.props.modifier.display_price
      var int_price = parseFloat(string_price.substring(2), 10)
      

      if(int_price > 0){
        return(
          <div
          className="priceSmall"
          style={{
            ...styles.price,
            fontWeight: this.state.selected ? "600" : "400"
          }}
        >
          {this.props.modifier.display_price}
        </div>
          )
      }
    }

    
  }

  containerStyle(){
    let style
    if (this.state.hover){
      style = JSON.parse(JSON.stringify(styles.containerHover))
    } else {
      style = JSON.parse(JSON.stringify(styles.container))
    }
    if(this.state.blackedOut){
      style['pointerEvents'] = 'none'
      style['opacity'] = 0.5
    }
    return style
  }

  render() {
    return (
      <div
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        onClick={this.click}
        style={this.containerStyle()}
      >
        <div>
          <Checkbox
            fillColor={this.props.rest.hex_color_primary}
            checked={this.state.selected}
          />
        </div>
        <div style={styles.dishName}>{this.props.modifier.name}</div>
        {this.renderPrice()}
        <div />
      </div>
    );
  }
}

var styles = {
  container: {
    padding: "5px",
    display: "flex",
    alignItems: "center"
  },

  containerHover: {
    backgroundColor: "#eee",
    padding: "5px",
    display: "flex",
    alignItems: "center"
  },
  dishName: {
    paddingLeft: "8px",
    paddingRight: "8px"
  },
  price: {
    flexGrow: "1",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "12px",
    fontWeight: "400",
    color: "#22252B"
  }
};

export default RestContextHOC(CheckboxModifier);
