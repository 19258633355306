import React, { Component } from "react"
import GooglePlacesAutocomplete from "../GooglePlacesAutocomplete"
import withErrorHandling from "../Contexts/withErrorHandling/withErrorHandling"
import extractAddressComponents from "../helpers/googlePlaces"
import Loading from "../Loading/Loading"
import { BrandRestLocation } from "./components/BrandRestLocation"
import { IllinoisModal } from "./components/IllinoisModal"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import Injection from '../Injection'
import "./BrandPage.scss"
const Api = require("../helpers/api")

class BrandPage extends Component {
  constructor(props) {
    super(props)
    const fulldomain = window.location.host
    this.subdomain = fulldomain.split(".")[0].split("-")[0]
    this.state = {
      rests: [],
      isRestLoaded: false,
      addressObj: {},
      aloha:
        this.subdomain === "aloha" 
    }
    this.loadRests()
  }

  loadRests() {
    let params = {
      subdomain: this.subdomain,
    }
    if (this.state.addressObj.lat) {
      params["lat"] = this.state.addressObj.lat
      params["lng"] = this.state.addressObj.lng
    }
    Api.callApi(
      "brand_page_info",
      (data) => {
        this.setState({ brand: data.brand, isBrandLoaded: true }, () =>
          this.getRests()
        )
      },
      () => {},
      params,
      null,
      null
    )
  }

  getRests() {
    let params = {
      subdomain: this.subdomain,
    }
    if (this.state.addressObj.lat) {
      params["lat"] = this.state.addressObj.lat
      params["lng"] = this.state.addressObj.lng
    }
    Api.callApi(
      "brand_page_rests",
      (data) => {
        this.setState({ rests: data.rests, isRestLoaded: true }, () => {
          this.getHours()
        })
      },
      null,
      params,
      null,
      null
    )
  }

  getHours = () => {
    for (let i in this.state.rests) {
      const rest = this.state.rests[i]
      Api.callApi(
        "rest_hours",
        (data) => {
          rest["hours"] = data
          this.setState(({ rests }) => {
            return {
              rests: rests.map((currRest, j) => {
                return j === i ? rest : currRest
              }),
            }
          })
        },
        null,
        null,
        null,
        rest.subdomain
      )
    }
  }

  renderLocationButton() {
    const isMobileContent =
      window.innerWidth <= 600 ? (
        <img
          src="/curloc.png"
          alt="loc"
          className="buttonImg curLocImg brand-page__cur-loc"
        />
      ) : (
        "Use Current Location"
      )

    if (
      this.state.addressObj &&
      this.state.addressObj.hasOwnProperty("address1")
    ) {
      return (
        <button
          className="brand-page__current-location"
          onClick={this.clearAddress}
        >
          Clear
        </button>
      )
    }
    return (
      <button
        className="brand-page__current-location"
        onClick={() => this.currentLocation()}
      >
        {this.state.loading_gps ? "Loading" : isMobileContent}
      </button>
    )
  }

  clearAddress = () => {
    this.setState({ addressObj: {} })
  }

  currentLocationSuccess(position) {
    this.setState(
      {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        loading_gps: false,
      },
      () =>
        Api.callApi(
          "reverse_geocode",
          this.reverseGeocodeSuccess.bind(this),
          this.reverseGeocodeFail.bind(this),
          null,
          position.coords
        )
    )
  }

  currentLocationError() {
    this.props.updateError(
      true,
      "Oops! Looks like you have Location Services blocked for this browser. To use the Current Location feature, please enable Location Services in your device's settings.",
      true,
      { zIndex: "1000", position: "fixed", top: "0", width: "99vw" }
    )
    this.setState({ loading_gps: false })
  }

  currentLocation() {
    this.setState({ loading_gps: true })
    navigator.geolocation.getCurrentPosition(
      this.currentLocationSuccess.bind(this),
      this.currentLocationError.bind(this)
    )
  }

  reverseGeocodeSuccess(data) {
    var components = extractAddressComponents(data.address_components)
    if (!components["address1"]) {
      this.props.updateError(
        true,
        "Sorry, we can't find your address - please select one from the dropdown!",
        true,
        { zIndex: "1000", position: "fixed", top: "0", width: "99vw" }
      )
      return
    }
    this.props.updateError(false)
    this.refs.autocomplete.setState({
      formattedAddress: data.formatted_address,
    })
    let addressObj = {
      lat: this.state.lat,
      lng: this.state.lng,
      place_id: data.place_id,
      formattedAddress: data.formatted_address,
      ...components,
    }
    this.setState({ addressObj: addressObj, loading_gps: false }, () =>
      this.loadRests()
    )
    // check for a matched addresss IF the user is logged in
  }

  reverseGeocodeFail(data) {
    this.setState({ loading_gps: false, geocoded_address: "geocode fail" })
  }

  geocodeSelect(addressObj) {
    if (!addressObj["address1"]) {
      this.props.updateError(
        true,
        "Sorry, we can't find your address - please select one from the dropdown.",
        true,
        { zIndex: "1000", position: "fixed", top: "0", width: "99vw" }
      )
      this.clearAddress()
      return
    }
    this.props.updateError(false)
    this.setState({ addressObj: addressObj }, () => this.loadRests())
  }

  handlePluralize = (length, word, additionalEndings = "") => {
    if (length > 1) {
      return word + additionalEndings + "s"
    }
    return word
  }

  getColor(rest){
    if(!this.state.brand.hex_color_primary) return "#4284f4"
    else if(rest.override_brand_colors) {
      return rest.hex_color_primary
    } else{
      return this.state.brand.hex_color_primary
    }
  }

  renderBrandLogo(){
    if(!this.state.brand.logo_url) return null
    else if(this.state.brand.website_url){
      let url = this.state.brand.website_url
      if (url.indexOf('http') === -1){
        url = 'http://'+url
      }
      return (
        <a href={url} target='_blank'>
          <img
            src={this.state.brand.logo_url}
            alt="Restaurant logo"
            className="brand-page-header__logo"
          />
        </a>
      )
    } else {
      return (
        <img
          src={this.state.brand.logo_url}
          alt="Restaurant logo"
          className="brand-page-header__logo"
        />
      )
    }
  }

  render() {
    if (!this.state.isRestLoaded || !this.state.isBrandLoaded) {
      return (
        <div className="brand-page__loading">
          <Loading />
        </div>
      )
    }
    const isAddressFilledOut =
      this.state.addressObj && this.state.addressObj.hasOwnProperty("address1")
    const locationPlural = this.handlePluralize(
      this.state.rests.length,
      "location"
    )

    let headerStyle
    if (this.state.brand) {
      this.state.brand.color_bg_logo_url
        ? (headerStyle = {
            backgroundImage: `url(${this.state.brand.color_bg_logo_url})`,
            backgroundSize: "cover",
          })
        : (headerStyle = {
            backgroundColor: this.state.brand.hex_color_primary,
          })
    }
    const bg = this.state.brand.hex_color_primary
      ? this.state.brand.hex_color_primary
      : "#4284f4"
    return (
      <div style={{ "--bg-primary": bg }}>
        <div className="brand-page-header__container" style={headerStyle}>
          {this.state.aloha && (
            <IllinoisModal inIllinois={() => this.setState({ aloha: false })} />
          )}
          <div className="brand-page-header__container--limit">
            {this.renderBrandLogo()}
            <div className="brand-page-header__brand-name">
              {this.state.brand.name}
            </div>
            <div className="brand-page__auto-complete-container">
              <div className="brand-page__auto-complete-header">
                Pick Your Store
              </div>
              <div className="brand-page__address">
                {this.renderLocationButton()}
                <GooglePlacesAutocomplete
                  smallWidth={true}
                  ref="autocomplete"
                  address={this.state.addressObj}
                  updateError={() => this.props.updateError()}
                  formattedAddress={
                    this.state.addressObj
                      ? this.state.addressObj.formattedAddress
                      : ""
                  }
                  onSelect={this.geocodeSelect.bind(this)}
                  currentLocation={() => this.currentLocation()}
                  isLoading={this.state.loading_gps}
                  handleAddressBackspace={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="brand-page__results-container">
          {isAddressFilledOut && (
            <div>
              Result: {this.state.rests.length} {locationPlural} near{" "}
              {this.state.addressObj.address1}
            </div>
          )}
          <div className="brand-page__location-container">
            {this.state.rests.map((rest, i) => (
              <BrandRestLocation
                rest={rest}
                key={i}
                bg={this.getColor(rest)}
                addressObj={this.state.addressObj}
              />
            ))}
          </div>
        </div>
        <Injection injections={this.state.brand.injections} 
          context={'BRAND_LOCATIONS'} />
      </div>
    )
  }
}

export default AnalyticsContextHOC(withErrorHandling(BrandPage))
