import React from "react"
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC"
import CartContextHOC from "../../Contexts/CartContextHOC"
import Cart from "../../SharedComponents/cart"
import classnames from "classnames"

const formatPrice = require("../../helpers/formatPrice")

class CartItems extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      subtotal: 0,
      expanded: null
    }
    if(this.props.cartObj){
      this.cartObj = new Cart(this.props.cartObj)
    }
  }

  //remove an item from the cart
  removeItem(item) {
    this.props.analytics.info("CartItemRemoved", item.name)
    this.props.cart.removeItem(item)
    this.forceUpdate()
  }

  renderModifiers(item) {
    if(this.props.thankyou) return
    return item.childrenFlat().map((modifier, i) => {
      let indent = 2 + 8 * modifier.level + "px"
      return (
        <div
          key={i}
          className="CartItemModifier"
          style={{ paddingLeft: indent }}
        >
          - {modifier.displayName()}
        </div>
      )
    })
  }

  renderSpecialInstructions(item) {
    if(this.props.thankyou) return
    if (item.specialInstructions) {
      return (
        <div className="CartItemSpecialInstructions">
          - "{item.specialInstructions}"
        </div>
      )
    }
  }

  expandItem(id) {
    if(this.props.thankyou) return 
    this.setState({ expanded: this.state.expanded === id ? null : id })
  }

  renderItemQuantity(item) {
    if (item.quantity > 1)
      return (
        <span>
          <span style={{fontSize:'18px'}} className={classnames({'bold':this.props.thankyou})}>
            {item.quantity}
          </span>
          <span style={{ fontWeight: 500, verticalAlign: "5%" }}>
            &nbsp;
            {(!this.props.checkout && !this.props.thankyou) && (<span>x</span>)}
            &nbsp;
          </span>
        </span>
      )
  }

  //we want to render the item name and then the modifier names
  renderItem(item, key) {
    return (
      <div style={{marginTop:'8px'}}>
        <div 
          className={classnames("CartItemContainer", 
            {"CartItemContainer--thankyou":this.props.thankyou && !this.props.thankyouDesktop})}
          onClick={() => this.expandItem(key)}
          style={{cursor:'pointer'}}
        >
          <div className='checkout-cart-item-price-row'>
            <div className='checkout-cart-item-name-container'> {/* left side with name and modifiers */}
              <div 
                className={classnames("CartItemName-new",
                  {"CartItemContainer--thankyou":this.props.thankyou && !this.props.thankyouDesktop})}
                style={this.props.fontWeight  ? {fontWeight:600} : {} }
              >
                {this.renderItemQuantity(item)}
                {item.displayName()}
              </div>

              {this.renderModifiers(item)}
              {this.renderSpecialInstructions(item)}
            </div> {/*left side with name and modifiers */}

            {this.state.expanded !== item.cart_item_id && (!this.props.thankyou || this.props.thankyouDesktop) && (
              <div 
                className="CartItemPrice--checkout"
                style={this.props.checkout || this.props.thankyou ? {color:'black'} : null}
              >
                {item.priceWithModifiersFormatted()}
              </div>
            )}
          </div>

          {this.state.expanded === key && (
            <button
              className="CartRemoveItem"
              onClick={() => this.removeItem(item)}
            >
              REMOVE
            </button>
          )}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {/* for the thankyou page, we dont use the cart context, but rather supply a cart
            in the props */ }
        {this.props.thankyou ? (
          <>
            {this.cartObj.itemsQuantity.map((topLevelItem, i) => (
              <div key={i}>{this.renderItem(topLevelItem, i)}</div>
            ))}
          </>
        ) : (
          <>
            {this.props.cart.cart().itemsQuantity.map((topLevelItem, i) => (
              <div key={i}>{this.renderItem(topLevelItem, i)}</div>
            ))}
          </>
        )}
          {this.props.cart.finance.promo && this.props.cart.coupon && this.props.cart.coupon.coupon.action.indexOf('free_delivery') == -1  && !this.props.cart.coupon.is_nft_coupon && !this.props.thankyou ? (
          <div
            className="CartItemContainer"
            onClick={() => this.expandItem(this.props.cart.coupon.keycode)}
          >
            <div className="CartItemName">
              Coupon {this.props.cart.coupon.keycode}
            </div>
            {this.state.expanded !== this.props.cart.coupon.keycode && (
              <div className="CartItemPrice" style={{ color: "#ea4335" }}>
                - {formatPrice.formatPrice(this.props.cart.finance.free_delivery ?  this.props.cart.finance.promo_without_free_delivery : this.props.cart.getIncentiveAmount('coupon'))}
              </div>
            )}
            {this.state.expanded === this.props.cart.coupon.keycode && (
              <button
                className="CartRemoveItem"
                onClick={() => this.props.cart.removeCoupon()}
              >
                REMOVE
              </button>
            )}
          </div>
        ) : null}


        {this.props.cart.finance.promo && this.props.cart.coupon  && this.props.cart.coupon.is_nft_coupon && !this.props.thankyou ? (
          <div
            className="CartItemContainer"
          >
            <div className='nft-irazu-checkout-right-container'>
              {window.location.href.indexOf('irazu') > -1 ? (
                <>
                  <img src='https://cdn.hngr.co/irazu_cropped_mint.gif' />
                  <div style={{paddingLeft:'15px'}}>
                    <div className='nft-irazu-checkout-right-container-title'>
                      SUPPER CLUB X IRAZU NFT
                    </div>

                    <div className='nft-irazu-checkout-right-container-middle-txt'>
                      NFT Promo Applied
                    </div>
                    
                    <div className='nft-irazu-checkout-right-container-bottom-txt'>
                      -1 Free Shake
                    </div>
                  </div>
                </>
              ) : (
                 <>
                  <img src='https://cdn.hngr.co/fat-cropped.gif' />
                  <div style={{paddingLeft:'15px'}}>
                    <div className='nft-irazu-checkout-right-container-title'>
                      SUPPER CLUB X FATBURGER NFT
                    </div>

                    <div className='nft-irazu-checkout-right-container-middle-txt'>
                      NFT Promo Applied
                    </div>
                    
                    <div className='nft-irazu-checkout-right-container-bottom-txt'>
                      -1 Fatburger
                    </div>
                  </div>
                </>
              )}
            </div>
          
          </div>
        ) : null}






      </div>
    )
  }
}

export default AnalyticsContextHOC(CartContextHOC(CartItems))
