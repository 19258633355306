import React, { Component } from 'react'
import AnalyticsContextHOC from './AnalyticsContextHOC'
import { withRouter } from 'react-router-dom';
import withWallet from "../withWallet"
import localforage from "localforage";

export const SignupModalContext = React.createContext()

class SignupProvider extends Component {

  constructor(props){
    super(props)
    this.state = {
      currentModal: 'signup', // can be 'signup' or 'login'
      checkoutLink: false,
      sendTo: null,
      hngr_token: null,
      thirdPartyInfo:undefined,
      close: ()=>this.close(),
      closeAndPossiblyCheckout: (fromOauth) => {
        this.closeAndPossiblyCheckout(fromOauth)},
      openSignup:(sendTo, thirdPartyInfo) => {
        this.open('signup', sendTo, thirdPartyInfo)
      },
      openPhoneCollector: (send_to) => {
        this.open('phoneVerify', send_to)
      },
      update_collect_extra_signup_data:(yesno)=>{
        this.setState({collect_extra_signup_data:yesno})},
      collect_extra_signup_data:true,
      openLogin: (sendTo) => this.open('login', sendTo),
      openForgotPassword:(checkoutLink) => {
        this.open('forgotPassword', checkoutLink)},
      saveSignupComponentState: (state)=>this.saveSignupComponentState(state),
      loadSignupComponentState: ()=>this.loadSignupComponentState(), // $
      signupComponentState:null,

      prepareForOauthRedirect: (callback)=>this.prepareForOauthRedirect(callback),
      oauthRedirectUrl:null,
      redirectFromOauth: ()=>this.redirectFromOauth(),
      loadOauthRedirectUrl: ()=>this.loadOauthRedirectUrl(), // $
      loadState: ()=>this.loadState(), //$

      fromOauthRedirect:null,
      cacheBuster:null,
      oauthInfo:null,
      setOauthInfo:(info)=>this.setOauthInfo(info),
      onWalletConnected:(publicKey)=>this.onWalletConnected(publicKey),
    }
  }

  async onWalletConnected(publicKey){
    let msg = new TextEncoder().encode("Log me in with Solana")
    let signature = await  this.props.signMessage(msg)
    let signatureBase64 = Buffer.from(signature).toString('base64')
    console.log("signature is", signatureBase64)
    alert('wallet address is ' + publicKey.toString() + ' signature is ' + signatureBase64)
  }

  // set the oauth info then load up everything that we need
  // to get back to the proper login/signup page
  setOauthInfo(info){
    this.props.analytics.debug("SettingOauthInfo", JSON.stringify(info))
    const hngr_token = info.hngr_token
    this.setState({oauthInfo:info, 
      hngr_token: hngr_token}, 
      ()=>this.redirectFromOauth())
  }

  // do a horrible chain of async so that everything is done 
  // serially
  redirectFromOauth(){
    this.props.analytics.debug("RedirectingBackFromOauth")
    this.loadSignupComponentState(
      ()=>this.loadOauthRedirectUrl(
        ()=>this.loadState(
          ()=>{
            this.props.analytics.debug("SettingFromOauthRedirect", 
              this.state.oauthRedirectUrl)
            this.setState({
              fromOauthRedirect: true,
              cacheBuster: Math.random()
            }, () => {
              this.props.history.replace({
                pathname: this.state.oauthRedirectUrl
              })
            })
          }
        )
      )
    )
  }

  prepareForOauthRedirect(callback){
    this.saveCurrentState(()=>this.saveOauthRedirectUrl(callback))
  }

  saveCurrentState(callback){
    let state = {}
    state['currentModal'] = this.state.currentModal
    state['checkoutLink'] = this.state.checkoutLink
    state['collect_extra_signup_data'] = this.state.collect_extra_signup_data
    localforage
      .setItem('modalContextState', JSON.stringify(state)) 
      .then(function(value) {callback()})
      .catch(function(err) {
        console.error(err);
      });   
  }

  loadState(callback){
    let that = this
    localforage
      .getItem('modalContextState')
      .then(function(value) {
        that.setState(JSON.parse(value), callback)
      })
      .catch(function(err) {
        console.error(err);
      });
  }

  saveOauthRedirectUrl(callback) {
    let that = this
    let url = window.location.pathname
    this.props.analytics.debug("SavingOauthRedirectUrl", url)
    localforage
      .setItem('oauthRedirectUrl', url) 
      .then(function(value) {
        that.props.analytics.debug("SavedOauthRedirectUrl", value)
        callback();
      })
      .catch(function(err) {
        that.props.analytics.debug(
          "ErrorSavingOauthRedirectUrl",
          JSON.stringify(err)
        )
      });
  }

  loadOauthRedirectUrl(callback) {
    let that = this
    this.props.analytics.debug("AboutToLoadOauthRedirectUrl")
    localforage
      .getItem('oauthRedirectUrl')
      .then(function(value) {
        that.props.analytics.debug("LoadedOauthRedirectUrl", value)
        that.setState({oauthRedirectUrl:value}, callback)
      })
      .catch(function(err) {
        that.props.analytics.debug(
          "FailedLoadingOauthRedirectUrl",
          JSON.stringify(err)
        )
        console.error(err);
      });
  }

  saveSignupComponentState(state){
    localforage
      .setItem('signupComponentState', JSON.stringify(state))
      .then(function(value) {})
      .catch(function(err) {
        console.error(err);
      });
  }


  loadSignupComponentState(callback){
    let that = this
    localforage
      .getItem('signupComponentState')
      .then(function(value) {
        that.setState({signupComponentState:JSON.parse(value)}, callback)
      })
      .catch(function(err) {
        console.error(err);
      });
  }

  changeUrl(){

    // when routing to the phone verification
    if (this.state.currentModal == 'phoneVerify') {
      let suffix = '/phone_verify'
      if (this.state.sendTo) {
        const encPath = encodeURIComponent(this.state.sendTo)
        suffix = suffix + '?sendTo=' + encPath
      }
    }    

    // when routing to AUTH only
    let suffix = '/auth'
    if (window.location.href.indexOf(suffix) === -1){
      let url = this.props.location.pathname.split('/')
      let pathname = url[0] + '/' + url[1] + suffix

      if (this.state.sendTo) {
        const encPath = encodeURIComponent(this.state.sendTo)
        pathname = pathname + '?sendTo=' + encPath
      }

      this.props.history.push({
        pathname: pathname,
        state:{
          ...this.props.location.state
        }
      })
    }
  }

  open(page, sendTo, thirdPartyInfo){
    this.setState({
      currentModal: page,
      checkoutLink: sendTo,
      sendTo: sendTo,
      thirdPartyInfo: thirdPartyInfo,
      fromOauthRedirect: false
    }, ()=>this.changeUrl())
  }

  close() {
    this.clearContext()
    this.props.analytics.info("ClosingSignupModal")
    let newpath = this.props.history.location.pathname.replace("/auth", '')
    this.props.history.replace({pathname: newpath})
  }

  closeAndPossiblyCheckout(fromOauth){
    this.close()
  }

  clearContext(){
    let keys = [
      'modalContextState', 
      'oauthRedirectUrl', 
      'signupComponentState']
    keys.forEach(key => {
      localforage.removeItem(key)
    })

  }

  render() {
    return  (
      <SignupModalContext.Provider value={this.state}>
        {this.props.children}
      </SignupModalContext.Provider>
    )
  }
}

export default withWallet(AnalyticsContextHOC(withRouter(SignupProvider)))
