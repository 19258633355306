import * as Sentry from "@sentry/browser"

export default function extractAddressComponentsFromPlacesApiResult(address_components, 
  formattedAddress, placeId){

  var extracted = {'placeId': placeId}
  for(var i in address_components){
    if(address_components[i].types.includes("street_number")){
      extracted['street_number'] = address_components[i]['long_name']
    }   
  
    if(address_components[i].types.includes("route")){
      extracted['street'] = address_components[i]['long_name']
    }   

    if(address_components[i].types.includes("premise")){
      extracted['premise'] = address_components[i]['long_name']
    }   

    if(address_components[i].types.includes("locality")){
      extracted['city'] = address_components[i]['long_name']
    }   

    if(address_components[i].types.includes("administrative_area_level_1")){
      extracted['state'] = address_components[i]['long_name']
    }   
 
    if(address_components[i].types.includes("postal_code")){
      extracted['zipcode'] = address_components[i]['long_name']
    }   
  }   

  // check for city in sublocality, but only if city isnt already found
  if (!('city' in extracted)){
    for(var j in address_components){
      if(address_components[j].types.includes("sublocality")){
          extracted['city'] = address_components[j]['long_name']
        }   
      }   
  }

  // address1 can either be street + street_number or premise
  if (extracted['street'] && extracted['street_number']){
    extracted['address1'] = extracted['street_number'] + ' ' +extracted['street']
  } else if (extracted['premise']) {
    extracted['address1'] =  extracted['premise']
  }
 
  return extracted
}
