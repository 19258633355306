import React, { Component } from 'react'
import SignupModalContextHOC from '../Contexts/SignupModalContextHOC'
import '../css/LoginSignup.css'
import Login from './Login'
import PhoneVerifier from './PhoneVerifier'
import UserContextHOC from '../Contexts/UserContextHOC'
import AnonymousPasswordReset from './AnonymousPasswordReset'
import withErrorHandling from "../Contexts/withErrorHandling/withErrorHandling";

class Modal extends Component {

  constructor(props) {
    // capture any sendTo url params
    super(props)
    const params = new URLSearchParams(window.location.search)
    const sendTo = params.get('sendTo')
    this.state = {sendTo: sendTo}
  }

  render() {
    if (this.props.signupModal.currentModal === 'signup') {
      return <Login 
        title='Sign Up' sendTo={this.state.sendTo}
        updateError={this.props.updateError} />
    } else if (this.props.signupModal.currentModal === 'phoneVerify') {
      return <PhoneVerifier 
        sendTo={this.props.signupModal.sendTo}
        updateError={this.props.updateError} />
    } else if (this.props.signupModal.currentModal === 'login') {
      return <Login title='Login' 
        updateError={this.props.updateError} 
        sendTo={this.state.sendTo} />
    } else if (this.props.signupModal.currentModal === 'forgotPassword') {
        return <AnonymousPasswordReset />
    } else {
      this.props.updateError(
        true,
        "Modal Error"
      );
      return null
    }
  }

}

export default withErrorHandling(UserContextHOC(SignupModalContextHOC(Modal)));
