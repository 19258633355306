import React, { useState, useEffect, useRef } from "react"
import MenuItem from "../MenuItem/MenuItem"
import RestContextHOC from "../../Contexts/RestContextHOC"
import "../../css/Menu.css"
import classnames from "classnames"

const MenuCategoryScroll = (props) => {
  const [visibility, setVisibility] = useState('visible')
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    checkIfBlackedOut()
  }, [props.rest.categoryHidden, props.rest.categoryGreyedOut])

  useEffect(() => {
    if (props.isVisible) {
      props.updateCurrentCategory(props.category, props.index)
    }
  }, [props.isVisible])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  }, [])

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  const containerRef = useRef(null);
  const outerContainerRef = useRef(null);

  const checkIfBlackedOut = () => {
    const menu_category_id = props.category.menu_category_id
    if (props.rest.categoryHidden.indexOf(menu_category_id) > -1) {
      setVisibility('hidden')
    } else if (props.rest.categoryGreyedOut.indexOf(menu_category_id) > -1) {
      setVisibility('greyed_out')
    } else {
      setVisibility('visible')
    }
  }

  const setContainerStyle = () => {
    if (visibility === 'greyed_out') {
      return {opacity: "0.5",
        pointerEvents: "none"}
    } else if (visibility === 'hidden') {
      return {display:'none'}
    } else {
      return {}
    }
  }

  const filterFeaturedItems = (items) => {
    let featured_items = []
    // filter out any blacked/greyed out items
    for(var i in items){
      let menu_item_id = items[i].menu_item_id
      if (props.rest.itemsHidden.indexOf(menu_item_id) > -1) {
        continue
      } else if (props.rest.itemsGreyedOut.indexOf(menu_item_id) > -1) {
        continue
      } else if (!items[i].image_url){
        continue
      } else {
        featured_items.push(items[i])
      }
    }
    return featured_items.slice(0,4)
  }

  const renderFeaturedMobileCategory = (featured_items) => {
    let num_featured_items = featured_items.length
    let first_row_items = featured_items.slice(0,2)
    let second_row_items = featured_items.slice(2,4)
    let first_row = (
      <div
        ref={containerRef}
        className='menu-items__container-featured-mobile'
      >
        {first_row_items.map((item,idx) => (
          <MenuItem
            containerRef={containerRef}
            isFeatured={true}
            key={item.menu_item_id}
            item={item}
            setExpanded={props.setExpanded}
            menu={props.menu}
            fourthFromLast={idx === props.category.items.length -5}
            updateCurrentCategory={
              ()=>props.updateCurrentCategory(props.category, props.index)
            }
          />
        ))}
      </div>
    )
    let second_row = null
    if (second_row_items.length){
      second_row = (
      <div
        ref={containerRef}
        className='menu-items__container-featured-mobile'
      >
        {second_row_items.map((item,idx) => (
          <MenuItem
            containerRef={containerRef}
            isFeatured={true}
            key={item.menu_item_id}
            item={item}
            setExpanded={props.setExpanded}
            menu={props.menu}
            fourthFromLast={idx === props.category.items.length -5}
            updateCurrentCategory={
              ()=>props.updateCurrentCategory(props.category, props.index)
            }
          />
        ))}
      </div>
      )
    }
    return (
      <>
        {first_row}
        {second_row}
      </>
    )
  }

  let items = props.category.items
  if(props.category.featured_items){
    items = filterFeaturedItems(items)
  }

  return (
    <div style={setContainerStyle()} className='menu-category-container'> 
      <div
        ref={props.categoryTitle} 
        className="MenuCategoryHeader"
      >
        {props.category.name}
      </div>
      <div
        className={classnames("MenuCategoryUnderline",
          {"MenuCategoryUnderline-featured":props.category.featured_items})}
        style={{ background: props.rest.hex_color_secondary }}
      />
      <p 
        className={classnames("MenuCategoryDescription",
          {'MenuCategoryDescription-featured':props.category.featured_items})}
      >
        {props.category.description}
      </p>
      {props.category.featured_items  && width < 800 ? (
        renderFeaturedMobileCategory(items) 
      ) : (
        <div 
          ref={containerRef}
          className={classnames("menu-items__container",
            {'menu-items__container-featured':props.category.featured_items})}
        >
          {items.map((item,idx) => {
            return (
              <MenuItem
                containerRef={containerRef}
                isFeatured={props.category.featured_items}
                key={item.menu_item_id}
                item={item}
                setExpanded={props.setExpanded}
                menu={props.menu}
                fourthFromLast={idx === props.category.items.length -5}
                updateCurrentCategory={
                  ()=>props.updateCurrentCategory(props.category, props.index)
                }
              />
          )})}
        </div>

      )}
    </div>
  )
}

export default RestContextHOC(MenuCategoryScroll)
