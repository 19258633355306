import React from "react"
import { Address } from "../Address"
import "./BrandRestLocation.scss"

const BrandRestLocation = ({ rest, addressObj, bg }) => {
  const renderHours = (allHours) => {
    if (!allHours) return ""

    const hours = allHours.pickup
    let open = false
    if(allHours.pickup && allHours.pickup.open){
      open = true
    }

    if(allHours.delivery && allHours.delivery.open){
      open = true
    }

    if (open) {
      // If they are open 24 hours
      return (
        <div className="brand-rest-location__hours--open">Currently Open.</div>
      )
    }

    return (
      <div className="brand-rest-location__hours--closed">
        Currently Closed.
      </div>
    )
  }

  const formatPhone = (phone) => {
    phone = phone.replace(/-/g, "").replace("(", "").replace(")", "")
    phone =
      "(" +
      phone.slice(0, 3) +
      ") " +
      phone.slice(3, 6) +
      "-" +
      phone.slice(6, 15)
    return phone
  }

  const getUTMs = () => {
    const params = new URLSearchParams(document.location.search)
    const utm_medium = params.get('utm_medium')
    const utm_source = params.get('utm_source')
    const utm_campaign = params.get('utm_campaign')
    if (utm_medium || utm_source || utm_campaign) {
      return {utm_medium: utm_medium,
        utm_source: utm_source,
        utm_campaign: utm_campaign}
    }
    return null
  }

  const getDeliveryPath = () => {
    const subdomain = rest.subdomain
    let domain = process.env.REACT_APP_HNGR_DOMAIN
    let protocol = window.location.protocol + "//"
    let url = protocol + subdomain + "." + domain + "/?order_type=delivery"
    if (addressObj.place_id) {
      url += "&place_id=" + addressObj.place_id
    }
    if (getUTMs()) {
      const utms = getUTMs()
      if (utms['utm_medium']) {
        url += "&utm_medium=" + utms['utm_medium']
      }
      if (utms['utm_source']) {
        url += "&utm_source=" + utms['utm_source']
      }
      if (utms['utm_campaign']) {
        url += "&utm_campaign=" + utms['utm_campaign']
      }
    }
    return url
  }

  const getPickupPath = () => {
    const subdomain = rest.subdomain
    let domain = process.env.REACT_APP_HNGR_DOMAIN
    let protocol = window.location.protocol + "//"
    let url = protocol + subdomain + "." + domain + "/?order_type=pickup"
    if (getUTMs()) {
      const utms = getUTMs()
      if (utms['utm_medium']) { 
        url += "&utm_medium=" + utms['utm_medium']
      }
      if (utms['utm_source']) { 
        url += "&utm_source=" + utms['utm_source']
      }
      if (utms['utm_campaign']) { 
        url += "&utm_campaign=" + utms['utm_campaign']
      }
    }
    return url
  }
  const pickupPath = getPickupPath()
  const deliveryPath = getDeliveryPath()
  const restDistance =
    rest.distance > 0.05 ? Math.round(rest.distance * 10) / 10 : rest.distance
  return (
    <div className="brand-rest-location__container" key={rest.restaurant_id}>
      <div className="brand-rest-location__rest-name brand-rest-location__body-text ">
        {rest.secondary_name ? rest.secondary_name : rest.name}
        {rest.distance ? (
          <div className="brand-rest-location__distance">{restDistance} mi</div>
        ) : null}
      </div>
      {rest.name && (
        <div className="brand-rest-location__body-text">
          {rest.secondary_name && rest.name}
        </div>
      )}
      <div className="brand-rest-location__rest-info-container">
        <div className="brand-rest-location__rest-info">
          <Address rest={rest} />
          <a
            href={"tel:" + rest.phone_number}
            className="brand-rest-location__telephone brand-rest-location__body-text mt_1"
          >
            {rest.phone_number && formatPhone(rest.phone_number)}
          </a>
        </div>
        <div className="brand-rest-location__body-text brand-rest-location__hours">
          <div className="brand-rest-location__hours--test">Today's Hours</div>
          <div className="brand-rest-location__hours--test ">
            {renderHours(rest.hours)}
          </div>
        </div>
      </div>

      <div className="brand-rest-location__button-container u-mar-t_2">

        {rest.pickup && (
          <a 
            href={pickupPath} 
            style={{backgroundColor:bg}} 
            className="brand-rest-location__order-button"
          >
            Order Pickup
          </a> 
        )}

        {rest.delivery && (
          <a 
            href={deliveryPath} 
            style={{backgroundColor:bg}} 
            className="brand-rest-location__order-button"
          >
            Order Delivery
          </a>
        )}

      </div>
    </div>
  )
}

// const StyledButton = ({as='Button'.}) => {
//   const Component = as
//   return <Component>

//   </Component>
// }

export default BrandRestLocation
