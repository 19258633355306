import React from "react";
import { ReactComponent as ApplePayLogoOutline } from "./ApplePayLogoOutline.svg";

import styles from "../PaymentCheckout/PaymentCheckout.module.scss";

const SelectApplePay = ({ selectApplePay, selected }) => {
  return (
    <button
      className={selected ? styles.applePaySelected : styles.savedCard}
      onClick={selectApplePay}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <ApplePayLogoOutline style={{ marginRight: "16px" }} />
        <div>Apple Pay</div>
      </div>
    </button>
  );
};

export default SelectApplePay;
