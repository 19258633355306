import React from "react"
import classnames from "classnames"
import RestContextHOC from "../../Contexts/RestContextHOC"
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC"
import withErrorHandling from "../../Contexts/withErrorHandling/withErrorHandling"
import MenuItemImg from "../MenuItemImg/MenuItemImg"
import TempOut from "./TempOut"
import { InView } from 'react-intersection-observer';
import "../../css/MenuItem.css"
import { withRouter } from "react-router-dom"

class MenuItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imageError:false,
      expanded: false,
      visible: 'visible',
      descriptionOverflow: false,
    }

    this.description = React.createRef()
    window.g = this
  }

  componentDidMount() {
    this.checkIfVisible()
    this.checkOverflow()
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.rest.itemsHidden) !==
      JSON.stringify(this.props.rest.itemsHidden)
    ) {
      this.checkIfVisible()
    }
    
    if (
      JSON.stringify(prevProps.rest.itemsGreyedOut) !==
      JSON.stringify(this.props.rest.itemsGreyedOut)
    ) {
      this.checkIfVisible()
    }
  } 

  /*
  Checks to see if the description of an item overflows its boundaries
  */
  checkOverflow = () => {
    const PSUEDO_BUFFER = 16
    if (this.description) {
      if (
        this.description.scrollHeight >
        this.description.clientHeight + PSUEDO_BUFFER
      ) {
        this.setState({ descriptionOverflow: true })
      }
    }
  }

  checkIfVisible() {
    let menu_item_id = this.props.item.menu_item_id
    if (this.props.rest.itemsHidden.indexOf(menu_item_id) > -1) {
      this.setState({visible: 'hidden'})
    } else if (this.props.rest.itemsGreyedOut.indexOf(menu_item_id) > -1) {
      this.setState({visible: 'greyed_out'}) 
    } else {
      this.setState({visible: 'visible'})
    }
  }

  calculateMinimum() {
    let modifier_classes = this.props.menu.menu_items[
      this.props.item.menu_item_id
    ].modifier_classes
    let total = 0
    let BIGMAX = 99999

    modifier_classes.forEach((mc) => {
      if (mc.minimum_num_selections > 0) {
        let min = BIGMAX
        mc.modifiers.forEach((mod) => {
          let modifier = this.props.menu.menu_items[mod.menu_item_id]
          // string modifiers will evaulate false here
          if (modifier) {
            min = Math.min(min, modifier.price)
          }
        })
        if (min !== BIGMAX) {
          total += min
        }
      }
    })
    return total
  }

  expandItem() {
    if (this.state.visible !== 'visible') {
      return
    }
    //update the parent component so we know the item is active
    this.props.setExpanded(true)
    this.props.history.push({
      pathname: `/menu/${this.props.item.menu_item_id}`,
      state: {
        item: this.props.item,
        expanded: this.state.expanded,
        previousUrl: this.props.location.pathname,
        itemModal:true
      },
    })
  
    if(this.props.isFeatured){
      this.props.analytics.info("ClickedFeaturedMenuItem", this.props.item.name)
    } else {
      this.props.analytics.info("ClickedMenuItem", this.props.item.name)
    }
  }

  getItemPrice() {
    if (this.props.item.price > 0) {
      return `${(this.props.item.price / 100).toFixed(2)}`
    } else {
      return `${(this.calculateMinimum() / 100).toFixed(2)}`
    }
  }

  featuredImgStyle(){
    if(!this.props.containerRef.current) return {}

    if (window.innerWidth < 800) {
      let parentWidth = this.props.containerRef.current.offsetWidth
      let bodyPaddingRight = window.getComputedStyle(document.body, null).getPropertyValue('padding-right')
      if(bodyPaddingRight){
        parentWidth += parseInt(bodyPaddingRight) + 4
      }
      let width = Math.floor(parentWidth/2-18)
      let height = 0.75 * width
      return {height:height+'px', width:width+'px'}
    } else {
      let width = Math.floor(this.props.containerRef.current.offsetWidth/4-16)
      let height = 0.75 * width
      return {height:height+'px', width:width+'px'}
    }
  
  }

  featuredItemWrapperStyle(){
    if(!this.props.containerRef.current) return {}
    let style ={...this.props.style}
    style.border = `1px solid ${this.props.rest.hex_color_secondary}`
    let width
    if (window.innerWidth < 800){
      // some bullpoop from the react-scroll lock component we use in the modal
      let parentWidth = this.props.containerRef.current.offsetWidth
      let bodyPaddingRight = window.getComputedStyle(document.body, null).getPropertyValue('padding-right')
      if(bodyPaddingRight){
        parentWidth += parseInt(bodyPaddingRight) + 4
      }
      width = Math.floor(parentWidth/2-18)
    } else {
      width = Math.floor(this.props.containerRef.current.offsetWidth/4-16)
    }
    style.width = width + 'px'
    return style
  }


  showImage(){
    return this.props.item.image_url && !this.state.imageError
  }

  render() {
    let blackedOutClass = ""
    if (this.state.visible === 'greyed_out') {
      blackedOutClass = "BlackedOut"
    } else if (this.state.visible === 'hidden'){
      blackedOutClass = 'HiddenItem'
    }
    if(this.props.isFeatured){
      return (
         <div
          className={`menu-item-wrapper-top-img ${blackedOutClass}`}
          style={{border:'1px solid ' + this.props.rest.hex_color_secondary}}
          onClick={() => this.expandItem()}
        >
          <div className="MenuItem">
            {this.showImage() && (
              <MenuItemImg
                onError={()=>this.setState({imageError:true})}
                className="MenuItemImgTop"
                image_url={this.props.item.image_url}
                alt={this.props.item.name}
              />
            )}
            <div className="menu-item__header__container">
              <div className="MenuItemHeader">
                {this.props.item.name}
              </div> 
            </div>
            <div className="MenuItemDescription">
              {this.props.item.description}
            </div>
            <div className="MenuItemPrice">
              {this.getItemPrice()}
            </div>
          </div>
        </div>
      )
    } else if(this.props.menu.display_menu_images === 'top' || window.KIOSK_MODE){
      return (
        <div
          className={`menu-item-wrapper-top-img ${blackedOutClass}`}
          style={{ ...this.props.style }}
          onClick={() => this.expandItem()}
        >
          <div className="MenuItem ">
            {this.showImage() && (
              <MenuItemImg
                onError={()=>this.setState({imageError:true})}
                className="MenuItemImgTop"
                image_url={this.props.item.image_url}
                alt={this.props.item.name}
              />
            )}
            <div className="menu-item__header__container">
              <div ref="name" className="MenuItemHeader">
                {this.props.item.name}
              </div>
              {this.state.blackedOut && <TempOut />} 
            </div>
            <div className="MenuItemDescription">
              {this.props.item.description}
            </div>
            <div id="itemPrice" className="MenuItemPrice">
              {this.getItemPrice()}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={`menu-item-wrapper menu-item-wrapper--side-image ${blackedOutClass}`}
          style={{ ...this.props.style }}
          onClick={() => this.expandItem()}
        >
          <div className={classnames("MenuItem MenuItemWithImg", {})}>
            <div
              className={classnames("menu-item__text-container", {
                "menu-item-description--overflow--with-image":
                  this.showImage()  &&
                  this.state.descriptionOverflow,
                "menu-item-description--overflow": this.state
                  .descriptionOverflow,
              })}
            >
              <div
                  className={classnames("menu-item__header__container", {
                    mr_4: !this.showImage()
                  })}
                >
                  {this.props.fourthFromLast ? (
                    <InView
                      as="div"
                      onChange={(visible)=>{
                        if(visible) this.props.updateCurrentCategory() 
                      }}
                      className="MenuItemHeader"
                    >
                      {this.props.item.name}
                    </InView>
                  ) : (
                    <div className="MenuItemHeader">
                      {this.props.item.name}
                    </div>
                  )}
                  {this.state.visible === 'greyed_out' && <TempOut />}
                </div>
                <div
                  className="MenuItemDescription menu-item-description--with-image"
                  ref={(e) => (this.description = e)}
                  dangerouslySetInnerHTML={{__html:this.props.item.description}}
                />
            </div>
            <div>
              {this.showImage() && (
                <MenuItemImg
                  onError={()=>this.setState({imageError:true})}
                  className="MenuItemImgSide"
                  image_url={this.props.item.image_url}
                  alt={this.props.item.name}
                />
              )}
              <div
                id="itemPrice"
                className={classnames("menu-item-price--side-img", {
                  [this.showImage()]:
                    "menu-item-price--side-img--with-image",
                })}
              >
                {this.getItemPrice()}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(
  AnalyticsContextHOC(RestContextHOC(withErrorHandling(MenuItem)))
)
