import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import "./header.scss"
const sizes = {
  xs: "xs",
  s: "s",
  subheader: "subheader",
  m: "m",
  l: "l",
  xl: "xl",
}

const alignments = {
  left: "left",
  center: "center",
  right: "right",
}

const weights = {
  bold: "bold",
  extrabold: "extrabold",
  black: "black",
}

const Header = ({ children, size, className, weight, options, ...rest }) => {
  return (
    <div
      className={classnames(`header-text header-text--${sizes[size]}`, {
        [`header-text--${weights[weight]}`]: weight,
        [`text--${options.color}`]: options.color,
        [`text--${alignments[options.align]}`]: options.align,
        [className]: className,
      })}
      style={{
        fontSize: options.fontSize,
      }}
      {...rest}
    >
      {children}
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizes)),
  options: PropTypes.shape({}),
}

Header.defaultProps = {
  title: "",
  size: sizes.m,
  weight: "bold",
  options: {
    align: alignments.left,
  },
}

export default Header
