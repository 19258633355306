import React, { Component } from 'react';
import GoogleMapsJSLoaderContextHOC from "./Contexts/GoogleMapsJSLoaderContextHOC";
import AnalyticsContextHOC from "./Contexts/AnalyticsContextHOC";
import RestContextHOC from "./Contexts/RestContextHOC";
import classnames from "classnames"
import extractAddressComponents from "./helpers/googlePlaces"
import { ReactComponent as Close } from "./assets/icons/modal-close.svg"
import { ReactComponent as CurLoc } from "./assets/icons/cur-loc.svg"
import './css/GooglePlacesSearch.css'
import "./css/GooglePlacesAutocomplete.css"

const api = require("./helpers/api")
const Subdomain = require("./helpers/subdomain")
const detectiOS = require("./helpers/detectiOS")

class PlacesSearchBox extends Component {

  constructor(props){
    super(props)
    this.state = {
      google_maps_loaded:false,
      input:this.props.initialAddressObj ? 
        this.props.initialAddressObj.formattedAddress : '',
      suggestions:[],
      currentSuggestionIndex:0,
      addressObj:this.props.initialAddressObj,
      error:this.props.error,
      verifiedAddress:false,
    }
    this.wrapperRef = React.createRef()
    this.onInput = this.onInput.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.handleArrowKeys = this.handleArrowKeys.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleFocusOut = this.handleFocusOut.bind(this)
    this.currentLocation = this.currentLocation.bind(this)
    this.onBlur = this.onBlur.bind(this)
    window.g = this
  }

  componentDidMount(){
    if(this.props.GoogleMapsJSLoader.google_maps_loaded){
      this.setState({google_maps_loaded:true}, ()=>this.initAutocomplete())
    }
    window.addEventListener("keydown", this.handleArrowKeys)
    document.addEventListener('mousedown', this.handleClickOutside);
    // this is the "done" button on the iOS keycoard
    if(detectiOS.detectiOS()){
      document.addEventListener('focusout', this.handleFocusOut);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleArrowKeys, false)
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    // this is the "done" button on the iOS keycoard
    if(detectiOS.detectiOS()){
      document.removeEventListener('focusout', this.handleFocusOut, false);
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(
      this.props.GoogleMapsJSLoader.google_maps_loaded &&
      !this.state.google_maps_loaded
    ){
      this.setState({google_maps_loaded:true}, ()=>this.initAutocomplete())
    }

    if(
      JSON.stringify(this.state.addressObj) !==
      JSON.stringify(prevState.addressObj)
    ){
      if(this.props.onAddressUpdate){
        this.props.onAddressUpdate(this.state.addressObj)
      }
    }

    if(this.state.verifiedAddress !== prevState.verifiedAddress){
      if (this.props.onVerifiedAddressUpdate){
        this.props.onVerifiedAddressUpdate(this.state.verifiedAddress)
      }
    }
  }


  // on blur from the input, we need to detect if the user selected
  // an address or not
  onBlur(e){
    // we don't want to run any logic while place api calls are being made
    if(this.place_api_calls_in_progress){
      setTimeout(()=>this.onBlur(), 50)
      return
    }
    // we are in an error state if the input has changed AND either:
    // - addressObj is null
    // - addressObj is the same as it was when the input was focused
    if(this.state.input !== this.onFocusInput){
      if(!this.state.addressObj){
        //alert('err no addres obj')
      }
      else if(JSON.stringify(this.state.addressObj) === this.onFocusAddressObjJSON){
        //alert('err no change')
      }
    } 
  }

  handleFocusOut(e){
    setTimeout(()=>{
      this.setState({suggestions:[]})
      if(
        !this.state.addressObj && 
        !this.place_api_calls_in_progress &&
        this.state.input
      ){
        this.setState({error:'Please select an address from the dropdown options'})
      }
    }, 350)
  }

  // Navigate through the drop down with arrow keys. The inndex starts
  // at -1 and pressing up gets it to 0.
  // Note that hovering into an item will set the current index
  handleArrowKeys(e) {
    if(!this.state.suggestions.length) return
    if(e.key === 'ArrowDown'){
      let newIndex = this.state.currentSuggestionIndex + 1
      newIndex = newIndex % this.state.suggestions.length
      this.setState({currentSuggestionIndex:newIndex})
      e.preventDefault()
    }

    if(e.key === 'ArrowUp'){
      let newIndex = this.state.currentSuggestionIndex - 1
      if(newIndex < 0) newIndex = this.state.suggestions.length + newIndex
      this.setState({currentSuggestionIndex:newIndex})
      e.preventDefault()
    }

    if(e.key === 'Enter'){
      this.selectPlace(
        this.state.suggestions[this.state.currentSuggestionIndex]) 
        window.document.activeElement.blur();
    }
  }

  errorOnNotSelecting(){
    if(this.focused && this.state.input && !this.state.addressObj){
      this.setState({error:'Please select an address from the dropdown options'})
    }
  }


  handleClickOutside(event) {
    if (
      this.wrapperRef && 
      this.wrapperRef.current && 
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.errorOnNotSelecting()
      this.setState({
        suggestions:[],
        currentSuggestionIndex:0
      })
    } 
  }

  initAutocomplete() {
    if(!this.state.google_maps_loaded) return
    this.service = new window.google.maps.places.AutocompleteService();
  }

  boundBias() {
    if (!this.props.rest) return false
    if (!this.props.rest.deliveryBoundingBox) return false
    if (!this.props.rest.deliveryBoundingBox.sw) return false

    let box = this.props.rest.deliveryBoundingBox
    var bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(box.sw.lat, box.sw.lng),
      new window.google.maps.LatLng(box.ne.lat, box.ne.lng)
    )   
    return bounds
  }

  currentLocation() {
    this.props.analytics.info("CheckerPageFindingLocation")
    if(this.props.currentLocationStart){
      this.props.currentLocationStart()
    }
    navigator.geolocation.getCurrentPosition(
      (position)=>{
        if(this.props.currentLocationEnd){
          this.props.currentLocationEnd()
        }
        this.setState({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        },  () =>
          api.callApi(
            "reverse_geocode",
            (data)=>{
              var components = extractAddressComponents(data.address_components)
              if (!components["address1"]) {
                this.props.analytics.info("CantFindGPSAddress")
                this.setState({
                  lat:null,
                  lng:null,
                  error:'Unable to find your address'
                })
                return
              }
              this.props.analytics.debug("GPSSuccess", components["address1"])
              let addressObj = {
                lat: this.state.lat,
                lng: this.state.lng,
                formattedAddress: data.formatted_address,
                formatted_address: data.formatted_address,
                ...components,
              }

              if(this.props.selectPlace){
                this.props.selectPlace(addressObj)
              }
              this.setState({ 
                addressObj: addressObj,
                input:data.formatted_address
              }, ()=>this.verifyDeliveryZone())
            },
            ()=>{
              this.setState({
                lat:null,
                lng:null,
                error:'Unable to find your address'
              })
            },
            null,
            position.coords
          )
        )
      },
      (err)=>{
        if(this.props.currentLocationEnd){
          this.props.currentLocationEnd()
        }
        this.props.analytics.info("CheckerPageGPSNoPermission")
        this.setState({
          lat:null,
          lng:null,
          error:'Location services are disabled for this browser. Please type in your address instead'
        })
      }
    )
  }

  verifyDeliveryZone(){
    if(this.props.page === 'locations-page') return
    let {
      city,
      state,
      zipcode,
      address1,
      lat,
      lng,
      formatted_address
    } = this.state.addressObj
    this.props.analytics.info("VerifyDeliveryZone", 
      JSON.stringify(this.state.addressObj))

    var payload = { 
      fulfill_order_at: this.props.futureOrderTime, 
      lat: lat, 
      lng: lng,
      address1: address1,
      city: city, 
      state: state, 
      zipcode: zipcode,
      formatted_address: formatted_address, 
      subdomain: Subdomain.getSubdomain()
    }   

    // dont try to verify if ASAP is closed and the diner hasn't 
    // selected a futureOrderTime yet
    if(
      this.props.rest.hours.delivery.open ||
      this.props.futureOrderTime
    ){
      api.callApi( 
        "delivery_zone_verify",
        (data)=>{
          if (data.success === 1) { 
            this.props.analytics.info("VerifiedDeliveryZone", data.message)
            this.setState({verifiedAddress:true})
          } else {
            this.setState({error:data.message})
            this.setState({verifiedAddress:false})
            this.props.analytics.info("AddressOutsideZone",
              data.message + data.formatted_address)
          }
        },
        (err)=>{
          this.setState({verifiedAddress:false})
          let error = 'Unknown error validating delivery address'
          this.setState({error:error})
        },
        payload
     )   
    }
  }

  selectPlace(place){
    this.props.analytics.info("AutocompleteSelectedPlace",
      JSON.stringify(place))
    this.place_api_calls_in_progress = true
    this.setState({suggestions:[], currentSuggestionIndex:0})
    let that = this
    let place_id = place.place_id
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ placeId: place.place_id }, (results, status) => {
      if(status !== 'OK'){
      this.props.analytics.info("AutocompleteGeocodeFasil", status)
        this.setState({error:'Unknown error'})
        return
      }
      this.props.analytics.info("AutocompleteGeocode",
        JSON.stringify(results))
      let address_components = results[0].address_components
      let formatted_address = results[0].formatted_address
      let lat = results[0].geometry.location.lat() 
      let lng = results[0].geometry.location.lng()
      
      address_components = extractAddressComponents(
        address_components,
        formatted_address,
        place_id
      )

      let addressObj = {
        place_id:place_id,
        formattedAddress:formatted_address,
        formatted_address: formatted_address,
        lat:lat,
        lng:lng,
        ...address_components
      }

      if(this.props.allowAnyPlace !== true){
        if (!address_components.address1){
          this.props.analytics.info("NoAddress1", JSON.stringify(addressObj))
          this.setState({
            error:"We can't find this address. Please choose a different address from the dropdown",
            input:formatted_address
          })
          return
        }
      }
        
      this.props.analytics.sendHam(
        "CHECKERPAGE_ADDRESS",
        {'physical_address': addressObj.formattedAddress}
      )   
      
      that.setState({
        error:null,
        addressObj:addressObj,
        input:formatted_address
      }, ()=>{
        this.place_api_calls_in_progress = false
        if(this.props.selectPlace){
          this.props.selectPlace(addressObj)
        }
        this.verifyDeliveryZone()
      })
    })
  }

  onInput(e){
    this.setState({
      input:e.target.value,
      addressObj:null,
      error:null
    })

    if(e.target.value === ''){
      this.setState({suggestions:[]})
      return
    }

    if(!this.service) return
    if(e.target.value && e.target.value.length < 2) return
    if(e.target.value && e.target.value.length % 2 === 1) return

    let params = {
      input:e.target.value,
      types:['address']
    }
    if(this.boundBias()){
      params['bounds'] = this.boundBias()
    }
    this.service.getPlacePredictions(params, 
      (results)=>this.renderResults(results))
  }

  onFocus(){
    this.onFocusInput = this.state.input
    this.focused = true
    if(this.state.input && !this.state.addressObj){
      let params = {
        input:this.state.input,
        types:['address']
      }
      this.service.getPlacePredictions(params, 
      (results)=>this.renderResults(results))
    }
  }

  renderResults(results){
    if(!results) results = []
    results = results.slice(0,4)
    this.setState({suggestions:results})
    this.props.onShowSuggestions()
  }

  getAutocompleteWrapperStyle(){
    if(this.props.page === 'locations-page'){
      if(this.state.suggestions){
        let px = this.state.suggestions.length * 50
        let style = {minHeight:px+'px'}
        return style
      }
    }
  }

  render() {
    return (
      <div className="places-autocomplet-container" ref={this.wrapperRef}>
        <div className="places-autocomplete-wrapper" style={this.getAutocompleteWrapperStyle()}>
          {this.state.error && (
            <div className='places-autocomplete-error'>
              {this.state.error} 
            </div>
          )}
          <input
            autocomplete="off"
            className={classnames('places-input', 
              {'places-input-error':this.state.error})}
            value={this.state.input}
            onChange={this.onInput}
            placeholder="Enter street address...."
            type="search"
            name="nameSearch"
            style={this.props.style}
            onFocus={this.onFocus}
            onBlur={()=>setTimeout(()=>this.focused = false, 500)}
          />
          {(this.state.addressObj || this.state.error) ? (
            <>
              {this.props.closeIcon ? (
               <div 
                className='autocomplete-curloc'
                onClick={()=>{
                  this.setState({input:'', addressObj:null})
                  if(this.props.onClear) this.props.onClear()
                }}
              >
                  {this.props.closeIcon}   
                </div>
              ) : (
               <div 
                  className='autocomplete-curloc'
                  style={{backgroundColor:'#A4A4A4'}}
                  onClick={()=>{
                    this.setState({input:'', addressObj:null})
                  }}
                >
                  <Close fill='white'/>
                </div>
              )}
            </>
          ) : (
            <div 
              className='autocomplete-curloc'
              onClick={this.currentLocation}
            >
              <CurLoc/>
            </div>
          )}
          <div className="autocomplete-dropdown-container">
            {this.state.suggestions.length > 0 &&
              this.state.suggestions.map((suggestion, index) => {
                return (
                  <div className='places-container'>
                  <div
                    key={suggestion.description +'_' + index}
                    onClick={()=>this.selectPlace(suggestion)}
                    onMouseOver={()=>
                      this.setState({currentSuggestionIndex:index})
                    }
                    className={classnames("places-item",
                      {"places-item-active":index === this.state.currentSuggestionIndex})}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}

export default RestContextHOC(AnalyticsContextHOC(
  GoogleMapsJSLoaderContextHOC(PlacesSearchBox)
))
