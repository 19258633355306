import React, { Component } from 'react';
import RestContextHOC from './Contexts/RestContextHOC'
import CartContextHOC from './Contexts/CartContextHOC'
import UserContextHOC from './Contexts/UserContextHOC'
import AnalyticsContextHOC from './Contexts/AnalyticsContextHOC'
import Loading from './Loading/Loading';
import withErrorHandling from "./Contexts/withErrorHandling/withErrorHandling";
import Modal from './Modal/Modal'
import './css/Rewards.css';
const api = require("./helpers/api")

class Rewards extends Component {

  constructor(props){
    super(props)
    this.state = {
      points: null,
      loading: true,
      userLoaded: this.props.user.user ? true : false,
      keycode: null,
      isRewardsUser: null,
      birthday:{mm:"", dd:""},
      claimedCoupon:false,
      signedUp:false
    }
  }

  componentDidMount(){
    this.getRewardsStatus()
  }

  componentDidUpdate(){
    if (!this.state.userLoaded && this.props.user.user){
      this.setState({userLoaded:true},()=>this.getRewardsStatus())
    }
  }

  getRewardsStatus(){
    if (this.state.userLoaded && this.props.rest.rest_id){
      api.callApi(
        'get_points',
        (data)=>this.handlePointsSuccess(data),
        (err)=>this.handlePointsFailure(err),
        null,
        null,
        [this.props.rest.rest_id, this.props.user.user.uniq_uuid]
      )
    } else {
      window.setTimeout(()=>{this.getRewardsStatus()},200)
    }
    
   }

  handlePointsSuccess(data){
    if (data.is_user){
      this.props.analytics.debug('RetrieveRewardsPointsSuccess', data.points)
    } else {
      this.props.analytics.debug('RetriveRewardsPoints', 'not signed up')
    }
    this.setState({
      isRewardsUser: data.is_user,
      points: data.points,
      loading: false
    })
  }

  handlePointsFailure(err){
    this.props.updateError(
      true,
      'Error getting points:\n' + err.msg,
      true, {zIndex:'1000', position: 'fixed', top:'0', width:'99vw'}
    );
    this.setState({
      isRewardsUser: false
    })
  }

  claimCoupon(){
    this.props.analytics.info('RewardsClickClaimCoupon')
    if (this.state.points > 199){
      this.setState({
        claimedCoupon:true
      })
      window.setTimeout(()=>{
        this.setState({
          loading: false,
          keycode: 'rewards'
        })
      }, 1000)
    }
    else {
      this.setState({error:'Whoops! You must earn 200 points before you can redeem your coupon...'})
    }
  }

  handleInputBirthday(e,field){
    console.log(parseInt(e.target.value))
    // if (!Number.isSafeInteger(parseInt(e.target.value))){
    //   console.log('this failed.')
    //   return
    // }
    let val = this.state.birthday
    val[field] = e.target.value
    if (val[field].length > 2){
      val[field]=val[field].slice(0, -1)
    }
    this.setState({birthday:val,error:false})
    if (e.target.value.length > 1){
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (form.elements[index + 1]){
        form.elements[index + 1].focus();
      }
    }
  }

  handleSpecialKeys(e,field){
    //handle enter being pushed
    if(e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      this.handleSubmitBirthday(e)
      return
    }
    //handle backspace being pushed
    if(e.keyCode === 8 && e.shiftKey === false) {
      e.preventDefault()
      let val = this.state.birthday
      val[field]=val[field].slice(0, -1)
      this.setState({birthday:val})
      return
    }
  }

  birthdayIsValid(){
    let daysInMonth = [31,29,31,30,31,30,31,31,30,31,30,31]
    if (parseInt(this.state.birthday['mm']) <= 12 && this.state.birthday['mm'] > 0){
      if(parseInt(this.state.birthday['dd']) <= daysInMonth[parseInt(this.state.birthday['mm'])-1]){
        return true
      }
    }
    return false
  }

  handleSubmitBirthday(){
    this.props.analytics.info('RewardsSubmitBirthday')
    if(this.birthdayIsValid()) {
      this.setState({
        signedUp: true
      })
      window.setTimeout(()=>{
        this.setState({
          loading: true,
        })
        let mm = this.state.birthday['mm']
        if (mm.length <2){ mm = '0' + mm}
        let dd = this.state.birthday['dd']
        if (dd.length <2){ dd = '0' + dd}
        let bd = mm + '-' + dd
        api.callApi(
          'init_rewards_user',
          (data)=>this.handleInitSuccess(data),
          (err)=>this.handleInitFailure(err),
          null,
          null,
          [this.props.rest.rest_id, this.props.user.user.uniq_uuid, bd]
        )
      },1000)
    }
    else{
      this.setState({error:'Whoops! Please enter a valid birthday in mm/dd format...'})
    }
  }

  handleInitSuccess(data){
    this.props.analytics.info('RewardsSignupSuccess')
    this.setState({
      isRewardsUser:true,
      loading:false
    })
    this.getRewardsStatus()
  }
  
  handleInitFailure(err){
    this.props.analytics.error('RewardsSignupFailure')
    this.props.updateError(
      true,
      err.msg + "\nOpen chat in the bottom right corner of your screen if you need help.",
      true, {zIndex:'1000', position: 'fixed', top:'0', width:'99vw'}
    );
  }

  handleClose(){
    this.props.analytics.info('ClickCloseRewards')
    let lastPath = this.props.location.pathname.replace("/rewards","")
    this.props.history.push({
      pathname: lastPath,
      search:window.location.search
    })
  }

  renderCouponButton(){
    let buttonStyle = this.state.points > 199 ? {background: this.props.rest.hex_color_primary} : {background: '#ccc'}
    return(
      <button
        onClick={()=>this.claimCoupon()}
        className='rewardsButton'
        style={buttonStyle}
        id={this.handleCouponClass()}>
        USE MY POINTS
      </button>
    )
  }

  handleUseCoupon(){
    this.props.analytics.info('RewardsClickAddToCart')
    this.props.cart.inputCoupon(this.state.keycode, (resp)=>console.log(resp), null, null, null)
    let lastPath = this.props.history.location.pathname.replace("/rewards","")
    this.props.history.push({
      pathname: lastPath,
      state: {openCart:true}
    })
  }

  handleCouponClass(){
    if (this.state.claimedCoupon){return 'couponExit'}
    return null
  }

  handleBirthdayClass(){
    if (this.state.signedUp){return 'couponExit'}
    return null
  }

  renderContent(){
    if (this.state.loading){
      return(
        <Loading/>
      )
    }

    //VIEW 1: NOT A REWARDS USER
    if (!this.state.isRewardsUser){
      this.props.analytics.debug('RewardsNonUserView')
      return(
        <span id={this.handleBirthdayClass()}>
          <div className='rewardsOptInText' id={this.handleBirthdayClass()}>Enter your birthday below to join our rewards program to earn special discounts for every dollar you spend!</div>
          <form id={this.handleBirthdayClass()}>
            <input
              pattern="^(0*([0-9]|[1-8][0-9]|9[0-9]|100))$"
              value={this.state.birthday['mm']}
              onChange={(e)=>this.handleInputBirthday(e,'mm')}
              onKeyDown={(e)=>this.handleSpecialKeys(e,'mm')}
              className="birthdayInput"
              id={this.handleBirthdayClass()}
              placeholder='mm'
              type="tel"/>
            <div className='birthdayDivider' id={this.handleBirthdayClass()}>/</div>
            <input 
              value={this.state.birthday['dd']}
              onChange={(e)=>this.handleInputBirthday(e,'dd')}
              onKeyDown={(e)=>this.handleSpecialKeys(e,'dd')}
              className="birthdayInput"
              id={this.handleBirthdayClass()}
              placeholder='dd'
              type="tel"/>
          </form>
          <br/>
          {this.renderError()}
          <button
            onClick={()=> this.handleSubmitBirthday()}
            className='rewardsButton'
            id={this.handleBirthdayClass()}
            style={{background:this.props.rest.hex_color_primary}}>
            START EARNING
          </button>
        </span>
      )
    }

    //VIEW 2: COUPON NOT GENERATED, RENDER POINTS
    if (this.state.isRewardsUser && !this.state.keycode) {
      this.props.analytics.debug('RewardsPointsView')
      return(
        <span id={this.handleCouponClass()}>
          <div className='rewardsPoints' id={this.handleCouponClass()}>{this.state.points}</div>
          <div className='rewardsPointsText' id={this.handleCouponClass()}>points</div>
          {this.renderCouponButton()}
          {this.renderError()}
          {this.renderFinePrint('points')}
        </span>
      )
    }

    //COUPON IS GENERATED, RENDER THE KEYCODE
    if (this.state.keycode){
      this.props.analytics.debug('RewardsClaimedCouponView')
      return(
        <span>
          <div
            className='rewardsKeycode'
            style={{color:this.props.rest.hex_color_primary}}>
            {this.state.keycode}
          </div>
          <div className='rewardsKeycodeText'>your coupon code</div>
          <button
            onClick={()=>this.handleUseCoupon()}
            className='rewardsButton smallMargins'
            style={{background:this.props.rest.hex_color_primary}}>
            ADD TO CART
          </button>
          {/* <br/>
          <button
            className='rewardsButton'
            style={{background:'#ccc'}}>
            SEND VIA EMAIL
          </button> */}
          {this.renderFinePrint('coupon')}
        </span>
      )
    }
  }

  /* note: we are ok using dangerouslySetInnerHTML because this content
     is coming from the backend system where people aren't probably 
     going to be inserting script tags and shit */
  renderFinePrint(arg){
    if (arg === 'points') {
      return(
        <span>
          <div className='rewardsFinePrint'
            dangerouslySetInnerHTML={{__html: this.props.rest.rewards.terms_and_conditions}}>
          </div>
        </span>
      )
    }
    if (arg === 'coupon') {
      return(
        <span>
          <div className='rewardsFinePrint'>
            By using this coupon code, you are agreeing to the terms of use of this coupon through {this.props.rest.name}.
          </div>
          <div className='rewardsFinePrint'>
            Coupon gives $10 off one order of $20 or more.
          </div>
        </span>
      )
    }
  }

  renderError(){
    if (this.state.error){
      this.props.analytics.error('RewardsPageInputError', this.state.error)
      return(
        <div className='rewardsError'>{this.state.error}</div>
      )
    }
  }

  render() {
    return (
      <Modal close={()=>this.handleClose()} style={{padding:'32px',textAlign:'center'}}>
        <div className='rewardsHeader'>Rewards.</div>
        {this.renderContent()}
      </Modal>
    );
  }
}

export default withErrorHandling(
  AnalyticsContextHOC(
    UserContextHOC(CartContextHOC(RestContextHOC(Rewards)))
  )
);
