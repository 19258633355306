import React, { Component } from "react";
import MenuCategoryHOC from "../MenuCategoryHOC";
import RestContextHOC from "../../Contexts/RestContextHOC";
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC";

class MenuCategoryDesktop extends Component {

  constructor(props){
    super(props)
    this.state = {
      visibility:'visible'
    }
  }

  componentDidMount(){
    this.handleOverflow()
    window.addEventListener('resize',()=>this.handleOverflow())
    this.checkVisibility()
  }

  checkVisibility(){
    let menu_category_id = this.props.category.menu_category_id
    if(this.props.rest.categoryHidden.indexOf(menu_category_id) > -1){
      this.setState({visibility: 'hidden'})
    } else if (this.props.rest.categoryGreyedOut.indexOf(menu_category_id) > -1) {
      this.setState({visibility: 'greyed_out'})
    } else {
      this.setState({visibility: 'visible'})
    }
  }

  componentDidUpdate(prevProps){
    if (this.props.expanded !== prevProps.expanded){
      this.handleOverflow()
    }
    if( 
      (JSON.stringify(prevProps.rest.categoryGreyedOut) !==  
      JSON.stringify(this.props.rest.categoryGreyedOut)) 
      ||
      (JSON.stringify(prevProps.rest.categoryHidden) !==  
      JSON.stringify(this.props.rest.categoryHidden)) 
    ){ 
      this.checkVisibility()
    } 
  }

  handleOverflow(){
    window.setTimeout(()=>{ if (!this.props.isVisible && !this.props.expanded){
      this.props.detectCategoryOverflow()
    }},200)
  }

  getBlackedOutBodyStyle(){
    if(this.state.visibility === 'greyed_out'){
      return {'textDecoration':'line-through'}
    } else if (this.state.visibility === 'hidden'){
      return {display:'none'}
    } else {
      return {}
    }
  }

  getMenuCategorySelectorStyle(){
    let style  = {}
    if (this.props.selected) {
      style['color'] = this.props.rest.hex_color_secondary
    } else if (this.state.visibility === 'hidden'){
      style['display'] = 'none'
    }
    return style
  }

  render(){
    return (
      <div
        className='MenuCategorySelector'
        onClick={() => this.props.scrollToCategory(this.props.category, false, this.props.selectedIdx)}
        style={this.getMenuCategorySelectorStyle()}
      >
        {this.props.category.image_url && this.state.visibility !== 'hidden' && <img className='MenuCategorySelectorImg' src={this.props.category.image_url} alt='category' />}
        <div style={this.getBlackedOutBodyStyle()} className='MenuCategorySelectorBody'>{this.props.category.name}</div>
      </div>
    );
  }
}

export default AnalyticsContextHOC(
  RestContextHOC(MenuCategoryHOC(MenuCategoryDesktop))
);
