import React, { Component } from "react"
import withRouterAndRef from "../withRouterAndRef"
import Loading from "../Loading/Loading"
import RestaurantCard from "./RestaurantCard"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import "./Search.css"
import ArrowWithCircle from "./ArrowWithCircle"
const Api = require("../helpers/api")

class AllLocations extends Component {
  constructor(props) {
    super(props)
    const fulldomain = window.location.host
    this.subdomain = fulldomain.split(".")[0].split("-")[0]
    this.state = {
      isDesktop: window.innerWidth > 700,
      brand:null,
      states:null,
      showState: null,
      showDropdown: null
    }
    this.handleResize = this.handleResize.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)

    this.dropdownRef = React.createRef()
    this.stateRefs = {}
  }

  componentWillMount(){
    this.addFonts()
  }

  componentDidMount(){
    this.getBrandInfo()
    this.getRests()
    window.addEventListener("resize", this.handleResize)
    window.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
    window.removeEventListener('click', this.handleClickOutside)
  }
  
  handleClickOutside(e){
    if(!this.state.showDropdown) return
    if(  
      e.target.className !== 'locations-page-dropdown-option' &&
      e.target.className !== 'locations-page-dropdown-mobile' &&
      e.target.className !== 'locations-page-dropdown' &&
      e.target.getAttribute("svgclass") !== 'dropdown-svg' &&
      e.target.className !== 'locations-page-dropdown-option-container' 
    ){
      this.setState({showDropdown:false})   
    }
  }

  handleResize(){
    this.setState({
      isDesktop: window.innerWidth > 700
    })
  }
  
  addFonts(){
    if(window.addedBitcoinFonts) return

    let links =` 
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap" rel="stylesheet">
    `
    document.head.innerHTML = document.head.innerHTML + links
    window.addedBitcoinFonts = true
  }

  getBrandInfo(){
   Api.callApi(
      'brand-info-v2',
      (data)=>{
        this.setState({
          brand:data.brand
        })
      },
      (err)=>{
        alert('Unknown error')
      },
      {subdomain:this.subdomain}
    )
  }

  getRests(){
   Api.callApi(
      'brand-v2-rests',
      (data)=>{
        
        for(let i in data.states){
          this.stateRefs[i] = React.createRef()
        }
        this.setState({
          states:data.states
        })
      },
      (err)=>{
        alert('Unknown error')
      },
      {subdomain:this.subdomain}
    )
  }


  backToSearchPage(){
    this.props.history.push({
      pathname:'/'
      // state:{
      //   place:this.state.place
      // }
    })
  }

  renderDesktop(){
    return (
      <div className='all-locations-page-search-desktop-container'>
        <div className='locations-search-desktop-header'>
          <img className='locations-search-desktop-header-img' src={this.state.brand.logo_url}/>
        </div>
        {this.renderContent()}
      </div>
    )
  }
  
  renderMobile(){
    return (
     <div className='all-locations-page-mobile-container'>
        <div className='locations-search-mobile-header'>
          <img className='locations-search-mobile-header-img' src={this.state.brand.logo_url}/>
        </div>
        {this.renderMobileContent()}
      </div>
    )
  }

  scrollToStateAndOpen(state){
    if(this.stateRefs[state] && this.stateRefs[state].current){
      this.stateRefs[state].current.scrollIntoView()
      this.setState({showState: state})
      this.setState({showDropdown: false})
    }
  }

  revealDropdown(){
    if (this.state.showDropdown){
      document.querySelector('html').style.backgroundColor = ''
      this.setState({showDropdown: false})
    }
    else{
      document.querySelector('html').style.backgroundColor = '#343A40'
      this.setState({showDropdown: true})
    }
  }

  clickStateBar(state){
    if(this.state.showState === state){
      this.setState({showState: null})
    }
    else{
      this.setState({showState: state})
    }
  }

  renderArrowIcon(state){
    if(this.state.showState === state){
      return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 4L0 4L0 8H4L4 12H8V16H12V12H16V8L20 8V4L16 4V8H12V12H8V8H4V4Z" fill="#CCCCCC"/>
        </svg>
      )
    }
    else{
      return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 16L4 20L8 20L8 16L12 16L12 12L16 12L16 8L12 8L12 4L8 4L8 4.76995e-08L4 0L4 4L8 4L8 8L12 8L12 12L8 12L8 16L4 16Z" fill="#CCCCCC"/>
        </svg>
      )
      
    }
  }

  renderRestaurantCard(state){
    if (this.state.isDesktop){
      let rows = this.state.states[state].reduce(function (rows, key, index) { 
        return (index % 3 == 0 ? rows.push([key]) 
          : rows[rows.length-1].push(key)) && rows;
      }, []); 
  
      return(
        <div style={{width:'100%', paddingTop:'10px'}}>
            {rows.map((pair, idx) => (
              <>
              <div style={{display: 'flex', paddingTop:'5px', paddingBottom:'5px', alignItems:'flex-start'}}>
                  <div 
                    className='restaurant-card-container-all-locations'
                    style={{paddingRight:'16px'}}
                  >
                    <RestaurantCard
                      page='all-locations'
                      rest={pair[0]}
                      key={pair[0].restaurant_id}
                      smallWidth={true}
                    />
                  </div>
                  {pair.length > 1 && (
                    <div 
                      className='restaurant-card-container-all-locations'
                      style={{paddingRight:'8px', paddingLeft:'8px'}}
                    >
                      <RestaurantCard
                        page='all-locations'
                        rest={pair[1]}
                        key={pair[1].restaurant_id}
                        smallWidth={true}
                      />
                    </div>
                  )}
                  {pair.length > 2 && ( 
                    <div 
                      className='restaurant-card-container-all-locations'
                      style={{paddingLeft:'16px'}}
                    >
                      <RestaurantCard
                        page='all-locations'
                        rest={pair[2]}
                        key={pair[2].restaurant_id}
                        smallWidth={true}
                      />
                    </div>
                  )}
              </div>
                  
              </>
            ))}
        </div>
      )
    }
    else{
      return(
        <>
        {this.state.states[state].map((rest)=>
          <div style={{margin: '5px 5px'}}>
            <RestaurantCard
              page='all-locations'
              key={rest.restaurant_id}
              rest={rest}
              smallWidth={false}
            />
          </div>
        )}
        </>
      )
    }
    
  }

  renderContent(){
    return (
      <div className='locations-page-inner-container'>
        <div className='locations-page-inner-inner-container'>
          <div className='locations-page-title'>
            Bitcoin Pizza Locations
          </div>
          <div className='locations-page-button-dropdown-container'>
            <div className='locations-page-button' onClick={()=>(this.backToSearchPage())}>Find a Location Near Me</div>
            <div style={{color:'white', fontFamily: 'Space Mono', fontSize:'18px', margin:'0px 31px'}}>or</div>
            <div className='locations-page-full-dropdown-container'>
              <div onClick={()=>this.revealDropdown()} ref={this.dropdownRef} className='locations-page-dropdown'>
                Select a State 
                <svg svgclass='dropdown-svg' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4 4H0V8H4V12H8V16H12V12H16V8H20V4H16V8H12V12H8V8H4V4Z" fill="#FC9601"/>
                </svg>
              </div>
              {this.state.showDropdown && 
                <div style={{top: this.dropdownRef.current, width: this.dropdownRef.current.offsetWidth}} className='locations-page-dropdown-option-container'>
                  {Object.keys(this.state.states).map(state => (
                    <div className='locations-page-dropdown-option' onClick={()=>this.scrollToStateAndOpen(state)}>
                    {state}
                    </div>
                    ))}
                </div>
              }
    </div>

      </div>

      {Object.keys(this.state.states).map(state => (
            <div className='locations-page-state-bar-container'>
            <div className='locations-page-state-bar' 
            ref={this.stateRefs[state]} 
            style={{scrollMarginTop: '100px'}} 
            onClick={()=>(
                this.clickStateBar(state)
                )}
            >
            {state}
            {this.renderArrowIcon(state)}
            </div>
            {(this.state.showState === state) && 
            <div className='locations-page-rest-card-container'>
            {this.renderRestaurantCard(state)}
            </div>}
            </div>
            ))}
    </div>
      </div>

      )
  }

  renderMobileContent(){
    return (
        <div className='locations-page-inner-container-mobile'>
        <div className='locations-page-inner-inner-container-mobile'>
        <div className='locations-page-title'>
        Bitcoin Pizza Locations
        </div>
        <div className='locations-page-button-dropdown-container-mobile'>
        <div className='locations-page-button-mobile' onClick={()=>(this.backToSearchPage())}>Find a Location Near Me</div>
        <div style={{color:'white', fontFamily: 'Space Mono', fontSize:'18px', margin:'0px 31px'}}>or</div>
        <div className='locations-page-full-dropdown-container-mobile'>
        <div onClick={()=>this.revealDropdown()} ref={this.dropdownRef} className='locations-page-dropdown-mobile'>
        Select a State 
        <svg svgclass='dropdown-svg' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 4H0V8H4V12H8V16H12V12H16V8H20V4H16V8H12V12H8V8H4V4Z" fill="#FC9601"/>
        </svg>
        </div>
        {this.state.showDropdown && 
        <div style={{top: this.dropdownRef.current, width: this.dropdownRef.current.offsetWidth}} className='locations-page-dropdown-option-container-mobile'>
        {Object.keys(this.state.states).map(state => (
              <div className='locations-page-dropdown-option-mobile' onClick={()=>this.scrollToStateAndOpen(state)}>
              {state}
              </div>
              ))}
        </div>
        }
    </div>

      </div>

      {Object.keys(this.state.states).map(state => (
            <div className='locations-page-state-bar-container'>
            <div className='locations-page-state-bar-mobile' 
            ref={this.stateRefs[state]} 
            style={{scrollMarginTop: '100px'}} 
            onClick={()=>(
                this.clickStateBar(state)
                )}
            >
            {state}
            {this.renderArrowIcon(state)}
            </div>
            {(this.state.showState === state) && 
            <div className='locations-page-rest-card-container'>
            {this.renderRestaurantCard(state)}
            </div>}
            </div>
            ))}

    </div>
      </div>

      )
  }

  render() {
    if(!this.state.brand){
      return <Loading />
    }
    if(!this.state.states){
      return <Loading/>
    }

    if(this.state.isDesktop) return this.renderDesktop()
    else return this.renderMobile()

  }
}


export default AnalyticsContextHOC(withRouterAndRef(AllLocations))
