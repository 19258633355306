import React from 'react';
import '../css/LoadingIndicator.css';

class LoadingIndicator extends React.Component {
  constructor(props){
    super(props)
    window.load = this
  }
  render() {
    return (
      <div>
        {!this.props.noBG && <div className="bg"></div>}
        <div className="loader">
          <svg viewBox="0 0 32 32" width="32" height="32">
            <circle id="spinner" cx="16" cy="16" r="14" fill="none"></circle>
          </svg>

          {this.props.solana && (
            <div style={{fontSize:'18px', fontWeight:'600', marginTop:'13px', textAlign:'center'}}>
              <div>
                We are processing your payment
              </div>
              <div style={{marginTop:'12px'}}>
                Solana transactions could take up to 60 seconds
              </div>
            </div>
          )}

        </div>
        <div className="loadingText">
          {this.props.loadingText}
        </div>
      </div>
    )
  }
}

export default LoadingIndicator;
