import React, { Component } from 'react';
import RestContextHOC from './Contexts/RestContextHOC'
import GoogleMapsJSLoaderContextHOC from "./Contexts/GoogleMapsJSLoaderContextHOC";
import AnalyticsContextHOC from './Contexts/AnalyticsContextHOC'
import GoogleMapReact from 'google-map-react'
import MapMarker from './sparkles/MapMarker'
import Modal from './Modal/Modal'
import Loading from './Loading/Loading'
const api = require('./helpers/api')

class Contact extends Component {

  constructor(props){
    super(props)
    this.state = {
      other_locations: []
    }
  }

  componentDidMount(){
    this.getOtherLocations()
  }

  getOtherLocations(){
    if (this.props.rest.rest_id){
      api.callApi(
        'other_locations',
        res=>this.setState({other_locations:res}),
        err=>console.log(err),
        null,
        {rest_id:this.props.rest.rest_id}
      )
    } else window.setTimeout(()=>this.getOtherLocations(), 200)
  }

  handleClose(){
    this.props.analytics.info('ClickCloseContact')
    let lastPath = this.props.location.pathname.replace("/contact","")
    this.props.history.push({
      pathname: lastPath,
      search:window.location.search
    })
  }

  renderMap(){
    return(
      <div style={{width: '92%', height:'300px', margin:' 16px auto'}}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDx3LPzjrY1Y4258OJOHvWCzVqoR0TUi4o'}}
          defaultZoom={14}
          center={{
            lat: this.props.rest.lat,
            lng: this.props.rest.lng
          }}
        >
          <MapMarker lat={this.props.rest.lat} lng={this.props.rest.lng} logo={this.props.rest.logo_color}/>
        </GoogleMapReact>
        </div>
    )
  }

  formatPhone(phone){
    phone = phone.replace(/-/g, "")
    phone = '(' + phone.slice(0,3)+") "+phone.slice(3,6)+"-"+phone.slice(6,15)
    return phone
  }

  formatSite(site){
    return site.replace("http://","").replace("https://","")
  }

  buildSiteUrl(site){
    return 'http://' + site.replace("http://","").replace("https://","")
  }

  renderOtherLocations(){
    if (this.state.other_locations.length > 0){
      return(<>
        <h3>Other Locations:</h3>
        {this.state.other_locations.map((location,i)=>(
          <a href={'https://' + location.subdomain + '.hngr.co'}>
            <button className='hngrButton' style={{fontSize: '12px', background: this.props.rest.hex_color_secondary, padding:'6px 12px'}}>{location.name} - {location.secondary_name}</button>
          </a>
        ))}
      </>)
    }
  }

  render() {
    console.log(this.state)
    let phonelink = 'tel:' + this.props.rest.phone
    if (this.props.rest.website_url){
      return (
        <Modal close={()=>this.handleClose()} style={{padding: '32px', textAlign:'center'}}>
          <div className='rewardsHeader' style={{textAlign:'center', margin: '16px', marginBottom:'0'}}>{this.props.rest.name}</div>
          {this.props.rest.secondary_name && <h3 style={{margin:0}}>{this.props.rest.secondary_name}</h3>}
          <br/>
          {this.renderMap()}
          <strong>
            {this.props.rest.address}<br/>
            {this.props.rest.city}, {this.props.rest.state} {this.props.rest.zipcode}<br/>
            <a href={phonelink} style={{color:'blue',textDecoration:'underline'}}>{this.props.rest.phone && this.formatPhone(this.props.rest.phone)}</a><br/>
            <a href={this.buildSiteUrl(this.props.rest.website_url)} target='_blank' rel='noopener noreferrer' style={{color:'blue',textDecoration:'underline'}}>{this.props.rest.website_url && this.formatSite(this.props.rest.website_url)}</a>
          </strong>
          {this.renderOtherLocations()}
        </Modal>
      )
    } else return (
      <Modal close={()=>this.handleClose()}>
        <div className='rewardsHeader' style={{textAlign:'center', margin: '16px'}}>{this.props.rest.name}</div>
        <Loading/>
      </Modal>
    )
  }
}

export default GoogleMapsJSLoaderContextHOC(AnalyticsContextHOC(RestContextHOC(Contact)))
