import React, { Component } from "react"
import AppPage from "../AppPage"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import RestContextHOC from "../Contexts/RestContextHOC"
import EntryPointHOC from "../Contexts/EntryPointHOC"
import { withRouter } from "react-router-dom"
import CardLogoImg from "../Checkout/CardLogoImg"
import CartItems from "../Components/Cart/CartItemsCheckout"
import OrderReceipt from '../Orders/OrderReceipt'
import { ReactComponent as Accepted} from "./accepted.svg";
import Injection from '../Injection'
import BitcoinCircle from "../Checkout/BitcoinCircle"
import classnames from "classnames"
import "./ThankYou.css"

const Api=require("../helpers/api");
const Timestamp = require("../helpers/timestamps");
const formatPrice = require("../helpers/formatPrice");


const USER_FRIENDLY_STATUSES = {
  'confirmed':'Confirmed',
  'unconfirmed':'Unconfirmed',
  'snoozed':'Confirmed',
  'ready_for_pickup':'Ready for Pickup',
  'out_for_delivery':'Out for Delivery',
  'completed':'Completed',
  'cancelled':'Canceled'
}


const SHOW_THESE_STATUSES = [
  'unconfirmed',
  'completed',
  'cancelled',
  'ready_for_pickup'
]

class ThankYou extends Component {
  constructor(props) {
    super(props)
    const params = new URLSearchParams(window.location.search);
    const diner_id = params.get('diner_id')
    const hash_id = params.get('hash_id');

    // stupid hack for iOS where the query params are lost on 
    // auto refresh
    if(!hash_id){
      window.location.href = '/orders'
    }

    this.state = {
      diner_id:diner_id,
      hash_id:hash_id,
      page:'thankyou',
      isMobile: window.innerWidth < 920,
      receiptHeight:null,
      estimated_ready_at:null
    }

    this.showReceipt = this.showReceipt.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.updateReceiptHeight = this.updateReceiptHeight.bind(this)
    this.hiddenRightSideRef = React.createRef()
    this.desktopCartRef = React.createRef()

  }

  componentDidMount(){
    window.addEventListener("resize", this.handleResize)
    this.getStatus()
    this.getCart()
    this.setState({mounted:true})
    document.body.style.backgroundColor = "#FAFAFA"

  }
  
  componentWillUnmount(){
    document.body.style.backgroundColor = null
  }

  handleResize(){
    this.props.analytics.debug("ThankyouHandlingResize", window.innerWidth)
    this.setState({
      isMobile:window.innerWidth < 920
    })  
  }

  getCart(){
   Api.callApi(
      'thankyou-cart',
      (resp)=>{
        this.setState({
          cartObj:resp
        })
      },
      (err)=>{},
      {diner_id: this.state.diner_id,
      hash_id: this.state.hash_id}
    )
  }

  getStatus(){
    if(this.state.status === 'cancelled' || this.state.status === 'completed'){
      return
    }

    Api.callApi(
      'order_status',
      (res)=>{
        this.setState({
          status: res.status,
          cancelled_reason:res.cancelled_reason,
          is_cash_order:res.is_cash_order,
          is_bitcoin_order:res.is_bitcoin_order,
          opennode_charge_status:res.opennode_charge_status,
          bitcoin_link:res.bitcoin_link,
          card_last4:res.card_last4,
          card_type:res.card_type,
          pickup_or_delivery: res.pickup_or_delivery,
          delivery_service: res.delivery_service,
          created_at: res.created_at,
          delivery_address: res.address,
          estimated_ready_at:res.estimated_ready_at,
          delivery_address2:res.address2,
          delivery_zipcode:res.zipcode,
          delivery_city:res.city,
          delivery_state:res.state,
          delivery_instructions: res.delivery_instructions,
          total: res.total,
          subtotal: res.subtotal,
          order_at_iso: res.order_at_iso,
          order_at: res.order_at,
          fulfill_order_at: res.fulfill_order_at,
          hash_id: res.hash_id}
        )
        let polling_interval = 60000
        if(res.status === 'unconfirmed'){
          polling_interval = 5000
        }
        if(res.is_bitcoin_order && res.opennode_charge_status !== 'paid'){
          polling_interval = 5000
        }
        setTimeout(()=>this.getStatus(), polling_interval)
      },
      (err)=>{},
      {diner_id: this.state.diner_id,
      hash_id: this.state.hash_id,
      estimated_ready_at:this.state.estimated_ready_at,
      cancelled_reason:this.state.cancelled_reason}
    )
  }

  updateReceiptHeight(height){
    this.setState({receiptHeight:height})
  }

  showReceipt(){
    this.setState({
      page:'receipt'
    })
  }

  hasSocialMedia(){
    return this.props.rest.facebook_url ||
      this.props.rest.insta_url ||
      this.props.twitter_url
  }  

  formattedPhone() {
    let phone = this.props.rest.phone_number_e164
    if (!phone) return ''
    phone = '('+phone.slice(2,5)+") "+phone.slice(5,8)+"-"+phone.slice(8,15)
    return phone
  }


  desktopCartStyle(){
    if(this.state.mounted && this.hiddenRightSideRef.current){
      let t = this.hiddenRightSideRef.current.offsetTop
      let l = this.hiddenRightSideRef.current.offsetLeft
      let right = window.innerWidth - l
      return {
        position:'absolute',
        overflowX:'visible',
        overflowY:'visible',
        left:l+'px',
        right:right+'px' ,
        "--rest-primary": this.props.rest.hex_color_primary
      }
    }
    else {
      return {display:'none'}
    }
  }

  // we need to set the height to that of the receipt so that 
  // the background color on the whole page is right
  desktopContainerStyle(){
    if(this.state.receiptHeight){
      let height = this.state.receiptHeight +  50
      return {
        minHeight:height + 'px'
      }
    }
  }

  renderTopHeaderStatus(){
    let TOP_HEADER_BY_STATUS = {
      'unconfirmed':'Thank you for your order!',
      'confirmed':'Your order is confirmed',
      'ready_for_pickup':'Your order is ready!',
      'out_for_delivery':'Your order is on the way!',
      'completed': 'Your order is completed',
      'cancelled': 'Your order was canceled',
      'snoozed':'Your scheduled order is confirmed'
    }
    if (this.state.fulfill_order_at){
      TOP_HEADER_BY_STATUS['confirmed'] = TOP_HEADER_BY_STATUS['snoozed']
    }
    return TOP_HEADER_BY_STATUS[this.state.status]
  }

  renderSubHeaderStatus(){
    let SUBHEADER_BY_STATUS = {}
    if(this.state.pickup_or_delivery === 'pickup'){
      SUBHEADER_BY_STATUS = {
        'unconfirmed':`Check this page to track your ${this.state.pickup_or_delivery} order`,
        'confirmed':`Check this page to track your ${this.state.pickup_or_delivery} order`,
        'snoozed':`Check this page to track your ${this.state.pickup_or_delivery} order`,
        'ready_for_pickup':'Head to the restaurant to pickup your order',
        'completed':'We hope you enjoy your meal!',
        'cancelled':this.state.cancelled_reason,
      }
    } else if (this.state.pickup_or_delivery === 'delivery'){
      SUBHEADER_BY_STATUS = {
        'unconfirmed':`Check this page to track your ${this.state.pickup_or_delivery} order`,
        'confirmed':`Check this page to track your ${this.state.pickup_or_delivery} order`,
        'snoozed':`Check this page to track your ${this.state.pickup_or_delivery} order`,
        'out_for_delivery':'A driver is on the way to deliver your order',
        'completed':'We hope you enjoy your meal!',
        'cancelled':this.state.cancelled_reason,
      }
      if (this.state.delivery_service === 'DOORDASHDRIVE'){
        SUBHEADER_BY_STATUS['out_for_delivery']  = 'A DoorDash driver will deliver your order from us' 
      }
    }
    if(this.state.status === 'out_for_delivery'){
      return (
        <span className='thankyou-car-line'> <Car/> {SUBHEADER_BY_STATUS[this.state.status]} </span>
      )

    } else { 
      return SUBHEADER_BY_STATUS[this.state.status]
    }
  }

  renderProgressBar(){
    // dont show with autoconfirm
    if(this.props.rest.autoconfirm) return
    if(!this.state.status) return

    //each status needs to be mapped to a percent
    const status_percent = {
      'unconfirmed':5,
      'confirmed':50,
      'ready_for_pickup':100,
      'out_for_delivery':100,
      'completed':100,
      'cancelled':0,
      'snoozed':50
    }

    let percent_color = status_percent[this.state.status]
    let percent_grey = 100-percent_color

    return (
      <div className='thankyou-status-checker-container'>
        <div className='thankyou-status-bar-container'>
          <div 
            style={{backgroundColor:this.props.rest.hex_color_secondary, width:percent_color+'%'}}
            className='thankyou-color-bar'
          >
          </div>
          <div style={{width:percent_grey+'%'}} className='thankyou-grey-bar'></div>
        </div>
      </div>
    )
  }

  renderStatusList(){
    if(!this.state.pickup_or_delivery) return null

    let asap_statuses, future_statuses

    if(this.state.pickup_or_delivery === 'pickup'){
      asap_statuses = [
        'Confirming your order',
        'Our kitchen is making your order',
        'Ready for pickup'
      ]
      future_statuses = [
        'Confirming your order',
        'Your scheduled order is confirmed',
        'Ready for pickup'
      ]
    }

    else if(this.state.pickup_or_delivery === 'delivery'){
      asap_statuses = [
        'Confirming your order',
        'Our kitchen is making your order',
        'Out for delivery'
      ]
      future_statuses = [
        'Confirming your order',
        'Your scheduled order is confirmed',
        'Out for delivery'
      ]
    }


    const status_index = {
      'cancelled':-1,
      'unconfirmed':0,
      'confirmed':1,
      'snoozed':1,
      'ready_for_pickup':2,
      'out_for_delivery':2,
      'completed':3,
    }
    let index = status_index[this.state.status]
  
    let spinner = (
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{fill:this.props.rest.hex_color_secondary, margin:'auto', background: 'rgb(255, 255, 255) none repeat scroll 0% 0%', display: 'block', shapeRendering: 'auto'}} width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" fill="none" stroke={this.props.rest.hex_color_secondary} stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </circle>
      </svg>
    )

    let greyCheckmark = (
      <svg className='thankyou-status-checkmark' style={{fill:'gray'}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
    )

    let colorCheckmark = (
      <svg className='thankyou-status-checkmark' style={{fill:this.props.rest.hex_color_secondary}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
    )

    let statuses
    if (this.state.fulfill_order_at) statuses = future_statuses
    else statuses = asap_statuses
    return (
      <div className='thankyou-status-list'>
        {/* include these hidden state vars in render so a rerender happens when 
            they update */ }
        <span style={{display:'none'}}>
          {this.state.pickup_or_delivery}
          {this.state.receiptHeight}
        </span>
        {statuses.map((status,idx)=>(
          <>
             {(idx < index || (this.state.fulfill_order_at && index === 1 && idx === 1)) && (
              <div className='thankyou-status-list-status flex-start'>
                <div className='thank-you-status-list-left'>
                  {colorCheckmark}
                </div>
                <div className='thanky-you-status-list-right'>
                  {status}
                </div>
              </div>
            )}
           
            {(idx == index && !(this.state.fulfill_order_at && index === idx && index === 1)) &&  (
              <div className='thankyou-status-list-status flex-start'>
                <div className='thank-you-status-list-left'>
                  {spinner}
                </div>
                <div 
                  className='thanky-you-status-list-right' 
                  style={{color:this.props.rest.hex_color_secondary, fontWeight:'900'}}

                >
                  {status}
                </div>
              </div>
            )}

            {idx > index  && (
              <div className='thankyou-status-list-status flex-start'>
                <div className='thank-you-status-list-left'>
                  {greyCheckmark}
                </div>
                <div className='thanky-you-status-list-right thankyou-grey'>
                  {status}
                </div>
              </div>
            )}

            {(this.state.delivery_service === 'DOORDASHDRIVE' && idx === 2) && ( 
              <div 
                className={classnames('thankyou-doordash', 
                  {'thankyou-grey':index < 2})}
              >
                <span className='thankyou-car'><Car/></span>This restaurant uses DoorDash for delivery services
              </div>
            )}

          </>
        ))}
      </div>
    )
  }

  renderBitcoinStuff(){
    if(!this.state.is_bitcoin_order) return null
    if(this.state.opennode_charge_status !== 'paid') return
    return (
      <div className='thankyou-bitcoin-paid'> 
        <div className='thankyou-click-pay-price'>
          {formatPrice.formatPrice(this.state.total)}
        </div>
        <div className='thankyou-bitcoin-image-container'>
          <Accepted />
        </div>
        <div className='thankyou-bitcoin-txn-complete'>
          Your Transaction is Complete
        </div>
      </div>
    )
  }


  renderUnpaidBitcoin(){
    return (
      <div className='thankyou-container'>
        <div className='thankyou-unpaid-bitcoin-container'>
          <div className='thankyou-bitcoin-pizza-title'>Bitcoin Pizza</div>

          {!this.state.opennode_charge_status && this.state.bitcoin_link && (
            <> 
              <div className='thankyou-yellow-blurb thank-you-please-complete'>
                Please complete this transaction to proceed with your order.
              </div>
              
              <div className='thankyou-click-pay-blurb'>
                <div className='thankyou-click-pay-price'>
                  {formatPrice.formatPrice(this.state.total)}
                </div>
                
                <img src='https://cdn.hngr.co/bitcoin.gif' style={{marginTop:'15px', marginBottom:'15px', height:'80px', width:'80px'}}/>

                <div className='thankyou-click-contine'>
                  Click Pay with Bitcoin to continue
                </div>
              </div>

              <a className='pay-with-bitcoin-button' href={this.state.bitcoin_link}>
                <BitcoinCircle scale="0.7"/>
                <div className='pay-with-bitcoin-button-text'>Pay With Bitcoin</div>
              </a>

              <div className='pay-with-bitcoin-redirect-warning'>
                You will be redirected to Opennode, our Bitcoin processor
              </div>
            </>
          )}
          
          {this.state.opennode_charge_status === 'processing' && (
            <>
              <div className='bitcoin-is-processing-text'>
                Your Bitcoin transaction is in process...
                <br/><br/>
                On-chain transactions take on average 10 min and Lightnng can take up to 5 min.
                <br/><br/>
                We will update this page once this transaction is complete.
              </div>

              <div className='bitcoin-is-processing'>
              
                <div className='thankyou-processing-blurb'>
                  <div className='thankyou-click-pay-price'>
                    {formatPrice.formatPrice(this.state.total)}
                  </div>

                  <div 
                    className='thankyou-bitcoin-image-container thankyou-hour-glass-container'
                  >
                    <img src='/hourglass.gif'/>
                  </div>

                  <div className='thankyou-txn-in-progress'>
                    Your Transaction is still in progress
                  </div>
                </div>
              
              </div>
            </>
          )}


        </div>
      </div>

    )

  }


  renderContent(){
    return (
      <div className='thankyou-container'>
        {this.state.page === 'thankyou' && (
          <div className=''>
            <div className='thankyou-text-large'>
              {this.renderTopHeaderStatus()}
            </div>
            {!this.props.rest.autoconfirm && (
              <div className='thankyou-text-small'>
                {this.renderSubHeaderStatus()}
              </div>
            )}
          </div>
        )}

        {this.state.page === 'receipt' && (
          <div className='thankyou-text-large'>
            Your Order
          </div>
        )}
        <Injection context={'THANK_YOU'} 
          order_at={this.state.order_at_iso}
          total={this.state.total}
          hash_id={this.state.hash_id} />
        <div className='thankyou-order-details'>
          <div>Order: {this.state.hash_id}</div>
          <div>Received <b>{this.state.order_at}</b></div>
        </div>

        {this.state.page === 'thankyou' && (
          <>
            {this.state.status && (
              <>
                {SHOW_THESE_STATUSES.indexOf(this.state.status) > -1 ? 
                  (
                    <div style={{paddingTop:'7px', paddingBottom:'7px'}}> 
                      {this.state.status === 'unconfirmed' ? (
                        <div className='thankyou-order-status'> 
                          {this.state.pickup_or_delivery == 'pickup' ? 'Pickup' : 'Delivery'} Order Placed
                        </div>
                      ) : ( 
                        <>
                          <div>Your order is:</div>
                          <div className='thankyou-order-status'> {USER_FRIENDLY_STATUSES[this.state.status]} </div>
                        </>
                      )}
                    </div>
                  ) : 
                  (
                    <>
                      {this.state.fulfill_order_at && (
                        <div className='thankyou-ready-at'>
                          {this.state.pickup_or_delivery === 'pickup' && (
                            <div>Your order will be ready at</div>
                          )}
                          {this.state.pickup_or_delivery === 'delivery' && (
                            <div>Your order will be delivered at</div>
                          )}
                          <div className='thankyou-order-status'>{this.state.fulfill_order_at}</div>
                        </div>
                      )}
                      {this.state.estimated_ready_at && (
                        <div className='thankyou-ready-at'>
                          {this.state.pickup_or_delivery === 'pickup' && (
                            <div>Your order will be ready at:</div>
                          )}
                          {this.state.pickup_or_delivery === 'delivery' && (
                            <div>Your order will be delivered at:</div>
                          )}
                          <div className='thankyou-order-status'>{this.state.estimated_ready_at}</div>
                        </div>
                      )}        
                    </>
                  )
                }
              </>
            )}
            {this.renderProgressBar()}
            
            <div className='thankyou-section left-right grey-bottom-border'>
              {this.state.pickup_or_delivery === 'pickup' && (
                <>
                  <div>
                    <div className='extra-bold'>Pickup Location</div>
                    <div>{this.props.rest.address}</div>
                    <div>{this.props.rest.city}, {this.props.rest.state} {this.props.rest.zipcode}</div>
                  </div>
                  <a href={`http://maps.google.com?q=${this.props.rest.address}, ${this.props.rest.city} ${this.props.rest.state}`} target='_blank'>
                    <div 
                      className='thankyou-map-icon-container'
                      style={{backgroundColor:this.props.rest.hex_color_secondary}}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className='map-icon'  viewBox="0 0 32 32">
                        <path d="M21 6l-10-4-11 4v24l11-4 10 4 11-4v-24l-11 4zM12 4.554l8 3.2v19.692l-8-3.2v-19.692zM2 7.401l8-2.909v19.744l-8 2.909v-19.744zM30 24.599l-8 2.909v-19.744l8-2.909v19.744z"></path>
                      </svg>
                    </div>
                  </a>
                </>
              )}

              {this.state.pickup_or_delivery === 'delivery' && (
                <>
                  <div>
                    <div className='extra-bold'>Deliver to:</div>
                    <div>{this.state.delivery_address}</div>
                    {this.state.delivery_address2 && (<div>{this.state.delivery_address2}</div>)}
                    <div>{this.state.delivery_city}, {this.state.delivery_state} {this.state.delivery_zipcode}</div>
                  </div>
                </>
              )}
            </div>

            {(this.state.cartObj && this.state.isMobile) && (
              <div 
                onClick={this.showReceipt}
                className='thankyou-section grey-bottom-border thankyou-cart-mobile'
              >
                <div style={{width:'90%'}}>
                  <div className='extra-bold'>Your Order ({this.state.cartObj.items.length})</div>
                  <CartItems disable_edits={true} thankyou={true} cartObj={this.state.cartObj}/> 
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                </div>
              </div>  
            ) }
            
            <div className='thankyou-section  grey-bottom-border'>
              {this.state.card_type && (
                <div className='card_type  flex-direction-row-start'>
                  <CardLogoImg cardName={this.state.card_type} />
                  <div className='thank-you-card-details'>
                    <div className='last-4 bold'>Ending in {this.state.card_last4}</div>
                  </div>
                </div>
              )}

              {this.state.is_cash_order && (
                <div className='flex-direction-row-start'>
                  <MoneyIcon/>
                  <div style={{marginLeft:'10px'}} className='bold'>Pay with Cash</div>
                </div>
              )}

              {this.renderBitcoinStuff()}
            </div>

            {this.state.isMobile  && (
              <div className='thankyou-section grey-bottom-border left-right'>
                <div className='bold' style={{width:'36%'}}>
                  Run into an issue with your order?
                </div>
                <a href={`tel:+${this.props.rest.phone_number_e164}`} style={{width:'60%'}}>
                  <div style={{width:'100%', display:'flex', justifyContent:'flex-end'}}>
                    <div 
                      style={{border:'3px solid ' + this.props.rest.hex_color_secondary}}
                      className='thankyou-call-button'
                    >
                      <svg style={{fill:this.props.rest.hex_color_secondary}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg>
                      <div 
                        style={{
                          paddingLeft:'13px',
                          fontWeight:'bold',
                          color:this.props.rest.hex_color_secondary
                        }}
                      >
                        Call Restaurant
                      </div>
                    </div>
                  </div>
                </a>
              </div>  
            )}

            {!this.state.isMobile  && (
              <div className='thankyou-section grey-bottom-border'>
                <div>
                  Run into an issue with your order?
                </div>
                <a 
                  className='bold'
                  href={`tel: ${this.props.rest.phone_number_e164}`} 
                  style={{color:this.props.rest.hex_color_secondary, textDecoration:'underline'}}
                >
                  Call {this.formattedPhone()}
                </a>
              </div>
            )}

            <div 
              className={classnames('thankyou-section grey-bottom-border',
                {'left-right':this.hasSocialMedia() && this.state.isMobile})}
            >
              <div 
                className={classnames('thankyou-please-continue',
                 {'thankyou-please-continue-with-social-media':this.hasSocialMedia() && this.state.isMobile})}
              >
                Thank You for Ordering Direct!
              </div>

              {this.props.cart.supperclubWallet && (
                <div 
                  className='thankyou-section'
                >
                  <a
                    className='bold'
                    href={process.env.REACT_APP_SUPPERCLUB_URL}
                  >
                    <div 
                      className='thankyou-call-button'
                      style={{border:'3px solid ' + this.props.rest.hex_color_primary}}
                    >
                      Back to Supper Club
                    </div>
                  </a>
                </div>
              )}

              {this.hasSocialMedia() && (
                <div className={classnames(
                  {'thankyou-social-media-container':this.state.isMobile},
                  {'thankyou-social-media-container-dekstop':!this.state.isMobile})}
                >
                  {this.props.rest.facebook_url && (
                    <a href={this.props.rest.facebook_url} target='_blank'>
                      <svg style={{fill:this.props.rest.hex_color_primary}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/></svg>
                    </a>
                  )}
                
                  {this.props.rest.insta_url && (
                    <a href={this.props.rest.insta_url} target='_blank'>
                      <svg style={{fill:this.props.rest.hex_color_primary}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                    </a>
                  )}

                  {this.props.rest.twitter_url && (
                    <a href={this.props.rest.twitter_url} target='_blank'>
                      <svg style={{fill:this.props.rest.hex_color_primary}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-.139 9.237c.209 4.617-3.234 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.08-4.03 3.199-4.03.943 0 1.797.398 2.395 1.037.748-.147 1.451-.42 2.086-.796-.246.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.439.656-.996 1.234-1.639 1.697z"/></svg>
                    </a>
                  )}
                </div>
              )}
            </div>
          </>
        )}

        {this.state.page === 'receipt' && (
          <div className='thankyou-receipt-container'>
            <OrderReceipt 
              key={this.state.opennode_charge_status}
              receiptClass='thankyou-orderReceiptFrame'
              containerClass='thankyou-orderReceiptContainer'
              hash_id={this.state.hash_id}
              diner_id={this.state.diner_id}
            />
          </div>
        )}

      </div>
    )
  }

  render() {
    return (
      <AppPage 
        back={this.state.page === 'receipt' ? ()=>this.setState({page:'thankyou'}) : null}
        hideCart  
        fullpage
        {...this.props}
      >

        {this.state.isMobile && (
          <>
            {this.renderContent()}
          </>
        )}
        
        {!this.state.isMobile && (
          <div className='checkout-desktop-container' style={this.desktopContainerStyle()}>
            <div className='checkout-desktop-left'>
              {(this.state.is_bitcoin_order === true  && this.state.opennode_charge_status !== 'paid')  ? (
                <>
                  {this.renderUnpaidBitcoin()}
                </>
              ) : (
                <>
                  {this.renderContent()}
                </>
              )}
            </div>
            <div ref={this.hiddenRightSideRef} className='hidden-right-side'></div>
            <div
              ref={this.desktopCartRef}
              style={this.desktopCartStyle()}
              className='checkout-desktop-right'
            >
                <div className='checkout-desktop-right-inner'>
                  <div className='checkout-desktop'>
                    <OrderReceipt 
                      onHeightUpdate={this.updateReceiptHeight}
                      loadingBackground={true}
                      centerReceipt
                      receiptClass='thankyou-dekstop-receipt'
                      containerClass='thankyou-orderReceiptContainer-desktop'
                      hash_id={this.state.hash_id}
                      diner_id={this.state.diner_id}
                    />
                  </div>
                </div>
            </div>
          </div>
        )}
      </AppPage>
    )
  }
}

const Car = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M7 13.5c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm9 1c0-.276-.224-.5-.5-.5h-7c-.276 0-.5.224-.5.5s.224.5.5.5h7c.276 0 .5-.224.5-.5zm4-1c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm-17.298-6.5h-2.202c-.276 0-.5.224-.5.5v.511c0 .793.926.989 1.616.989l1.086-2zm19.318 3.168c-.761-1.413-1.699-3.17-2.684-4.812-.786-1.312-1.37-1.938-2.751-2.187-1.395-.25-2.681-.347-4.585-.347s-3.19.097-4.585.347c-1.381.248-1.965.875-2.751 2.187-.981 1.637-1.913 3.382-2.684 4.812-.687 1.273-.98 2.412-.98 3.806 0 1.318.42 2.415 1 3.817v2.209c0 .552.448 1 1 1h1.5c.552 0 1-.448 1-1v-1h13v1c0 .552.448 1 1 1h1.5c.552 0 1-.448 1-1v-2.209c.58-1.403 1-2.499 1-3.817 0-1.394-.293-2.533-.98-3.806zm-15.641-3.784c.67-1.117.852-1.149 1.39-1.246 1.268-.227 2.455-.316 4.231-.316s2.963.088 4.231.316c.538.097.72.129 1.39 1.246.408.681.81 1.388 1.195 2.081-1.456.22-4.02.535-6.816.535-3.048 0-5.517-.336-6.805-.555.382-.686.779-1.386 1.184-2.061zm11.595 10.616h-11.948c-1.671 0-3.026-1.354-3.026-3.026 0-1.641.506-2.421 1.184-3.678 1.041.205 3.967.704 7.816.704 3.481 0 6.561-.455 7.834-.672.664 1.231 1.166 2.01 1.166 3.646 0 1.672-1.355 3.026-3.026 3.026zm5.526-10c.276 0 .5.224.5.5v.511c0 .793-.926.989-1.616.989l-1.086-2h2.202z"/></svg>
)

const MoneyIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 7v10h-20v-10h20m2-2h-24v14h24v-14zm-12 3c-2.208 0-4 1.792-4 4s1.792 4 4 4 4-1.792 4-4-1.792-4-4-4zm.2 5.979v.42h-.4v-.399c-.414-.007-.843-.105-1.2-.29l.181-.657c.383.146.891.305 1.29.215.46-.104.555-.577.046-.805-.372-.172-1.512-.322-1.512-1.296 0-.546.417-1.034 1.194-1.141v-.425h.401v.407c.29.007.616.058.977.168l-.145.658c-.307-.107-.646-.205-.977-.185-.595.035-.648.551-.232.766.684.322 1.576.561 1.576 1.418.002.687-.536 1.054-1.199 1.146z"/></svg>
)

export default withRouter(
  EntryPointHOC(RestContextHOC(AnalyticsContextHOC(ThankYou))
))
