import React, { Component, useMemo, useState, useEffect} from "react"
import { WalletModalProvider, WalletMultiButton, WalletConnectButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import "./WalletButton.css"
import {
    WalletReadyState,
} from '@solana/wallet-adapter-base';

require('@solana/wallet-adapter-react-ui/styles.css');


const WalletButton = (props) => {

  const { name, readyState, wallet, connect, connecting, connected, publicKey } = useWallet();

  return (
    <WalletMultiButton>
      <div className='wallet-button-change'>
        <div>Change</div>
      </div>
    </WalletMultiButton>
  )

}

export default WalletButton

