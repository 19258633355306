import React from 'react';

const Privacy = () => (

<div>

    <h1>Privacy Policy</h1>
    
    <h3>Last Updated: June 01, 2021</h3>
    
    <p>At Blueprint Alpha, Inc. (d/b/a Captain) (“Captain” “we,” “us,” or “our”), we are committed to handling personal information we collect about you responsibly. This Privacy Policy describes how we process personal information we obtain through (i) our websites, mobile applications, or other digital properties that link to this Privacy Policy (collectively, the “Sites”); (ii) events, conferences, and related activities (the “Events”); and (iii) our restaurant partners (“Partners”) that use Captain Orders, Insights, Actions, or other Captain services or software offerings (the “Services”). This Privacy Policy does not apply to companies or sites that Captain does not own or control.</p>
    
    <h4>Table of Contents:</h4>
    
    <ol>
        <li>What does Captain do?</li>
        <li>Privacy information for Captain’s Sites, Events, and Services</li>
        <li>Your choices</li>
        <li>California residents: your privacy rights</li>
        <li>Children</li>
        <li>Data retention</li>
        <li>Information security</li>
        <li>Updates and changes to this Privacy Policy</li>
        <li>Contact information</li>
    </ol>
    
    <p>10.Captain For Restaurants Privacy Policy</p>
    
    <ol>
        <li>What does Captain do?</li>
    </ol>
    
    <p>Captain is a technology company focused on enhancing relationships between our Partners and their customers. We provide our Partners with the Services to help them serve their customers, generate insights into who their customers are and how they interact with Partner restaurants, and to help our Partners use those insights to improve their restaurants and grow their customer base. Captain uses data to operate the Sites and Services, to support our Partners, and to enable more relevant marketing and advertising. We also give you ways to control how we collect, use, and share information about you. This Privacy Policy is intended to explain the information we collect, how it is used, and what your rights and choices are.</p>
    
    <ol start="2">
        <li>Privacy information for Captain’s Sites, Events, and Services</li>
    </ol>
    
    <p>A. How does Captain collect information?</p>
    
    <p>We collect information from the following categories of sources in connection with our Sites, Events, and Services:</p>
    
    <p>We collect information directly from individuals who visit or use the Sites or Services.</p>
    
    <p>If you visit the Sites, you may choose to provide information to us, such as when you create or log into your account, or contact us to request or provide information. People who visit Captain’s Sites are ordinarily business representatives (such as for our Partners), not individual consumers.</p>
    
    <p>If you visit a Partner site that uses the Services, you may choose to provide information to us to create an account or complete a transaction (for example, to place a food order with the Partner site).</p>
    
    <p>We collect information from individuals who interact with Captain or its representatives at Events:</p>
    
    <p>If you interact with Captain or its representatives at an Event, you may choose to provide information to us, such when you sign up for a email list or ask us to contact you with more information about our Services. People who interact with Captain at Events are ordinarily business representatives (such as for our Partners), not individual consumers.</p>
    
    <p>We collect information automatically from your browser or device.</p>
    
    <p>When you interact with our Sites or Services online, we (and third parties) may collect technical information from your browser, device, and internet connection by automated means such as pixels, cookies, web beacons, local storage, JavaScript, Software Development Kits (SDKs), Application Programming Interfaces (APIs), MAIDs, and other similar technologies. We may also collect this kind of information when you interact with email messages we or our Partners have sent you. As we and our Service Providers adopt additional technologies, we may also gather information through other methods.</p>
    
    <p>We collect information from Partner sites that use our Services.</p>
    
    <p>Our Partners use our Services with, and allow us to collect information from, the websites and other online properties they make available to their customers (for example, to enable online ordering from their restaurants).</p>
    
    <p>We collect information from other companies.</p>
    
    <p>We collect information about you when other companies we work with share that information with us. These companies may include our Partners, online food ordering and delivery companies, advertising and marketing technology companies, marketing analytics and attribution companies, consumer data resellers, and others. Our collection of this information may include matching offline demographic or interest data with the online identifiers we use with the Services, and associating multiple web browser or internet-connected devices with a single individual or household.</p>
    
    <p>The other companies we obtain information from may be companies affiliated or unaffiliated with us. In some cases, one company may fall into more than one of these categories.</p>
    
    <p>B. The categories of information Captain collects, how it uses that information, and how it is shared.</p>
    
    <p>Note to California residents: In this section, we provide the categories of personal information Captain may collect directly from you and the business or commercial purposes for which we may use and share that information. Some of the ways we use and share this information may involve what the CCPA refers to as a “sale” of personal information. To exercise your right to opt out of “sales,” you can follow the instructions found at this link: Do Not Sell My Personal Information.</p>
    
    <p>Above, we describe how and from whom Captain collects information about you. Below, we provide additional details about: (1) the categories of information that we collect; (2) our business and commercial purposes for collecting that information; and (3) the categories of third parties we have disclosed it to for a business purpose. In addition, we may combine any of the information we collect for any of the purposes discussed below, and may use any of the data we collect or combine to create pseudonymized, de-identified, or aggregate information, and may use or share de-identified and aggregate information for any purpose.</p>
    
    <p>i. We collect contact and account-related information about our Partners and prospective customers when they use our Sites and Services, and about individuals when they place an order on a Partner site that uses Services.</p>
    
    <p>What we collect:</p>
    
    <ul>
        <li>Full name</li>
        <li>Email address</li>
        <li>Telephone number</li>
        <li>Physical address (including state, city, zip code, and street name and number)</li>
        <li>Account username and password</li>
        <li>Demographic information (e.g., age, gender, interests)</li>
        <li>Professional or employment-related information (e.g., the name of the company you work for and your title)</li>
        <li>Payment information (such as payment method details)</li>
        <li>Transaction information (such as the products or services you have purchased from us or from a Partner site)</li>
        <li>Customer service information (for example, when you contact us for support with the Services or questions about your account)</li>
        <li>Any other information you choose to include when you contact us, create an account, or place an order on a Partner site.</li>
    </ul>
    
    <p>Why we collect and share it:</p>
    
    <ul>
        <li>To enable individuals to place orders on Partner sites using the Services and to communicate with them about the status of their order (for example, through email or SMS text message)</li>
        <li>To provide the Sites and Services to our Partners (including online ordering; delivery and logistics; analytics; customer acquisition, segmentation, and retention; data and customer management)</li>
        <li>To maintain and improve the Sites and Services (including for internal research; product development; operations; auditing; security and anti-fraud; debugging; short-term and transient use; quality control; legal compliance)</li>
        <li>For our own advertising and marketing purposes (including to reach Site visitors or Event participants with email marketing messages, advertising, or other promotional materials).</li>
        <li>To provide advertising and marketing services to our Partners (including the delivery of digital advertising, marketing, or promotional materials; the creation of segments to tailor messaging based on interests, location, or demographics; optimizing, measuring and analyzing digital advertising and marketing).</li>
    </ul>
    
    <p>With whom we have shared or sold it:</p>
    
    <ul>
        <li>Our vendors and service providers, including companies that provide web hosting, marketing, data hygiene, analytics, operational, delivery and logistics, accounting, auditing, legal, security, or other services to us (“Service Providers”).</li>
        <li>Our Partners (or at the direction of our Partners, for example, to their vendors or service providers)</li>
    </ul>
    
    <p>ii. Captain collects identifiers and other technical information about the device(s) you use, the sites you visit, and your internet connection when you use the Sites or Services.</p>
    
    <p>What we collect:</p>
    
    <ul>
        <li>Online identifiers (e.g., IDFAs on iOS devices or Advertising IDs on Android devices; cookie IDs on your web browser; and other device or online identifiers, such as proprietary IDs or other pseudonymous IDs created and used by us or our Partners).</li>
        <li>Information about the device you are using (e.g., the name and version of your computer or device; the operating system it is running; and system activity and settings).</li>
        <li>Information about the software you have installed on your device (for example, the mobile apps you have installed that use the Services).</li>
        <li>Information about your internet connection, including the web browser you are using, current IP address, carrier, and type of internet connection. We may infer your general location (for example, your city and state) based on your IP address.</li>
    </ul>
    
    <p>Why we collect and share it:</p>
    
    <ul>
        <li>To enable individuals to place orders on Partner sites using the Services</li>
        <li>To provide the Sites and Services to our Partners (including online ordering; delivery and logistics; analytics; customer acquisition, segmentation, and retention; data and customer management, etc.)</li>
        <li>To maintain and improve the Sites and Services (including for internal research; product development; operations; auditing; security and anti-fraud; debugging; short-term and transient use; quality control; legal compliance, etc.)</li>
        <li>For our own advertising and marketing purposes (including to reach Site visitors or Event participants with email marketing messages, advertising, or other promotional materials).</li>
        <li>To provide advertising and marketing services to our Partners (including the delivery of digital advertising, marketing, or promotional materials; the creation of segments to tailor messaging based on interests, location, or demographics; optimizing, measuring and analyzing digital advertising and marketing).</li>
    </ul>
    
    <p>With whom we have shared or sold it:</p>
    
    <ul>
        <li>Our Partners (or at the direction of our Partners, for example, to their vendors or service providers)</li>
        <li>Our Service Providers and other vendors.</li>
    </ul>
    
    <p>iii. Captain collects information about how you interact with our Site, the Services, and advertising and marketing materials.</p>
    
    <p>What we collect:</p>
    
    <ul>
        <li>Information about how you use our Site and Partner sites that use the Services (including the pages you visit; date/time stamps associated with those page visits; clickstream information and patterns of user activity; information about the products you view or order; search terms input; and Site or Service features used).</li>
        <li>Information about how you interact with marketing emails, digital advertisements, and other promotional materials, such as whether you view a marketing email or click on an ad for a Partner site.</li>
        <li>Information related to user journeys across sites or other online properties, such as whether a Partner’s customer was “referred” through another app, website or a shared link.</li>
    </ul>
    
    <p>Why we collect and share it:</p>
    
    <ul>
        <li>To provide the Sites and Services to our Partners (including online ordering; delivery and logistics; analytics; customer acquisition, segmentation, and retention; data and customer management, etc.)</li>
        <li>To maintain and improve the Sites and Services (including for internal research; product development; operations; auditing; security and anti-fraud; debugging; short-term and transient use; quality control; legal compliance, etc.)</li>
        <li>For our own advertising and marketing purposes (including to reach Site visitors or Event participants with email marketing messages, advertising, or other promotional materials).</li>
        <li>To provide advertising and marketing services to our Partners (including the delivery of digital advertising, marketing, or promotional materials; the creation of segments to tailor messaging based on interests, location, or demographics; optimizing, measuring and analyzing digital advertising and marketing).</li>
    </ul>
    
    <p>With whom we have shared or sold it:</p>
    
    <ul>
        <li>Our Partners (or at the direction of our Partners, for example, to their vendors or service providers)</li>
        <li>Our Service Providers and other vendors</li>
    </ul>
    
    <p>iv. We collect demographic and interest-based information.</p>
    
    <p>What we collect:</p>
    
    <ul>
        <li>Information that describes the characteristics of our Partners’ customers, including consumer interests and affinities, site usage (e.g., frequent vs. infrequent site visitor), and others consumer characteristics. This information may be based on inferences we have drawn from other information we collect about you through the Services, through our Partners, or through other companies.</li>
        <li>Sometimes this information includes demographic information (including “protected classifications” for purposes of the California CCPA), such as gender, age, or inferred income level. </li>
    </ul>
    
    <p>Why we collect and share it:</p>
    
    <ul>
        <li>To provide the Sites and Services to our Partners (including online ordering; delivery and logistics; analytics; customer acquisition, segmentation, and retention; data and customer management)</li>
        <li>To maintain and improve the Sites and Services (including for internal research; product development; operations; auditing; security and anti-fraud; debugging; short-term and transient use; quality control; legal compliance)</li>
        <li>For our own advertising and marketing purposes (including to reach Site visitors or Event participants with email marketing messages, advertising, or other promotional materials).</li>
        <li>To provide advertising and marketing services to our Partners (including the delivery of digital advertising, marketing, or promotional materials; the creation of segments to tailor messaging based on interests, location, or demographics; optimizing, measuring and analyzing digital advertising and marketing).</li>
    </ul>
    
    <p>With whom we have shared or sold it:</p>
    
    <ul>
        <li>Our Partners (or at the direction of our Partners, for example, to their vendors or service providers)</li>
        <li>Our Service Providers and other vendors</li>
    </ul>
    
    <p>v. Other Reasons Captain may share information about you.</p>
    
    <p>Above, we describe the kinds of information we collect, use and disclose about you in connection with the Sites, Events, and Services in the ordinary course of our business. In certain other circumstances, Captain may also disclose any of the categories of information described above as follows. </p>
    
    <ul>
        <li>In the event of a corporate transaction. We may share information about you in the event of a major corporate transaction, including a merger, investment, acquisition, reorganization, consolidation, bankruptcy, liquidation, or sale of some or all of our assets, or for purposes of due diligence connected with any such transaction.</li>
        <li>With third parties at your direction or with your consent. We may share information with companies, organizations or individuals outside of Captain when you direct us to or when you give your consent.</li>
        <li>For legal purposes. We may need to share information we have collected about you in order to: (a) comply with legal process or a regulatory investigation (e.g., a valid subpoena or court order); (b) enforce our Terms of Service, this Privacy Policy, or other contracts, or to investigate of potential violations thereof; (c) protect the rights, property or personal safety of us, our Sites, Events, or Services, our customers, our agents and affiliates, its users and/or the public. We likewise may provide information to other companies and organizations (including law enforcement) for fraud protection, spam/malware prevention, and other similar purposes.</li>
    </ul>
    
    <ol start="3">
        <li>Your choices</li>
    </ol>
    
    <p>We provide several ways for you to exercise control over how we use information about you, described below.</p>
    
    <p>A. Opting out of tailored advertising.</p>
    
    <p>As described in Sections 2 above, information we collect through the Sites, Events and Services may be used to tailor advertising and marketing messages, both for us and for third parties. You can exercise control over how information about you is used to tailor the ads you see online. </p>
    
    <p>On the web.</p>
    
    <p>· Interest-based advertising opt out. As described in sections 2, certain information we collect through the Services and Sites may be used or shared to tailor ads you see on the web. To learn more about this type of advertising or to opt out of participating companies, please visit the Digital Advertising Alliance’s opt-out program at https://www.aboutads.info/. We make no representation about the accuracy or effectiveness of this opt out mechanism.</p>
    
    <p>· Browser settings. Some tailored advertising is carried out using cookies. You may be able to set your browser to refuse certain types of cookies, or to alert you when they are being used. Some browsers offer similar settings for HTML5 local storage and other technologies. Please consult the &quot;Help&quot; section of your browser for more information. However, if you block or otherwise reject cookies or other similar technologies, certain websites (including our Sites) may not function as expected. Because we and third parties may use non-cookie technologies with your browser or device, browser settings that block cookies used in isolation may not affect the functioning of those technologies.</p>
    
    <p>On mobile devices.</p>
    
    <p>You can change the settings on your mobile device to manage how your device collects and shares certain information for advertising purposes.</p>
    
    <p>· On iOS mobile devices, you can navigate to “Settings” from your device’s home screen; then navigate to “Privacy” from the “Settings” menu.</p>
    
    <p>o On devices running iOS 13 or earlier, select “Advertising” from the “Privacy” menu; and turn on “Limit Ad Tracking.”</p>
    
    <p>o On devices running iOS 14 or later, select “Tracking” from the “Privacy” menu, and turn off “Allow Apps to Request to Track.” If you have already granted permission for an App to track you, you may also revoke that App’s permission to track through the “Tracking” menu.</p>
    
    <p>· On Android mobile devices, you can navigate to “Settings” from your device’s home screen; select “Google Settings” from the “Settings” menu; select “Ads” from the “Google Settings” menu; and check the “Opt Out of Interest-Based Ads” box.</p>
    
    <p>Modifying your mobile device settings or browser settings, or opting out of interest-based advertising using your web browser, may have different results depending on the type of device you are using, the type of web browser you are using, and the version of the device operating system you are running. Opting out of tailored advertising does not prevent you from seeing advertising online; instead, the ads you see are less likely to be customized to your interests. Opting out is generally effective only on a device-by-device, or browser-by-browser basis. If you use more than one browser or device, the effect of any opt-out mechanism you use may be limited to the device on which you used the mechanism.</p>
    
    <p>B. Do Not Track</p>
    
    <p>Do Not Track (“DNT”) is a setting that users can enable in certain web browsers. DNT signals do not have a commonly agreed up meaning and we do not currently recognize or respond to DNT signals.</p>
    
    <p>C. Email Marketing</p>
    
    <p>You may opt out of or unsubscribe from our marketing emails using the links provided in the footer of those emails. </p>
    
    <ol start="4">
        <li>California residents: your privacy rights</li>
    </ol>
    
    <p>The California Consumer Privacy Act of 2018 (“CCPA”) grants certain rights to residents of California regarding their personal information. If you are a natural person who resides in California (“Consumer”), this section of the Privacy Policy applies to you. If you are not a Consumer, this section does not apply to you and you should not rely on it. </p>
    
    <p>A. The right to access and disclosure.</p>
    
    <p>Consumers may request that we provide the following details about the personal information we have collected about them during the past 12 months:</p>
    
    <p>the categories and specific pieces of personal information we have collected about them;</p>
    
    <p>the categories of sources from which we collected the personal information;</p>
    
    <p>the business or commercial purpose for which we collected or sold the personal information;</p>
    
    <p>the categories of third parties with whom we shared the personal information; and</p>
    
    <p>the categories of personal information that we sold or disclosed for a business purpose, and the categories of third parties to whom we sold or disclosed that information for a business purpose.</p>
    
    <p>B. The right to deletion.</p>
    
    <p>You may request that we delete the personal information we have collected from you. In certain circumstances, we may deny your request to delete (for example, certain types of information are required to provide Sites and Services to you; and we may retain certain information for important business purposes such as fulfilling our legal obligations, or for security, auditing or de-bugging purposes). </p>
    
    <p>C. How to exercise your access and deletion rights.</p>
    
    <p>California residents may make requests for access to or deletion of their personal information via email at privacy@gocaptain.com or via this form.</p>
    
    <p>For security purposes, we may attempt to verify your identity before fulfilling your request. To verify your identity, we may need to request additional information from you (including, but not limited to your name, telephone number, or email address).</p>
    
    <p>If we are able to verify your identity, we will respond to your request to the best of our ability and as required by law. If we cannot verify your identity, we may request additional information from you or we may deny your request. If we are unable to complete your request fully, we will provide you additional information about the reasons that we could not comply with your request.</p>
    
    <p>D. The right to nondiscrimination.</p>
    
    <p>We will not deny, charge different prices for, or provide a different level of quality of goods or services if you choose to exercise your rights under the CCPA.</p>
    
    <p>E. The right to opt-out of the sale of your personal information.</p>
    
    <p>You have the right to opt out of the sale of your personal information under the CCPA.</p>
    
    <p>As explained further in this Privacy Policy, we and our advertising partners collect certain pieces of information about the use of our Sites and Services such as device identifiers, cookies, advertising IDs, IP addresses, and usage activity, so that we and third parties can deliver ads that are more relevant to you. This type of information sharing with our advertising partners may be considered a “sale” under the CCPA. Also as explained in our Privacy Policy, we may share certain of your information with third party partners for advertising and marketing purposes. To learn how opt out of this type of “sale” by participating companies, you can visit the CCPA Opt Out Tool provided by the Digital Advertising Alliance at https://www.privacyrights.info/.
    </p>
    
    <p>The opt outs described above are device- and browser-based. Because the information being shared relates to your device ID and/or browser ID, you must opt out on each device and each browser where you want your choice to apply. Opt-outs may be stored via cookies. If you clear cookies, your opt-out will no longer be valid and you must opt out again where you want your choices to apply. Captain does not maintain or control the opt-out mechanisms and settings above and is not responsible for their operation.</p>
    
    <p>In addition, if you do not want us to share personal information about you with other third parties in a way that may be considered a “sale” under the CCPA, please submit a request to privacy@gocaptain.com or via this form.</p>
    
    <p>F. Authorized Agents.</p>
    
    <p>You may designate an agent to make the requests described above. If you do so, we will take steps both to verify the identity of the person seeking to exercise their rights as listed above, and to verify that your agent has been authorized by you to make a request on your behalf through providing us with a signed written authorization or a copy of a power of attorney.</p>
    
    <ol start="5">
        <li>Children</li>
    </ol>
    
    <p>Our Sites, Events and Services are not developed for, offered to, or directed at children under the age of 16. We do not knowingly collect or sell information about children under the age of 16. If you believe your child, who is under the age of 16 has provided us with personal information, please contact us at privacy@gocaptain.com and we will use reasonable efforts to remove that information from our records.</p>
    
    <ol start="6">
        <li>Data retention</li>
    </ol>
    
    <p>Captain retains information it collects from its Sites, Services and Events for as long as necessary to fulfill the purposes described in this Privacy Policy. To provide security and business continuity for the activities described in this Privacy Policy, we may make backups of certain data, which we may retain for longer than the original data. We may use any information we collect to create aggregate, de-identified or non-identified user information, which we may keep for an indefinite period. </p>
    
    <ol start="7">
        <li>Information security</li>
    </ol>
    
    <p>To help protect the information we hold, we have put in place physical, technical, and administrative safeguards. However, we cannot guarantee that information we collect under this Privacy Policy will never be used or disclosed in a manner that is inconsistent with this Privacy Policy.</p>
    
    <ol start="8">
        <li>Updates and changes to this Privacy Policy</li>
    </ol>
    
    <p>We may update this Privacy Policy from time to time in response to changing legal, technical or business developments, or to clarify the information it contains. When we update our Privacy Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Privacy Policy changes if and where this is required by applicable data protection laws.</p>
    
    <p>You can see when this Privacy Policy was last updated by checking the “last updated” date displayed at the top of this Privacy Policy.</p>
    
    <ol start="9">
        <li>Contact information</li>
    </ol>
    
    <p>If you have any questions, concerns or requests in relation to this Privacy Policy, please contact us at privacy@gocaptain.com.</p>

</div> 
) ;

export default Privacy;
