import "react-app-polyfill/ie11"
import "url-search-params-polyfill"
import 'intersection-observer' //polyfill
import React, {useMemo} from "react"
import { clusterApiUrl } from '@solana/web3.js'
import { render } from "react-dom"
import { BrowserRouter, Route, withRouter, Switch } from "react-router-dom"
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary"
import { StripeProvider } from "react-stripe-elements"
import * as Sentry from "@sentry/browser"
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import registerCustomSW from "./registerCustomServiceWorker"
import LandingPage from "./LandingPage"
import Account from "./Account/Account"
import Privacy from "./Privacy"
import TOS from "./TOS"
import Orders from "./Orders/Orders"
import LoginSignupModal from "./LoginSignup/Modal"
import Login from "./LoginSignup/Login"
import PhoneVerifier from "./LoginSignup/PhoneVerifier"
import EmailRegistration from './LoginSignup/EmailRegistration'
import EmailLogin from './LoginSignup/EmailLogin'
import PasswordReset from './LoginSignup/PasswordReset'
import AnonymousPasswordReset from './LoginSignup/AnonymousPasswordReset'
import MissingInformation from './LoginSignup/MissingInformation'
import MissingEmail from './LoginSignup/MissingEmail'
import Checkout from "./Checkout/CheckoutWrapper"
import Rewards from "./Rewards"
import Contact from "./Contact"
import CheckerPage from "./CheckerPageNew"
import TrackOrder from "./Orders/TrackOrder"
import ThankYou from "./ThankYou/ThankYou"
import Menu from "./Menu/Menu"
import MenuByUUID from "./MenuByUUID"
import ErrorPage from "./ErrorPage"
import { BrandPage } from "./BrandPage"
import AbandonedCart from "./Kiosk/AbandonedCart"
import LotsOfLocationsPage from './LotsOfLocationsPage/Search'
import LotsOfLocationsAllLocationsPage from './LotsOfLocationsPage/AllLocations'
import ContactlessPayment from './ContactlessPayment/ContactlessPayment'
import QRSessionTest from "./QRSessionTest"
import { Capacitor } from "@capacitor/core";
//contexts
import CartProvider from "./Contexts/CartContext"
import RestProvider from "./Contexts/RestContext"
import UserProvider from "./Contexts/UserContext"
import AnalyticsProvider from "./Contexts/AnalyticsContext"
import GoogleMapsJSLoaderProvider from "./Contexts/GoogleMapsJSLoaderContext"
import SignupModalProvider from "./Contexts/SignupModalContext"
import CartContextHOC from "./Contexts/CartContextHOC"

import MenuItemModal from "./Menu/MenuItemModal"

import OauthUniversal from "./OauthUniversal"
import OauthFailure from "./OauthFailure"

//styles
import "./css/Global.css"
import RestContextHOC from "./Contexts/RestContextHOC"

import LogRocket from 'logrocket';

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';



const Subdomain = require("./helpers/subdomain")

window.DEBUG_ID = Math.floor(Math.random() * 100000)

// CLOWNTOWN IE11 POLYFILLS!!!!

// polyfill for IE missing the includes method
// which is required for some stuff (mostly sentry)
// https://mzl.la/2MnpSqD
if (!String.prototype.includes) {
  // eslint-disable-next-line
  String.prototype.includes = function (search, start) {
    // eslint-disable-next-line
    "use strict"
    if (typeof start !== "number") {
      start = 0
    }

    if (start + search.length > this.length) {
      return false
    } else {
      return this.indexOf(search, start) !== -1
    }
  }
}

// polyfill for IE missing the Number.isSafeInteger method
// https://mzl.la/2lGtsPZ
Number.isSafeInteger =
  Number.isSafeInteger ||
  function (value) {
    return Number.isInteger(value) && Math.abs(value) <= Number.MAX_SAFE_INTEGER
  }

// polyfill for IE missing the Number.isInteger method
Number.isInteger =
  Number.isInteger ||
  function (value) {
    return (
      typeof value === "number" &&
      isFinite(value) &&
      Math.floor(value) === value
    )
  }


// global var to alert when no cs session detected. Used in AnalyticsContext
window.NO_CS_SESSION_ALERT_COUNT = 0

// set the API location
if (process.env.REACT_APP_FOODCOIN_API_LOCATION) {
  window.API_HOST = process.env.REACT_APP_FOODCOIN_API_LOCATION
}

// set clickstream location
if (process.env.REACT_APP_FOODCOIN_CLICKSTREAM_LOCATION) {
  window.CLICKSTREAM_HOST = process.env.REACT_APP_FOODCOIN_CLICKSTREAM_LOCATION
}

if (process.env.REACT_APP_HNGR_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_HNGR_SENTRY_DSN,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    ignoreErrors: [
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Cancelled",
      "TypeError: cancelled",
      "The request timed out",
      "Network request failed",
      "The network connection was lost",
      /.*NetworkError: Failed to execute.*/,
      "SecurityError: The operation is insecure",
      /SecurityError: Blocked a frame.*/,
      'Could not load "util"',
      'ServiceWorkerRegistration',
      'AbortError: The operation was aborted',
      'messageHandler',
      'custom-service-worker.js',
      'Blocked a frame with origin',
      "ResizeObserver loop limit exceeded",
      "Non-Error promise rejection captured with value: ",
      "/Non-Error promise rejection captured with value:.*/",
      "ResizeObserver loop completed with undelivered notifications.",
      /.*UnknownError: An internal error was encountered in the Indexed Database server.*/,
      'UnknownError: Attempt to delete range from database without an in-progress transaction',
      /.*Could not load "places_impl.*/,
      /.*g.readyState'.*/,
      'AbortError: Version change transaction was aborted in upgradeneeded event handler.',
      /.*instantSearchSDKJSBridgeClearHighlight.*/,
      "Failed to read the 'error' property from 'IDBRequest': The request has not finished.",
      "Should not already be working.",
      "A mutation operation was attempted on a database that did not allow mutati",
      "Load failed",
      "Preflight response is not successful"

    ],
    environment: process.env.REACT_APP_HNGR_SENTRY_ENVIRONMENT,
    beforeSend(event, hint) {
      try {
        event.extra.CLICKSTREAM_SESSION_ID = window.CLICKSTREAM_SESSION_ID
        return event
      } catch (err) {
        return event
      }
    },
  })
}

const BIG_BRAND_LOCATIONS = [
  'seabasspizza',
  'bitcoinpizza'
]

const LockedRoute = RestContextHOC((props) => {
  const { component } = props
  React.useEffect(() => {
    props.rest.checkSandbox()
  }, [])
  return <Route {...props} component={component} />
})

class App extends React.Component {
  constructor(props) {
    super(props)
    // aggressively make a brief API call
    // to server to confirm it is available, if not
    // attempt to use our reverse proxy host
    const ok_url = window.API_HOST + "/api/ok"
    fetch(ok_url)
      .then((res) => {})
      .catch((err) => {
        window.API_HOST = process.env.REACT_APP_FOODCOIN_API_FAILOVER
      })
    
    if(window.location.href.indexOf('elevationburger') > -1){
      window.location.href = 'https://order.elevationburger.com/'
    }  

    if(window.location.href.indexOf('hurricanewings-locations') >-1){
      window.location.href = 'https://order.hurricanewings.com/'
    }

    if(window.location.href.indexOf('buffaloscafe-locations') >-1){
      window.location.href = 'https://order.buffalos.com/'
    }

    if(window.location.href.indexOf('fatburger') >-1){
      window.location.href = 'https://order.fatburger.com/'
    }

    if(window.location.href.indexOf('sophias') >-1){
      window.location.href = 'https://www.sophiasthaikitchen.com/'
    }

  }

  render() {
    //return <LotsOfLocationsPage/>

    const subdomain = Subdomain.getSubdomain()
    if (!subdomain) {
      window.location.href = "https://home.hngr.co"
      return <div />
    }

    // in order to decide if a brand locations page is the btc style or the
    // original style, we look at a hardcoded list 
    if (window.location.hostname.indexOf("-locations") > -1) {
      for(let i in BIG_BRAND_LOCATIONS){
        if(window.location.hostname.indexOf(BIG_BRAND_LOCATIONS[i]) > -1){
          if(window.location.href.indexOf('all-locations') > -1){
            return <LotsOfLocationsAllLocationsPage/>
          } else{
            return <LotsOfLocationsPage/>
          }
        }
      }
      return <BrandPage />
    }
    
    return (
      <div
        style={{
          "--inset-top": "env(safe-area-inset-top, 0px)",
          "--inset-bottom": "env(safe-area-inset-bottom, 0px)",
          "--supperclub-back-height": 
            (Capacitor.getPlatform() == 'android' || Capacitor.getPlatform() == 'ios') && !window.KIOSK_MODE 
            ? '50px' : '0px'
        }}
      >
        {/* ACTUAL PAGE VIEWS */}
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route
            exact
            path="/menu-by-uuid/:menu_uuid"
            key="menu-by-uuid"
            component={MenuByUUID}
          />
          <Route path="/menu" key="menu" component={Menu} />
          <LockedRoute
            path="/checkout"
            key="checkout"
            component={Checkout}
          />
          <Route path="/orders" key="orders" component={Orders} />
          <Route path="/account" key="account" component={Account} />
          <Route exact path="/privacy" key="privacy" component={Privacy} />
          <Route path="/oauth_complete" key="oauth_complete" 
            component={OauthUniversal} />
          <Route path="/oauth_failure" key="oauth_failure" 
            component={OauthFailure}/>
        
          <Route 
            exact 
            path="/contactless-payment/:hash_id" 
            key="contactlesspayment" 
            component={ContactlessPayment}
          />
          <Route 
            path="/thankyou" 
            key="contactlesspayment" 
            component={ThankYou}
          />


          <Route path ="/qr-test" key="qr-test" component={QRSessionTest} />
          <Route key="error" component={ErrorPage} />
        </Switch>

        {/* MODAL OVERLAYS */}
        <Route
          path="/menu/:menuitemid(\d+)"
          key="item"
          component={MenuItemModal}
        />

        { /* auth related endpoints */ }
        <Route path="*/moreinfo*" component={MissingInformation} />
        <Route path="*/set_name*" component={MissingInformation} />
        <Route path="*/email_missing*" component={MissingEmail} />
        <Route path="*/auth*" key="auth" component={LoginSignupModal} />
        <Route path="*/signup*" component={Login} />
        <Route path="*/email_register*" component={EmailRegistration} />
        <Route path="*/email_login*" component={EmailLogin} />
        <Route path="*/phone_verify*" component={PhoneVerifier} />
        <Route path="*/password_reset" component={PasswordReset} />
        <Route path="*/anon_password_reset*" component={AnonymousPasswordReset} />

        <Route path="*/start" key="checkerpage" component={CheckerPage} />
        <Route path="*/track" key="track" component={TrackOrder} />
        <Route path="*/rewards" key="rewards" component={Rewards} />
        <Route path="*/contact" key="contact" component={Contact} />
        <Route exact path="/tos" key="tos" component={TOS} />

        <Route path="*/abandoned" component={AbandonedCart} />

      </div>
    )
  }
}

const AppWithRouter = CartContextHOC(withRouter(App))

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Devnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
            new SolletWalletAdapter({ network }),
            new SolletExtensionWalletAdapter({ network }),
        ],
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect={false}>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

render(
  <BrowserRouter>
    <ErrorBoundary>
      <Context>
        <GoogleMapsJSLoaderProvider>
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
            <UserProvider>
              <AnalyticsProvider
                gaTrackingID={process.env.REACT_APP_GOOGLE_ANALYTICS_ID}
                version={process.env.REACT_APP_VERSION}
                subdomain={Subdomain.getSubdomain()}
              >
                <RestProvider>
                  <CartProvider>
                    <SignupModalProvider>
                      <AppWithRouter />
                    </SignupModalProvider>
                  </CartProvider>
                </RestProvider>
              </AnalyticsProvider>
            </UserProvider>
          </StripeProvider>
        </GoogleMapsJSLoaderProvider>
      </Context>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById("root")
)

registerCustomSW()
