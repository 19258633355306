import React, { Component } from "react"
import classnames from "classnames"
import AppPage from "../AppPage"
import MenuCategoryMobile from "./MenuCategoryMobile/MenuCategoryMobile"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import MenuCategoryDesktop from "./MenuCategoryDesktop/MenuCategoryDesktop"
import RestContextHOC from "../Contexts/RestContextHOC"
import CartContextHOC from "../Contexts/CartContextHOC"
import MenuCategoryScroll from "./MenuCategoryScroll/MenuCategoryScroll"
import Injection from '../Injection'
import "../css/Menu.css"
import MenuSearch from "./MenuSearch/MenuSearch"
import LoadingIndicator from "../Loading/LoadingIndicator"
import NoItemFound from "./MenuSearch/NoItemFound"
import TrackVisibility from "react-on-screen"
import { InView } from 'react-intersection-observer';
import scrollToComponent from "react-scroll-to-component"
import MenuHighlightBar from "./MenuHighlightBar"
import { objectValues } from "../helpers/objectValues"
import styles from "./MenuCategoryScroll/MenuCategoryScroll.module.scss"
import { MENU_TYPES } from "../constants"
import EntryPointHOC from "../Contexts/EntryPointHOC"
import withErrorHandling from "../Contexts/withErrorHandling/withErrorHandling"
import MenuLoading from "./MenuLoading/MenuLoading"
import { withRouter } from "react-router-dom"
import withWallet from "../withWallet" 
import WalletButton from "../WalletButton"
import Fade from 'react-reveal/Fade';

const api = require("../helpers/api")
const subdomain = require("../helpers/subdomain")
const categorySorter = require("../helpers/menuCategorySorter")

class Menu extends Component {
  constructor(props) {
    super(props)
    this.menu = React.createRef()
    let secondary_menu_id = null
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.secondary_menu_id
    ) secondary_menu_id = this.props.history.location.state.secondary_menu_id

    window.a = this
    this.state = {
      secondary_menu_id: secondary_menu_id,
      noItemFound: false,
      loaded: false,
      isSearchLoading: false,
      searchValue: "",
      clicked: false,
      clickedCategory: false,
      mobileExpanded: false,
      waitingForContextMenu:false,
      selectedCategory: null, 
      selectedIdx: null,
      menu_category_id:
        this.props.category && this.props.category.menu_category_id,

      expanded_item_ref: null, //keep a reference to expanded item so we can close it
      width: window.innerWidth,
      targets: document.querySelectorAll("#scrollTitle"),
      // user interacted is a flag to detect bounces vs. actual page views
      user_interacted: false,
      editItem:false,
      foundNFTs:this.props.cart.foundNFTs,
      nft_tab:'my_nft'
    }
    this.scrollToCategory = this.scrollToCategory.bind(this)
    this.setExpanded = this.setExpanded.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleSelectMenuChange = this.handleSelectMenuChange.bind(this)
    this.categoryTitle = {}
    this.menuHeight = null
  }

  componentWillMount(){
    this.props.history.listen((location, action) => {
      if (
        location.state && 
        location.state.secondary_menu_id && 
        this.state.secondary_menu_id !== location.state.secondary_menu_id
      ){
        // kill the state so that on refresh we get a clean slate
        window.history.replaceState(null, '')
        let state = {
          secondary_menu_id:location.state.secondary_menu_id, 
          menu:null,
          editItem:location.state.editItem
        }
        if(state.editItem){
          this.props.analytics.info("SecondaryMenuWithEditItem", 
            JSON.stringify(state.editItem)) 
        }
        this.setState({...state},()=>this.getMenu())
      } else if(this.state.secondary_menu_id){  //
        if(action === 'POP'){
          // don't go back to previous menu if we are popping from a menu item, popping off a menu item or popping back
          // to the original menu view
          if(
            this.state.secondary_menu_id === this.props.rest.menu.menu_id && 
            location.state && 
            (location.state.itemModal || 
             (location.state.fromItemModal &&  (location.state.current_menu === this.state.secondary_menu_id)) || 
             (location.state.secondary_menu_id === this.state.secondary_menu_id))
          ){
            return
          }
          this.setState({secondary_menu_id:this.props.rest.default_menu_id, menu:null}, 
           ()=>this.getMenu())
        }
      }
    })
  }

  componentDidMount() {
    this.getMenu()
    // we don't care about stuff below if we are redirecting
    this.setState({
      width: window.innerWidth,
      categoryOverflow: false,
      expandCategories: false,
    })
    window.addEventListener("resize", this.handleResize.bind(this))
    window.addEventListener("scroll", this.handleScroll)
    if (this.state.redirect_back_to_categories) return
    this.checkIfFatOpen()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
    window.removeEventListener("resize", this.handleResize)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.menu && !this.state.loading_menu && !this.state.waitingForContextMenu) {
      this.getMenu()
    }

    if(!this.state.menu && this.props.rest.menu && this.state.waitingForContextMenu && !this.state.loading_menu){
      this.getMenu()
    }

    if (
      prevProps.rest.sandboxed_menu_uuid !== this.props.rest.sandboxed_menu_uuid
    ) {
      this.getMenu()
    }

    if(JSON.stringify(this.state.foundNFTs) !== JSON.stringify(this.props.cart.foundNFTs)){
      this.setState({foundNFTs:this.props.cart.foundNFTs})
    }

    if(this.state.nftOwner !== this.props.cart.nftOwner){
      this.setState({nftOwner:this.props.cart.nftOwner})
    }

    if(prevProps.publicKey && !this.props.publicKey){
      this.props.cart.unsetFoundNFTs()
      this.props.cart.removeCoupon()
    }
    
  }

  async checkIfFatOpen(){
    if(window.location.href.indexOf('seabass') > -1 ){
      this.setState({fatOpen:true}) 
      return
    }


    if(window.location.href.indexOf('revel-lab') > -1 ){
      this.setState({fatOpen:true}) 
      return
    }


    let headers = {"Content-Type": "application/json"}
    let url = window.API_HOST + "/api/is-fatburger-open" 
    let r = await fetch(url, { method: "get", headers: headers}) 
    let resp = await r.json()
    this.setState({fatOpen:resp.open})
  }


  handleScroll() {
    if (window.scrollY > window.innerHeight && !this.state.user_interacted) {
      this.props.analytics.info("MenuPageScroll", "User interacted with page.")
      this.setState({ user_interacted: true })
    }
  }

  getMenu() {
    const menu_load_start = Date.now()
    const rest_subdomain = subdomain.getSubdomain()
    if (this.props.rest.sandboxed_menu_uuid) {
      //let menu_uuid = this.props.match.params.menu_uuid
      let menu_uuid = this.props.rest.sandboxed_menu_uuid

      this.setState({ loading_menu: true })
      api.callApi(
        "menu_by_id",
        (res) => {
          this.setState({
            selectedCategory: res.menu[Object.keys(res.menu)[0]],
            menu: res,
            loading_menu: false,
          })
          this.props.rest.setMenu(res)

          // If order is for on premise, lock menu
          if (this.props.rest.menu.menu_type === MENU_TYPES.ON_PREMISE) {
            this.props.rest.lockMenu()
            if (this.props.location.state && this.props.location.state.fromId) {
              this.props.cart.emptyCart()
            }
          }

          const menu_load_finish = Date.now()
          const menu_load_time = (menu_load_finish - menu_load_start) / 1000
          this.props.analytics.info("MenuPageLoaded", menu_load_time)
          if (res.ads && res.ads.length) {
            this.props.analytics.info("MegaphoneShown")
          }
        },
        (err) => console.log(err),
        null,
        null,
        menu_uuid
      )
    } else {
      if (this.state.secondary_menu_id) {
        this.setState({ loading_menu: true })
        api.callApi(
          "menu_by_id",
          (res) => {
            this.setState({
              selectedCategory: res.menu[Object.keys(res.menu)[0]],
              menu: res,
              loading_menu: false,
            }, ()=>{
              const menu_load_finish = Date.now()
              const menu_load_time = (menu_load_finish - menu_load_start) / 1000
              this.props.analytics.info("MenuPageLoaded", menu_load_time)
              if (res.ads && res.ads.length) {
                this.props.analytics.info("MegaphoneShown")
              }
              let that = this
              // this whole mess is for when we git switched to a secondary menu
              // by editing an item in the cart. We need to wait until the 
              // the rest context has the menu set before we can push the path
              // to edit item
              let callback = () => {
                if(that.state.editItem){
                  this.props.analytics.info("SecondaryMenuGoingToRedirectToEditItem")
                  that.props.history.push({
                    pathname: `/menu/${that.state.editItem.item.menu_item_id}`,
                    state: {
                      item: that.state.editItem.item,
                      editItem:that.state.editItem
                    },
                  })  
                  // not sure if this is neccessary
                  setTimeout(()=>that.setState({editItem:null}), 200)
                }
              }
              this.props.rest.setMenu(res, callback)
            })
          },
          (err) => console.log(err),
          null,
          null,
          this.state.secondary_menu_id
        )
      } else {
        // we should never get a menu here, this is the default case
        // and should be fetched by the rest context
        // race condition when refreshing from sandboxed.... if this
        // api call returns after the sandboxed one
        if (this.props.rest.sandboxed_menu_uuid) return
        if(this.props.rest.menu){
          this.setState({
            menu:this.props.rest.menu,
            loading_menu:false,
            selectedCategory:this.props.rest.menu[Object.keys(this.props.rest.menu)[0]]
          })
        } else {
          this.setState({waitingForContextMenu:true})
          return
        }
      }
    }
    this.props.rest.refreshBlackouts(this.props.cart.futureOrderTime)
  }

  detectCategoryOverflow() {
    this.setState({ categoryOverflow: true })
  }
  handleResize() {
    this.setState({ width: window.innerWidth, categoryOverflow: false })
  }

  setExpanded(status) {
    this.setState({ mobileExpanded: status })
  }

  clearSearch() {
    this.setState({
      searchValue: "",
      isSearchLoading: false,
      noItemFound: false,
      menu: this.props.rest.menu,
      mobileCategoryExpanded: true,
    })
  }

  onSearchChange(e) {
    e.preventDefault()
    if (!this.state.isSearchLoading) {
      this.setState({ isSearchLoading: true })
    }

    if (this.state.mobileCategoryExpanded) {
      this.setState({ mobileCategoryExpanded: false })
    }

    this.setState({ searchValue: e.target.value })
    if (!e.target.value) {
      this.clearSearch()
      return
    }
    const value = e.target.value.toLowerCase()
    let menu = JSON.parse(JSON.stringify(this.props.rest.menu.menu))
    let newMenu = {}
    Object.keys(menu).forEach((key) => {
      menu[key].items.forEach((item) => {
        const itemIdx = item.name.toLowerCase().indexOf(value)
        if (itemIdx !== -1 && key != 'Popular Menu Items') {
          if (!newMenu[key]) {
            newMenu[key] = JSON.parse(JSON.stringify(menu[key]))
            newMenu[key].items = []
          }
          newMenu[key].items.push(item)
        }
      })
    })
    this.categoryTitle = []
    window.scrollTo(0, 0)
    let updated_menu = JSON.parse(JSON.stringify(this.state.menu))
    updated_menu.menu = newMenu
    const noItemFound = Object.keys(newMenu).length === 0
    this.setState({
      isSearchLoading: false,
      menu: updated_menu,
      noItemFound,
    })
  }

  scrollToCategory(category, isInitialRender, selectedIdx) {
    // document.body.style.overflow = ""
    // document.body.style.position = ""
    // document.documentElement.style.overflow = ""
    // document.documentElement.style.position = ""
    // document.documentElement.style.height = ""

    if (category.menu_category_id) {
      scrollToComponent(this[category.menu_category_id], {
        align: "top",
        offset: -124,
        duration: 500,
      })
      this.setState({
        expandCategories: false,
        selectedIdx,
        menu_category_id: category.menu_category_id,
        selectedCategory: category,
      })

      // clickstream event, dont fire on initial render because
      // that will happen via the click from the big icon page
      if (!isInitialRender) {
        this.props.analytics.debug("MenuCategoryChange", category.name)
      }

      setTimeout(()=>this.updateCurrentCategory(category), 700)
    }
  }

  updateCurrentCategory(category, selectedIdx) {
    this.setState({
      selectedIdx,
      selectedCategory: this.state.menu.menu[category.name],
      menu_category_id: category.menu_category_id,
    })
  }

  closeExpandedItem() {
    if (this.state.expanded_item_ref) {
      this.state.expanded_item_ref.setState({ expanded: false })
    }
  }

  setMobileExpanded(status) {
    this.setState({ mobileCategoryExpanded: status })
  }

  handleCategoryStyle() {
    if (this.state.expandCategories)
      return {
        width: "calc(100vw - 136px)",
        paddingRight: "120px",
        height: "auto",
        whiteSpace: "normal",
      }
    if (this.state.categoryOverflow)
      return {
        width: "calc(100vw - 136px)",
        paddingRight: "120px",
        overflowX: "hidden",
      }
  }

  renderOverflowButton() {
    if (this.state.categoryOverflow) {
      return (
        <div
          className="MenuCategorySelectorBody MenuMore"
          onClick={() =>
            this.setState({ expandCategories: true, categoryOverflow: false })
          }
        >
          More...
        </div>
      )
    }
    if (this.state.expandCategories) {
      return (
        <div
          className="MenuCategorySelectorBody MenuMore"
          onClick={() =>
            this.setState({ expandCategories: false, categoryOverflow: true })
          }
        >
          Less...
        </div>
      )
    }
  }

  renderCategories() {
    if (this.state.width < 800) {
      return (
        <MenuCategoryMobile
          rest={this.props.rest}
          categories={this.state.menu.menu}
          menu_id={this.props.menu_id}
          setExpanded={this.setExpanded}
          selectedCategory={this.state.selectedCategory}
          menu_category_id={this.state.menu_category_id}
          scrollToCategory={this.scrollToCategory}
          value={this.state.searchValue}
          clearSearch={this.clearSearch}
          onSearchChange={this.onSearchChange}
          setMobileExpanded={(status) => this.setMobileExpanded(status)}
        />
      )
    } else {
      return (
        <div
          className="MenuCategoriesDesktop"
          id="Categories"
          style={this.handleCategoryStyle()}
        >
          {this.renderOverflowButton()}
          <MenuSearch
            onSearchChange={this.onSearchChange}
            value={this.state.searchValue}
          />

          {categorySorter
            .sortCategories(this.state.menu.menu)
            .map((category, index) => {
              return (
                <TrackVisibility key={index}>
                  <MenuCategoryDesktop
                    {...this.props}
                    key={category.menu_category_id}
                    selected={
                      category.menu_category_id === this.state.menu_category_id
                    }
                    scrollToCategory={this.scrollToCategory}
                    selectedIdx={index}
                    category={category}
                    expanded={this.state.expandCategories}
                    detectCategoryOverflow={this.detectCategoryOverflow.bind(
                      this
                    )}
                  />
                </TrackVisibility>
              )
            })}
        </div>
      )
    }
  }

  isOverflown(clientWidth, clientHeight, scrollWidth, scrollHeight) {
    return scrollHeight > clientHeight || scrollWidth > clientWidth
  }

  renderNFTBanner(){
    if (window.location.href.indexOf('irazu') > -1) return null

    if (this.state.nftOwner) return null

    let walletFound = false
    if(window.solana || window.solflare){
      walletFound = true
    }
    let crypto = false
    if(walletFound && this.props.rest.has_nft_promo){
      crypto = true
    }
    if(!crypto) return null

    return (
      <div className='crypto-banner-wrapper'>
        <div className='crypto-banner'>
          <div style={{display:'flex', alignItems:'center'}}>
            <img src='https://cdn.hngr.co/brand/Hold%20the%20Mayo/Hold%20The%20Mayo-The%20Garage/menu_images/23021262-c03f36b8-15c1-47c0-b9ed-a83a5596f502.gif' style={{height:'64px', width:'64px'}}/>
            <div className='unlock-exclusive'>
              Unlock exclusive rewards with a Fatburger NFT
            </div>
          </div>
          {this.props.publicKey && !this.state.nftOwner && (
            <div className='looking-for-nft'>
              <div>Searching for NFT...</div>
              <img src='https://cdn.hngr.co/nftgreenspinner.gif'/>
            </div>
          )}
          {!this.props.publicKey && (<WalletButton />)}
        </div>
      </div>



    )
  }

  renderNFT(){
    console.log("render nft")
    let sd = subdomain.getSubdomain()
    if(sd.indexOf('irazu') > -1 ){
      return this.renderIrazuNFT()
    } else{
      return this.renderFatNFT()
    }
  }

  renderFatNFT(){
    let publicKey = this.props.publicKey
    if(!publicKey){
      return null
    }
    if(!this.state.nftOwner){
      return null
    }
    
    let str = this.props.publicKey.toString()
    let address = str.substr(0,4) + '...' + str.substr(str.length-4, str.length)

    
    return (
      <div className='nft-outer-container' key='yay'>
        <Fade top>
          <div className='nft-container-irazu'>
            <div className='nft-tabs'>
              <div 
                className={classnames('nft-tab', {'nft-tab-selected':this.state.nft_tab === 'my_nft'})}
                onClick={()=>this.setState({nft_tab:'my_nft'})}
              >
                <div>MY NFT</div>
              </div>

              <div 
                className={classnames('nft-tab', {'nft-tab-selected':this.state.nft_tab === 'my_perks'})}
                onClick={()=>this.setState({nft_tab:'my_perks'})}
              >
                <div>MY PERKS</div>
              </div>

              <div 
                className={classnames('nft-tab', {'nft-tab-selected':this.state.nft_tab === 'help'})}
                onClick={()=>this.setState({nft_tab:'help'})}
              >
                <div>HELP</div>
              </div>
            </div>


            {this.state.nft_tab === 'my_nft' && (

              <div className='nft-irazu-right'>
                <div className='nft-irazu-right-img-box'>
                  <img src='https://cdn.hngr.co/fat-cropped.gif'/>
                </div>

                <div className='nft-irazu-right-content'>
                  <div className='nft-irazu-right-content-top'>
                    <div className='nft-detected'>
                      <div>NFT Detected</div>
                    </div>

                    <div className='nft-irazu-right-content-title'>
                      NATIONAL HAMBURGER DAY NFT ‘22
                    </div>

                    <div className='nft-irazu-right-blurb'>
                      Welcome to Fatburger in Web3. We're happy to see you.
                    </div>
                  </div>

                  <div className='nft-irazu-right-content-bottom' style={{display:'flex'}}>
                    <div className='nft-irazu-edition-box' style={{marginRight:'16px'}}>
                      <div className='nft-irazu-edition-box-title'>{address}</div>
                      <div className='nft-irazu-edition-box-subtitle'>Address</div>
                    </div>

                    <div className='nft-irazu-edition-box'>
                      <div className='nft-irazu-edition-box-title'>{this.props.cart.nftNumber}/500</div>
                      <div className='nft-irazu-edition-box-subtitle'>Edition</div>
                    </div>
                  </div>

                </div>
              </div>
            )}

            {this.state.nft_tab === 'my_perks' && this.state.fatOpen && (
              <div className='nft-irazu-right'>
                <div style={{width:'240px', height:'24p0x'}}>
                  <img style={{height:'240px'}} src='https://cdn.hngr.co/fatburger-square.png'/>
                </div>

                <div className='nft-irazu-right-content'>
                    <div className='nft-detected'>
                      <div>NFT Detected</div>
                    </div>

                    <div className='nft-irazu-right-content-title'>
                      1 FREE ORIGINAL FATBURGER
                    </div>

                    <div className='nft-irazu-right-blurb'>
                      Enjoy an Original Fatburger on the house, courtesy of Fatburger and the Supper Club.
                    </div>

                  <div className='nft-irazu-right-content-bottom'>
                    {!(this.props.cart.coupon  && this.props.cart.coupon.is_nft_coupon) && !this.props.cart.nftAlreadyRedeemed  ? (
                      <div 
                        className={classnames('nft-irazu-green-button',
                          {'nft-irazu-button-claimed':this.props.cart.coupon && this.props.cart.coupon.is_nft_coupon})
                        }
                        onClick={async ()=>this.redeemFatNFT()}
                      >
                        <div>Claim Perk</div>
                      </div>
                    ) : (
                      <div style={{display:'flex'}}>
                        <div className='redeemable-june-1'>
                          <div>Perk Claimed</div>
                        </div>
                      </div>
                    )}


                  </div>
                </div>
              </div>
            )}


            {this.state.nft_tab === 'my_perks' && !this.state.fatOpen && (
              <div className='nft-irazu-right'>
                <div style={{width:'240px', height:'240px'}}>
                  <img style={{height:'240px'}} src='https://cdn.hngr.co/fatburger-dim.png'/>
                </div>

                <div className='nft-irazu-right-content'>
                  <div className='nft-irazu-right-content-top'>
                    <div className='nft-detected'>
                      <div>NFT Detected</div>
                    </div>

                    <div className='nft-irazu-right-content-title'>
                      Perks Coming Soon
                    </div>

                    <div className='nft-irazu-right-blurb'>
                      Come back June 1st to officially redeem your exclusive NFT Perk: 1 Free Original Fatburger.
                    </div>
                  </div>

                  <div className='nft-irazu-right-content-bottom' style={{display:'flex'}}>
                    <div className='redeemable-june-1'>
                      <div>Redeemable June 1, 2022</div>
                    </div>
                  </div>

                </div>
              </div>
            )}

            {this.state.nft_tab === 'help' && (
              <div className='nft-irazu-right'> 
                <div className='nft-irazu-help-box' style={{marginRight:'42px'}}>
                  <div className='nft-irazu-help-box-title'>
                    SUPPER CLUB
                  </div>
                  <div className='nft-irazu-help-box-blurb'>
                    Restaurants are the heart-and-soul of every local community and Web3 should improve restaurant-customer relationships. Supper Club partners with the best local restaurants and offers our hungry community new perks and access in Web3. Learn more about us.
                  </div>
                </div>

                <div className='nft-irazu-help-box' style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between', height:'100%'}}>
                  <a 
                    className='nft-irazu-help-box-green-button'
                    target='_blank'
                    href='https://supperclub.xyz'
                  >
                    <div>Learn More</div>
                  </a>

                  <a 
                    className='nft-irazu-help-box-white-button'
                    target='_blank'
                    href='https://fatburger.com/nft-nationalhamburgerday'
                  >
                    <div>Terms &amp; Conditions</div>
                  </a>

                  <a 
                    className='nft-irazu-help-box-discord-button'
                    target='_blank'
                    href='https://discord.gg/2AH3WCuaSP'
                  >
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                      <div style={{marginRight:'4px'}}>Join Supper Club Discord</div>
                      <Discord style={{marginLeft:'4px'}}/>
                    </div>
                  </a>


                </div>


              </div>
            )}

 

          </div>
        </Fade>
      </div>
    )
  }



  renderIrazuNFT(){
    let publicKey = this.props.publicKey
    if(!publicKey){
      console.log("nopenope2")
      return null
    }
    if(!this.state.nftOwner){
      console.log("nopenopenope")
      return null
    }
    console.log("yepyepyep")
    return (
      <div className='nft-outer-container' key='yay'>
        <Fade top>
          <div className='nft-container-irazu'>
            <div className='nft-tabs'>
              <div 
                className={classnames('nft-tab', {'nft-tab-selected':this.state.nft_tab === 'my_nft'})}
                onClick={()=>this.setState({nft_tab:'my_nft'})}
              >
                <div>MY NFT</div>
              </div>

              <div 
                className={classnames('nft-tab', {'nft-tab-selected':this.state.nft_tab === 'my_perks'})}
                onClick={()=>this.setState({nft_tab:'my_perks'})}
              >
                <div>MY PERKS</div>
              </div>

              <div 
                className={classnames('nft-tab', {'nft-tab-selected':this.state.nft_tab === 'help'})}
                onClick={()=>this.setState({nft_tab:'help'})}
              >
                <div>HELP</div>
              </div>
            </div>


            {this.state.nft_tab === 'my_nft' && (

              <div className='nft-irazu-right'>
                <div className='nft-irazu-right-img-box'>
                  <img src='https://cdn.hngr.co/irazu_cropped_mint.gif'/>
                </div>

                <div className='nft-irazu-right-content'>
                  <div className='nft-irazu-right-content-top'>
                    <div className='nft-detected'>
                      <div>NFT Detected</div>
                    </div>

                    <div className='nft-irazu-right-content-title'>
                      IRAZU X SUPPER CLUB NFT
                    </div>

                    <div className='nft-irazu-right-blurb'>
                      Irazu - Bucktown's famous Costa Rican restaurant teams up with Supper Club to drop 100 limited edition NFTs with perks to feed Web3.
                    </div>
                  </div>

                  <div className='nft-irazu-right-content-bottom'>
                    <div className='nft-irazu-edition-box'>
                      <div className='nft-irazu-edition-box-title'>{this.props.cart.nftNumber}/100</div>
                      <div className='nft-irazu-edition-box-subtitle'>Edition</div>
                    </div>
                  </div>

                </div>
              </div>
            )}


            {this.state.nft_tab === 'my_perks' && (
              <div className='nft-irazu-right'>
                <img style={{width:'240px', height:'240px'}} src='https://cdn.hngr.co/oatmeal_shake.png'/>

                <div className='nft-irazu-right-content'>
                  <div className='nft-irazu-right-content-top'>
                    <div className='nft-detected'>
                      <div>NFT Detected</div>
                    </div>

                    <div className='nft-irazu-right-content-title'>
                      CLAIM YOUR PERK
                    </div>

                    <div className='nft-irazu-right-blurb'>
                      Surprise, surprise! You get 1 free oatmeal shake, courtsey of Irazu and the Supper Club.
                    </div>
                  </div>

                  <div className='nft-irazu-right-content-bottom'>
                    <div 
                      className={classnames('nft-irazu-green-button',
                        {'nft-irazu-button-claimed':this.props.cart.coupon && this.props.cart.coupon.is_nft_coupon})
                      }
                      onClick={async ()=>{
                        if(this.props.cart.finance.promo) return
                        let msg = new TextEncoder().encode("I want my free shake!")
                        let signature = await this.props.signMessage(msg)
                        let signatureBase64 = Buffer.from(signature).toString('base64')
                        let url = window.API_HOST + "/api/verify-nft-message-irazu" 
                        let headers = {"Content-Type": "application/json"}
                        let data = {
                          signature:signature,
                          nft_address:this.props.cart.nftAddress,
                          wallet_address:this.props.publicKey.toString(),
                          subdomain:subdomain.getSubdomain()
                        }
                        let r = await fetch(url, { method: "post", headers: headers, body: JSON.stringify(data) }) 
                        let resp = await r.json()
                        console.log("check respopnse", resp)
                        if(resp.error){
                          alert(resp.message)
                          api.callApi(
                            "post-to-nft-slack",
                            (resp)=>{console.log(resp)},
                            (error)=>{console.log("error posting top slack", error)},
                            {msg:"[ordering site - clicked claim perk but got error]  - "+resp.message + " - " + this.props.publicKey.toString() + " - " + window.CLICKSTREAM_SESSION_ID}
                          )
                        } else{
                          api.callApi(
                            "post-to-nft-slack",
                            (resp)=>{console.log(resp)},
                            (error)=>{console.log("error posting top slack", error)},
                            {msg:"[ordering site - clicked claim perk item modal popped up! ] - " + this.props.publicKey.toString() + " - " + window.CLICKSTREAM_SESSION_ID}
                          )
                          this.props.cart.inputCoupon(resp.keycode, null, null, false, true)
                        }
                      }}
                    >
                      <div>Claim Perk</div>
                    </div>
                  </div>

                </div>
              </div>
            )}

            {this.state.nft_tab === 'help' && (
              <div className='nft-irazu-right'> 
                <div className='nft-irazu-help-box' style={{marginRight:'42px'}}>
                  <div className='nft-irazu-help-box-title'>
                    SUPPER CLUB
                  </div>
                  <div className='nft-irazu-help-box-blurb'>
                    Restaurants are the heart-and-soul of every local community and Web3 should improve restaurant-customer relationships. Supper Club partners with the best local restaurants and offers our hungry community new perks and access in Web3. Learn more about us.
                  </div>
                </div>

                <div className='nft-irazu-help-box' style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  <a 
                    className='nft-irazu-help-box-green-button'
                    target='_blank'
                    href='https://irazu.supperclub.xyz/mint/guide/how-to'
                  >
                    <div>Learn More</div>
                  </a>

                  <a 
                    className='nft-irazu-help-box-discord-button'
                    target='_blank'
                    href='https://discord.gg/2AH3WCuaSP'
                  >
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                      <div style={{marginRight:'4px'}}>Join Discord</div>
                      <Discord style={{marginLeft:'4px'}}/>
                    </div>
                  </a>


                </div>


              </div>
            )}

 

          </div>
        </Fade>
      </div>
    )
  }





  renderMenuMegaphones() {
    if (this.state.menu && this.state.menu.ads && this.state.menu.ads.length) {
      return (
        <div className="MegaphoneWrapper">
          {this.state.menu.ads.map((ad, i) => (
            <MenuHighlightBar
              key={i}
              content={ad.content}
              img_url={ad.img_url}
              button_text={ad.button_text}
              button_action={ad.button_action}
            />
          ))}
        </div>
      )
    }
  }


  // if theres > 4 menus and we're on desktop,
  // add some special rules
  secondaryMenuContainerStyle(){
    if (window.innerWidth < 800)  return
    if (this.props.rest && this.props.rest.menus && this.props.rest.menus.length >= 5){
      return {
        top:'23px'
      }
    }
  }

  handleSelectMenuChange(e){
    this.props.history.push({
      pathname: '/menu',
      state:{  secondary_menu_id: e.target.value }
    })
  }

  renderSecondaryMenu() {
    if (this.props.rest.sandboxed_menu_uuid) return

    if (this.props.rest.menus && this.props.rest.menus.length > 1) {
      return (
        <div className='menu-select-container'>
          <div>Choose a Menu</div>
          <select className='menu-select' onChange={this.handleSelectMenuChange}>
            {this.props.rest.menus.map((menu) => {
              if (menu.uniq_uuid == this.state.menu.menu_id) {
                return(
                <option
                  key={menu.uniq_uuid}
                  selected = {true}>
                    {menu.name === "Menu" ? "Regular Menu" : menu.name}
                </option>)
              }
              if (menu.uniq_uuid !== this.state.menu.menu_id) { //check if on current option. remove if statement, use it to decide what first option is shown based on selected == {true}
                return (
                  <option
                    className="SecondaryMenu"
                    key={menu.uniq_uuid}
                    value={menu.uniq_uuid}
                    // onChange={() =>{
                    //   this.props.history.push({
                    //     pathname: '/menu',
                    //     state:{  secondary_menu_id: menu.uniq_uuid }
                    //   })
                    // }} 
                      /*
                      this.setState({ secondary_menu_id: menu.uniq_uuid }, () =>
                      this.getMenu()
                      )
                      */
                    
                  >
                    {menu.name === "Menu" ? "Regular Menu" : menu.name}
                  </option>
                )
              } 
            })}
          </select>
        </div>
      )
    } else return null
  }



  async redeemFatNFT(){
    this.setState({fatburgerModalOpen:false})
    let msg = new TextEncoder().encode("I want my free Fatburger!")
    let signature = await this.props.signMessage(msg)
    let signatureBase64 = Buffer.from(signature).toString('base64')
    let url = window.API_HOST + "/api/verify-nft-message-irazu" 
    let headers = {"Content-Type": "application/json"}
    let data = {
      signature:signature,
      nft_address:this.props.cart.nftAddress,
      wallet_address:this.props.publicKey.toString(),
      subdomain:subdomain.getSubdomain()
    }
    let r = await fetch(url, { method: "post", headers: headers, body: JSON.stringify(data) }) 
    let resp = await r.json()
    console.log("check respopnse", resp)
    if(resp.error){
      alert(resp.message)
      api.callApi(
        "post-to-nft-slack",
        (resp)=>{console.log(resp)},
        (error)=>{console.log("error posting top slack", error)},
        {msg:"[ordering site - clicked claim perk but got error]  - "+resp.message + " - " + this.props.publicKey.toString() + " - " + window.CLICKSTREAM_SESSION_ID}
      )
    } else{
      api.callApi(
        "post-to-nft-slack",
        (resp)=>{console.log(resp)},
        (error)=>{console.log("error posting top slack", error)},
        {msg:"[ordering site - clicked claim perk item modal popped up! ] - " + this.props.publicKey.toString() + " - " + window.CLICKSTREAM_SESSION_ID}
      )
      this.props.cart.inputCoupon(resp.keycode, null, null, false, true)
    }
  }


  render() {
    let that = this
    return (
      <AppPage footer footerClassName="legal-footer-menu">

        {this.state.menu ? (
          <div ref={this.menu} className="MenuContainer">
            <Injection context={'MENU_PAGEVIEW'} />
            {this.renderCategories()}
           <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                paddingTop: "calc(60px + var(--supperclub-back-height))",
              }}
            >
              {this.renderMenuMegaphones()}
              {this.renderNFTBanner()}
              {this.renderNFT()}
              {this.state.isSearchLoading ? (
                <LoadingIndicator />
              ) : !this.state.noItemFound ? (
                categorySorter
                  .sortCategories(objectValues(this.state.menu.menu))
                  .map((category, index) => (
                    <section
                      style={{ position: "relative" }}
                      ref={(section) => {
                        this[category.menu_category_id] = section
                      }}
                      key={index}
                    >
                      <div className='SecondaryMenuContainer' style={this.secondaryMenuContainerStyle()}>
                        {index === 0 && this.renderSecondaryMenu()}
                      </div>
                     <InView 
                        as="div" 
                        style={{position:'relative', top:'130px'}}
                        threshold={1}
                        onChange={(inView, entry) => {
                          if(inView){
                            that.updateCurrentCategory(category)
                          } 
                        }}
                      >
                        <span style={{width:'10px', height:'1px'}}></span>
                      </InView>
                        <MenuCategoryScroll
                          category={category}
                          searchValue={this.state.searchValue}
                          clearSearch={this.clearSearch}
                          key={category.menu_category_id}
                          index={index}
                          menu={this.state.menu}
                          categoryTitle={(el) =>
                            (this.categoryTitle[category.name] = el)
                          }
                          setExpanded={this.setExpanded}
                          updateCurrentCategory={(cat) =>
                            this.updateCurrentCategory(cat)
                          }
                        />
                     <InView 
                        as="div" 
                        threshold={1}
                        style={{position:'relative', top:'-200px'}}
                        onChange={(inView, entry) => {
                          if(inView){
                            that.updateCurrentCategory(category)
                          } 
                        }}
                      >
                        <span style={{width:'10px', height:'1px'}}></span>
                      </InView>
                    </section>
                  ))
              ) : (
                <NoItemFound value={this.state.searchValue} />
              )}
              {this.state.searchValue && (
                <button className={styles.clear} onClick={this.clearSearch}>
                  CLEAR SEARCH
                </button>
              )}
            </div>
          </div>
        ) : (
          <MenuLoading rest={this.props.rest} />
        )}
      </AppPage>
    )
  }
}

export default withWallet(withRouter(EntryPointHOC(
  AnalyticsContextHOC(RestContextHOC(CartContextHOC(withErrorHandling(Menu))))
)))

const Discord = props => (<svg {...props} width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.331 2.22784C18.7571 1.49141 17.092 0.968143 15.3797 0.671875C15.1664 1.05747 14.9172 1.57609 14.7454 1.98869C12.8993 1.71109 11.0701 1.71109 9.2579 1.98869C9.08615 1.57619 8.83134 1.05747 8.61609 0.671875C6.90214 0.96828 5.23561 1.49288 3.66092 2.23169C0.527415 6.96691 -0.322056 11.5844 0.102632 16.1365C2.18098 17.6885 4.19511 18.6314 6.1753 19.2483C6.66743 18.5716 7.10246 17.8551 7.4759 17.1063C6.76492 16.8356 6.07951 16.502 5.42783 16.1095C5.59933 15.9824 5.76679 15.8499 5.92996 15.7123C9.8789 17.5593 14.1697 17.5593 18.0715 15.7123C18.2354 15.849 18.4028 15.9815 18.5736 16.1095C17.9208 16.503 17.2342 16.8373 16.5218 17.1082C16.8973 17.8601 17.3316 18.5773 17.8224 19.2502C19.8044 18.6333 21.8204 17.6905 23.8988 16.1365C24.3972 10.8595 23.0475 6.28431 20.331 2.22775V2.22784ZM8.01393 13.337C6.82846 13.337 5.85627 12.2303 5.85627 10.8827C5.85627 9.535 6.80774 8.42641 8.01393 8.42641C9.22021 8.42641 10.1923 9.53303 10.1716 10.8827C10.1735 12.2303 9.22021 13.337 8.01393 13.337ZM15.9875 13.337C14.802 13.337 13.8299 12.2303 13.8299 10.8827C13.8299 9.535 14.7813 8.42641 15.9875 8.42641C17.1938 8.42641 18.1659 9.53303 18.1451 10.8827C18.1451 12.2303 17.1938 13.337 15.9875 13.337Z" fill="#39F376"/>
</svg>)
