import React, { Component } from "react"
import ScrollLock, { TouchScrollable } from "react-scrolllock"
import { ButtonWrapper } from "../atoms"
import { ReactComponent as Close } from "../assets/icons/modal-close.svg"
import "./Modal.css"

class Modal extends Component {
  static defaultProps = {
    close: () => {},
    isUnclosable: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      closing: false,
    }

    this.handleEsc = this.handleEsc.bind(this)
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleEsc)
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEsc)
  }

  handleEsc(e) {
    if (e.key === "Escape") {
      this.closeModal(e, true)
    }
  }

  closeModal = (e, override) => {
    if (this.props.isUnclosable) return

    if (
      e.target.className === "Background" ||
      e.target.classList.contains("Close") ||
      override === true
    ) {
      this.setState({ closing: true }, () => {
        window.setTimeout(() => {
          this.props.close()
        }, 500)
      })
    }
  }

  render() {
    return (
      <ScrollLock>
        <div
          className="Background"
          id={this.state.closing ? "BackgroundExit" : null}
          onClick={(e) => this.closeModal(e, "background")}
          style={{...this.props.backgroundStyle}}
        >
          <TouchScrollable>
            <div
              className="Modal"
              id={this.state.closing ? "ModalExit" : null}
              style={{ ...this.props.style }}
              onClick={null}
            >
              {!this.props.isUnclosable && !this.props.stickyX && !this.props.hideX && (
                <ButtonWrapper className="Close" onClick={this.closeModal}>
                  <Close aria-label="Close modal" />
                </ButtonWrapper>
              )}
             
              {this.props.stickyX && (
                <div className='modal-down-X-outer-container'> 
                  <div 
                    onClick={(e)=>this.closeModal(e,true)} 
                    className='modal-down-X-container'
                    style={{position:'absolute'}}
                  >
                    <Close aria-label="Close modal" />
                  </div>
                </div>
              )}

              {this.props.children}
            </div>
          </TouchScrollable>
        </div>
      </ScrollLock>
    )
  }
}

export default Modal
