import React from "react";
import AnalyticsContextHOC from "./Contexts/AnalyticsContextHOC";
import AppPage from './AppPage'
import { withRouter } from "react-router-dom";

const ErrorPage = props => {

  props.analytics.error('404BadUrl',window.location.href)

  return (
  <AppPage hideCart>
    <div style={{width: '80vw', left: '10vw', height: '80vh', position:'fixed', display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', textAlign:'center'}}>
      <h1 style={{fontWeight:900}}>Whoops!</h1>
      <img src='/Empanada.png' style={{width: '96px'}} alt='empanada'/>
      <strong>Looks like you found a bad URL. Feel free to click the chat icon if you need help!</strong>
      <br/>
      <button style={{background:'#4284f4'}} className='hngrButton' onClick={()=>props.history.push('/menu')}>MENU</button>
      <button style={{background:'#4284f4'}} className='hngrButton' onClick={()=>props.history.push('/orders')}>ORDERS</button>
      <button style={{background:'#4284f4'}} className='hngrButton' onClick={()=>props.history.push('/menu')}>ACCOUNT</button>
    </div>
    </AppPage>
  )
}

export default withRouter(AnalyticsContextHOC(ErrorPage));
