import React from "react";
import HalfToppings from "./HalfToppings/HalfToppings";
import RestContextHOC from "../Contexts/RestContextHOC";
const formatPrice = require("../helpers/formatPrice");

class HalfToppingModifier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blackedOut:false,
      halfSelected: false // we care about half-selected because it impacts what
      // price we show
    };
    this.selectHalf = this.selectHalf.bind(this);
    this.selectNotHalf = this.selectNotHalf.bind(this);
  }


  // if theere is a coupon modifier selected, then ignore any other is-defaults
  componentDidMount() {
    this.checkIfBlackedOut()
  }

  componentDidUpdate(prevProps){
    if(
      prevProps.rest.itemBlackouts.length !=
      this.props.rest.itemBlackouts.length
    ){
      this.checkIfBlackedOut()
    }
  }

  checkIfBlackedOut(){
    if(!this.props.modifier.item) return

    let menu_item_id = this.props.modifier.item.menu_item_id
    if(this.props.rest.itemBlackouts.indexOf(menu_item_id) > -1){
      this.setState({blackedOut:true})
    } else{
      this.setState({
        blackedOut:false
      })
    }
  }

  selectHalf() {
    this.setState({ halfSelected: true });
  }

  selectNotHalf() {
    this.setState({ halfSelected: false });
  }

  // if a "left" or "right" is selected, then we want to display that price,
  // otherwise we display the full modifier price
  displayPrice() {
    if (
      this.state.halfSelected &&
      this.props.modifierClass.half_price_override
    ) {
      let price = this.props.modifierClass.half_price_override;
      return "+" + formatPrice.formatPrice(price);
    } else if (
      this.state.halfSelected &&
      this.props.modifierClass.half_price_override_dollars
    ) {
      // add .001 to deal with floating point .05 going to .049999999999
      let price = Math.round(this.props.modifierClass.half_price_override_dollars*100+.001);
      return "+" + formatPrice.formatPrice(price);
     } else if (this.state.halfSelected && this.props.modifier.item.half_price) {
      let price = this.props.modifier.item.half_price;
      return "+" + formatPrice.formatPrice(price);
    } else {
      return this.props.modifier.display_price;
    }
  }

  containerStyle(){
    let style = JSON.parse(JSON.stringify(styles.container))
    if(this.state.blackedOut){
      style['pointerEvents'] = 'none'
      style['opacity'] = 0.5
    }
    return style
  }

  render() {
    return (
      <div style={this.containerStyle()}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <HalfToppings
            selectHalf={this.selectHalf}
            editModifiers={this.props.editModifiers}
            selectNotHalf={this.selectNotHalf}
            modifier={this.props.modifier}
            addModifier={this.props.addModifier}
            removeModifier={this.props.removeModifier}
          />
          <div style={styles.dishName}>{this.props.modifier.name}</div>
        </div>
        <div
          style={{
            ...styles.price,
            fontWeight: this.state.halfSelected ? "600" : "400"
          }}
        >
          {this.displayPrice()}
        </div>
      </div>
    );
  }
}

var styles = {
  container: {
    padding: "5px",
    display: "flex",
    alignItems: "center"
  },
  dishName: {
    paddingLeft: "8px",
    paddingRight: "8px"
  },
  price: {
    flexGrow: "1",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "12px",
    fontWeight: "400",
    color: "#22252B"
  }
};

export default RestContextHOC(HalfToppingModifier);
