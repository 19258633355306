import React from "react";
import RestContextHOC from "../Contexts/RestContextHOC";
import UserContextHOC from "../Contexts/UserContextHOC";
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from 'react-router-dom'
import '../css/MenuHighlightBar.css';

class MenuHighlightBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        showModal: true
    }
  }

  closeHighlightBar(){
    this.props.analytics.info('MegaphoneClosed')
    var container = this.currentMegaphone
    container.style.animationDelay = 0
    container.style.animation = 'shrink-out .2s'
    setTimeout(()=>{
      this.setState({showModal: false})
    }, 200)
  }

  componentDidUpdate(prevProps) {
    if(!this.state.ad_type && this.props.ad_type) {
      this.setState({ad_type: this.props.ad_type})
    }
  }

  renderLeftImage(){
    if(this.props.left_image_url !== null) {
      return(<div><img className='leftImage' alt='' src={this.props.left_image_url}></img></div>)
    }
  }

  renderCenterText(){
    return ReactHtmlParser(this.props.center_text)
  }

  handleButton(){
    if (!this.props.user.user){
      this.props.history.push(`/menu/auth`)
    } else {
      if (this.props.button_action === 'rewards'){
        this.props.history.push(`menu/rewards`)
      }
      if (this.props.button_action === 'history'){
        this.props.history.push(`/orders`)
      }
      if (this.props.button_action === 'open'){
        this.props.history.push(`orders`)
      }
    }
  }

  render() {
    if(this.state.showModal && this.props.content){
      return(
        <div className='MegaphoneContainer' ref={megaphone => {this.currentMegaphone = megaphone}}>
          {this.props.img_url && <span><img src={this.props.img_url} className='MegaphoneImg' alt='announcement'/><br/></span>}
          
          {ReactHtmlParser(this.props.content)}
          {this.props.button_text && <div><button className='MegaphoneButton hngrButton' style={{background:this.props.rest.hex_color_secondary}} onClick={()=>this.handleButton()}>{this.props.button_text}</button></div>}
          <div className='MegaphoneClose' onClick={()=>this.closeHighlightBar()}>X</div>
        </div>
      )
    } else return null
  }

}
export default withRouter(
  AnalyticsContextHOC(RestContextHOC(UserContextHOC(MenuHighlightBar))))
