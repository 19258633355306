import React from "react"
import "../../css/MenuItem.css"

const TempOut = () => {
  return (
    <div className="menu-item--temp-out__wrapper ml_1">
      <div className="menu-item--temp-out">Temporarily Out</div>
    </div>
  )
}

export default TempOut
