import React from 'react'
import RecentOrder from './RecentOrder'
import OpenOrder from './OpenOrder'
import AppPage from '../AppPage'
import Loading from '../Loading/Loading'
import AnalyticsContextHOC from '../Contexts/AnalyticsContextHOC'
import UserContextHOC from '../Contexts/UserContextHOC'
import RestContextHOC from '../Contexts/RestContextHOC'
import EntryPointHOC from '../Contexts/EntryPointHOC'
import { Link } from 'react-router-dom'
const Api = require('../helpers/api')

class Orders extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      loading:true,
      open_orders:[],
      previous_orders:[],
      width: window.innerWidth,
      locked:!!this.props.rest.sandboxed_menu_uuid
    }
  }

  componentDidMount(){
    if (this.props.user.user){
      this.loadRecentOrders(this.props.rest.rest_id, this.props.user.user.uniq_uuid)
      this.loadOpenOrders()
    }
    this.props.analytics.pageview('/orders')
  }
  
  componentDidUpdate(prevProps){
    console.log("rest", this.props.rest.sandboxed_menu_uuid)
    if(!prevProps.user.user && this.props.user.user){
      this.loadRecentOrders(this.props.rest.rest_id, this.props.user.user.uniq_uuid)
      this.loadOpenOrders()
    }

    if(!!this.props.rest.sandboxed_menu_uuid !== this.state.locked){
      this.setState({locked:!!this.props.rest.sandboxed_menu_uuid})
    }
  }


  //load the open orders from the api. If this is called with a null diner_id, do nothing.
  //that can happen if we load this page directly and the user context isn't loaded yet
  loadRecentOrders(rest_id, diner_id){
    if (!rest_id){
      window.setTimeout(()=>this.loadRecentOrders(this.props.rest.rest_id,this.props.user.user.uniq_uuid),200)
      return
    }
    if (diner_id === null) return
    Api.callApi(
      'recent_orders',
      (data)=>this.handleApiSuccess(data),
      ()=>{},
      null,
      null,
      [this.props.rest.rest_id,this.props.user.user.uniq_uuid],
      null
    )
  }

  handleApiSuccess(data){
    this.setState({previous_orders:data.orders, loading:false})
  }

  checkRecentOrders(){
    if (this.state.previous_orders.length < 1 || this.state.previous_orders === undefined) {
      return (
        <div className='recentOrderEmptyText'>
            You do not have any past orders.
        </div>
      )    
    }
    else {
      return(
        this.state.previous_orders.map((order,i) => {
          let noBorder = false
          let numOrders = Object.keys(this.state.previous_orders).length
          if (i === numOrders - 1){
            noBorder = true
          }
          
          if((order.delivery_or_pickup === 'dine in' && this.state.locked) ||
             (order.delivery_or_pickup !== 'dine in' && !this.state.locked)
          )
          {
            return(
              <RecentOrder key={order.hash_id} order={order} rest={this.props.rest} noBorder={noBorder}/>
            )
          }
        })
      )
    }
  }

  loadOpenOrders(){
    if (this.props.user.user.uniq_uuid && this.props.rest.rest_id){
      Api.callApi(
        'open_orders',
        (data)=>{this.setState({open_orders:data.open_orders, loading:false})},
        (err)=>{console.log(err)},
        {diner_id:this.props.user.user.uniq_uuid, rest_id:this.props.rest.rest_id}
      )
    } else {
      window.setTimeout(()=>{this.loadOpenOrders()},200)
    }
  }

  checkOpenOrders(){
    if (this.state.open_orders.length < 1 || this.state.open_orders === undefined) {
      return (
        <div className='recentOrderEmptyText'>
            You do not currently have any open orders.
        </div>
      )    
    }
    else {
      return(
        this.state.open_orders.map((order,i) => {
          let noBorder = false
          let numOrders = Object.keys(this.state.open_orders).length
          if (i === numOrders - 1){
            noBorder = true
          }
          return(
            <OpenOrder key={order.hash_id} order={order} rest={this.props.rest} noBorder={noBorder}/>
          )
        })
      )
    }
  }

  formatPhoneNumber(){
    if (this.props.rest.phone){
      let phone = this.props.rest.phone
      phone = phone.replace(/-/g, "")
      phone = phone.slice(0,3)+"-"+phone.slice(3,6)+"-"+phone.slice(6,15)
      return phone
    }
    else{
      window.setTimeout(()=>{this.formatPhoneNumber()},500)
    }
  }

  render(){
    if(this.state.loading) {
      return ( <AppPage {...this.props}><Loading/></AppPage> )
    }
    return (
      <AppPage {...this.props}>
        <div className='recentOrderPage'>
          <Link to='/menu'>
            <button className='hngrButton BackToMenuButton' style={{background: this.props.rest.hex_color_secondary}}>
              Back to Menu
            </button>
          </Link>
          <div className='recentOrderHeader'>Open Orders</div>
          {this.checkOpenOrders()}
          <div className='recentOrderHeader'>Previous Orders</div>
          {this.checkRecentOrders()}
        </div>
      </AppPage>
    )
  }
}

export default EntryPointHOC(AnalyticsContextHOC(RestContextHOC(UserContextHOC(Orders))))
