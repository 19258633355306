import React, { Component } from "react";
import RestContextHOC from '../Contexts/RestContextHOC'
import UserContextHOC from "../Contexts/UserContextHOC";
import CartContextHOC from "../Contexts/CartContextHOC";
import AnalyticsContextHOC from '../Contexts/AnalyticsContextHOC'
import * as EmailValidator from 'email-validator';
import './KioskDinerDetails.css'

const INPUT_STYLE = {
  base: {
    fontSize: '18px',
    fontWeight:'400',
    padding:'10px 15px',
    textAlign:'left',
    fontFamily: 'sans-serif',
    color: '#717171',
    '::placeholder': {
        fontSize: '16px',
        color: '#aab7c4',
        fontWeight:400,
    },
    width:'75%',
    height:'45px',
    boxSizing:'border-box',
    lineHeight:'25px',
    border:'1px solid #a8a8a8'
  }
}

const ERROR_MSG = {
  width:'100%'
}


class KioskDinerDetails extends Component {


  constructor(props){
    super(props);
    this.state = {
      errorMessage: null,
      firstName:null,
      email:null,
      phone:null
    }
    
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.firstName != this.props.cart.kioskDinerDetails.firstName){
      let details = this.props.cart.kioskDinerDetails
      details.firstName = this.state.firstName
      this.props.cart.setKioskDinerDetails(details)
    }
    
    if(this.state.email != this.props.cart.kioskDinerDetails.email){
      let details = this.props.cart.kioskDinerDetails
      details.email = this.state.email
      this.props.cart.setKioskDinerDetails(details)
    }
    
    if(this.state.phone != this.props.cart.kioskDinerDetails.phone){
      let details = this.props.cart.kioskDinerDetails
      details.phone = this.state.phone
      this.props.cart.setKioskDinerDetails(details)
    }
  }


  handleFirstNameChange(o){
    this.props.cart.setKioskDinerDetails({
      'firstName': o.target.value
    });
  }

  handleLastNameChange(o){
    this.props.cart.setKioskDinerDetails({
      'lastName': o.target.value
    });
  }

  handleEmailChange(o){
    if (EmailValidator.validate(o.target.value) || !o.target.value){
      this.props.cart.setKioskDinerDetails({
        'email': o.target.value
      });
      this.setState({'errorMessage': null});
    }else{
      this.setState({'errorMessage': 'Email address is invalid'});
    }

  }

  handlePhoneChange(o){
    let phone = o.target.value;
    phone = phone.replace(/\-/g, "")
    phone = phone.replace(/\(/g, "")
    phone = phone.replace(/\)/g, "")
    phone = phone.replace(/\ /g, "")
    /*eslint-enable */
    if (phone.slice(0, 2) === "+1") {
      phone = phone.substring(2, phone.length)
    }
    if (phone.slice(0, 1) === "1") {
      phone = phone.substring(1, phone.length)
    }
    //o.target.value = `(${phone.slice(0,3)}) ${phone.slice(3,3)}-${phone.slice(6,3)}`;
    this.setState({phone:phone})
    if(o.type == 'blur' && phone.length != 10 && phone.length > 0){
      this.setState({'errorMessage': 'Phone is invalid'})
    } else {
      this.setState({'errorMessage': null})
    }
  }

  

  render() {
    return (
      <div className='checkout-flex-center'>
        <div className='checkout-section'>
          <div className='checkout-section-header'>Contact Info</div>
          <div className='elements-container'>
            <div className='element-row'>
              <div style={{width:'100%'}}>
                <div className='checkout-elements-header'>First Name (required)</div>
                  <input
                    style={INPUT_STYLE.base}
                    autocomplete='off'
                    value={this.state.firstName}
                    onChange={e => this.setState({firstName:e.target.value})}
                    maxLength='50'
                    className='checkout-kiosk-diner-firstname'
                  />
              </div>
            </div>
            {true == false && (
            <div className='element-row'>
              <div style={{width:'100%'}}>
                <div className='checkout-elements-header'>Last Name</div>
                  <input
                    style={INPUT_STYLE.base}
                    autocomplete='off'
                    onBlur={this.handleLastNameChange}
                    maxLength='50'
                    className='checkout-kiosk-diner-lastname'
                  />
              </div>
            </div>
            )}
            <div className='element-row'>
              <div style={{width:'100%'}}>
                <div className='checkout-elements-header checkout-kiosk-diner-prompt'>
                  Want a receipt and order updates?  Enter your email and 
                  phone number below.
                </div>
              </div>
            </div>
            <div className='element-row'>
              <div style={{width:'100%'}}>
                <div className='checkout-elements-header'>Email</div>
                  <input
                    style={INPUT_STYLE.base}
                    autocomplete='off'
                    value={this.state.email}
                    onChange={e=>this.setState({email:e.target.value})}
                    maxLength='50'
                    type='email'
                    className='checkout-kiosk-diner-email'
                  />
              </div>
            </div>
            <div className='element-row'>
              <div style={{width:'100%'}}>
                <div className='checkout-elements-header'>Phone</div>
                  <input
                    style={INPUT_STYLE.base}
                    autocomplete='off'
                    onBlur={this.handlePhoneChange}
                    onChange={this.handlePhoneChange}
                    maxLength='14'
                    type='tel'
                    className='checkout-kiosk-diner-phone'
                  />
              </div>
            </div>
            {this.state.errorMessage && (
              <div className='element-row'>
                <div className='checkout-error-container' style={ERROR_MSG}>
                  {this.state.errorMessage} 
                </div>
              </div>
            )}
          </div>
        </div>
      </div>


    )
  }
}

export default RestContextHOC(
  AnalyticsContextHOC(CartContextHOC(UserContextHOC(KioskDinerDetails))))
