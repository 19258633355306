import React, { Component } from "react"

import EntryPointHOC from "../Contexts/EntryPointHOC"
import RestContextHOC from "../Contexts/RestContextHOC"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import CartContextHOC from "../Contexts/CartContextHOC"
import UserContextHOC from "../Contexts/UserContextHOC"
import SignupModalContextHOC from "../Contexts/SignupModalContextHOC"
import Modal from "../Modal/Modal"
import "../css/LoginSignup.css"

const Subdomain = require("../helpers/subdomain")
const Api = require("../helpers/api.js")

class PasswordReset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: null,
      password2: null,
      done: false,
      error: false,
      loading: false,
      errorMessage: ''
    }
    this.handleEsc = this.handleEsc.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleSubmission = this.handleSubmission.bind(this)
    this.registrationSuccess = this.registrationSuccess.bind(this)
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEsc, false)
    this.props.analytics.info("PasswordResetPage")
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEsc, false)
  }

  handleClickOutside() {
    this.handleClose()
  }

  // registration with backend has been successfull
  // so invoke logins everywhere and then close the modal
  registrationSuccess(data) {
    this.setState({done: true})
  }

  handleSubmission() {
    this.props.analytics.info('PasswordResetSubmitButton')
    if (this.state.password !== this.state.password2) {
      this.setState({error: "Passwords don't match"})
      return
    }
    if (!this.state.password || this.state.password.length < 4) {
      this.setState({error: "Password too short. Must be at least 4 characters"})
      return
    }
    let payload = {password: this.state.password,
      subdomain: Subdomain.getSubdomain()}

    // send the API request, success means close modal, set user tokens
    // and move back to original page
    Api.callApi('diner_password_reset',
      this.registrationSuccess,
      (resp) => {
        let err = 'Unknown Error'
        console.log(resp)
        if (resp.message) { err = resp.message }
        this.setState({error: err})},
      payload
    )
  }

  handleEsc(e) {
    if (e.keyCode === 27) {
      e.preventDefault()
      this.handleClose()
      return
    }
  }

  handleClose() {
    this.props.analytics.info('PasswordResetClose')
    let lastPath = this.props.location.pathname.replace("/password_reset","")
    this.props.history.push({
      pathname: lastPath,
      search: window.location.search
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.rest.name && this.state.loading === true) {
      this.setState({loading: false})
    }
  }

  render() {
      return (
        <Modal close={this.handleClickOutside}>
        <div className='inputContainer'>
          { !this.state.done && (
          <div>
          <div className="header_v2">Change Password</div>

          <div className='inputLabel'>PASSWORD:</div>
          <input type='password' className='inputBody'
            value={this.state.password}
            onChange={(e)=>{this.setState({password: e.target.value.trim()})}}
            autocomplete="new-password"/>

          <div className='inputLabel'>ENTER AGAIN:</div>
          <input type='password' className='inputBody'
            value={this.state.password2}
            onChange={(e)=>{this.setState({password2: e.target.value.trim()})}}
            autocomplete="new-password"/>

          {this.state.error && (
            <div className="modalError">{this.state.error}</div>
          )}

          <button
            className="AccountButton"
            style={{background: this.props.rest.hex_color_primary}}
            onClick={e => this.handleSubmission()}>NEXT</button>
          </div>)}
    
        { this.state.done && (
          <div>
          <div className="header_v2">Password Updated!</div>
          You have successfully updated your password.

          <button
            className="AccountButton"
            style={{background: this.props.rest.hex_color_primary}}
            onClick={e => this.handleClose()}>CLOSE</button>
          </div>)
        }

        </div>
        </Modal>)
  }
}

export default RestContextHOC(SignupModalContextHOC(
  AnalyticsContextHOC(
    CartContextHOC(
      EntryPointHOC(UserContextHOC(
        UserContextHOC(RestContextHOC(PasswordReset))))))))
