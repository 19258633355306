import React from "react"
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC"
import RestContextHOC from "../../Contexts/AnalyticsContextHOC"
import CartContextHOC from "../../Contexts/CartContextHOC"
import { withRouter, Redirect } from "react-router-dom"

const formatPrice = require("../../helpers/formatPrice")

class CartItems extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      subtotal: 0,
      expanded: null
    }
  }

  removeItem(item) {
    this.props.analytics.info("CartItemRemoved", item.name)
    this.props.cart.removeItem(item)
    this.forceUpdate()
  }

  editItem(item){
    this.props.analytics.info("ClickEditItem", item.name)
    // we have the cart_item_id here, so we can look up the actual
    // spot this item is in the cart
    let itemInCart = null
    for(var i in this.props.cart.items){
        if(this.props.cart.items[i].cart_item_id === item.cart_item_id){
          itemInCart = JSON.parse(JSON.stringify(this.props.cart.items[i]))
          // add in all the other cart_item_ids so we can deal with quantity 
          itemInCart['cart_item_ids'] = item.cart_item_ids
        }
    }
    if(item.menu_uuid === this.props.rest.menu.menu_id){
      this.props.analytics.info("ClickEditItemSameMenu", item.name)
      this.props.history.push({
        pathname: `/menu/${item.origItem.menu_item_id}`,
        state: {
          item: item.origItem,
          editItem:itemInCart
        },  
      })  
    } else {
      //ugh. If we aren't on the current menu, we need to get to the secondary menu,
      // then display te item
      this.props.analytics.info("ClickEditItemOtherMenu", item.name)
      this.props.history.push({
        pathname: '/menu',
        state:{
          secondary_menu_id: item.menu_uuid,
          editItem:itemInCart
        }
       })
    }
  }

  renderModifiers(item) {
    return item.childrenFlat().map((modifier, i) => {
      let indent = 2 + 8 * modifier.level + "px"
      return (
        <div
          key={i}
          className="CartItemModifier"
          style={{ paddingLeft: indent }}
        >
          - {modifier.displayName()}
        </div>
      )
    })
  }

  renderSpecialInstructions(item) {
    if (item.specialInstructions) {
      return (
        <div className="CartItemSpecialInstructions">
          - "{item.specialInstructions}"
        </div>
      )
    }
  }

  expandItem(id) {
    this.setState({ expanded: this.state.expanded === id ? null : id })
  }

  renderItemQuantity(item) {
    if (item.quantity > 1)
      return (
        <span>
          {item.quantity}
          <span style={{ fontWeight: 500, verticalAlign: "5%" }}>
            &nbsp;x&nbsp;
          </span>
        </span>
      )
  }

  //we want to render the item name and then the modifier names
  renderItem(item, key) {
    return (
      <div>
        <div className="CartItemContainer" onClick={() => this.expandItem(key)}>
          <div className="CartItemName">
            {this.renderItemQuantity(item)}
            {item.displayName()}
          </div>
          {this.state.expanded !== item.cart_item_id && (
            <div className="CartItemPrice">
              {item.priceWithModifiersFormatted()}
            </div>
          )}
          {this.renderModifiers(item)}
          {this.renderSpecialInstructions(item)}

          <div className='CartItemEditRemoveContainer'>
            <div 
              className='CartItemLink'
              style={{color:this.props.rest.hex_color_secondary}}
              onClick={()=>this.editItem(item)}
            >
              Edit
            </div>
            <div
              className='CartItemLink'
              onClick={()=>this.removeItem(item)}
              style={{color:this.props.rest.hex_color_secondary}}
            >
              Remove
            </div>
          </div>

        </div>
     </div>
    )
  }

  render() {
    return (
      <div>
        {this.props.cart.cart().itemsQuantity.map((topLevelItem, i) => (
          <div key={i}>{this.renderItem(topLevelItem, i)}</div>
        ))}
        {(
          (this.props.cart.finance.promo && this.props.cart.coupon && !this.props.cart.coupon.is_nft_coupon) ||
          (this.props.cart.coupon && this.props.cart.coupon.coupon.action === 'free_delivery')
        ) ? (
          <div
            className="CartItemContainer"
            onClick={() => this.expandItem(this.props.cart.coupon.keycode)}
          >
            <div className="CartItemName">
              Coupon {this.props.cart.coupon.keycode}
            </div>
            {this.state.expanded !== this.props.cart.coupon.keycode && (
              <div className="CartItemPrice" style={{ color: "#ea4335" }}>
                - {formatPrice.formatPrice(this.props.cart.finance.free_delivery ?  this.props.cart.finance.promo_without_free_delivery : this.props.cart.getIncentiveAmount('coupon'))}
              </div>
            )}

            {this.state.expanded === this.props.cart.coupon.keycode && (
            <button
              className="CartRemoveItem"
              onClick={() => this.props.cart.removeCoupon()}
            >
              REMOVE
            </button>
          )}
          </div>
        ) : null} 

        {(this.props.cart.finance.promo && this.props.cart.coupon && this.props.cart.coupon.is_nft_coupon) ? (
          <>
            {window.location.href.indexOf('irazu') > -1 ? (
              <div className='cart-nft-irazu'>
                <div className='cart-nft-irazu-header'>SUPPER CLUB X IRAZU NFT</div>
                <div className='cart-nft-irazu-promo-applied'>NFT Promo Applied</div>
                <div className='cart-nft-irazu-bottom'>-1 Shake</div>
              </div>
            ) : (
              <div className='cart-nft-irazu'>
                <div className='cart-nft-irazu-header'>SUPPER CLUB X FATBURGER NFT</div>
                <div className='cart-nft-irazu-promo-applied'>NFT Promo Applied</div>
                <div className='cart-nft-irazu-bottom'>-1 Fatburger</div>
              </div>
            )}
          </>
        ) : null}
      </div>
    )
  }
}

export default withRouter(
  RestContextHOC(AnalyticsContextHOC(CartContextHOC(CartItems)))
)
