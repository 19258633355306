import React, { useState } from "react"
import "./RestaurantHeader.css"

const RestaurantHeader = props => {
  const [expanded, setExpanded] = useState(false)

  const title = props.rest.name + " | Order Online"
  return (
    <div
      className="RestaurantHeader"
      style={{
        background: props.rest.hex_color_primary
          ? props.rest.hex_color_primary
          : "#ccc"
      }}
    >
      <img
        className="RestaurantHeaderLogo"
        src={props.rest.logo_url}
        alt="logo"
      />
    </div>
  )
}

export default RestaurantHeader
