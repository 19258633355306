import React from "react";
import styles from "./MenuSearch.module.scss";

const NoItemFound = props => {
  return (
    <div className={styles.container}>
      <img
        src="https://cdn.hngr.co/Dinner.png"
        alt="Dinner Plate"
        className={styles.image}
      />
      <div className={styles.textContainer}>
        <h2 className={styles.header}>No results found for {props.value}</h2>
        <div className={styles.subheader}>Try searhing for another item!</div>
      </div>
    </div>
  );
};

export default NoItemFound;
