import React, { Component } from "react"

import EntryPointHOC from "../Contexts/EntryPointHOC"
import RestContextHOC from "../Contexts/RestContextHOC"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import ThirdPartyLogin from './ThirdPartyLogin'
import CartContextHOC from "../Contexts/CartContextHOC"
import UserContextHOC from "../Contexts/UserContextHOC"
import SignupModalContextHOC from "../Contexts/SignupModalContextHOC"
import Checkbox from "../sparkles/Checkbox/Checkbox"
import Modal from "../Modal/Modal"
import "../css/LoginSignup.css"
import "../css/EmailLogin.css"
import * as EmailValidator from 'email-validator';

const Subdomain = require("../helpers/subdomain")
const Api = require("../helpers/api.js")

class EmailLogin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      authMethod: 'PASSWORD',
      email: null,
      password: null,
      error: false,
      loading: true,
      errorMessage: '',
      suggestOauth: null
    }

    this.handleEsc = this.handleEsc.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
    this.handleSubmitPassword = this.handleSubmitPassword.bind(this)
    this.loginSuccess = this.loginSuccess.bind(this)
    this.loginFailure = this.loginFailure.bind(this)
    this.sendToCreateAccount = this.sendToCreateAccount.bind(this)
    this.sendToForgotPassword = this.sendToForgotPassword.bind(this)
  }

  validateEmail(email) {
    return EmailValidator.validate(email)
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEsc, false)
    this.props.analytics.info("EmailLoginPage")
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEsc, false)
  }

  handleClickOutside() {
    this.handleClose()
  }

  sendToCreateAccount() {
    let pathname = '/menu/signup'
    const params = new URLSearchParams(window.location.search)
    if (params.get('sendTo')) {
      pathname += '?sendTo=' + params.get('sendTo')
    }
    this.props.history.replace({pathname: pathname})
  }

  sendToForgotPassword() { 
    let pathname = '/menu/anon_password_reset'
    const params = new URLSearchParams(window.location.search)
    if (params.get('sendTo')) {
      pathname += '?sendTo=' + params.get('sendTo')
    }
    this.props.history.replace({pathname: pathname})
  }

  /* response function which takes a successful OR a bad
     login and pushes the user in! */
  loginSuccess(resp) {
    if (resp.success == '0') {
      return this.loginFailure(resp)
    }

    this.props.analytics.info('EmailLoginSuccess', JSON.stringify(resp))
    this.props.user.loginUser(resp.userdata, resp.hngr_token)
    // if we have a sendTo = checkout then
    // either check to see if phone number
    // and route to that or else send to checkout
    const params = new URLSearchParams(window.location.search)
    let pathname = '/menu'
    const userPhone = resp.userdata.phone_number_e164
    if (params.get('sendTo') === 'checkout' && !userPhone) {
      pathname = '/menu/phone_verify?sendTo=checkout'
    } else if (params.get('sendTo') === 'checkout') {
      pathname = '/checkout'
    } 
    this.props.history.replace({pathname: pathname})
  }

  loginFailure(resp) {
    this.props.analytics.info('EmailLoginFailure', JSON.stringify(resp))
    if (resp.platform) {
      this.setState({suggestOauth: resp.platform})
    }
    this.setState({loading: false, 
      error: true, 
      errorMessage: resp.msg})
  }

  handleSubmitPassword(){
    this.setState({error: false})
    if (!this.validateEmail(this.state.email)) {
      let msg = "Invalid email address"
      this.setState({error: true, errorMessage: msg})
      return
    }
    if (!this.state.password || this.state.password.length < 4) {
      this.setState({error: true,
        errorMessage: "Password incorrect."})
      return
    }
    this.setState({loading: true})
    this.props.analytics.info('SubmittingPassword')
    let payload = {email: this.state.email,
      password: this.state.password,
      subdomain: Subdomain.getSubdomain()}

    Api.callApi(
      'email_login',
      this.loginSuccess,
      this.loginFailure,
      payload
    )
  }

  handleEsc(e) {
    if (e.keyCode === 27) {
      e.preventDefault()
      this.handleClose()
      return
    }
  }

  handleClose() {
    this.props.analytics.info('EmailLoginClose')
    let lastPath = window.location.pathname.replace("/email_login", "")
    this.props.history.push({pathname: lastPath})
  }


  componentDidUpdate(prevProps) {
    if (this.props.rest.name && this.state.loading === true) {
      this.setState({loading: false})
    }
  }

  render() {
      return (
        <Modal close={this.handleClickOutside}>
        {!this.state.suggestOauth && 
          <div className='emailLogin'>
            <div>
            <h3>Login to Continue</h3>

            <div class='words'>
            Don't have an account? <a className='regularHotlink'
              onClick={() => this.sendToCreateAccount()}>Sign Up</a>
          </div>
          <div className='label'>
            Email:

          </div>

          <input 
            value={this.state.email}
            type='email'
            onChange={(e)=>{this.setState({email: e.target.value})}}
            autocomplete="username email"/>

          <div className='label'>Password:
            <a className='forgotLink'
              onClick={() => this.sendToForgotPassword()}>Forgot Password?</a>
          </div>
          <input type='password' 
            value={this.state.password}
            onChange={(e)=>{this.setState({password: e.target.value})}} />

          {this.state.error && (
            <div className="modalError">{this.state.errorMessage}</div>
          )}

          <button
            disabled={this.state.loading}
            style={{background: this.props.rest.hex_color_primary}}
            onClick={(e) => this.handleSubmitPassword(e)}>
            Login
          </button>
        </div>
      </div>}

  {this.state.suggestOauth && 
    <div className='emailLogin'>
      <div>
      <h3>Login to Continue</h3>
      <div className='words'>
        You previously created an account with the following email address
      </div>
      <h3>{this.state.email}</h3>
      <div className='words'>
        Please Log in to Continue
      </div>

      {this.state.suggestOauth === 'GOOGLE' && (
        <ThirdPartyLogin
          type='google'
          title={'Log in'}
          sendTo={this.props.sendTo}
          setStage={this.props.setStage}
          PromoOptIn={this.state.PromoOptIn}
          isLogin={true}
        />
      )}

      {this.state.suggestOauth === 'FACEBOOK' && (
        <ThirdPartyLogin
          type='facebook'
          title={'Log in'}
          sendTo={this.props.sendTo}
          PromoOptIn={this.state.PromoOptIn}
          isLogin={true}
        />
      )}
      </div>
    </div>
    }
   </Modal>
  )
  }

}
export default RestContextHOC(SignupModalContextHOC(
  AnalyticsContextHOC(
    CartContextHOC(
      EntryPointHOC(UserContextHOC(
        UserContextHOC(RestContextHOC(EmailLogin))))))))
