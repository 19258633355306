import React, { Component } from 'react';
const $script = require("scriptjs");

export const GoogleMapsJSLoaderContext = React.createContext()

class GoogleMapsJSLoaderProvider extends Component {

	constructor(props){
		super(props)
		this.state = {
      google_maps_loaded:false
		}
    this.loadGoogleMaps()
	}

  loadGoogleMaps(){
    //check if it's already loaded
    if (typeof window.google === 'object' && typeof window.google.maps === 'object'){
      this.setState({google_maps_loaded:true})
      return
    }
		var that = this
    var url = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`
		$script(url, function() {
      that.setState({google_maps_loaded:true})
		})
  }

  render() {
    return  (
      <GoogleMapsJSLoaderContext.Provider value={this.state}>
        {this.props.children}
      </GoogleMapsJSLoaderContext.Provider>
    )
  }
}

export default GoogleMapsJSLoaderProvider;
