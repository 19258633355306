import React from "react";
import styles from "./PaymentCheckout.module.scss";
import CardLogoImg from "./CardLogoImg";
import { ReactComponent as SelectedCheck } from "./SelectedCheck.svg";
import SelectApplePay from "../CheckoutSelectPayment/SelectApplePay";

class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected
    };
  }

  renderDelete() {
    if (this.props.onDelete) {
      return (
        <button onClick={this.props.onDelete} className={styles.delete}>
          DELETE
        </button>
      );
    }
  }

  renderDefault() {
    if (this.props.onDefault) {
      return (
        <button onClick={this.props.onDefault} className={styles.default}>
          SET AS DEFAULT
        </button>
      );
    }
  }

  renderSelected() {
    if (this.props.card === "applePay") {
      return (
        <div className={styles.selected}>
          <SelectApplePay selected />
          <SelectedCheck />
        </div>
      );
    } else if (this.props.card === 'cash'){
       return (
        <div className={styles.selected}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{position:'absolute', width: '28px', height:"17px", borderRadius: '2px', background:'#41BC4D', color: '#fff'}}>$</div>
            <div style={{position:'absolute', width: '28px', height:"17px", borderRadius: '2px', background:'#41BC4D77', transform:'rotate(-20deg)'}}></div>
            <div style={{marginLeft: '44px', display:'inline-block'}}>Pay with Cash</div>
          </div>
          <SelectedCheck />
        </div>
      );
    } else {
      return (
        <div className={styles.selected}>
          <div style={{ display: "flex" }}>
            <CardLogoImg cardName={this.props.card.brand} />
            <div>Card ending in {this.props.card.last4}</div>
          </div>
          <SelectedCheck />
        </div>
      );
    }
  }

  render() {
    const IS_NEW = this.props.type === "new";
    return (
      <div className={styles.addContainer}>
        {this.props.selected ? (
          this.renderSelected()
        ) : (
          <div
            className={IS_NEW ? styles.add : styles.savedCard}
            onClick={this.props.onClick}
            role="presentation"
          >
            {IS_NEW ? (
              <>
                <CardLogoImg color="#417D9D" />
                <div>{this.props.title}</div>
              </>
            ) : (
              <div style={{ display: "flex" }}>
                <CardLogoImg cardName={this.props.card.brand} />
                <div>Card ending in {this.props.card.last4}</div>
              </div>
            )}
            <div className={styles.actions}>
              {this.renderDelete()}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PaymentMethod;
