import React, { Component } from "react"
import EntryPointHOC from "../Contexts/EntryPointHOC"
import RestContextHOC from "../Contexts/RestContextHOC"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import CartContextHOC from "../Contexts/CartContextHOC"
import UserContextHOC from "../Contexts/UserContextHOC"
import { Text, Header, ButtonWrapper } from "../atoms"

import Modal from "../Modal/Modal"
import "./AbandonedCart.css"

const KIOSK_WARNING_TIMEOUT = 30000;

class AbandonedCart extends Component {

  constructor(props) {
    super(props)

    this.state = {

    }

    this.handleClose = this.handleClose.bind(this)
    this.handleStartNewOrder = this.handleStartNewOrder.bind(this)

  }

  componentDidMount() {
    setTimeout(this.handleStartNewOrder, KIOSK_WARNING_TIMEOUT);
    this.props.analytics.info("Abandoned Cart")
  }

  handleClose() {
      this.props.cart.lastUpdated = Date.now();
      let currentURL = window.location.pathname
      let lastPath = currentURL.substring(0, currentURL.lastIndexOf("/"))
      this.props.history.push({
        pathname: lastPath,
      })
      return
  }

  handleStartNewOrder() {
    this.props.cart.emptyCart(); 
    window.location.href = '/';
  }

  renderRestaurantHeader() {
    const { secondary_name } = this.props.rest
    const secondary = secondary_name
    return (
      <div className='abandoned-rest-info-container'>
       <img
          className="abandoned-rest-logo"
          src={this.props.rest.logo_color}
          alt="logo"
        />
        <div className='abandoned-rest-name'>{this.props.rest.name}</div>
        { this.props.rest.secondary_name && (
          <div className='abandoned-rest-name-secondary'>
            {this.props.rest.secondary_name}
          </div>
        )}
      </div> 
    )
  }


  render() {
      return (
        <Modal close={() => this.handleClose(true)}>

        <div
          className="abandonedContentContainer"
          style={{
            "--rest-primary": this.props.rest.hex_color_primary,
            "--rest-secondary": this.props.rest.hex_color_secondary,
          }}
        >
          {this.renderRestaurantHeader()}

          <div className="abandoned-header">Are you still there?</div>
          <div className="abandoned-action-container">
            <button
              className="AccountButton"
              style={{background: this.props.rest.hex_color_primary, width:'50%'}}
              onClick={e => this.handleClose()}>Continue Order</button>
            <button
              className="AccountButton"
              style={{background: this.props.rest.hex_color_secondary, width:'50%'}}
              onClick={e => this.handleStartNewOrder()}>Start New Order</button>
          </div>
        </div>

        </Modal>
      )
  }
}

export default RestContextHOC(
  AnalyticsContextHOC(
    CartContextHOC(
      EntryPointHOC(UserContextHOC(
        UserContextHOC(RestContextHOC(AbandonedCart)))))))
