import React, { Component } from "react"
import ReactTooltip from "react-tooltip"
import RestContextHOC from "../Contexts/RestContextHOC"
import UserContextHOC from "../Contexts/UserContextHOC"
import CartContextHOC from "../Contexts/CartContextHOC"
import { Capacitor } from "@capacitor/core"
import { injectStripe } from "react-stripe-elements"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import CreditCardAdd from "./PaymentMethods/CreditCardAdd"
import CreditCardSaved from "./PaymentMethods/CreditCardSaved"
import CreditCardFee, {
  ApplePayAlert,
  AvoidCardFees,
  DebitSavings,
  FeeNotification,
  AvoidApplePayFees,
} from "./PaymentMethods/CreditCardFee"
import { ReactComponent as Opennode } from "./Opennode.svg"
import LoadingIndicator from "../Loading/LoadingIndicator"
import "./CheckoutSelectPayment.css"
import classnames from "classnames"
import WalletButton from "../WalletButton"
import WalletButtonConnected from "../WalletButtonCheckoutConnected"
import WalletChangeButton from "../WalletButtonCheckoutChange"
import withWallet from "../withWallet"
import { Error } from "./PaymentMethods/error"

const api = require("../helpers/api")
const Subdomain = require("../helpers/subdomain")
const LAMPORTS_PER_SOL = 1000000000

class CheckoutSelectPayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPayment: null,
      canUseApplePay: false,
      allowSol: null,
      solBalance: null,
      usdcBalance: null,
      solanaType: "usdc",
      canUseCash: null,
      enoughSol: false,
      canUseBitcoin: null,
      savedCards: null,
      chooseCardEditOpen: false,
      paymentError: null,
      ccError: null,
      zipcode: "",
      paymentError: this.props.paymentError,
      customPaymentName: "",
      customPaymentNumber: "",
      publicKey: this.props.publicKey,
      solPrice: this.props.solPrice,
      walletBalanceLoaded: null,
    }
    window.sally = this
    if (this.props.rest.rest_loaded && this.props.cart.pickupOrDeliveryLoaded) {
      if (
        this.props.rest.allowCashPickup &&
        this.props.cart.pickup_or_delivery === "pickup"
      ) {
        this.state["canUseCash"] = true
      } else if (
        this.props.rest.allowCashDelivery &&
        this.props.cart.pickup_or_delivery === "delivery"
      ) {
        this.state["canUseCash"] = true
      } else {
        this.state["canUseCash"] = false
      }
      this.state.canUseBitcoin = this.props.rest.acceptsBitcoin
    }
    this.enterNewCardRef = React.createRef()
    this.selectPaymentRef = React.createRef()
    this.customPaymentRef = React.createRef()
    this.customPaymentNumberRef = React.createRef()
    this.customPaymentNameRef = React.createRef()
    this.handleCardAddCancel = this.handleCardAddCancel.bind(this)
    this.deleteSavedCard = this.deleteSavedCard.bind(this)
    this.loadSavedCards()
    this.props.updateZipcode("")
    this.props.cart.shouldSaveCard(true)

    let subdomain = Subdomain.getSubdomain()
    this.subdomain = subdomain
    if (
      subdomain.indexOf("buffaloscafe") > -1 ||
      subdomain.indexOf("elevationburger") > -1 ||
      subdomain.indexOf("fatburger") > -1 ||
      subdomain.indexOf("hurricanewings") > -1
    ) {
      this.allowSupperclub = false
    } else {
      this.allowSupperclub = true
    }
    if (
      Capacitor.getPlatform() === "ios" ||
      Capacitor.getPlatform() === "android"
    ) {
      this.allowFoodcoin = true
    } else {
      this.allowFoodcoin = true
    }
    /*
    if (subdomain === 'mayo' || subdomain === 'midnightexpressdiner' || subdomain === 'seabasspizza'){
      this.canUseCustom = true 
    }
    */
    this.canUseCreditCard = true
    if (subdomain == "friscos-catering") {
      this.canUseCreditCard = false
    }
  }

  componentDidMount() {
    this.canUseApplePay()
    this.acceptedCoins()
    if (!this.canUseCreditCard) {
      this.setState({ selectedPayment: "cash" }, () =>
        this.props.cart.selectPaymentNew("cash")
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.user.user && this.props.user.user) {
      this.loadSavedCards()
    }

    if (
      this.state.canUseCash === null &&
      this.props.rest.rest_loaded &&
      this.props.cart.pickupOrDeliveryLoaded
    ) {
      if (
        this.props.rest.allowCashPickup &&
        this.props.cart.pickup_or_delivery === "pickup"
      ) {
        this.state["canUseCash"] = true
      } else if (
        this.props.rest.allowCashDelivery &&
        this.props.cart.pickup_or_delivery === "delivery"
      ) {
        this.state["canUseCash"] = true
      } else {
        this.state["canUseCash"] = false
      }
    }

    if (this.props.rest.acceptsBitcoin !== this.state.canUseBitcoin) {
      this.setState({ canUseBitcoin: this.props.rest.acceptsBitcoin })
    }

    if (
      this.props.rest.allowSOL !== this.state.allowSOL &&
      window.innerWidth > 920
    ) {
      this.setState({ allowSOL: this.props.rest.allowSOL })
    }

    // TODO: hook into pickup or delivery changing
    if (
      prevProps.cart.pickup_or_delivery !==
        this.props.cart.pickup_or_delivery &&
      this.props.rest.rest_loaded &&
      this.props.cart.pickupOrDeliveryLoaded
    ) {
      if (
        this.props.rest.allowCashPickup &&
        this.props.cart.pickup_or_delivery === "pickup"
      ) {
        this.state["canUseCash"] = true
      } else if (
        this.props.rest.allowCashDelivery &&
        this.props.cart.pickup_or_delivery === "delivery"
      ) {
        this.state["canUseCash"] = true
      } else {
        this.state["canUseCash"] = false
      }
    }

    // if we are going from pickup->delivery or delivery->pickup AND we had to
    // just reset the payment options to empty in the cart (due to cash being
    // allowed in one thing and not in the the thing we switched to), then
    // we want to set the selectedpayment to credit card, essentially reinitailizing
    // this component
    // Note: this assumes the "default" payment is credit card... which is true as of now
    if (
      this.state.selectedPayment === "cash" &&
      prevProps.cart.cash &&
      !this.props.cart.isSomeFormOfPaymentSelected()
    ) {
      this.setState({ selectedPayment: "credit_card" })
    }

    if (this.state.solBalance !== this.props.cart.solBalance) {
      this.setState({ solBalance: this.props.cart.solBalance })
    }

    if (this.state.usdcBalance !== this.props.cart.usdcBalance) {
      this.setState({ usdcBalance: this.props.cart.usdcBalance })
    }

    if (
      (this.props.publicKey && !prevProps.publicKey) ||
      (!this.props.publicKey && prevProps.publicKey)
    ) {
      let p1 = this.props.publicKey ? this.props.publicKey.toString() : null
      let p2 = this.state.publicKey ? this.state.publicKey.toString() : null
      if (p1 !== p2) {
        this.setState({ publicKey: this.props.publicKey })
      }
    }

    if (this.state.paymentError !== this.props.paymentError) {
      this.setState({ paymentError: this.props.paymentError })
    }

    /*
    if(this.state.solPrice && this.state.enoughSol !== this.props.cart.enoughSol(this.state.solPrice)){
      this.setState({enoughSol:this.props.cart.enoughSol(this.state.solPrice)}, ()=>this.setSolTypeBasedOnBalance())
    }*/

    if (this.state.enoughUSDC !== this.props.cart.enoughUSDC()) {
      this.setState({ enoughUSDC: this.props.cart.enoughUSDC() }, () =>
        this.setSolTypeBasedOnBalance()
      )
    }

    if (this.state.enoughFoodcoin !== this.props.cart.enoughFoodcoin()) {
      this.setState({ enoughFoodcoin: this.props.cart.enoughFoodcoin() }, () =>
        this.setSolTypeBasedOnBalance()
      )
    }

    if (
      this.state.enoughFoodcoinAndUSDC !==
      this.props.cart.enoughFoodcoinAndUSDC()
    ) {
      this.setState(
        { enoughFoodcoinAndUSDC: this.props.cart.enoughFoodcoinAndUSDC() },
        () => this.setSolTypeBasedOnBalance()
      )
    }

    if (
      this.state.enoughFoodcoinAndSol !==
      this.props.cart.enoughFoodcoinAndSol(this.state.solPrice)
    ) {
      this.setState(
        {
          enoughFoodcoinAndSol: this.props.cart.enoughFoodcoinAndSol(
            this.state.solPrice
          ),
        },
        () => this.setSolTypeBasedOnBalance()
      )
    }

    if (this.state.solPrice !== this.props.solPrice) {
      this.setState({ solPrice: this.props.solPrice })
    }

    if (this.props.cart.walletConnected !== this.state.walletBalanceLoaded) {
      this.setState({ walletBalanceLoaded: this.props.cart.walletConnected })
    }
  }

  acceptedCoins() {
    api.callApi(
      "accepted_tokens",
      (resp) => {
        console.log("accepted coins", resp.tokens)
      },
      (err) => {
        console.log("error getting accepted coins", err)
      },
      { rest_uuid: this.props.rest.rest_id }
    )
  }

  isLoading() {
    return this.state.apiLoading || this.state.paymentRequestLoading
  }

  canUseApplePay() {
    if (this.subdomain == "friscos-catering") {
      this.setState({ canUseApplePay: false })
      return
    }

    const paymentRequest = this.props.stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "test",
        amount: 100,
      },
    })

    paymentRequest
      .canMakePayment()
      .then((result) => {
        let state = { paymentRequestLoading: false }
        if (!result || !result.applePay) {
          this.props.analytics.info("CheckoutCanNOTUseApplePay")
          state.canUseApplePay = false
        } else {
          this.props.analytics.info("CheckoutCanUseApplePay")
          state.canUseApplePay = true
        }
        this.setState({ ...state })
      })
      .catch((err) => {
        this.setState({ paymentRequestLoading: false })
      })
  }

  loadSavedCards(fromDelete) {
    if (this.props.user.user) {
      api.callApi(
        "saved_cards",
        (resp) => {
          this.props.analytics.info(
            "CheckoutSavedCardsResponse",
            JSON.stringify(resp)
          )
          let defaultCard = null
          for (let i in resp.cards) {
            if (resp.cards[i].is_default) {
              defaultCard = resp.cards[i]
            }
          }
          let state = { savedCards: resp.cards }
          if (!this.props.cart.cash && !this.props.cart.applePay) {
            state.selectedPayment = "credit_card"
            if (defaultCard) {
              state["selectedCard"] = defaultCard.id
              this.props.cart.selectPaymentNew(
                "card",
                defaultCard,
                defaultCard,
                true
              )
            } else if (resp.cards && resp.cards.length > 0) {
              // do nothing
            } else {
              this.setState({ enterNewCard: true })
            }
          }
          this.setState({ ...state })
          this.props.unsetLoading()
        },
        () => {
          this.props.unsetLoading()
          this.setState({ savedCards: [] })
        },
        null,
        null,
        this.props.user.user.uniq_uuid
      )
    }
  }

  addNewCard() {
    this.props.analytics.info("CheckoutAddNewCard")
    this.props.cart.selectPaymentNew(null)
  }

  selectSavedCard(card) {
    this.props.analytics.info("CheckoutSelectSavedCard")
    this.props.cart.selectPaymentNew("card", card, card)
  }

  selectApplePay() {
    this.props.analytics.info("CheckoutSelectApplePay")
    this.props.cart.selectPaymentNew("applePay")
  }

  reloadSavedCards() {
    this.props.analytics.info("CheckoutReloadSavedCards")
    this.loadSavedCards()
  }

  loaded() {
    return (
      this.state.canUseApplePay !== null &&
      this.state.canUseCash !== null &&
      this.state.savedCards !== null
    )
  }

  ccError(msg) {
    this.props.analytics.info("CheckoutCCError", msg)
    this.setState({ ccError: msg }, () => {
      this.selectPaymentRef.current.scrollIntoView()
    })
  }

  paymentMethodStyle() {
    let numOptions = 1
    if (this.state.canUseCash) numOptions += 1
    if (this.state.canUseApplePay) numOptions += 1
    if (this.canUseCustom) numOptions += 1
    if (this.state.canUseBitcoin) numOptions += 1
    if (this.state.allowSOL) numOptions += 1
    if (this.allowSupperclub) numOptions += 1

    if (numOptions === 1) return { display: "none" }
    else if (numOptions == 2) return { width: "45%" }
    else return { width: "30%" }
  }

  paymentHeaderStyle() {
    let numOptions = 1
    if (this.state.canUseCash) numOptions += 1
    if (this.state.canUseApplePay) numOptions += 1
    if (this.canUseCustom) numOptions += 1

    if (numOptions === 1) return { display: "none" }
    else return {}
  }

  deleteSavedCard(card) {
    this.props.analytics.info("CheckoutDeleteSavedCard", JSON.stringify(card))
    if (this.props.cart.savedCard && this.props.cart.savedCard.id === card.id) {
      this.setState({ selectedPayment: null }, () =>
        this.props.cart.selectPaymentNew(null)
      )
    }

    const payload = {
      diner_id: this.props.user.user.uniq_uuid,
      card_id: card.id,
    }

    this.props.setLoading()
    api.callApi(
      "delete_card",
      () => {
        this.props.analytics.info("CheckoutDeleteCardSuccess")
        this.loadSavedCards()
      },
      () => {
        this.props.analytics.info("CheckoutDeleteCardFail")
        this.setState({ apiLoading: false })
        this.props.unsetLoading()
        alert("Failed to delete")
      },
      payload
    )
  }

  setSolTypeBasedOnBalance() {
    if (this.state.enoughFoodcoin) {
      this.props.cart.setSolanaTokens([
        {
          token: "foodcoin",
          amount: this.props.cart.finance.total,
        },
      ])
      this.setState({ solanaType: "foodcoin" })
      return
    }

    if (this.state.enoughFoodcoinAndUSDC) {
      let foodcoinBalance = this.props.cart.foodcoinBalance
      let foodcoinAmount = foodcoinBalance * 100
      let outstandingBalance =
        this.props.cart.finance.total - foodcoinBalance * 100
      let outstandingDollars = outstandingBalance / 100
      let usdcAmount = outstandingDollars * 1000000
      this.props.cart.setSolanaTokens([
        { token: "foodcoin", amount: foodcoinAmount },
        { token: "usdc", amount: usdcAmount },
      ])

      this.setState({ solanaType: "foodcoin/usdc" })
      return
    }

    if (this.state.enoughFoodcoinAndSol) {
      // in this situation we max out the foodcoin, then calculate how much
      // usdc they use
      let foodcoinAmount = this.props.cart.foodcoinBalance * 100
      let solAmount = this.solAmountAfterFoodcoin() * LAMPORTS_PER_SOL
      this.props.cart.setSolanaTokens([
        { token: "foodcoin", amount: foodcoinAmount },
        { token: "sol", amount: solAmount },
      ])
      this.setState({ solanaType: "foodcoin/sol" })
      return
    }

    if (this.state.enoughUSDC) {
      this.props.cart.setSolanaTokens([
        {
          token: "usdc",
          amount: this.props.cart.finance.total * 10000,
        },
      ])
      this.setState({ solanaType: "usdc" })
      return
    }

    if (this.state.enoughSol) {
      this.props.cart.setSolanaTokens([
        {
          token: "sol",
          amount: this.solAmount() * LAMPORTS_PER_SOL,
        },
      ])
      this.setState({ solanaType: "sol" })
      return
    }
  }

  solAmount() {
    if (!this.state.solPrice) return
    return (this.props.cart.finance.total / this.state.solPrice).toFixed(6)
  }

  solAmountAfterFoodcoin() {
    if (!this.state.solPrice) return
    return (
      (this.props.cart.finance.total - this.props.cart.foodcoinBalance * 100) /
      this.state.solPrice
    ).toFixed(6)
  }

  renderBitcoinBlurb() {
    if (this.state.selectedPayment !== "bitcoin") return null
    return (
      <div className="checkout-bottom-border checkout-padding checkout-custom-payment-section checkout-bitcoin-blurb">
        <div className="checkout-pay-with-bitcoin">Pay With Bitcoin.</div>
        <div className="checkout-opennode-blurb">
          <div className="checkout-opennode-img-container">
            <Opennode />
          </div>
          <div className="checkout-opennode-text">
            You will be directed to Opennode to securely complete your purchase.
          </div>
        </div>

        <div className="checkout-bitcoin-note">
          NOTE: Please note that when using OpenNode as your payment method,
          once you have clicked the "Continue with Bitcoin" button, you will
          have only 10 minutes to complete your payment. If you are unable to
          complete your payment, you will have to place a new order.
          <br />
          <br />
          All orders fully paid by OpenNode are final and cannot be refunded for
          any crypto or hard currency. Any refunds will be made in the form of a
          Bitcoin Pizza coupon code for the full value of your order.
        </div>
      </div>
    )
  }

  renderSupperclubBlurb() {
    if (this.state.selectedPayment !== "supperclub") return null

    return (
      <a
        target="_blank"
        href="https://www.supperclub.xyz/"
        style={{ padding: "0px", borderBottomWidth: "0px" }}
        className="checkout-bottom-border checkout-padding  checkout-supperclub-blurb"
      >
        <img
          src="https://cdn.hngr.co/supperclub/selectss.png"
          style={{ width: "100%", height: "100%", marginBottom: "0px" }}
        />
      </a>
    )
  }

  renderWallet() {
    if (this.state.selectedPayment !== "sol") return null

    if (this.state.publicKey) {
      if (!this.state.walletBalanceLoaded) {
        return (
          <div className="select-payment-bottom-shit solana-container solana-connected">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "230px",
              }}
            >
              Loading...
            </div>
          </div>
        )
      }

      // loading if we are still getting their balances

      // we need a this.state.enoughSOL and a this.state.enoughUSDC
      // a function in the cart should tell us this

      if (
        !this.state.enoughSol &&
        !this.state.enoughUSDC &&
        !this.state.enoughFoodcoin
      ) {
        return (
          <div className="select-payment-bottom-shit solana-container solana-connected">
            <div className="solana-top-row">
              <div style={{ width: "49px", height: "1px" }}></div>
              <div className="solana-just-show-address">
                <WalletButtonConnected />
              </div>
              <div className="wallet-change-button-container">
                <WalletChangeButton />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="solana-not-enough-funds">
                <div>
                  You don’t have enough funds in your Solana wallet to complete
                  this transaction.
                </div>
                <div>Please add funds or use a different payment method.</div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div
            style={{ background: "none" }}
            className="select-payment-bottom-shit solana-container solana-connected"
          >
            <div className="solana-top-row">
              <div className="solana-just-show-address">
                <WalletButtonConnected />
              </div>
              <div className="wallet-change-button-container">
                <WalletChangeButton />
              </div>
            </div>
            <div
              className="solana-connected-bottom-portion"
              style={{ paddingTop: "12px" }}
            >
              <div className="solana-pay-with">Pay With</div>

              {this.state.enoughFoodcoin && (
                <CryptoSingleOption
                  token="FOOD"
                  select={() => {
                    this.props.cart.setSolanaTokens([
                      {
                        token: "foodcoin",
                        amount: this.props.cart.finance.total,
                      },
                    ])
                    this.setState({ solanaType: "foodcoin" })
                  }}
                  amount={this.props.cart.finance.total / 100}
                  selected={this.state.solanaType === "foodcoin"}
                />
              )}

              {!this.state.enoughFoodcoin &&
                this.state.enoughFoodcoinAndUSDC && (
                  <CryptoSplitOption
                    token1="FOOD"
                    amount1={this.props.cart.foodcoinBalance}
                    token2="USDC"
                    amount2={(
                      this.props.cart.finance.total / 100 -
                      this.props.cart.foodcoinBalance
                    ).toFixed(2)}
                    select={() => {
                      // in this situation we max out the foodcoin, then calculate how much
                      // usdc they use
                      let foodcoinBalance = this.props.cart.foodcoinBalance
                      let foodcoinAmount = foodcoinBalance * 100
                      let outstandingBalance =
                        this.props.cart.finance.total - foodcoinBalance * 100
                      let outstandingDollars = outstandingBalance / 100
                      let usdcAmount = outstandingDollars * 1000000
                      this.props.cart.setSolanaTokens([
                        { token: "foodcoin", amount: foodcoinAmount },
                        { token: "usdc", amount: usdcAmount },
                      ])

                      this.setState({ solanaType: "foodcoin/usdc" })
                    }}
                    amount={this.props.cart.finance.total / 100}
                    selected={this.state.solanaType === "foodcoin/usdc"}
                  />
                )}

              {!this.state.enoughFoodcoin &&
                this.state.enoughFoodcoinAndSol && (
                  <CryptoSplitOption
                    token1="FOOD"
                    amount1={this.props.cart.foodcoinBalance}
                    token2="SOL"
                    amount2={this.solAmountAfterFoodcoin()}
                    select={() => {
                      // in this situation we max out the foodcoin, then calculate how much
                      // usdc they use
                      let foodcoinAmount = this.props.cart.foodcoinBalance * 100
                      let solAmount =
                        this.solAmountAfterFoodcoin() * LAMPORTS_PER_SOL
                      this.props.cart.setSolanaTokens([
                        { token: "foodcoin", amount: foodcoinAmount },
                        { token: "sol", amount: solAmount * LAMPORTS_PER_SOL },
                      ])
                      this.setState({ solanaType: "foodcoin/sol" })
                    }}
                    amount={this.props.cart.finance.total / 100}
                    selected={this.state.solanaType === "foodcoin/sol"}
                  />
                )}

              {this.state.enoughUSDC && (
                <CryptoSingleOption
                  token="USDC"
                  select={() => {
                    this.props.cart.setSolanaTokens([
                      {
                        token: "usdc",
                        amount: this.props.cart.finance.total * 10000,
                      },
                    ])
                    this.setState({ solanaType: "usdc" })
                  }}
                  amount={this.props.cart.finance.total / 100}
                  selected={this.state.solanaType === "usdc"}
                />
              )}

              {this.state.enoughSol && (
                <CryptoSingleOption
                  token="SOL"
                  select={() => {
                    this.props.cart.setSolanaTokens([
                      {
                        token: "sol",
                        amount: this.solAmount() * LAMPORTS_PER_SOL,
                      },
                    ])
                    this.setState({ solanaType: "sol" })
                  }}
                  amount={this.solAmount()}
                  selected={this.state.solanaType === "sol"}
                />
              )}
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className="select-payment-bottom-shit solana-container solana-unconnected">
          <ReactTooltip />
          <div className="solana-top-row">
            <div style={{ width: "24px", height: "1px" }}></div>
            <div>Solana</div>
            <Tooltip data-tip="Solana payments require a wallet." />
          </div>
          <div className="solana-bottom-portion">
            <WalletButton checkout={true} />
          </div>
        </div>
      )
    }

    // on connect, show the balance opf SOL and USDC
    return (
      <div className="checkout-bottom-border checkout-padding checkout-custom-payment-section checkout-wallet-section">
        <WalletButton />
        {this.state.solBalance !== null && (
          <div>
            USDC balance: {this.state.usdcBalance}
            <br />
          </div>
        )}

        <div>
          <input
            name="solana-type"
            type="radio"
            value="solana"
            onChange={(e) => {
              this.props.cart.setSolanaToken("solana")
              this.setState({ solanaType: e.target.value })
            }}
            checked={this.state.solanaType === "solana"}
          />
          SOLANA
        </div>
        <div>
          <input
            name="solana-type"
            type="radio"
            value="usdc"
            onChange={(e) => {
              this.props.cart.setSolanaToken("usdc")
              this.setState({ solanaType: e.target.value })
            }}
            checked={this.state.solanaType === "usdc"}
          />
          USDC
        </div>
      </div>
    )
  }

  renderCustomPayment() {
    if (this.state.selectedPayment !== "custom") return
    let that = this
    return (
      <div ref={this.customPaymentRef} className="">
        <div className="add-new-card-text">Marymount Manhattan College</div>
        <div style={{ marginBottom: "15px", color: "red" }}>
          <b>
            <i>You can not buy alcohol with your student ID</i>
          </b>
        </div>
        <div className="checkout-elements-header">Name</div>
        <input
          className="StripeElement--empty zipcode-input"
          style={{ width: "100%" }}
          ref={this.customPaymentNameRef}
          placeholder="Name"
          value={this.state.customPaymentName}
          onKeyPress={(e) => {
            if (e.charCode === 13) {
              that.customPaymentNameRef.current.blur()
            }
          }}
          onKeyDown={(evt) => evt.key === "." && evt.preventDefault()}
          onChange={(e) =>
            this.setState({ customPaymentName: e.target.value }, () => {
              this.props.cart.setCustomPaymentName(this.state.customPaymentName)
              this.props.updateCustomPaymentName(this.state.customPaymentName)
            })
          }
        />

        <div className="checkout-elements-header" style={{ marginTop: "15px" }}>
          Student ID #
        </div>
        <input
          className="StripeElement--empty zipcode-input"
          style={{ width: "100%" }}
          ref={this.customPaymentNumberRef}
          type="number"
          placeholder="Student ID"
          value={this.state.customPaymentNumber}
          onKeyPress={(e) => {
            if (e.charCode === 13) {
              that.customPaymentNumberRef.current.blur()
            }
            if (e.charCode > 31 && (e.charCode < 48 || e.charCode > 57)) {
              e.preventDefault()
            }
          }}
          onKeyDown={(evt) => evt.key === "." && evt.preventDefault()}
          onChange={(e) => {
            if (
              e.target.value.indexOf(".") === -1 &&
              (e.target.value.match(/^-?\d+$/) || e.target.value === "")
            ) {
              this.setState({ customPaymentNumber: e.target.value }, () => {
                this.props.updateCustomPaymentNumber(
                  this.state.customPaymentNumber
                )
                this.props.cart.setCustomPaymentNumber(
                  this.state.customPaymentNumber
                )
              })
            }
          }}
        />
      </div>
    )
  }

  renderCash() {
    if (!this.props.cart.cash) return null
    return (
      <div className="select-payment-bottom-shit cash-container">
        <Cash style={{ fill: "#00A324" }} />
        <div>Pay Cash at the restaurant for your meal</div>
      </div>
    )
  }

  renderApplePay() {

    if (this.state.selectedPayment !== "applepay") return
    const hasPaymentMethodFees = this.props.rest.paymentMethodFees

    const hasFeesForDebitAndCredit =
      !!hasPaymentMethodFees?.find(
        (f) => f.method === "debit" && f.type === "surcharge"
      ) &&
      !!hasPaymentMethodFees?.find(
        (f) => f.method === "credit" && f.type === "surcharge"
      )

    if (hasFeesForDebitAndCredit) {
      return (
        <AvoidCardFees />
      )
    } else if (hasPaymentMethodFees?.length > 0) {
      return (
        <AvoidApplePayFees />
      )
    }
  }

  handleCardAddCancel() {
    this.setState({
      enterNewCard: false,
    })
  }

  renderCreditCardStuff() {
    if (this.state.selectedPayment !== "credit_card") return
    let that = this

    let paymentMethodFees = null
    const hasPaymentMethodFees = this.props.rest.paymentMethodFees
    const cardType = this.props.cart.cardDetails?.funding

    const fee = this.props.cart.finance.paymentMethodFees
    const savings = this.props.cart.calculatePaymentMethodSavings(
      this.props.cart.finance.subtotal
    )

    if (hasPaymentMethodFees?.length > 0) {
      if (
        cardType === "debit" &&
        !hasPaymentMethodFees.find((fee) => {
          return (
            fee.method === "debit"
            && fee.type === "surcharge"
          )
        })
      ) {
        paymentMethodFees = (
          <DebitSavings
            fee={savings}
            brand={this.props.cart.cardDetails?.brand}
          />
        )
      } else if (cardType === "credit") {
        if (this.props.cart.paymentMethodFees) {
          paymentMethodFees = this.props.cart.paymentMethodFees.map((f, i) => {
            if (i > 0) return null
            return <CreditCardFee fee={fee} />
          })
        }
      }
    }

    const hasFeesForDebitAndCredit =
      !!hasPaymentMethodFees?.find(
        (f) => f.method === "debit" && f.type === "surcharge"
      ) &&
      !!hasPaymentMethodFees?.find(
        (f) => f.method === "credit" && f.type === "surcharge"
      )

    if (this.state.enterNewCard) {
      return (
        <>
          <CreditCardAdd
            setCardType={(cardType) => {
              this.setState({
                cardType,
              })
            }}
            updateZipcode={this.props.updateZipcode}
            handleCardAddCancel={this.handleCardAddCancel}
          />
          {hasPaymentMethodFees?.length > 0 && !!paymentMethodFees && paymentMethodFees}
          {hasPaymentMethodFees?.length > 0 && !paymentMethodFees && (
            <>
              {hasFeesForDebitAndCredit ? (
                <CreditCardFee fee={savings} />
              ) : (
                <FeeNotification fee={savings} />
              )}
            </>
          )}
        </>
      )
    }

    //if has saved cards show saved cards
    if (this.state.savedCards !== null) {
      return (
        <div className="select-saved-card-outer-container">
          <div
            className="flex-row space-between"
            style={{ marginBottom: "15px" }}
          >
            {this.state.chooseCardEditOpen ? (
              <div
                style={{ marginBottom: "0px" }}
                className="add-new-card-text"
              >
                Manage Saved Cards
              </div>
            ) : (
              <div
                style={{ marginBottom: "0px" }}
                className="add-new-card-text"
              >
                Saved Cards
              </div>
            )}
            <div
              className="cancel-card"
              onClick={() => {
                this.setState({
                  chooseCardEditOpen: !this.state.chooseCardEditOpen,
                })
              }}
            >
              {this.state.chooseCardEditOpen ? "Cancel" : "Manage Cards"}
            </div>
          </div>

          {this.state.savedCards.map((card) => (
            <CreditCardSaved
              chooseCardEditOpen={this.state.chooseCardEditOpen}
              card={card}
              setCardType={(cardType) => {
                this.setState({
                  cardType,
                })
              }}
              deleteSavedCard={this.deleteSavedCard}
            />
          ))}

          {!this.state.chooseCardEditOpen && (
            <div
              className="saved-card-container"
              onClick={() => {
                this.setState({ enterNewCard: true })
                this.props.cart.unselectPayment()
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PlusBox
                  style={{ marginRight: "14px", height: "23px", width: "23px" }}
                />
                <div> Add New Card </div>
              </div>
            </div>
          )}
          {hasPaymentMethodFees?.length > 0 && !!paymentMethodFees && paymentMethodFees}
          {hasPaymentMethodFees?.length > 0 && !paymentMethodFees && (
            <>
              {hasFeesForDebitAndCredit ? (
                <CreditCardFee fee={savings} />
              ) : (
                <FeeNotification fee={savings} />
              )}
            </>
          )}
        </div>
      )
    }
  }

  render() {
    if (!this.loaded()) {
      return (
        <div>
          <LoadingIndicator />
        </div>
      )
    }

    return (
      <div className="checkout-section">
        <div
          className="checkout-section-header"
          style={this.paymentHeaderStyle()}
        >
          Payment Method
        </div>

        <div ref={this.selectPaymentRef} className="payment-methods">
          {this.canUseCreditCard && (
            <div
              className={classnames(
                "payment-method",
                {
                  "payment-method-unselected":
                    this.state.selectedPayment !== "credit_card",
                },
                {
                  "payment-method-selected":
                    this.state.selectedPayment === "credit_card",
                }
              )}
              onClick={() => {
                this.setState({ selectedPayment: "credit_card" }, () => {
                  this.props.cart.unselectPayment()
                })
              }}
            >
              <div>Credit & Debit Card</div>
            </div>
          )}

          {this.state.canUseApplePay && (
            <div
              onClick={() => {
                this.setState({ selectedPayment: "applepay" }, () =>
                  this.props.cart.selectPaymentNew("applePay")
                )
              }}
              className={classnames(
                "payment-method",
                { "payment-method-unselected": !this.props.cart.applePay },
                { "payment-method-selected": this.props.cart.applePay }
              )}
            >
              <div>Apple Pay</div>
            </div>
          )}

          {this.state.allowSOL && (
            <div
              onClick={() => {
                this.props.analytics.info("CryptoClicked")
                this.setState({ selectedPayment: "sol", zipcode: "" }, () =>
                  this.props.cart.selectPaymentNew("sol", false)
                )
              }}
              className={classnames(
                "payment-method",
                { "payment-method-unselected": !this.props.cart.sol },
                {
                  "payment-method-selected":
                    this.props.cart.sol &&
                    this.state.selectedPayment !== "supperclub",
                }
              )}
            >
              <div>Crypto</div>
            </div>
          )}

          {this.allowSupperclub && (
            <div
              onClick={() => {
                this.props.analytics.info("CryptoClicked")
                this.setState(
                  { selectedPayment: "supperclub", zipcode: "" },
                  () => this.props.cart.selectPaymentNew("sol", true)
                )
              }}
              className={classnames(
                "payment-method",
                {
                  "payment-method-unselected":
                    this.state.selectedPayment !== "supperclub",
                },
                {
                  "payment-method-selected":
                    this.state.selectedPayment === "supperclub",
                }
              )}
            >
              <div>Mobile Wallet</div>
            </div>
          )}

          {this.state.canUseCash && (
            <div
              onClick={() => {
                this.setState({ selectedPayment: "cash", zipcode: "" }, () =>
                  this.props.cart.selectPaymentNew("cash")
                )
                this.props.updateZipcode("")
              }}
              className={classnames(
                "payment-method",
                { "payment-method-unselected": !this.props.cart.cash },
                { "payment-method-selected": this.props.cart.cash }
              )}
            >
              <div>Cash</div>
            </div>
          )}

          {this.state.canUseBitcoin && (
            <div
              onClick={() => {
                this.setState({ selectedPayment: "bitcoin", zipcode: "" }, () =>
                  this.props.cart.selectPaymentNew("bitcoin")
                )
              }}
              className={classnames(
                "payment-method",
                { "payment-method-unselected": !this.props.cart.bitcoin },
                { "payment-method-selected": this.props.cart.bitcoin }
              )}
            >
              <div>Bitcoin</div>
            </div>
          )}

          {this.canUseCustom && (
            <div
              onClick={() => {
                this.setState({ selectedPayment: "custom" }, () =>
                  this.props.cart.selectPaymentNew("custom")
                )
              }}
              className={classnames(
                "payment-method",
                { "payment-method-unselected": !this.props.cart.customPayment },
                { "payment-method-selected": this.props.cart.customPayment }
              )}
            >
              <div>Student ID</div>
            </div>
          )}
        </div>
        {this.props.cart.applePay && 1 == 0 && <ApplePayAlert />}
        {this.renderCreditCardStuff()}
        {this.renderCash()}
        {this.renderApplePay()}
        {this.renderCustomPayment()}
        {this.renderBitcoinBlurb()}
        {this.renderSupperclubBlurb()}
        {this.renderWallet()}

        {this.state.paymentError && (
          <div className="element-row">
            <div
              className="payment-method-fee payment-method-red"
              style={{ width: "100%" }}
            >
              <Error />
              <div>
                <div className="credit-card-fee-header">Error</div>
                <div className="credit-card-fee-description">
                  Something went wrong. Please try again.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const CryptoSingleOption = (props) => (
  <div>
    <div
      className={classnames(
        "saved-card-container",
        "solana-pay-type-container",
        { "solana-pay-type-selected": props.selected }
      )}
      onClick={props.select}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <CryptoOptionToken {...props} />

        {props.selected ? (
          <svg height="24" width="24">
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="#b7b7b7"
              fill="#000AFF"
              stroke-width="3"
            />
          </svg>
        ) : (
          <CircleUnfilledBlack style={{ fillOpacity: 0 }} />
        )}
      </div>
    </div>
  </div>
)

const CryptoSplitOption = (props) => (
  <div>
    <div
      className={classnames(
        "saved-card-container",
        "solana-pay-type-container",
        { "solana-pay-type-selected": props.selected }
      )}
      onClick={props.select}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <CryptoOptionToken token={props.token1} amount={props.amount1} />

          <svg
            style={{
              width: "15px",
              height: "15px",
              marginLeft: "15px",
              marginRight: "15px",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
          </svg>

          <CryptoOptionToken token={props.token2} amount={props.amount2} />
        </div>

        {props.selected ? (
          <svg height="24" width="24">
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="#b7b7b7"
              fill="#000AFF"
              stroke-width="3"
            />
          </svg>
        ) : (
          <CircleUnfilledBlack style={{ fillOpacity: 0 }} />
        )}
      </div>
    </div>
  </div>
)

const CryptoOptionToken = (props) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    {props.token === "SOL" && <SOL className="solana-payment-svg" />}

    {props.token === "USDC" && <USDC className="solana-payment-svg" />}

    {props.token === "FOOD" && (
      <img
        src="https://cdn.hngr.co/foodcoin_alpha1.png"
        className="solana-payment-png"
      />
    )}
    <div className="solana-option-name-amount-container">
      <div className="solana-option-token-name">{props.token}</div>
      <div className="solana-option-token-amount">{props.amount}</div>
    </div>
  </div>
)

const CircleUnfilledBlack = (props) => (
  <svg height="24" width="24" {...props}>
    <circle
      {...props}
      cx="12"
      cy="12"
      r="10"
      stroke="#252525"
      stroke-width="2"
      fill="white"
    />
  </svg>
)

export const ErrorIcon = (props) => (
  <svg
    width="38px"
    height="38px"
    viewBox="0 0 44 44"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Welcome"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Desktop-HD" transform="translate(-93.000000, -865.000000)">
        <g id="warning" transform="translate(93.000000, 865.000000)">
          <circle id="Oval" fill="#FFBDBD" cx="22" cy="22" r="22"></circle>
          <circle
            id="Oval"
            stroke="#D60000"
            fill="#FFFFFF"
            cx="22"
            cy="22"
            r="14"
          ></circle>
          <g
            id="Group-7"
            transform="translate(20.000000, 13.000000)"
            fill="#D60000"
          >
            <circle id="Oval" cx="2" cy="15" r="2"></circle>
            <polygon
              id="Rectangle"
              points="4.96047647e-13 -6.21724894e-15 4.03427886 -6.21724894e-15 3.02224566 11 1.02224566 11"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const Cash = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 8c-2.208 0-4 1.792-4 4s1.792 4 4 4c2.209 0 4-1.792 4-4s-1.791-4-4-4zm.2 5.979v.42h-.4v-.399c-.414-.007-.843-.105-1.2-.29l.181-.657c.383.146.891.305 1.29.215.46-.104.555-.577.046-.805-.372-.172-1.512-.322-1.512-1.296 0-.546.417-1.034 1.194-1.141v-.425h.401v.407c.29.007.616.058.977.168l-.145.658c-.307-.107-.646-.205-.977-.185-.595.035-.648.551-.232.766.684.322 1.576.561 1.576 1.418.002.687-.536 1.054-1.199 1.146zm-12.2-8.979v14h24v-14h-24zm22 9.422c-1.151.504-2.074 1.427-2.578 2.578h-14.844c-.504-1.151-1.427-2.074-2.578-2.578v-4.844c1.151-.504 2.074-1.427 2.578-2.578h14.844c.504 1.151 1.427 2.074 2.578 2.578v4.844z" />
  </svg>
)

const PlusBox = (props) => (
  <svg
    {...props}
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 9.75V11.25C16.5 11.5594 16.2469 11.8125 15.9375 11.8125H11.8125V15.9375C11.8125 16.2469 11.5594 16.5 11.25 16.5H9.75C9.44063 16.5 9.1875 16.2469 9.1875 15.9375V11.8125H5.0625C4.75313 11.8125 4.5 11.5594 4.5 11.25V9.75C4.5 9.44063 4.75313 9.1875 5.0625 9.1875H9.1875V5.0625C9.1875 4.75313 9.44063 4.5 9.75 4.5H11.25C11.5594 4.5 11.8125 4.75313 11.8125 5.0625V9.1875H15.9375C16.2469 9.1875 16.5 9.44063 16.5 9.75ZM21 2.25V18.75C21 19.9922 19.9922 21 18.75 21H2.25C1.00781 21 0 19.9922 0 18.75V2.25C0 1.00781 1.00781 0 2.25 0H18.75C19.9922 0 21 1.00781 21 2.25ZM18.75 18.4688V2.53125C18.75 2.37656 18.6234 2.25 18.4688 2.25H2.53125C2.37656 2.25 2.25 2.37656 2.25 2.53125V18.4688C2.25 18.6234 2.37656 18.75 2.53125 18.75H18.4688C18.6234 18.75 18.75 18.6234 18.75 18.4688Z"
      fill="#717171"
    />
  </svg>
)

const Tooltip = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24C18.6281 24 24 18.6281 24 12C24 5.37188 18.6281 0 12 0ZM12 6C12.8283 6 13.5 6.67172 13.5 7.5C13.5 8.32828 12.8283 9 12 9C11.1717 9 10.5 8.32969 10.5 7.5C10.5 6.67031 11.1703 6 12 6ZM13.875 18H10.125C9.50625 18 9 17.4984 9 16.875C9 16.2516 9.50391 15.75 10.125 15.75H10.875V12.75H10.5C9.87891 12.75 9.375 12.2461 9.375 11.625C9.375 11.0039 9.88125 10.5 10.5 10.5H12C12.6211 10.5 13.125 11.0039 13.125 11.625V15.75H13.875C14.4961 15.75 15 16.2539 15 16.875C15 17.4961 14.4984 18 13.875 18Z"
      fill="white"
    />
  </svg>
)

const USDC = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.65 24 24 18.65 24 12C24 5.34996 18.65 0 12 0C5.34996 0 0 5.34996 0 12C0 18.65 5.34996 24 12 24Z"
      fill="#2775CA"
    />
    <path
      d="M15.3001 13.8998C15.3001 12.1499 14.2501 11.5499 12.1501 11.2999C10.6501 11.0999 10.3501 10.6999 10.3501 9.99985C10.3501 9.29977 10.8501 8.84989 11.8501 8.84989C12.7501 8.84989 13.2501 9.14989 13.5001 9.89989C13.5501 10.0499 13.7001 10.1499 13.8501 10.1499H14.65C14.8501 10.1499 15.0001 9.99985 15.0001 9.79993V9.74989C14.8 8.64985 13.9 7.79989 12.7501 7.69993V6.49993C12.7501 6.29989 12.6001 6.14989 12.3501 6.09985H11.6001C11.4001 6.09985 11.2501 6.24985 11.2 6.49993V7.64989C9.70002 7.84993 8.7501 8.84989 8.7501 10.0999C8.7501 11.7499 9.75006 12.3999 11.8501 12.6499C13.2501 12.8999 13.7001 13.1999 13.7001 13.9999C13.7001 14.8 13 15.3499 12.0501 15.3499C10.75 15.3499 10.3 14.7998 10.15 14.0498C10.1001 13.8499 9.9501 13.7499 9.8001 13.7499H8.95002C8.7501 13.7499 8.6001 13.8999 8.6001 14.0999V14.1499C8.80002 15.3999 9.60006 16.2998 11.2501 16.5499V17.7499C11.2501 17.9498 11.4001 18.0998 11.65 18.1499H12.4C12.6001 18.1499 12.7501 17.9999 12.8001 17.7499V16.5499C14.3001 16.2998 15.3001 15.2498 15.3001 13.8998V13.8998Z"
      fill="white"
    />
    <path
      d="M9.45 19.1499C5.55 17.75 3.54996 13.4 5.00004 9.54992C5.75004 7.44992 7.40004 5.84996 9.45 5.09996C9.65004 5 9.75 4.85 9.75 4.59992V3.89996C9.75 3.69992 9.65004 3.54992 9.45 3.5C9.39996 3.5 9.3 3.5 9.24996 3.54992C4.5 5.04992 1.89996 10.1 3.39996 14.85C4.29996 17.6499 6.45 19.8 9.24996 20.7C9.45 20.7999 9.65004 20.7 9.69996 20.4999C9.75 20.45 9.75 20.4 9.75 20.3V19.5999C9.75 19.4499 9.6 19.25 9.45 19.1499ZM14.75 3.54992C14.55 3.44996 14.35 3.54992 14.3 3.74996C14.25 3.8 14.25 3.84992 14.25 3.95V4.64996C14.25 4.85 14.4 5.04992 14.55 5.15C18.45 6.54992 20.45 10.8999 19 14.75C18.25 16.85 16.6 18.45 14.55 19.2C14.35 19.2999 14.25 19.4499 14.25 19.7V20.4C14.25 20.6 14.35 20.75 14.55 20.7999C14.6 20.7999 14.7 20.7999 14.75 20.75C19.5 19.25 22.1 14.1999 20.6 9.44996C19.7 6.59996 17.5 4.44992 14.75 3.54992V3.54992Z"
      fill="white"
    />
  </svg>
)

const SOL = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_237_1397)">
      <path
        d="M23.8764 18.4867L19.9145 22.6261C19.8284 22.716 19.7242 22.7877 19.6084 22.8367C19.4926 22.8857 19.3676 22.911 19.2414 22.9109H0.459952C0.370334 22.9109 0.28267 22.8854 0.207731 22.8375C0.132791 22.7896 0.0738392 22.7215 0.0381187 22.6414C0.00239817 22.5613 -0.008535 22.4729 0.00666254 22.3868C0.0218601 22.3008 0.0625262 22.2209 0.123665 22.1571L4.08858 18.0177C4.17448 17.9281 4.27835 17.8565 4.39376 17.8075C4.5092 17.7585 4.63371 17.7332 4.75963 17.733H23.5401C23.6297 17.733 23.7173 17.7585 23.7923 17.8063C23.8672 17.8542 23.9261 17.9224 23.962 18.0024C23.9976 18.0825 24.0086 18.171 23.9934 18.257C23.9781 18.3431 23.9375 18.4229 23.8764 18.4867ZM19.9145 10.1513C19.8284 10.0613 19.7242 9.98965 19.6084 9.94065C19.4926 9.89168 19.3676 9.86642 19.2414 9.86649H0.459952C0.370334 9.86649 0.28267 9.89198 0.207731 9.93987C0.132791 9.98775 0.0738392 10.0559 0.0381187 10.136C0.00239817 10.216 -0.008535 10.3045 0.00666254 10.3906C0.0218601 10.4766 0.0625262 10.5564 0.123665 10.6203L4.08858 14.7596C4.17448 14.8493 4.27835 14.9208 4.39376 14.9698C4.5092 15.0188 4.63371 15.0442 4.75963 15.0444H23.5401C23.6297 15.0444 23.7173 15.0189 23.7923 14.971C23.8672 14.9231 23.9261 14.855 23.962 14.7749C23.9976 14.6949 24.0086 14.6064 23.9934 14.5203C23.9781 14.4343 23.9375 14.3545 23.8764 14.2906L19.9145 10.1513ZM0.459952 7.17794H19.2414C19.3676 7.17799 19.4926 7.15275 19.6084 7.10375C19.7242 7.05476 19.8284 6.98306 19.9145 6.89315L23.8764 2.75379C23.9375 2.68996 23.9781 2.61012 23.9934 2.52408C24.0086 2.43804 23.9976 2.34955 23.962 2.26948C23.9261 2.18942 23.8672 2.12126 23.7923 2.07338C23.7173 2.02551 23.6297 2 23.5401 2H4.75963C4.63371 2.00021 4.5092 2.02559 4.39376 2.07458C4.27835 2.12356 4.17448 2.19511 4.08858 2.28479L0.124687 6.42415C0.0636074 6.4879 0.0229592 6.56768 0.00772717 6.6536C-0.00750485 6.73955 0.00334156 6.82797 0.0389361 6.908C0.0745307 6.98803 0.133326 7.05618 0.208112 7.10413C0.282898 7.15209 0.370422 7.17773 0.459952 7.17794Z"
        fill="url(#paint0_linear_237_1397)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_237_1397"
        x1="2.02588"
        y1="23.4093"
        x2="21.1469"
        y2="1.28327"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.08" stop-color="#9945FF" />
        <stop offset="0.3" stop-color="#8752F3" />
        <stop offset="0.5" stop-color="#5497D5" />
        <stop offset="0.6" stop-color="#43B4CA" />
        <stop offset="0.72" stop-color="#28E0B9" />
        <stop offset="0.97" stop-color="#19FB9B" />
      </linearGradient>
      <clipPath id="clip0_237_1397">
        <rect
          width="24"
          height="20.9109"
          fill="white"
          transform="translate(0 2)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default injectStripe(
  withWallet(
    RestContextHOC(
      AnalyticsContextHOC(CartContextHOC(UserContextHOC(CheckoutSelectPayment)))
    )
  )
)
