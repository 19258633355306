import React, { Component } from 'react'
import AnalyticsContextHOC from '../Contexts/AnalyticsContextHOC'
import UserContextHOC from '../Contexts/UserContextHOC'
import CartContextHOC from '../Contexts/CartContextHOC'
import SignupModalContextHOC from '../Contexts/SignupModalContextHOC'
import { withRouter } from 'react-router-dom'
import '../css/LoginSignup.css'
const Api = require('../helpers/api.js')
const Subdomain = require("../helpers/subdomain")
const LoginHelper = require('../helpers/login')

class LoginInput extends Component {

  constructor(props){
    super(props);
    this.state = {
      email:'',
      password:'',
      flow: props.flow,
      error:false
    }
    this.loginCallback = this.loginCallback.bind(this)
    this.gotoRegister = this.gotoRegister.bind(this)
    this.gotoLogin = this.gotoLogin.bind(this)
  }

  /* force the user to the email registration form */
  gotoRegister() {
    this.props.analytics.info('EmailSignupGotoRegister')
    let pathname = '/menu/email_register'
    const params = new URLSearchParams(window.location.search)
    if (params.get('sendTo')) {
      pathname += '?sendTo=' + params.get('sendTo')
    }

    this.props.history.push({pathname: pathname})
  }

  gotoLogin() {
    this.props.analytics.info('EmailSignupGotoLogin')
    let pathname = '/menu/email_login'
    const params = new URLSearchParams(window.location.search)
    if (params.get('sendTo')) {
      pathname += '?sendTo=' + params.get('sendTo')
    }
    this.props.history.push({pathname: pathname})
  }


  handleInputEmail(e){
    this.props.analytics.debug('TypingInEmail',e.target.value)
    this.setState({email:e.target.value,error:false})
  }

  handleInputPassword(e,listener){
    if(listener === 'keydown' && e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      this.handleSubmitPassword(e)
      return
    }
    if (listener === 'change'){this.props.analytics.debug('TypingInPassword',`password_length = ${e.target.value.length}`)
    this.setState({password:e.target.value,error:false})
    }
    
  }

  handleSubmitPassword(){
    this.props.analytics.info('SubmittingPassword')
    const edata = this.props.signupModal.collect_extra_signup_data
    let payload = {email: this.state.email,
      password: this.state.password,
      collect_extra_signup_data: edata,
      subdomain: Subdomain.getSubdomain()}
 
    Api.callApi(
      'email_login',
      this.loginCallback,
      ()=>{this.props.updateError(
        true,
        "Unknown Error"
      )},
      payload
    )
  }

  /* response function which takes a successful OR a bad
     login and pushes the user in! */
  loginCallback(resp){
    if (resp.success){
      this.props.user.loginUser(resp.userdata, resp.hngr_token)
      this.props.history.replace({pathname: '/menu/'})
    } else if(resp.msg){
      this.props.analytics.error('LoginFailure', this.state.email)
      this.setState({error:true})
    }

  }

  renderErrorPassword() {
    if (this.state.error) {
      return(
        <div className='errorText'>
          Invalid email or password
        </div>
      )
    }
  }

  // note that we include a div with fakeImage class, which
  // is just a 22x22 box so it lines up with the oauth
  // components above
  render() {
      if (this.props.flow == 'signup') {
        return(
          <div className='loginButtons'>
            <div
              onClick={() => this.gotoRegister()}
              style={{background: this.props.data.primary_color}}
              className="oauthButton emailButton">
             <div className="oauthFiller" />
             <div className="oauthContainer" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'flex-start'}}>
             <img
              src="/envelope.svg"
              className="oauthLogo"
              alt="Email Login Button"
            />
             <div className='buttonText'>Sign Up with Email</div>
             </div>
             <div className="oauthFiller" />
             </div>
           </div>)
      } else {
        return(
        <div className='loginButtons'>
          <div
            onClick={() => this.gotoLogin()}
            style={{background: this.props.data.primary_color}}
            className="oauthButton emailButton">
           <div className="oauthFiller" />
           <div className="oauthContainer" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'flex-start'}}>
             <img
              src="/envelope.svg"
              className="oauthLogo"
              alt="Email Login Button"
            />
           <div className='buttonText'>Sign in with Email</div>
           </div>
           <div className="oauthFiller" />
           </div>
        </div>)
      }
  }
}

export default CartContextHOC(withRouter(
  SignupModalContextHOC(UserContextHOC(
    AnalyticsContextHOC(LoginInput)))))
