import React from "react";
import styles from "./MenuItemExpanded/MenuItemExpanded.module.scss";

export const ItemRequiredTag = (props) => {
  if(props.numSelected && props.numSelected >= props.numSelectionsNeeded){
    return (
      <div className={styles.selectedContainer}>
        <div style={{whiteSpace:'nowrap'}}  className={styles.requiredText}>
          Selected
          <Checkmark 
            style={{
              fill:'white',
              height:'12px', 
              width:'12px',
              marginLeft:'6px',
              position:'relative',
              top:'1px'
            }}
          />
        </div>
      </div>
    );
  } else {
    let needed = props.numSelectionsNeeded
    if(props.numSelected) needed = needed - props.numSelected
    let text = props.quantityInput ? 'Add' : 'Choose'
    return (
      <div className={styles.requiredContainer}>
        <div style={{whiteSpace:'nowrap'}} className={styles.requiredText}>{text} {needed}</div>
      </div>
    );
  }
};

const Checkmark = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}  width="24" height="24" viewBox="0 0 24 24"><path d="M0 11.522l1.578-1.626 7.734 4.619 13.335-12.526 1.353 1.354-14 18.646z"/></svg>
)
