import React, { useState, useRef, useEffect } from "react"
import classnames from "classnames"
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC"
import CartContextHOC from "../../Contexts/CartContextHOC"
import RestContextHOC from "../../Contexts/RestContextHOC"
import UserContextHOC from "../../Contexts/UserContextHOC"
import CouponInput from "../../sparkles/CouponInput/CouponInput"
import SignupModalContextHOC from "../../Contexts/SignupModalContextHOC"
import { withRouter } from "react-router-dom"
import withErrorHandling from "../../Contexts/withErrorHandling/withErrorHandling"
import CartItems from "./CartItems"
import CheckoutBottom from "./CheckoutBotttom"
import CheckerButton from "./CheckerButton"
import Draggable from "react-draggable"
import { Swipeable } from "react-swipeable"
import "./Cart.css"
const Cart = (props) => {
  const [expanded, setExpanded] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const el = useRef(null)

  useEffect(() => {
    document.addEventListener("keydown", _handleKeyDown)
    window.addEventListener("resize", handleResize)

    return () => document.removeEventListener("keydown", _handleKeyDown, false)
  }, [])

  useEffect(() => {
    if (props.cart.forceOpenCart === true) {
      setExpanded(true)
    }
  }, [props.cart.forceOpenCart])

  useEffect(() => {
    if (props.cart.scrollIntoView) {
      if (el.current) {
        el.current.scrollIntoView()
      }
      props.cart.setForceScrollBottom(false)
    }
  }, [props.cart.forceScrollBottom])

  const closeCart = () => {
    window.setTimeout(() => {
      setExpanded(false)
    }, 200)
  }

  const placeOrder = () => {

    props.cart.stillAcceptingOrders((accepting_orders) => {
      if (!accepting_orders.accepting_orders && !props.rest.allow_future_orders) {
        props.analytics.info(
          "CartCheckoutPrevented",
          "Restaurant not accepting orders"
        )
        props.updateError(true, "Sorry, the restaurant has closed.", true, {
          zIndex: "1000",
          position: "fixed",
          top: "60px",
          left: "0px",
          width: "99vw",
          maxWidth: "100%",
        })

        return
      }

      if (props.cart.items.length === 0) {
        props.analytics.info("CartCheckoutPrevented", "Empty cart")
        props.updateError(
          true,
          "Looks like you have an empty cart. Add some food and give it another go!",
          true,
          { zIndex: "1000", position: "fixed", top: "0", width: "99vw" }
        )
        return
      }

      if (!props.cart.isCheckerPageComplete() && !props.rest.isLocked()) {
        let url = props.history.location.pathname.split()
        props.history.push(url[0] + "/start")
      } else {
        let next_url = "/checkout"
        if (props.user.user) {
          if(
            props.user.user?.origin === 'supperclub_app' &&
            !props.user.user?.email
          ){
            let pathname = '/menu/email_missing?sendTo=checkout'
            props.history.replace({pathname: pathname})
          } else if (
            (!props.user.user.first_name || 
            !props.user.user.last_name) &&
            props.user.user.origin != 'supperclub_app'
          ) {
            props.analytics.info("CartClickedCheckout",
              "Logged in Missing info")
            let pathname = '/menu/moreinfo?sendTo=checkout'
            props.history.push({pathname: pathname})
          } else if (props.user.user.email === null ||
            props.user.user.email.includes('RELAY@hngr.co')) {
            let pathname = '/menu/email_missing?sendTo=checkout'
            props.history.replace({pathname: pathname})
          } else if (props.user.user.phone_number_e164 === null) {
            props.analytics.info("CartClickedCheckout", "Logged in - no phone")
            props.signupModal.openPhoneCollector('checkout')
          } else {
            props.analytics.info("CartClickedCheckout", "Logged in")
            props.history.push({pathname: next_url})
          }
        } else {
          props.analytics.info("CartClickedCheckout", "Not logged in")
          let pathname = '/menu/auth?sendTo=checkout&flow=login'
          props.history.push({pathname: pathname})
        }
      }
    })
  }

  // if we get an ESCAPE key, then close the sidebar and tear down the listener
  const _handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      closeCart()
      document.removeEventListener("keydown", _handleKeyDown)
    }
  }

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  const clickedOutside = (e) => {
    if (e.target.className === 'CartBackground'){
      closeCart()
    }
    else{
      return
    }
  }

  if (width < 800) {
    if (expanded) {
      return (
        <div className="CartBackground" onClick={(e)=>clickedOutside(e)}>
          <Swipeable onSwipedDown={closeCart}>
            <Draggable axis="y" scale={0.25} bounds={{ top: 0 }}>
              <div className="Cart">
                <img
                  src="/close.png"
                  className="CartClose"
                  onClick={() => closeCart()}
                  alt="close"
                />
                <div className="CartHeader">Your Order</div>
                <CheckerButton
                  isLocked={props.rest.isLocked()}
                  rest={props.rest}
                  cart={props.cart}
                  hex_color_primary={props.rest.hex_color_primary}
                />
                <div className="CartItemsWrapper">
                  <CartItems disable_edits={props.disable_edits} />
                </div>
                <CouponInput />
                {props.cart.items.length === 0 &&
                  <div style={{fontSize: '16px', color: '#D7D7D7', margin: '25px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <EmptyCart/>
                    <div style={{marginTop: '20px'}}> Your cart is empty </div>
                    <div> Please add items to continue </div>
                  </div>
                }
              </div>
            </Draggable>
            <CheckoutBottom
              disable_edits={props.disable_edits}
              cart={props.cart}
              rest={props.rest}
              placeOrder={placeOrder}
            />
          </Swipeable>
          <button
            className="CartFooter"
            onClick={() => setExpanded(true)}
            style={{ background: props.rest.hex_color_secondary }}
          >
            <img src="/cart_white.png" className="CartIcon" alt="cart" />
            &nbsp;&nbsp;&nbsp;&nbsp;{props.cart.items.length}{" "}
            {props.cart.items.length === 1
              ? "item in your cart"
              : "items in your cart"}
          </button>
        </div>
      )
    } else {
      return (
        <button
          className={classnames("CartFooter", {
            "CartFooter NoIntercom": !props.rest.use_intercom,
          })}
          onClick={() => setExpanded(true)}
          style={{ background: props.rest.hex_color_secondary }}
        >
          <img src="/cart_white.png" className="CartIcon" alt="cart" />
          &nbsp;&nbsp;&nbsp;&nbsp;{props.cart.items.length}{" "}
          {props.cart.items.length === 1
            ? "item in your cart"
            : "items in your cart"}
        </button>
      )
    }
  } else {
    // DESKTOP
    return (
      <div className="CartDesktop" data-testid="cart">
        <div className="CartHeader">Your Order</div>
        <CheckerButton
          rest={props.rest}
          cart={props.cart}
          isLocked={props.rest.isLocked()}
          hex_color_primary={props.rest.hex_color_primary}
        />
        <div className="CartItemsWrapper">
          <CartItems disable_edits={props.disable_edits} />
          <div ref={el}>
            {" "}
            {!props.cart.nftOwner && (<CouponInput />)}{" "}
          </div>
          {props.cart.items.length === 0 &&
            <div style={{fontSize: '16px', color: '#D7D7D7', margin: '25px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <EmptyCart/>
              <div style={{marginTop: '20px'}}> Your cart is empty </div>
              <div> Please add items to continue </div>
            </div>
          }
        </div>
        <CheckoutBottom
          disable_edits={props.disable_edits}
          cart={props.cart}
          rest={props.rest}
          placeOrder={placeOrder}
        />
      </div>
    )
  }
}

export default SignupModalContextHOC(
  withRouter(
    AnalyticsContextHOC(
      UserContextHOC(CartContextHOC(RestContextHOC(withErrorHandling(Cart))))
    )
  )
)

const EmptyCart = props =>(
  <svg xmlns="http://www.w3.org/2000/svg" fill="#D7D7D7" width="120" height="120" viewBox="0 0 24 24"><path d="M6.665 9.068l-3.665-1.66v14l3.665 2.592 14.335-2.155v-14.845l-14.335 2.068zm-1.665 1.441l1 .453v10.118l-1-.707v-9.864zm14 9.615l-11 1.653v-10.881l11-1.587v10.815zm-2-15.833l-.001 1.749c0 .246-.18.455-.423.492-.303.045-.576-.19-.576-.495v-1.746c.001-.691-.231-1.304-.653-1.726-.368-.37-.847-.565-1.384-.565-1.547 0-2.96 1.558-2.963 3.268v1.681c0 .247-.181.457-.425.494-.302.046-.575-.189-.575-.494l.001-1.683c.004-2.261 1.866-4.266 3.962-4.266 1.717 0 3.039 1.387 3.037 3.291zm-9.999 2.209v-2.235c.004-2.26 1.866-4.265 3.962-4.265.492 0 .944.125 1.35.332-.423.17-.822.4-1.188.683l-.162-.015c-1.547 0-2.961 1.558-2.963 3.268v2.232c0 .248-.182.458-.427.494-.3.045-.572-.187-.572-.494z"/>
  </svg>
)
