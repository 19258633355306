import React, { Component } from "react"

import EntryPointHOC from "../Contexts/EntryPointHOC"
import RestContextHOC from "../Contexts/RestContextHOC"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import CartContextHOC from "../Contexts/CartContextHOC"
import UserContextHOC from "../Contexts/UserContextHOC"
import SignupModalContextHOC from "../Contexts/SignupModalContextHOC"
import Modal from "../Modal/Modal"
import "../css/MissingEmail.css"
import * as EmailValidator from 'email-validator';

const Subdomain = require("../helpers/subdomain")
const Api = require("../helpers/api.js")

class MissingEmail extends Component {
  constructor(props) {
    super(props)

    // capture any sendTo url params
    const params = new URLSearchParams(window.location.search)
    const sendTo = params.get('sendTo')

    // determine whether to allow email change
    // which we generally only allow on account creation
    // and not when editing
    let title = "We need more info"
    let allowEmail = true
    if (window.location.href.indexOf('set_name') !== -1) {
      allowEmail = false
      title = 'Edit Name'
    }

    this.state = {
      email: null,
      error: false,
      loading: false,
      errorMessage: '',
      sendTo: sendTo
    }
    this.handleEsc = this.handleEsc.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
    this.handleSubmission = this.handleSubmission.bind(this)
    this.registrationSuccess = this.registrationSuccess.bind(this)
  }

  validateEmail(email) {
    return EmailValidator.validate(email)
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEsc, false)
    this.props.analytics.info("MissingEmailPage")
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEsc, false)
  }

  handleClickOutside() {
    this.handleClose(true)
  }

  // registration with backend has been successfull
  // so invoke logins everywhere and then close the modal
  registrationSuccess(data) {
    if(data.error){
      this.setState({error: data.error})
      return
    }
    this.props.analytics.info('MissingEmailSubmitNowLogin')
    this.props.user.loginUser(data.userdata, data.hngr_token)
    this.props.cart.loginUser(data.userdata)
    this.handleClose()
  }

  handleSubmission() {
    this.props.analytics.info('MissingEmailSubmitButton')
    if (!this.validateEmail(this.state.email)) {
      let msg = "Invalid email address"
      this.setState({error: msg})
      return
    }

    let payload = {
      email: this.state.email,
      diner_uuid:this.props.user.user.diner_id,
      subdomain: Subdomain.getSubdomain()
    }
    this.props.analytics.info('MissingEmailSubmitButtonOK', payload)

    // send the API request, success means close modal, set user tokens
    // and move back to original page
    Api.callApi('diner_update_email',
      this.registrationSuccess,
      (resp) => {
        let err = 'Unknown Error'
        console.log(resp)
        if (resp.message) { err = resp.message }
        this.setState({error: err})},
      payload
    )
  }

  handleEsc(e) {
    if (e.keyCode === 27) {
      e.preventDefault()
      this.handleClose(true)
      return
    }
  }

  handleClose(forceClose=false) {
    this.props.analytics.info('MissingEmailClose')

    if (forceClose) {
      let currentURL = window.location.pathname
      let lastPath = currentURL.substring(0, currentURL.lastIndexOf("/"))
      this.props.history.push({
        pathname: lastPath,
        search: window.location.search
      })
      return
    }

    if (this.state.sendTo === 'checkout') {
      if (this.props.user.user.phone_number_e164 === null) {
        let pathName = '/menu/phone_verify?sendTo=checkout'
        this.props.history.replace({pathname: pathName})
      } else {
        let pathName = '/checkout'
        this.props.history.replace({pathname: pathName})
      }
    } else {
      let currentURL = window.location.pathname
      let lastPath = currentURL.substring(0, currentURL.lastIndexOf("/"))
      this.props.history.push({
        pathname: lastPath,
        search: window.location.search
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.rest.name && this.state.loading === true) {
      this.setState({loading: false})
    }

    if (prevProps.user.user == null && this.props.user.user) {
      this.setState({email: this.props.user.user.email,
        first: this.props.user.user.first_name,
        last: this.props.user.user.last_name})
    }
  }

  render() {
      return (
        <Modal close={() => this.handleClose(true)}>
        <div className='missingEmail'>
          <h3>We Need Your Email</h3>
          <div className='words'>
          We need an email address in order to send you a receipt and communicate about your order but didn't get one from your registration.
          </div>
          <div>
            <div className='label'>Email:</div>
            <input 
              value={this.state.email}
              type='email'
              onChange={(e)=>{this.setState({email: e.target.value.trim()})}}
              autocomplete="username email"/>
          </div>

          {this.state.error && (
            <div className="modalError">{this.state.error}</div>
          )}

          <button
            style={{background: this.props.rest.hex_color_primary}}
            onClick={e => this.handleSubmission()}>NEXT</button>

        </div>
        </Modal>)
  }

}

export default RestContextHOC(SignupModalContextHOC(
  AnalyticsContextHOC(
    CartContextHOC(
      EntryPointHOC(UserContextHOC(
        UserContextHOC(RestContextHOC(MissingEmail))))))))
