import React from "react";
import "./BooleanCircle.scss";

const BooleanCircle = props => {
  return (
    <div className={props.filled ? "hngr-radio checked" : "hngr-radio"}>
      {props.filled && (
        <div
          className="radio-inner"
          style={{ backgroundColor: props.fillColor || "#222222" }}
        />
      )}
    </div>
  );
};

export default BooleanCircle;
