import React, { Component, useMemo, useState, useEffect} from "react"
import { WalletModalProvider, WalletMultiButton, WalletConnectButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import  Waves from "./assets/icons/Waves"
import CartContextHOC from "./Contexts/CartContextHOC"
import SignupModalContextHOC from "./Contexts/SignupModalContextHOC"
import "./WalletButton.css"
import LogRocket from 'logrocket';
import {
    WalletReadyState,
} from '@solana/wallet-adapter-base';

const api = require("./helpers/api")

require('@solana/wallet-adapter-react-ui/styles.css');

const WalletButton = (props) => {

  const { name, readyState, wallet, connect, connecting, connected, publicKey } = useWallet();


  const content = useMemo(() => {
      if (connecting) return 'Connecting ...';
      if (connected) {
        return 'Connected';
      }
      if (wallet) return 'Connect Wallet';
      return 'Connect Wallet';
  }, [connecting, connected, wallet]);

  useEffect(() => {
    if(wallet && window.CLICKED_WALLET){
      connect().catch(() => {}); // Ignore this error because it'll be handled by the provider
    }
    if(wallet && window.CLICKED_WALLET_SIGNUP && props.signUp){ 
      connect().catch(() => {}); // Ignore this error because it'll be handled by the provider
    }
  }, [wallet]);

  useEffect(() => {
    if(publicKey){
      props.cart.onWalletConnected(publicKey)
      if(props.signUp){
        props.signupModal.onWalletConnected(publicKey)
      }
    }
  }, [publicKey]);




  return (
    <WalletMultiButton>
      <div 
        className='connect-wallet-button' 
        onClick={()=>{
          if(props.signUp){
            window.CLICKED_WALLET_SIGNUP = true
          } else {
            window.CLICKED_WALLET = true
          }

          LogRocket.init(process.env.REACT_APP_LOGROCKET);
          api.callApi(
            "post-to-nft-slack",
            (resp)=>{console.log(resp)},
            (error)=>{console.log("error posting top slack", error)},
            {msg:"[ordering site] clicked connect wallet  " + window.location.href}
          )

        }}
      >
        <img className='sol-img' src='/sol.png' style={{marginRight:'8px'}}/>
        {content}
      </div>
    </WalletMultiButton>
  )

}

export default SignupModalContextHOC(CartContextHOC(WalletButton))
