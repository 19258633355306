import React, { Component } from "react"
import classnames from "classnames"
import TimeSelect from "./sparkles/TimeSelect/TimeSelect"
import GooglePlacesSearch from './GooglePlacesSearch'
import RestContextHOC from "./Contexts/RestContextHOC"
import { withRouter, Redirect } from "react-router-dom"
import EntryPointHOC from "./Contexts/EntryPointHOC"
import CartContextHOC from "./Contexts/CartContextHOC"
import AnalyticsContextHOC from "./Contexts/AnalyticsContextHOC"
import extractAddressComponents from "./helpers/googlePlaces"
import { areValuesEmpty } from "./helpers/object"
import Loading from "./Loading/Loading"
import { MENU_TYPES } from "./constants"
import { Text, Header, ButtonWrapper } from "./atoms"
import { ReactComponent as Close } from "./assets/icons/modal-close.svg"
import { ReactComponent as CurLoc } from "./assets/icons/cur-loc.svg"
import { ReactComponent as RightArrow } from "./assets/icons/chevron-right.svg"
import { ReactComponent as DeliveryIcon } from "./assets/icons/Delivery.svg"
import { ReactComponent as PickupIcon } from "./assets/icons/Pickup.svg"
import Modal from "./Modal/Modal"
import "./css/CheckerPageNew.css"
import "./css/GooglePlacesAutocomplete.css"

const api = require("./helpers/api")
const Subdomain = require("./helpers/subdomain")
const Timestamp = require("./helpers/timestamps");


class CheckerPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      pickup_or_delivery: null,
      menu: this.props.rest.menu,
      addressObj: null,
      verifiedAddress: false,
      height: window.innerHeight
    }

    this.timeSelectRef = React.createRef()
    this.placesRef = React.createRef()
    this.bottom = React.createRef()

    if(this.props.cart.initial_address){
      this.setGooglePlacesAddress(
        JSON.parse(JSON.stringify(this.props.cart.initial_address)))
    }

    // get search params and identify if pickup or delivery
    let params = new URLSearchParams(window.location.search)
    if (
      params.get('order_type') === "delivery" || 
      params.get('order_type') === "pickup"
    ) {
      this.state["pickup_or_delivery"] = params.get('order_type')
    }

    window.checker = this
    this.delivery = this.delivery.bind(this)
    this.pickup = this.pickup.bind(this)
    this.closeCheckerPage = this.closeCheckerPage.bind(this)
    this.startOrder = this.startOrder.bind(this)
    this.updateTimeSelected = this.updateTimeSelected.bind(this)
  }  

  componentDidMount(){
    this.setInitialPickupOrDelivery()
    this.setState({height:window.innerHeight})
    window.addEventListener("resize", this.handleResize.bind(this))

  }

  componentWillUnount(){
    window.removeEventListener("resize", this.handleResize)
  }

  componentDidUpdate(prevProps, prevState){
    if (
      this.props.rest &&
      this.props.rest.hours &&
      !this.state.loaded 
    ) {
      this.setState({ loaded: true })
    }

    if(this.props.rest.menu && !this.state.menu){
      this.setState({menu:this.props.rest.menu})
    }

    if(
      this.props.cart.initial_address && 
      !prevProps.cart.initial_address
    ){
      this.setGooglePlacesAddress(
        JSON.parse(JSON.stringify(this.props.cart.initial_address)))
    }
  }

  handleResize(){
    this.setState({height:window.innerHeight})
  }

  setInitialPickupOrDelivery(){
    let params = new URLSearchParams(window.location.search)
    if (
      params.get('order_type') === "delivery" || 
      params.get('order_type') === "pickup"
    ) {
      return
    }

    if(!this.props.rest.menu){
      setTimeout(()=>this.setInitialPickupOrDelivery(), 30)
      return
    }
    if (!this.props.cart.pickupOrDeliveryLoaded || !this.props.rest.hours){
      setTimeout(()=>this.setInitialPickupOrDelivery(), 30)
      return
    }
    
    if(this.props.rest.menu.menu_type === 'PICKUP'){
      this.setState({pickup_or_delivery:'pickup'})
      return
    } else if(this.props.rest.menu.menu_type === 'DELIVERY'){
      this.setState({pickup_or_delivery:'delivery'})
      return
    }
    
    if(this.props.cart.pickup_or_delivery){
      this.setState({pickup_or_delivery:this.props.cart.pickup_or_delivery})
      return
    } 
      
    //if delivery open but not pickup 
    if(
      this.props.rest.hours.delivery.open  &&
      !this.props.rest.hours.pickup.open
    ){
      this.setState({pickup_or_delivery:'delivery'})
      return
    }
      
    this.setState({pickup_or_delivery:'pickup'})
    
  }

  setGooglePlacesAddress(addressObj){
    if(this.placesRef && this.placesRef.current){
      this.placesRef.current.setState({
        addressObj:addressObj,
        input:addressObj.formattedAddress,
        verifiedAddress:true
      })
    } else{
      setTimeout(()=>this.setGooglePlacesAddress(addressObj), 50)
    }
  }

  pickup(){
    this.props.analytics.info("ChangeOrderType", 'pickup')
    this.setState({pickup_or_delivery:'pickup'})
  }

  delivery(){
    this.props.analytics.info("ChangeOrderType", 'delivery')
    this.setState({pickup_or_delivery:'delivery'})
  }


  openNow(type){
    if(type === 'pickup'){
      return this.props.rest.hours.pickup.open
    } 
    if(type === 'delivery'){
      return this.props.rest.hours.delivery.open
    }
  }

  closeCheckerPage() {
    this.props.analytics.info("CloseCheckerPage", "CloseModal")
    let path = this.props.location.pathname.replace("/start", "")
    this.props.history.push(path)
  }

  startOrder(){
    if(this.state.pickup_or_delivery === 'delivery'){
      this.props.analytics.info("CheckerPageStartOrderClicked", "delivery")
      let {
        city,
        state,
        zipcode,
        address1,
        lat,
        lng,
        formatted_address
      } = this.state.addressObj

      var payload = {
        fulfill_order_at: this.state.futureOrderTime, 
        lat: lat, 
        lng: lng,
        address1: address1,
        city: city, 
        state: state, 
        zipcode: zipcode,
        formatted_address: formatted_address, 
        subdomain: Subdomain.getSubdomain()
      }

      this.props.analytics.info("CheckerPageVerifyZone",
        JSON.stringify(payload))

      api.callApi( 
        "delivery_zone_verify",
        (data)=>{
          if (data.success === 1) { 
            this.props.analytics.info("StartDeliveryOrderSuccess", data.message)
            this.props.cart.saveCheckerPage()
            this.props.cart.setDeliveryZone(data.delivery_zone)
            this.props.cart.setInitialAddress(this.state.addressObj)
            this.props.cart.setFutureOrderTime(this.state.futureOrderTime)
            this.props.analytics.info("CloseCheckerPage", "StartOrderDelivery")
            let prevPage = this.props.location.pathname.replace("/start", "")
            this.props.history.push(prevPage)
          } else {
            //this.setState({ deliveryZoneError: data.message });
            if(this.placesRef && this.placesRef.current){
              this.placesRef.current.setState({error:data.message})
            }
            this.props.analytics.info("StartDeliveryOrderFailure",
              data.message + data.formatted_address)
          } 
        },
        (err)=>{
          if(this.placesRef && this.placesRef.current){
            let error = 'Unknown error validating delivery address'
            this.placesRef.current.setState({error:error})
          }
        },
        payload
      )
    } else if(this.state.pickup_or_delivery === 'pickup'){
      this.props.analytics.info("CheckerPageStartOrderClicked", "pickup")
      this.props.cart.setFutureOrderTime(this.state.futureOrderTime)
      this.props.cart.setPickup()
      this.props.analytics.info("CloseCheckerPage", "StartOrderPickup")
      let prevPage = this.props.location.pathname.replace("/start", "")
      this.props.history.push(prevPage)
    }
  }


  /*
    dont show the prep time IF we have a futuer order tiem and we're on the curren oen
    so ! (type ===state.pickup_or_delivery && futureOrderTme)
  */

  renderRightSideOfPickupDeliveryButton(type){
    // show the prep times if the rest is open and no future order
    // time is selected AND we're on the currently selected type
    if(
      this.props.rest.hours[type].open &&
      !(this.state.futureOrderTime  && this.state.futureOrderTime !== '0' && type === this.state.pickup_or_delivery)
    ){
      let prep_time
      if(type === 'pickup') prep_time = this.props.rest.default_pickup_time
      if(type === 'delivery') prep_time = this.props.rest.default_delivery_time
      return (
        <div className='checkerpage-rightside-wrapper'>
          <div className='checkerpage-prep-time'>
            {prep_time}m
          </div>
        </div>
      )
    } else {
      let svg
      if(type === 'pickup') svg = (<PickupBag />) 
      if(type === 'delivery') svg = (<Delivery/>)
      return (
        <div className='checkerpage-rightside-wrapper'>
          <div className='checkerpage-future-order-icon'>
            {svg}
          </div>
        </div>
      )
    }
  }

  everythingIsClosed(){
    if(this.props.rest.menu.menu_type === 'PICKUP_AND_DELIVERY'){
      return (!this.props.rest.hours.delivery.open &&
              !this.props.rest.hours.pickup.open)
    } else if (this.props.rest.menu.menu_type === 'PICKUP') {
      return !this.props.rest.hours.pickup.open
    } else if (this.props.rest.menu.menu_type === 'DELIVERY'){
      return !this.props.rest.hours.delivery.open
    }
  }

  everythingIsClosedEarly(){
    if(this.props.rest.menu.menu_type === 'PICKUP_AND_DELIVERY'){
    return (this.props.rest.hours.pickup.tablet_close_early &&
            this.props.rest.hours.delivery.tablet_close_early)
    } else if (this.props.rest.menu.menu_type === 'PICKUP') {
      return this.props.rest.hours.pickup.tablet_closed_early
    } else if (this.props.rest.menu.menu_type === 'DELIVERY'){
      return this.props.rest.hours.delivery.tablet_clsoed_early
    }
  }

  // pickup is an option, but its closed now and now future ordering avialble
  pickupUnavailable(){
    if(
      !this.props.rest.hours.pickup.open && 
      !this.props.rest.checkIfFuturePickupAvailable()
    ){
      return true 
    } else{
      return false
    }
  }

  // delivery is an option, but its closed now and now future ordering avialble
  deliveryUnavailable(){
    if(
      !this.props.rest.hours.delivery.open && 
      !this.props.rest.checkIfFutureDeliveryAvailable()
    ){
      return true 
    } else{
      return false
    }
  }

  renderOrderLaterOnly(type){
    //dont render this is both pickup AND delivery are closed
    if(
      !this.props.rest.hours.pickup.open &&
      !this.props.rest.hours.delivery.open
    ){
      return null
    }

    let order_later_only = (
      <div className='checkerpage-order-later-only'>
        Order Later Only <Clock/>
      </div>
    )
    if(type === 'pickup'){
      if(
        !this.props.rest.hours.pickup.open && 
        this.props.rest.checkIfFuturePickupAvailable()
      ){
        return order_later_only
      }
    }
    if(type === 'delivery'){
      if(
        !this.props.rest.hours.delivery.open && 
        this.props.rest.checkIfFutureDeliveryAvailable()
      ){
        return order_later_only
      }
    }
  }

  renderOrderingUnavailable(type){
    if(type === 'pickup'){
      if(this.pickupUnavailable()){
        return (
          <div className='checkerpage-currently-unavailable'>
            Currently Unavailable
          </div>
        )
      }
    }
    if(type === 'delivery'){
      if(this.deliveryUnavailable()){
        return (
          <div className='checkerpage-currently-unavailable'>
            Currently Unavailable
          </div>
        )
      }
    }

  }

  fromLanding(){
    if (
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.fromLanding
    ){
      return true
    } else{
      return false
    }
  }

  nextAvailableTime() {
    let pickup = null
    let delivery = null
    let isToday = false
    let isTomorrow = false
    let today
    let tomorrow

    // the first index into the dict is today
    for(let i in this.props.rest.futureHours['pickup']){
      today = i
      break
    }

    for(let i in this.props.rest.futureHours['pickup']){
      if(this.props.rest.futureHours['pickup'][i].length){
        pickup = this.props.rest.futureHours['pickup'][i][0]
        if(i === today) isToday = true
        if(i == window.TOMORROW_TS ) isTomorrow = true
        break
      }
    }

    for(let i in this.props.rest.futureHours['delivery']){
      if(this.props.rest.futureHours['delivery'][i].length) {
        delivery = this.props.rest.futureHours['delivery'][i][0]
        if(i === today) isToday = true
        if(i === tomorrow) isTomorrow = true
        break
      }
    }

    let ts 
    // this should never happen
    if(!pickup && !delivery) {
      return ''
    } else if(!pickup) {
      ts = delivery 
    } else if(!delivery) {
      ts = pickup
    } else if(delivery < pickup) {
      ts = delivery
    } else {
      ts = pickup
    }

    // set the background menu based on this next available time
    //if (!this.state.futureOrderTime) {
    //  const rest_subdomain = Subdomain.getSubdomain()
    //  this.props.rest.refreshBlackouts(rest_subdomain, ts)
    //}

    let dow = Timestamp.getDayOfWeek(ts)
    if(isToday) {
      dow = "Today"  
    } else if (isTomorrow) {
      dow = "Tomorrow"
    }

    let mm = Timestamp.getMonthInt(ts)
    let dd = Timestamp.getDayOfMonth(ts)
    let time = Timestamp.getTimeOfDay(ts)
    return `${dow}, ${mm}/${dd} at ${time}`
  }

  // Cases: 
  // -- need to pick a future order time
  // -- need to enter delivery address
  // -- good to go
  renderGoButton(){
    let order_type = this.state.pickup_or_delivery
    if(order_type === 'pickup') order_type = 'Pickup'
    if(order_type === 'delivery') order_type = 'Delivery'

    let text
    if (this.fromLanding()){
      text = 'Start'
    } else{
      text = 'Update'
    }

    let good_to_go = ( 
      <div onClick={this.startOrder} className={classnames('checkerpage-go-button', 'checkerpage-start-order')}>
        <div>{text} Your {order_type} Order</div>
        <GoCaret/> 
      </div>
    )

    // pick up is good IF the restaurant is open for pickup
    // OR the restaurant is open later and fulfill_order_at is set
    if(this.state.pickup_or_delivery === 'pickup'){
     //pick is open now
      if(this.props.rest.hours.pickup.open){
        return good_to_go
      }
      if(!this.props.rest.hours.pickup.open){
        if(this.state.futureOrderTime){
          return good_to_go
        } else {
          return (
            <div 
              className={classnames('checkerpage-go-button', 
                'checkerpage-go-button-disabled')}
              >
              <div>Select a time to schedule your order</div>
            </div>
          )
        }
      }
    } else if(this.state.pickup_or_delivery === 'delivery'){
    // we only need a verified address if ASAP is closed
      if(!this.state.addressObj || 
         (!this.state.verifiedAddress && this.props.rest.hours.delivery.open)){
        return (
          <div 
            className={classnames('checkerpage-go-button', 
              'checkerpage-go-button-disabled')}
            >
            <div>Enter Your Delivery Address to Continue</div>
          </div>
        )
      }
      if(!this.props.rest.hours.delivery.open){
        if(this.state.futureOrderTime){
          return good_to_go
        } else {
          return (
            <div 
              className={classnames('checkerpage-go-button', 
                'checkerpage-go-button-disabled')}
              >
              <div>Select a time to schedule your order</div>
            </div>
          )
        }
      }
      return good_to_go  
    }
  }

  renderRestInfo(){
    return (
      <div className='checkerpage-rest-info-container'>
       <img
          className="checkerpage-rest-logo"
          src={this.props.rest.logo_color}
          alt="logo"
        />
        <div className='checkerpage-rest-name'>{this.props.rest.name}</div>
        { this.props.rest.secondary_name && (
          <div className='checkerpage-rest-name-secondary'>
            {this.props.rest.secondary_name}
          </div>
        )}
      </div> 
    )
  }

  renderPickupDeliveryButtons(){
    let pickup, delivery, oneOption 
    if(this.props.rest.menu.menu_type === 'PICKUP_AND_DELIVERY'){
      pickup = true
      delivery = true
      oneOption = false
    } else if (this.props.rest.menu.menu_type === 'PICKUP'){
      pickup = true
      delivery = false
      oneOption = true
    } else if (this.props.rest.menu.menu_type === 'DELIVERY'){
      pickup = false
      delivery = true
      oneOption = true
    }

    return(
      <div className='checekerpage-pickup-delivery-container'>
        {pickup && (
          <div
            onClick={this.pickup}
            className={classnames('checkerpage-pickup-delivery-selector',
              {'one-option':oneOption},
              {'checker-page-pickup-delivery-selected':
              this.state.pickup_or_delivery === 'pickup'},
              {'checker-option-disabled':this.pickupUnavailable()})
            }
          >
            {oneOption && (<div className='hidden-one-option'>&nbsp;</div>)}
            <div>Pickup</div>
            {this.renderRightSideOfPickupDeliveryButton('pickup')}
            {this.renderOrderLaterOnly('pickup')}
            {this.renderOrderingUnavailable('pickup')}
          </div>
        )}
        
        {delivery && (
          <div
            onClick={this.delivery}
            className={classnames('checkerpage-pickup-delivery-selector',
              {'one-option':oneOption},
              {'checker-page-pickup-delivery-selected':
              this.state.pickup_or_delivery === 'delivery'},
              {'checker-option-disabled':this.deliveryUnavailable()})
            }
          >
            {oneOption && (<div className='hidden-one-option'>&nbsp;</div>)}
            <div>Delivery</div>
            {this.renderRightSideOfPickupDeliveryButton('delivery')}
            {this.renderOrderLaterOnly('delivery')}
            {this.renderOrderingUnavailable('delivery')}
          </div>
        )}

      </div> 
    )
  }

  // this is to top-align the modal as long as the screen is > 600px.
  // otherwise center the modal vertically and don't set a max height
  modalStyle(){
   let style = {
      padding: "15px 16px 0px 16px",
      maxWidth:'450px',
      "--rest-primary": this.props.rest.hex_color_primary,
      "--rest-secondary": this.props.rest.hex_color_secondary,
    }
    if(this.state.height > 600){
      style['maxHeight'] = 'calc(100vh - 80px) !important'
    }
    return style
  }

  // this used to do something. NOT ANYMORE!
  modalBackgroundStyle(){
    return {}
  }

  // used when updating the time
  updateTimeSelected(val) {
    let time = val
    if(val === 'false') time = false
    this.setState({futureOrderTime:time})

  }

  render() {
    if (
      !this.state.loaded || !this.state.menu || 
      !this.state.pickup_or_delivery
    ) {
      return (
        <Modal
          close={this.closeCheckerPage}
          style={this.modalStyle()} 
          backgroundStyle={this.modalBackgroundStyle()}
        >
          <div 
            style={{
              width:'100%', 
              display:'flex', 
              alignItems:'center',
              justifyContent:'center'
            }}
          >
            <Loading />
          </div>
        </Modal>
      )
    }

    if (
      this.everythingIsClosed() && 
      !this.state.clickedPastWereClosed && 
      (this.fromLanding() || !this.props.rest.checkIfFutureAvailable()) 
    ) {
      
      // two different pages, one for closed early due to tablet
      // and one for the restaurant is just normally closed

      // rest is closed early due to tablet
      if(this.everythingIsClosedEarly()){
        return (
          <Modal
            close={this.closeCheckerPage}
            style={this.modalStyle()} 
            backgroundStyle={this.modalBackgroundStyle()}
          >
            <div
              className="checkerpage-container"
              style={{
                "--rest-primary": this.props.rest.hex_color_primary,
                "--rest-secondary": this.props.rest.hex_color_secondary,
                paddingBottom: this.props.rest.checkIfFutureAvailable() ?
                  null : '10px'
              }}
            >
              {this.renderRestInfo()}
              <div className='checkerpage-were-closed-container'>
                <div className='were-closed'>Sorry, ordering is temporarily unavailable.</div>
                {(
                  this.props.rest.checkIfFutureDeliveryAvailable() || 
                  this.props.rest.checkIfFuturePickupAvailable()
                ) && (
                  <div style={{textAlign:'center'}}>Please schedule an order for a future time or come back later.</div>
                )}
              </div>
              <img
                className='checkerpage-closed-hourglass'
                src='https://cdn.hngr.co/HourGlass.png'
              />
              <div
                className='checkerpage-browse-our-menu'
                onClick={this.closeCheckerPage}
              >
                Browse Our Menu
              </div>
            </div>
              {this.props.rest.checkIfFutureAvailable() && (
                <div 
                  className={classnames('checkerpage-go-button', 'checkerpage-start-order')}
                  onClick={()=>{
                    this.props.analytics.info('CheckerPageScheduleYourOrder')
                    this.setState({clickedPastWereClosed:true})
                  }}
                >
                  <div>Schedule Your Order for Later</div>
                  <Clock/> 
                </div>
              )}

          </Modal>
        )
      } else {
        // rest is closed regularly, not thru tablet
        return (
          <Modal
            close={this.closeCheckerPage}
            style={this.modalStyle()} 
            backgroundStyle={this.modalBackgroundStyle()}
          >
            <div
              className="checkerpage-container"
              style={{
                "--rest-primary": this.props.rest.hex_color_primary,
                "--rest-secondary": this.props.rest.hex_color_secondary,
                paddingBottom: this.props.rest.checkIfFutureAvailable() ?
                  null : '10px'
              }}
            >
              {this.renderRestInfo()}
              <div className='checkerpage-were-closed-container' style={{lineHeight:'12px'}}>
                {( this.props.rest.checkIfFutureDeliveryAvailable() || 
                   this.props.rest.checkIfFuturePickupAvailable() ) ? (
                     <div className='checkerpage-were-closed-container'>
                      <div className='were-closed'>Only Scheduled Ordering Available</div>
                      <div>Order ahead and select an available time</div>
                    </div>
                  ) : (<div className='were-closed'>Sorry, we're closed</div>)}
              </div>
              {/* <ClosedSign className='checkerpage-closed-sign'/> */}

            {( this.props.rest.checkIfFutureDeliveryAvailable() || 
               this.props.rest.checkIfFuturePickupAvailable() ) && (
            
              <div className='checkerpage-were-closed-container'
                style={{marginTop:'12px', fontSize:'18px'}}
              >
                <div className='earliest-time-header'>
                  <MiniClock/>
                  <div>Earliest Available Time:</div>
                </div> 
                <div>
                  {this.nextAvailableTime()}
                </div>
              </div>
            )}

              <div
                className='checkerpage-browse-our-menu'
                onClick={this.closeCheckerPage}
              >
                Browse Our Menu
              </div>
            </div>
            {(
              this.props.rest.checkIfFutureDeliveryAvailable() || 
              this.props.rest.checkIfFuturePickupAvailable()
            ) && (
              <div 
                className={classnames('checkerpage-go-button', 'checkerpage-start-order')}
                onClick={()=>{
                  this.props.analytics.info('CheckerPageScheduleYourOrder')
                  this.setState({clickedPastWereClosed:true})
                }}
              >
                <div>Schedule Your Order for Later</div>
                <Arrow/> 
              </div>
            )}
          </Modal>
        )
      }
    }

    return (
      <Modal
        close={this.closeCheckerPage}
        style={this.modalStyle()} 
        backgroundStyle={this.modalBackgroundStyle()}
      >
        <div
          className="checkerpage-container"
          style={{
            "--rest-primary": this.props.rest.hex_color_primary,
            "--rest-secondary": this.props.rest.hex_color_secondary,
          }}
        >
          {this.renderRestInfo()}

          {this.everythingIsClosed() && ( 
            <div className='select-time-to-schedule-order'>
              Select a time to schedule your order
            </div>
          )}
       
          {this.renderPickupDeliveryButtons()}

          {this.state.pickup_or_delivery === 'delivery' && (
            <div className='checkerpage-google-places-wrapper'>
              <GooglePlacesSearch 
                initialAddressObj={this.state.addressObj}
                futureOrderTime={this.state.futureOrderTime}
                ref={this.placesRef}
                onShowSuggestions={()=>{
                  if(this.bottom && this.bottom.current){
                    this.bottom.current.scrollIntoView({ behavior: "smooth" })
                  }
                }}
                onAddressUpdate={(val)=>{
                  this.setState({addressObj:val})
                  if(this.placesRef && this.placesRef.current){
                    this.placesRef.current.setState({error:null})
                  }
                }}
                onVerifiedAddressUpdate={(val)=>{
                  this.setState({verifiedAddress:val})
                }}
              />
            </div>
          )}

          <div className='checkerpage-timeselect-container'>
            <TimeSelect
              key={'timeselect' + this.state.pickup_or_delivery}
              updateTime={(val)=>this.updateTimeSelected(val)}
              ref={this.timeSelectRef}
              pickup_or_delivery={this.state.pickup_or_delivery}
              suggestedTime={this.state.futureOrderTime}
              {...this.props}
            />
          </div> {/* checkerpage-timeselect-container */}

          <div ref={this.bottom} style={{position:'absolute', bottom:'0px'}}>&nbsp;</div>
        </div> { /* checkerpage-container */ }
        {this.renderGoButton()}
      </Modal>
    )
  }

}

export default withRouter(
  EntryPointHOC(
    AnalyticsContextHOC(
      CartContextHOC(RestContextHOC(CheckerPage))
    )
  )
)

const PickupBag = props => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.44922 4.19694C5.24845 4.39218 5.14599 5.05244 4.8382 8.13312C4.63488 10.169 4.34743 13.0006 4.19962 14.4256C4.05181 15.8506 3.96727 17.1578 4.01195 17.3307C4.07535 17.5765 4.39023 17.7798 5.45872 18.2648L6.82443 18.8846L13.2093 18.9622C18.8102 19.0302 19.619 19.0155 19.7971 18.8424C19.9086 18.7339 20 18.5469 20 18.4268C19.9999 18.3066 19.727 15.6622 19.3935 12.5502C19.0599 9.43816 18.7315 6.36918 18.6634 5.73039C18.5749 4.89765 18.472 4.50613 18.3002 4.3477C18.0844 4.14868 17.4413 4.11838 11.8754 4.04449C6.02935 3.96684 5.67729 3.97516 5.44922 4.19694ZM17.3796 5.7538C17.4616 6.26369 17.8938 10.2866 18.1305 12.7453C18.2064 13.5323 18.298 14.3079 18.3341 14.4688L18.3999 14.7615H12.8463C7.50836 14.7615 7.2926 14.752 7.2926 14.518C7.2926 14.1852 6.65027 6.65327 6.55624 5.88388L6.4808 5.26602H11.8911H17.3012L17.3796 5.7538ZM9.57258 6.55857L9.28365 6.95309L10.1044 7.46883C11.001 8.03232 11.7494 8.20519 12.7793 8.08656C13.4206 8.01268 14.6828 7.48041 14.8536 7.21181C14.9524 7.0565 14.6614 6.27098 14.5309 6.34044C13.3269 6.98145 12.8479 7.11776 12.0254 7.05364C11.5559 7.01696 11.0212 6.89247 10.8373 6.77684C9.76305 6.10201 9.8908 6.12399 9.57258 6.55857ZM18.5286 16.3041C18.5286 16.4373 18.5669 16.8178 18.6137 17.1496L18.6988 17.7532H13.1294H7.56012L7.55865 17.3955C7.55785 17.1987 7.51986 16.8183 7.47425 16.55L7.39132 16.0622H12.96C18.3002 16.0622 18.5286 16.0721 18.5286 16.3041Z" fill="black"/>
  </svg>
)

const Delivery = props => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2181 4.16542C10.0149 4.32942 9.79602 4.88401 8.91323 7.47165C8.32989 9.18174 7.51478 11.5602 7.10202 12.7571C6.68927 13.954 6.3521 15.052 6.35289 15.1972C6.35392 15.4037 6.568 15.5745 7.33656 15.9818L8.3189 16.5024L13.4907 16.5676C18.0274 16.6248 18.6876 16.6124 18.8678 16.467C18.9808 16.3759 19.0934 16.2188 19.1181 16.1179C19.1426 16.017 19.464 13.7958 19.8322 11.1818C20.2003 8.56783 20.5638 5.99002 20.6397 5.45345C20.7388 4.75399 20.7356 4.42513 20.6285 4.29205C20.4941 4.12489 19.9778 4.09943 15.4707 4.03737C10.7367 3.97215 10.449 3.97914 10.2181 4.16542ZM19.5918 5.47312C19.5537 5.90141 19.0786 9.28051 18.766 11.3457C18.666 12.0067 18.5811 12.6582 18.5774 12.7933L18.5708 13.0392H14.0584C9.7214 13.0392 9.54804 13.0312 9.5961 12.8346C9.66444 12.5552 10.6895 6.22863 10.7711 5.58238L10.8367 5.06341H15.2325H19.6283L19.5918 5.47312ZM13.0833 6.14909L12.7675 6.48047L13.3285 6.91367C13.9412 7.38698 14.5138 7.53218 15.3749 7.43254C15.9112 7.37048 17.046 6.9234 17.24 6.69778C17.3521 6.56733 17.277 5.90753 17.1567 5.96587C16.0468 6.50429 15.6296 6.61879 14.9745 6.56493C14.6006 6.53412 14.1917 6.42956 14.066 6.33243C13.3318 5.7656 13.4311 5.78407 13.0833 6.14909ZM18.3585 14.335C18.3312 14.4468 18.2841 14.7664 18.254 15.0451L18.1992 15.5521H13.6741H9.14902L9.22129 15.2516C9.26105 15.0863 9.3083 14.7669 9.32635 14.5415L9.35915 14.1317H13.8837C18.2226 14.1317 18.4062 14.14 18.3585 14.335Z" fill="black"/>
  <circle cx="8.5" cy="18.5" r="1.5" fill="black"/>
  <circle cx="17.5" cy="18.5" r="1.5" fill="black"/>
  <line x1="4.5" y1="5.5" x2="8.5" y2="5.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
  <line x1="4.5" y1="11.5" x2="8.5" y2="11.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
  <line x1="2.5" y1="8.5" x2="7.5" y2="8.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

const Arrow = props => (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.7348 12.9814L9.40132 20.3987C9.04764 20.7564 8.47423 20.7564 8.12058 20.3987L7.26525 19.5336C6.91217 19.1765 6.91149 18.5977 7.26374 18.2397L13.0756 12.3336L7.26374 6.42761C6.91149 6.06965 6.91217 5.49086 7.26525 5.13374L8.12058 4.26862C8.47426 3.91089 9.04767 3.91089 9.40132 4.26862L16.7348 11.6859C17.0884 12.0437 17.0884 12.6236 16.7348 12.9814Z" fill="white"/>
</svg>)

const Clock = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.848 12.459c.202.038.202.333.001.372-1.907.361-6.045 1.111-6.547 1.111-.719 0-1.301-.582-1.301-1.301 0-.512.77-5.447 1.125-7.445.034-.192.312-.181.343.014l.985 6.238 5.394 1.011z"/></svg>)

const MiniClock = props => (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 2C6.47768 2 2 6.47768 2 12C2 17.5223 6.47768 22 12 22C17.5223 22 22 17.5223 22 12C22 6.47768 17.5223 2 12 2ZM12 20.3036C7.41518 20.3036 3.69643 16.5848 3.69643 12C3.69643 7.41518 7.41518 3.69643 12 3.69643C16.5848 3.69643 20.3036 7.41518 20.3036 12C20.3036 16.5848 16.5848 20.3036 12 20.3036Z" fill="#C0BBBB"/>
<path d="M16.6792 15.3905L12.8595 12.6289V5.99944C12.8595 5.88158 12.7631 5.78516 12.6453 5.78516H11.3569C11.239 5.78516 11.1426 5.88158 11.1426 5.99944V13.3762C11.1426 13.4459 11.1747 13.5102 11.231 13.5503L15.6613 16.7807C15.7578 16.8503 15.8917 16.8289 15.9613 16.7352L16.7274 15.6905C16.797 15.5914 16.7756 15.4575 16.6792 15.3905V15.3905Z" fill="#C0BBBB"/>
</svg>)

const GoCaret = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>)

const ClosedSign = props => (<svg {...props} version="1.1"  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 463 463"><path d="M431.5,220h-28.893L252.546,69.939C254.113,66.792,255,63.248,255,59.5c0-12.958-10.542-23.5-23.5-23.5 S208,46.542,208,59.5c0,3.748,0.887,7.292,2.454,10.439L60.393,220H31.5C14.131,220,0,234.131,0,251.5v144 C0,412.869,14.131,427,31.5,427h400c17.369,0,31.5-14.131,31.5-31.5v-144C463,234.131,448.869,220,431.5,220z M231.5,51 c4.687,0,8.5,3.813,8.5,8.5s-3.813,8.5-8.5,8.5s-8.5-3.813-8.5-8.5S226.813,51,231.5,51z M221.061,80.546 C224.208,82.113,227.752,83,231.5,83s7.292-0.887,10.439-2.454L381.393,220H81.607L221.061,80.546z M448,395.5 c0,9.099-7.402,16.5-16.5,16.5h-400c-9.098,0-16.5-7.401-16.5-16.5v-144c0-9.099,7.402-16.5,16.5-16.5h400 c9.098,0,16.5,7.401,16.5,16.5V395.5z"/>
  <path d="M63.5,291c6.991,0,13.248,4.427,15.569,11.017c1.376,3.905,5.661,5.956,9.565,4.582c3.907-1.377,5.958-5.659,4.582-9.566 C88.785,284.452,76.843,276,63.5,276C46.131,276,32,290.131,32,307.5v32c0,17.369,14.131,31.5,31.5,31.5 c13.327,0,25.265-8.438,29.705-20.998c1.38-3.905-0.666-8.19-4.571-9.571c-3.905-1.379-8.19,0.666-9.571,4.571 C76.737,351.58,70.483,356,63.5,356c-9.098,0-16.5-7.402-16.5-16.5v-32C47,298.402,54.402,291,63.5,291z"/>
  <path d="M151.5,356H119v-72.5c0-4.143-3.358-7.5-7.5-7.5c-4.142,0-7.5,3.357-7.5,7.5v80c0,4.143,3.358,7.5,7.5,7.5h40 c4.142,0,7.5-3.357,7.5-7.5S155.642,356,151.5,356z"/>
  <path d="M191.5,276c-17.369,0-31.5,14.131-31.5,31.5v32c0,17.369,14.131,31.5,31.5,31.5s31.5-14.131,31.5-31.5v-32 C223,290.131,208.869,276,191.5,276z M208,339.5c0,9.098-7.402,16.5-16.5,16.5s-16.5-7.402-16.5-16.5v-32 c0-9.098,7.402-16.5,16.5-16.5s16.5,7.402,16.5,16.5V339.5z"/>
  <path d="M263.5,316c-8.944,0-16.5-5.725-16.5-12.5c0-6.775,7.556-12.5,16.5-12.5c7.839,0,14.819,4.425,16.237,10.292 c0.973,4.025,5.023,6.5,9.052,5.528c4.026-0.973,6.501-5.025,5.528-9.052C291.27,285.155,278.309,276,263.5,276 c-17.369,0-31.5,12.337-31.5,27.5c0,15.163,14.131,27.5,31.5,27.5c8.944,0,16.5,5.725,16.5,12.5c0,6.775-7.556,12.5-16.5,12.5 c-7.839,0-14.819-4.425-16.237-10.292c-0.973-4.027-5.026-6.51-9.052-5.528c-4.026,0.973-6.501,5.025-5.528,9.052 C235.73,361.845,248.691,371,263.5,371c17.369,0,31.5-12.337,31.5-27.5C295,328.337,280.869,316,263.5,316z"/>
  <path d="M351.5,291c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5h-40c-4.142,0-7.5,3.357-7.5,7.5v80c0,4.143,3.358,7.5,7.5,7.5 h40c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5H319v-25h24.5c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5H319v-25H351.5z"/>
  <path d="M383.5,276h-8c-4.142,0-7.5,3.357-7.5,7.5v80c0,4.143,3.358,7.5,7.5,7.5h8c26.191,0,47.5-21.309,47.5-47.5 C431,297.309,409.691,276,383.5,276z M383.5,356H383v-65h0.5c17.92,0,32.5,14.579,32.5,32.5C416,341.421,401.42,356,383.5,356z" /></svg>)
