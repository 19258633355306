import React from "react"
import Modal from "../../../Modal/Modal"
import "./IllinoisModal.scss"

const IllinoisModal = ({ inIllinois }) => {
  return (
    <Modal isUnclosable>
      <div className="illinois-modal__content">
        <div className="illinois-modal__title">
          Continue ordering Aloha Poke from:
        </div>
        <div className="illinois-modal__button-container">
          <button
            className="brand-rest-location__order-button illinois-modal__button"
            onClick={inIllinois}
          >
            Illinois Locations
          </button>
          <a href="https://www.toasttab.com/locations/b1e27752-c2ce-4f29-928b-f125debc349d#!/">
            <button
              className="brand-rest-location__order-button illinois-modal__button illinois-modal__button--secondary"
              role="link"
            >
              All Other Locations
            </button>
          </a>
        </div>
      </div>
    </Modal>
  )
}

export default IllinoisModal
