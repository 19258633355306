import React, { Component } from "react"
import classnames from "classnames"
import RestContextHOC from "../../Contexts/RestContextHOC"
import CartContextHOC from "../../Contexts/CartContextHOC"
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC"
import withErrorHandling from "../../Contexts/withErrorHandling/withErrorHandling"
import "./TimeSelect.css"
import { ButtonWrapper } from "../../atoms"
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg"
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg"
import { ReactComponent as OpenIcon } from "../../assets/icons/down-chevron.svg"
import { areValuesEmpty } from "../../helpers/object"
const api = require("../../helpers/api")
const Timestamp = require("../../helpers/timestamps")
const Subdomain = require("../../helpers/subdomain")

class TimeSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hours: null,
      hoursModified: false,
      date: "",
      time: 0,
      pickupOrDelivery: this.props.delivery ? "delivery" : "pickup",
    }
    this.dateRef = React.createRef()
    this.timeRef = React.createRef()
    this.updateParent = this.updateParent.bind(this)
    this.updateParent()
    window.b = this
  }

  componentDidMount() {
    if (this.props.rest.futureHours) {
      this.modifyHours()
    }

    //if we are coming from the cart update button , not the landing page
    if (
      this.props.currentPage === "addressPage" &&
      !(
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.fromLanding
      )
    ) {
      // if this is clicked from the cart, load the saved value
      // if it exists, otherwise initialize this
      if (this.props.cart.futureOrderTime) {
        this.loadValueFromCart()
      }
    }
    //checkout page
    if (this.props.currentPage !== "addressPage") {
      if (this.props.cart.futureOrderTime && this.props.pickup_or_delivery === this.props.cart.pickup_or_delivery) {
        this.loadValueFromCart()
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //if future hours have changed, regenerate the drodown list
    if (
      JSON.stringify(prevProps.rest.futureHours) !==
      JSON.stringify(this.props.rest.futureHours)
    ) {
      this.modifyHours()
    }

    // load value from cart on a refresh
    if (!prevProps.cart.futureOrderTime && this.props.cart.futureOrderTime) {
      this.loadValueFromCart()
    }

    // if ASAP is selected going into the checkout page
    if (
      !this.props.currentPage !== "addressPage" &&
      this.props.cart.futureOrderTimeLoaded &&
      !this.props.cart.futureOrderTime &&
      this.state.hours &&
      !this.state.date
    ) {
      if(this.state.hours["open"][this.props.pickup_or_delivery]){
        this.setToASAP()
      } else{
        this.setInitialDate()
      }
    }
  }

  loadValueFromCart() {
    if (!this.props.rest.futureHours) {
      setTimeout(() => this.loadValueFromCart(), 100)
      return
    }
    // we need to set the time to futureOrderTIme but we also need to extract
    // the day from it... Let's hack it by finding which key is <= the time but not
    // more than 24 hours less than
    let timestamp = this.props.cart.futureOrderTime

    // verify the timestmap is still valid
    if (timestamp) {
      let valid = false
      for (let d in this.props.rest.futureHours[
        this.props.pickup_or_delivery
      ]) {
        for (let j in this.props.rest.futureHours[
          this.props.pickup_or_delivery
        ][d]) {
          let ts = this.props.rest.futureHours[this.props.pickup_or_delivery][
            d
          ][j]
          if (parseInt(ts) === parseInt(timestamp)) {
            valid = true
          }
        }
      }

      if (!valid) {
        //if we got here, that means that that cart.futureOrderTime
        //is a bad value
        this.props.analytics.warn(
          "BadFutureOrderTimeLoadedFromCart",
          this.props.cart.futureOrderTime
        )
        if(this.props.currentPage === 'addressPage'){
          this.props.cart.setFutureOrderTime(null)
        }
        this.setState({ time: 0 }, this.updateParent)
      }
    }
    let secondsInADay = 60 * 60 * 24
    for (let ts in this.props.rest.futureHours[this.props.pickup_or_delivery]) {
      // sepcial hack for ASAP
      if (timestamp === 0) {
        this.setState({
          date: ts,
          time: timestamp,
        }, this.updateParent)
        return
      }

      if (timestamp >= ts && timestamp - secondsInADay < ts) {
        this.setState({
          date: ts,
          time: timestamp,
        }, this.updateParent)
        return
      }
    }
  }

  updateParent(){
    if(this.props.updateParent) {
      this.props.updateParent(this.state.time)
    }
  }

  setToASAP() {
    let date = Object.keys(this.state.hours[this.props.pickup_or_delivery])[0]
    this.setState({
      date: date,
      time: 0,
    }, this.updateParent)
  }

  saveToCart(){
    this.props.cart.setFutureOrderTime(this.state.time)
  }

  setInitialDate() {
    if (
      this.props.cart.futureOrderTime && 
      this.props.pickup_or_delivery === this.props.cart.pickup_or_delivery
    ) {
      return
    }

    if (
      this.props.rest.futureHours &&
      areValuesEmpty(this.props.rest.futureHours[this.props.pickup_or_delivery])
    ){
      return
    }

    if (this.props.rest.futureHours && this.props.pickup_or_delivery) {
      if (this.props.rest.futureHours["open"][this.props.pickup_or_delivery]) {
        if (Object.keys(this.props.rest.futureHours).length) {
          this.handleChangeDate(
            Object.keys(
              this.props.rest.futureHours[this.props.pickup_or_delivery]
            )[0],
            true
          )
        }
      } else {
        let first_date = Object.keys(
          this.props.rest.futureHours[this.props.pickup_or_delivery]
        )[0]
        let date
        if (
          this.props.rest.futureHours[this.props.pickup_or_delivery][first_date]
            .length
        ) {
          date = first_date
        } else {
          //find first day with times
          for (var i in this.props.rest.futureHours[
            this.props.pickup_or_delivery
          ]) {
            if (
              this.props.rest.futureHours[this.props.pickup_or_delivery][i]
                .length
            ) {
              date = i
              break
            }
          }
        }
        this.handleChangeDate(date, true)
      }
    } else {
      window.setTimeout(() => {
        this.setInitialDate()
      }, 100)
    }
  }

  modifyHours() {
    if (this.state.hoursModified) return
    let data = JSON.parse(JSON.stringify(this.props.rest.futureHours))
    // throw "ASAP' into the first row for pickup
    let firstDate = Object.keys(data["pickup"])[0]
    if (data["open"]["pickup"] && this.props.rest.allow_asap_orders  ) {
      data["pickup"][firstDate].unshift(0)
    }

    // throw "ASAP' into the first row for delivery
    if (data["open"]["delivery"] && this.props.rest.allow_asap_orders  ) {
      data["delivery"][firstDate].unshift(0)
    }

    this.setState({ hours: data, hoursModified: true }, () => {
      if (
        this.props.currentPage === "addressPage" || 
        this.props.pickup_or_delivery !== this.props.cart.pickup_or_delivery
      ) {
        this.setInitialDate()
        //this.possiblyLoadValueFromCart()
      }
    })
  }

  // only load value from the cart if its already async we loaded.
  // We do this instead of listening on componentDidUpdate because
  // this logic is WAY simpler and cleaner, even if it uses ugly
  // set timeouts. - Scott 7/15/2019
  possiblyLoadValueFromCart() {
    if (!this.props.cart.isTimeSelected()) {
      setTimeout(() => this.possiblyLoadValueFromCart, 100)
    } else {
      this.loadValueFromCart()
    }
  }

  possiblySetInitialDate() {
    if (!this.props.cart.futureOrderTimeLoaded) {
      setTimeout(() => this.possiblySetInitialDate(), 100)
    } else {
      // this will happen if we are opening up the checker page
      // but the future order is already set .... for example if
      // they click "edit pickup info"
      if (this.props.cart.futureOrderTime) {
        this.loadValueFromCart()
      } else {
        this.setInitialDate()
      }
    }
  }

  onHoursFail(err) {
    this.props.updateError(
      true,
      "Unable to get restaurant hours. Please call the restaurant.",
      true,
      { zIndex: "1000", position: "fixed", top: "0", width: "99vw" }
    )
    this.props.analytics.error("CheckerPageHoursError")
  }

  handleChangeDate(timestamp, dontUpdateTime) {
    let time = this.state.hours[this.props.pickup_or_delivery][timestamp][0]
    if (!dontUpdateTime) {
      this.setState({ date: timestamp, time: time }, this.updateParent)
      if(this.props.currentPage === 'addressPage'){
        this.props.cart.setFutureOrderTime(time)
      }
      this.props.analytics.info("TimeSelectChangeDate", timestamp)
    } else {
      this.setState({ date: timestamp })
    }
  }

  handleChangeTime(timestamp) {
    this.props.analytics.info("TimeSelectChangeTimeNew", timestamp)

    if (timestamp === "false") {
       this.props.cart.setFutureOrderTime(null)
       this.setState({ time: null })
       return
     }

    if (timestamp === "0") {
      this.setState({ time: timestamp }, this.updateParent)
      if(this.props.currentPage === 'addressPage'){
        this.props.cart.setFutureOrderTime(null)
      }
    } else {
      this.setState({ time: timestamp }, this.updateParent)
      if(this.props.currentPage === 'addressPage'){
        this.props.cart.setFutureOrderTime(timestamp)
      }
    }
  }

  hiddenDateSelectStyle(){
    if(!this.dateRef.current) return {} 

    let style = {
      top:this.dateRef.current.offsetTop + 'px',
      left:this.dateRef.current.offsetLeft + 'px',
      height:this.dateRef.current.offsetHeight + 'px',
      width:this.dateRef.current.offsetWidth + 'px',
    }
    return style
  }


  hiddenTimeSelectStyle(){
    if(!this.timeRef.current) return {} 

    let style = {
      top:this.timeRef.current.offsetTop + 'px',
      left:this.timeRef.current.offsetLeft + 'px',
      height:this.timeRef.current.offsetHeight + 'px',
      width:this.timeRef.current.offsetWidth + 'px',
    }
    return style
  }

  renderDates() {
    if (!this.props.rest.futureHours) {
      return <option>loading...</option>
    }

    let dates = []

    let firstIteration = true
    for (let timestamp in this.props.rest.futureHours[
      this.props.pickup_or_delivery
    ]) {
      let mm = Timestamp.getMonthInt(timestamp)
      let dd = Timestamp.getDayOfMonth(timestamp)
      let dow = Timestamp.getDayOfWeekFull(timestamp)

      if (
        firstIteration &&  
        ((this.props.rest.futureHours["open"][this.props.pickup_or_delivery] && this.props.rest.allow_asap_orders)||
         (timestamp === this.props.rest.futureHours['today'] && this.props.rest.futureHours[this.props.pickup_or_delivery][timestamp] && this.props.rest.futureHours[this.props.pickup_or_delivery][timestamp].length))
      ){ 
          dates.push(
            <option
              key={timestamp}
              value={timestamp}
              className="time-select__option"
            >
              Today
            </option>
          )
      }
      
      if(timestamp !== this.props.rest.futureHours['today']){
        // don't show closed days
        if (
          this.props.rest.futureHours[this.props.pickup_or_delivery][timestamp]
            .length
        ) {
          dates.push(
            <option key={dd} value={timestamp}>
              {dow}, {mm}/{dd}
            </option>
          )
        }
      }
      firstIteration = false
    }
    return dates
  }

 
  
  
  renderDate(){
    if(!this.props.rest.futureHours) {
      this.props.analytics.warn("RenderDateNoFutureHours")
      return null
    }
    let timestamp = this.state.date
    let mm = Timestamp.getMonthInt(timestamp)
    let dd = Timestamp.getDayOfMonth(timestamp)
    let dow = Timestamp.getDayOfWeekFull(timestamp)

    let dates = Object.keys(this.props.rest.futureHours[this.props.pickup_or_delivery])
    if (dates[0] === this.state.date){
      return "Today"
    }

    return `${dow}, ${mm}/${dd}`
  }

  renderTimes() {
    //render times if this.state.hours and this.state.date
    if (this.state.hours && this.state.date) {
      let date = this.state.date
      let hours = this.state.hours[this.props.pickup_or_delivery][date]
      // if the rest is closed, put "select a time" as the default option
      // to force them to make a selection
      let times = []

      if (
        !this.state.hours["open"][this.props.pickup_or_delivery] ||
        !this.props.rest.allow_asap_orders
      ) {
        times.push(
          <option key="-1" value={false}>
            Select A Time
          </option>
        )
      }

      for (let i in hours) {
        let timestamp = hours[i]

        if (timestamp !== 0) {
          times.push(
            <option key={timestamp} value={timestamp}>
              {Timestamp.getTimeOfDay(timestamp)}
            </option>
          )
        } else {
          times.push(
            <option key={timestamp} value={timestamp}>
              ASAP
            </option>
          )
        }
      }
      return times
    } else {
      return <option key={-99}>Loading..</option>
    }
  }


  renderTime(){
    if(this.state.time && this.state.time !== 'false'){
      return Timestamp.getTimeOfDay(this.state.time)
    } else{
      let times = this.renderTimes()
      if (times.length) return times[0].props.children
    }
  }

  render() {
    if (!this.props.rest.allow_future_orders) return null
    return !this.props.rest.futureHours ? null : (
      <div
        className={classnames("timeSelectContainer-new", {
          "timeSelectContainer--checkout-new":
            this.props.currentPage === "checkout",
        }, {"timeSelectContainer--pickup":
            this.props.currentPage === "addressPage" 
            && this.props.pickup_or_delivery === 'pickup'})}
      >
        <div className='time-select__hiden-select' style={this.hiddenDateSelectStyle()}>
           <select
            value={this.state.date}
            onChange={(e) => this.handleChangeDate(e.target.value)}
          >
            {this.renderDates()}
          </select>
        </div>

        <div className='time-select__overlay' ref={this.dateRef}>
          <div className="timeSelectImg-container">
            <Calendar className="bg-responsive-svg time-select__first-icon" />
          </div>

          <div className='time-select__overlay-text'>
            {this.renderDate()} 
          </div>

          <div className="timeSelectImg-container" style={{lineHeight:'18px'}}>
            <OpenIcon className="time-select__open-icon bg-responsive-svg"  />
          </div>

        </div>

        <div className='time-select__hiden-select' style={this.hiddenTimeSelectStyle()}>
           <select
            value={this.state.time}
            onChange={(e) => this.handleChangeTime(e.target.value)}
          >
            {this.renderTimes()}
          </select>
        </div>

        <div className='time-select__overlay' ref={this.timeRef}>
          <div className="timeSelectImg-container">
            <ClockIcon className="bg-responsive-svg time-select__first-icon" />
          </div>

          <div className='time-select__overlay-text'>
            {this.renderTime()} 
          </div>

          <div className="timeSelectImg-container" style={{lineHeight:'18px'}}>
            <OpenIcon className="time-select__open-icon bg-responsive-svg"  />
          </div>

        </div>

      </div>
    )
  }
}

export default AnalyticsContextHOC(CartContextHOC(RestContextHOC(TimeSelect)))

