import React, { Component } from 'react';
import RestContextHOC from './Contexts/RestContextHOC'
import CartContextHOC from './Contexts/CartContextHOC'
import UserContextHOC from './Contexts/UserContextHOC'
import QRCode from "react-qr-code";


const Api = require("./helpers/api")

class QRSession extends Component {

  constructor(props){
    super(props)
    this.state = {
    }
  }

  componentDidMount(){
    this.startSession()
  }

  startSession(){
    Api.callApi(
      'qr-session-start',
      (data)=>{
        this.setState({uuid:data.uuid}, ()=>this.poll())
      },
      (err)=>{console.log(err)},
      {}
    )
  }

  poll(){
    console.log("polling.......")
    Api.callApi(
      'qr-session-check',
      (resp)=>{
        if(resp.success){
          this.setState({wallet:resp.wallet})
        } else{
          setTimeout(()=>this.poll(), 100)
        }
      },
      (err)=>{
        setTimeout(()=>this.poll(), 100)
        console.log("error checking for qr session")
      },
      {uuid:this.state.uuid}
    )
  }
  
  render() {
    if(!this.state.uuid) {
      return (
        <div>Loading...</div>  
      )
    } 

    let url = "https://hngrapi.ngrok.io/api/qr-session-solana-pay?uuid="+this.state.uuid
    url = 'solana:' + encodeURIComponent(url)

    if(this.state.wallet){
      return (
        <div style={{padding:'50px', fontSize:'40px'}} >
          {this.state.wallet} 
        </div>
      )
    }

    return (
      <div style={{padding:'50px'}}>
        <QRCode value={url} />
      </div>
    )
  }
}


export default QRSession
