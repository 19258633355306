import React from "react";

const MenuCategory = ComposedComponent => {
  class MenuCategory extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        width: window.innerWidth
      };
    }

    loadCategory(name) {
      // this.props.history.push({
      //   pathname: `/menu/${this.props.rest_id}/${this.props.menu_id}/category`,
      //   state: {
      //     category: this.props.category,
      //     menu: this.props.menu
      //   }
      // });
    }

    render() {
      const { width } = this.state;
      const isMobile = width <= 1000;

      if (isMobile) {
        //MOBILE
        return (
          <div style={styles.scrolling}>
            <span onClick={() => this.loadCategory(this.props.category.name)}>
              <ComposedComponent {...this.props} />
            </span>
          </div>
        );
      } else {
        //DESKTOP
        return (
          <div>
            <span onClick={() => this.loadCategory(this.props.category.name)}>
              <ComposedComponent {...this.props} />
            </span>
          </div>
        );
      }
    }
  }

  return MenuCategory;
};

var styles = {
  scrolling: {
    display: "inline-block"
    // whiteSpace: 'nowrap',
  }
};
export default MenuCategory;
