import React from "react"
const Timestamp = require("../../helpers/timestamps")

const verifyTime = (time) => {
  //should return false for ASAP case
  const t = typeof time === "string" || typeof time === "number"
  //double bang to turn truthy values to true
  const bool = !!time
  const val = parseInt(time) > 0
  return t && bool && val
}

const buttonText = (menu, allow_future_orders) => {
  // default old  behavior, dont want to change til thoroughly tested
  if(!menu || !menu.menu_type){
    return '' 
  }

  if(menu.menu_type === 'PICKUP_OR_DELIVERY'){
    return 'Choose delivery or pickup...'
  } else if(menu.menu_type === 'PICKUP'){
      if(allow_future_orders){
        return 'Update pickup time...'
      } else{
        return 'Choose pickup...' 
      }
  } else if (menu.menu_type === 'DELIVERY'){
      return  'Update delivery address...'
  // else clause is just a fail safe in case i messed up this logic
   } else{
    return  'Choose delivery or pickup...'
  }

}

const formatOrderDate = (dt) => {
  let dow = Timestamp.getDayOfWeek(dt)
  let mm = Timestamp.getMonthInt(dt)
  let dd = Timestamp.getDayOfMonth(dt)
  let time = Timestamp.getTimeOfDay(dt)
  return `${dow}, ${mm}/${dd} @ ${time}`
}

function renderTime(props){
  if(!props.rest.allow_asap_orders && !props.cart.futureOrderTime){
    return null
  } else {
     if (verifyTime(props.cart.futureOrderTime)){
      return formatOrderDate(props.cart.futureOrderTime)
     } else{
      return 'As soon as possible'
     }
  }
}

const CheckerButton = (props) => {
  if (props.isLocked) return null
  if (window.KIOSK_MODE) return null

  if (props.cart.pickup_or_delivery) {
    return (
      <div
        className="CartCheckerButton"
        style={{ background: props.hex_color_primary }}
        onClick={() => props.cart.editCheckerPage()}
      >
        <img
          className="CartCheckerIcon"
          src={
            props.cart.pickup_or_delivery === "delivery"
              ? "https://imgcdn.hngr.co/delivery.png?size=100x100"
              : "https://imgcdn.hngr.co/pickup-new.png?size=100x100"
          }
          alt="checker"
        />
        <div className="CartCheckerText">
          <strong style={{ fontWeight: 900 }}>
            {props.cart.items.length}{" "}
            {props.cart.items.length === 1 ? "item" : "items"} for{" "}
            {props.cart.pickup_or_delivery}
          </strong>
          <br />
          {renderTime(props)}
        </div>
      </div>
    )
  } else {
      return (
        <div
          className="CartCheckerButton"
          style={{ background: props.hex_color_primary }}
          onClick={() => props.cart.editCheckerPage()}
        >
          <img className="CartCheckerIcon" src="/arrow_right.png" alt="checker" />
          <div className="CartCheckerText">{buttonText(props.rest.menu, props.rest.allow_future_orders)}</div>
        </div>
      ) 
    }
}
export default CheckerButton
