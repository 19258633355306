import React from "react"
import MenuItemModifierClass from "../MenuItemModifierClass"
import MenuItemQuantity from "../MenuItemQuantity"
import CartContextHOC from "../../Contexts/CartContextHOC"
import RestContextHOC from "../../Contexts/RestContextHOC"
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC"
import withErrorHandling from "../../Contexts/withErrorHandling/withErrorHandling"
import classnames from "classnames"
import { v4 as uuidv4 } from 'uuid';

import styles from "./MenuItemExpanded.module.scss"
import "./MenuItemExpanded.css"
import { withRouter, Redirect } from "react-router-dom"
import InputComponent from "../../sparkles/InputComponent/InputComponent"
import "../../css/MenuItemModal.css"
import {Text} from '../../atoms'
import {withResizeDetector} from 'react-resize-detector/build/withPolyfill';

const formatPrice = require("../../helpers/formatPrice")
const { detect } = require("detect-browser")
const browser = detect()

const CDN = "https://cdn.hngr.co"
const IMGCDN = "https://imgcdn.hngr.co"

class MenuItemExpanded extends React.Component {
  constructor(props) {
    super(props)
    this.initItem(this.props.editItem)
    this.invalidateCoupon = this.invalidateCoupon.bind(this)
    this.revalidateCoupon = this.revalidateCoupon.bind(this)
    if (browser) this.state["browser"] = browser.name
    this.lock = false
    this.onRefChange = this.onRefChange.bind(this)
    window.m = this
    this.state.width = null 
    this.state.stringModifierFudger = 0
    this.state.isMobile = this.props.isMobile
    this.state.imageError = false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      //this.props.updateError(false)
    }

    if (this.state.width !== this.props.width) {
      this.setState({width:this.props.width})
    }

    if(this.state.isMobile !== this.props.isMobile){
      this.setState({isMobile:this.props.isMobile})
    }

  }

  componentDidMount() {
   window.addEventListener("resize", this.handleResize.bind(this))
   document.body.style.zoom = "100%";
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize(){
    this.forceUpdate()
  }

  onRefChange(node){
    this.setState({containerRef:node})
  }

  /**
   * set the default state. This is called from the constructor and after an item has been
   * added to the cart and we want to reset the modifiers to their default state. When
   * this is called from the constructor, don't do any setStates or else react
   * will throw a warning

   if we are updating an item, we want to populate this from the existing item
   */
  initItem(editItem) {
    if (!this.props.rest.menu) {
      //remove everything after /category from the url.... note this will probably break in the future
      // eslint-disable-next-line
      this.state = { redirect: true }
      return
    }

    var item =
      this.props.rest.menu &&
      this.props.rest.menu.menu_items[this.props.item.menu_item_id]
   
    this.state = {
      cart_modifier_classes: {},
      modifier_class_by_id: {},
      /**
       * this is a lookup table for id->modifier_class
       * we use this so that when we recursively verify all
       * the modifiers, we can take a modifier_class_id
       * and then lookup the modifier_class info
       * everytime we add a new modifier (or submodifier),
       * we keep track of all the modifier
       * classes for it by updating this state
       */

      modifier_classes: item.modifier_classes,
      special_instructions: null,
      item: item,
      quantity: 1,
      price: 0,
      loaded: true,
      isCoupon: this.props.location.state.isCoupon,
    }

    if(editItem){
      this.state['special_instructions'] = editItem.special_instructions
      this.state['quantity'] = editItem.cart_item_ids.length
    }

    // if we have a modifier class id, then isCoupon should always be false
    // eslint-disable-next-line
    this.state.cart_modifier_classes = this.initializeCartModifierClasses(item)
    for (var i in this.state.modifier_classes) {
      // eslint-disable-next-line
      this.state.modifier_classes[i][
        "parent_modifier_classes_cart"
      ] = this.state.cart_modifier_classes
      // eslint-disable-next-line
      this.state.modifier_classes[i]["uuid"] = uuidv4()
    }
    // this.setState(this.state);
  }

  /**
   * When a modifier is selected, we need to see if that modifier has any sub-modifiers
   * that need to be displayed.
   */
  displaySubModifiers(
    modifier,
    parent_modifier_class,
    parent_cart_modifier_classes,
    editModifier
  ) {
    //modifiers of type string cant have submodifiers, so we check to make sure the selected modifier
    //is an item before attepting to display its submodifiers
    if (modifier.item) {
      var modifier_classes = JSON.parse(
        JSON.stringify(modifier.item.modifier_classes)
      )
      for (var i in modifier_classes) {
        // keep track of the parent uuiid, so that if this modifier is un-selected than we can hide this subdmodifier
        modifier_classes[i]["parent_uuid"] = modifier.uuid
        if (editModifier){
          modifier_classes[i]['editModifierParent'] = JSON.parse(JSON.stringify(editModifier))
        }

        // to set the correcty parent_modifier_classes_cart, we need to look at the parent_modifier_classes
        // and find the modifier that is the parent of the just selected one. To do that, we look at the parent_modifier_classes dict
        // and find the right modifier class by id, and then take the last modifier in that list
        var parent_modifier_list =
          parent_cart_modifier_classes[parent_modifier_class.modifier_class_id][
            "modifiers"
          ]
        modifier_classes[i]["parent_modifier_classes_cart"] =
          parent_modifier_list[parent_modifier_list.length - 1][
            "cart_modifier_classes"
          ]
      }

      // to insert the modifier into the flat list, we need to know the sub modifiers of the just selected modifier
      // as well as the id of the parent modifier class (so that we can find the right spot in the flat list to
      // insert the sub modifiers)
      this.insertModifierClasses(parent_modifier_class, modifier_classes)
    } else{
      // we want to update a dummy state variable here so that the isVAlid
      // logic runs when selecting a string modifier
      this.setState({stringModifierFudger:this.statestringModifierFudger+1})
    }
  }

  addQuantity() {
    this.props.analytics.info(
      "MenuItemQuantityIncrease",
      this.state.quantity + 1
    )
    this.setState({
      quantity: this.state.quantity + 1,
    })
  }

  subtractQuantity() {
    this.props.analytics.info(
      "MenuItemQuantityDecrease",
      this.state.quantity - 1
    )
    this.setState({
      quantity: Math.max(this.state.quantity - 1, 1),
    })
  }

  resetQuantity() {
    this.setState({ quantity: 1 })
  }

  // when setting modeifier[cart_modifier_classes] on a new modifier, we want to create stubs
  // of each possible modifier_class_id so that when we verify an items modifiers, we know all the possible
  // modifier_classes, instead of only having a modifier_class_id exist in cart_modifier_classes after a modifier
  // is added
  initializeCartModifierClasses(item) {
    if (item && item.modifier_classes) {
      var modifier_classes = item.modifier_classes
      var cart_modifier_classes = {}
      for (var i in modifier_classes) {
        var modifier_class_by_id = this.state.modifier_class_by_id
        modifier_class_by_id[modifier_classes[i].modifier_class_id] =
          modifier_classes[i]
        // we don't call setState because it generates a warning about setting state on a component that isnt mounted?
        // this.setState({modifier_class_by_id:modifier_class_by_id})
        // eslint-disable-next-line
        this.state[modifier_class_by_id] = modifier_class_by_id
        cart_modifier_classes[modifier_classes[i].modifier_class_id] = {
          modifiers: [],
          modifier_class_id: modifier_classes[i].modifier_class_id,
          row_order: modifier_classes[i].row_order,
        }
      }
      return cart_modifier_classes
    } else {
      return {}
    }
  }

  // use this so that we can assign an ID to the specific item
  // inside the cart
  getRandomId() {
    return Math.floor(Math.random() * Math.floor(99999))
  }

  verifyMinMaxModifiers(mc, num_modifiers) {
    var msg = ""
    if (mc.minimum_num_selections !== null) {
      if (num_modifiers < mc.minimum_num_selections) {
        // msg =
        //   `${mc.display_text} needs at least ${
        //     mc.minimum_num_selections
        //   } modifiers selected, you have` + ` only selected ${num_modifiers}`;
        msg = "Oops! Looks like you forgot to fill out some options."
        this.props.analytics.info("MenuItemFailToAddToCart", msg)
        let customStyles = { width: "90%" }
        if (window.innerWidth <= 1000) {
          customStyles = {
            ...customStyles,
          }
        }
        //this.props.updateError(true, msg, null, customStyles, false)
        return false
      }
    }
    //check the max
    if (mc.maximum_num_selections !== null) {
      if (num_modifiers > mc.maximum_num_selections) {
        msg = `Oops! You have too many options selected. The maximum amount is ${mc.maximum_num_selections}`
        //this.props.updateError(true, msg)
        this.props.analytics.info("MenuItemFailToAddToCart", msg)
        return false
      }
    }

    return true
  }

  //TODO: how do I get the info on a modifier_class from just its ID?
  getModifierClassFromId(modifier_class_id) {
    return this.state.modifier_class_by_id[modifier_class_id]
  }

  // modifier_classes is a dictionary where the keys are all the
  // modifier_class_ids for that item. Each key value is a list of all the modifiers
  // that have been added for that modifier class.
  // and the values are lists of modifiers

  // store the is_valid flag in this.valid_modifiers instead of using the return value
  // because Scott sucks at recursion
  hasValidModifiers(modifier_classes) {
    for (var modifier_class_id in modifier_classes) {
      var modifiers = modifier_classes[modifier_class_id]["modifiers"]
      //now we have the list of modifiers, we also need the modifier_class
      var modifier_class = this.getModifierClassFromId(modifier_class_id)
      //now that we have the list of modifiers and the modifierClass, we can go ahead
      //and check min_num_modifiers and max_num_modifiers
      if (!this.verifyMinMaxModifiers(modifier_class, modifiers.length)) {
        this.valid_modifiers = false && this.valid_modifiers
        return false
      }

      for (var i in modifiers) {
        var modifier = modifiers[i]
        this.hasValidModifiers(modifier.cart_modifier_classes)
      }
    }
    return true
  }

  isValid(){
    this.valid_modifiers = true
    this.hasValidModifiers(this.state.cart_modifier_classes)
    return this.valid_modifiers
  }

  addToCart() {
    this.props.analytics.info("AddToCart", this.props.item.name)
    this.valid_modifiers = true
    // hasValidMofiiers sets this.valid_modifiers.... bad code =|
    this.hasValidModifiers(this.state.cart_modifier_classes)
    if (!this.valid_modifiers) {
      document.getElementsByClassName("Modal")[0].scrollTop = 0
      return false
    }

    var item = {
      item: this.props.item,
      cart_modifier_classes: JSON.parse(
        JSON.stringify(this.state.cart_modifier_classes)
      ),
      cart_item_id: this.getRandomId(),
      price: this.calculateTotalPrice(false),
      tax: JSON.parse(JSON.stringify(this.totalTax)),
      special_instructions: this.state.special_instructions,
      menu_uuid:this.props.rest.menu.menu_id,
      added_tax_support:true
    }
   
    //if we are editing from the cart, we need to remove the old cart_item_ids before addding
    if(this.props.editItem){
      this.props.analytics.info("UpdateItem", this.props.item.name)
      this.props.cart.editItem(item, this.props.editItem, this.state.quantity)
    } else{
      this.props.cart.addToCart(item, this.state.quantity)
    }
    this.initItem() //re-init the item so if its added again it gets a fresh modifier state

    // if (!this.props.cart.isCheckerPageComplete()){
    //   this.props.cart.editCheckerPage()
    // } else
    this.props.CloseModal()
  }

  //note that right now we use the global this.total instead of the total in the argument....
  calculatePrice(node, total) {
    for (var i in node) {
      if (node[i]["item"]) {
        var modifier_class = this.getModifierClassFromId(
          node[i].modifier_class_id
        )
        let modifier_price
        if (modifier_class.half_price_override && node[i].half_selection) {
          node[i]["item"]["receipt_price"] = modifier_class.half_price_override
          node[i]['receipt_price'] = modifier_class.half_price_override
          modifier_price = modifier_class.half_price_override
          this.total += modifier_class.half_price_override
        } else if (
          modifier_class.half_price_override_dollars &&
          node[i].half_selection
        ) {
          //.001 to deal with 1.015*100 going to 101.49999999999
          node[i]["item"]["receipt_price"] =
            modifier_class.half_price_override_dollars * 100 + 0.001
          node[i]['receipt_price'] = node[i]["item"]["receipt_price"]
          modifier_price = Math.round(modifier_class.half_price_override_dollars * 100 + 0.001)
          this.total += modifier_class.half_price_override_dollars * 100 + 0.001
        } else if (node[i].half_selection && node[i].item.half_price) {
          node[i]["item"]["receipt_price"] = node[i]["item"]["half_price"]
          node[i]['receipt_price'] = node[i]["item"]["receipt_price"]
          modifier_price =  node[i]["item"]["half_price"]
          this.total += node[i]["item"]["half_price"]
        } else if (modifier_class.price_override !== null) {
          node[i]["item"]["receipt_price"] = modifier_class.price_override
          node[i]['receipt_price'] = node[i]["item"]["receipt_price"]
          modifier_price =  modifier_class.price_override
          this.total += modifier_class.price_override
        } else {
          node[i]["item"]["receipt_price"] = node[i]["item"]["price"]
          node[i]['receipt_price'] = node[i]["item"]["receipt_price"]
          modifier_price = node[i]["item"]["price"]
          this.total += node[i]["item"]["price"]
        }
        if(node[i]["item"]["is_taxable"] !== false){
          node[i]["item"]["tax"] = modifier_price * this.props.cart.taxRate
          node[i]['tax'] = node[i]["item"]["tax"]
          this.totalTax += modifier_price * this.props.cart.taxRate
          if(node[i]["item"].added_tax_percent){
            let additionalTax = modifier_price * node[i]["item"].added_tax_percent/100000.0 
            node[i]["added_tax_support"] = true
            this.totalTax += additionalTax
          }
        } else {
          node[i]["item"]["tax"] = 0 
        }
      }

      // designed for single modifier price overrides
      if (node[i].price_override) {
        this.total += node[i].price_override
        node[i]['receipt_price'] = node[i].price_override
        this.totalTax +=  node[i].price_override * this.props.cart.taxRate
      }

      if (node[i]["cart_modifier_classes"]) {
        this.calculatePrice(node[i]["cart_modifier_classes"], total)
      } else {
        this.calculatePrice(node[i]["modifiers"], total)
      }
    }
    return
  }

  // given the quantity and the modifiers, calculate the total price
  // of the item
  calculateTotalPrice(format) {
    this.formatDisplayPrice(format)
    this.total = this.props.item.price
    if (this.props.item.is_taxable !== false){
      this.totalTax = this.props.item.price * this.props.cart.taxRate 
      if (this.props.item.added_tax_percent) {
        let addedTax = this.props.item.price * this.props.item.added_tax_percent/100000.0
        this.totalTax += addedTax
      }
    } else{
      this.totalTax = 0
    }

    this.calculatePrice(this.state.cart_modifier_classes, 0)
    var total_price = this.total
    if (format) {
      return formatPrice.formatPrice(total_price)
    } else {
      return total_price
    }
  }

  // because we are listing items separately in the cart, this function works around to
  // display a price reflecting the quantity strictly inside the menu item
  formatDisplayPrice(format) {
    this.total = this.props.item.price
    this.calculatePrice(this.state.cart_modifier_classes, 0)
    var display_price = this.total * this.state.quantity
    if (format) {
      return formatPrice.formatPrice(display_price)
    } else {
      return display_price
    }
  }



  // insert child_modifier after parent modifier. Assume parent_modifier is already
  // in this.state.modifiers, so look it up, then insert child modfieirs after
  insertModifierClasses(
    parent_modifier_class,
    child_modifier_classes,
    callback = () => {}
  ) {
    
    //if there is a lock, don't even attempt to run this function
    if(this.lock){
      setTimeout(()=>{
        this.insertModifierClasses(parent_modifier_class, child_modifier_classes, callback)},
        100)
      return
    }
    this.lock = true


    // set the uuid of the child_modifier_classes. TODO: this needs to be abstracted somewhere
    // else since we duplicate this logic in the constructor
    for (let i in child_modifier_classes) {
      child_modifier_classes[i]["uuid"] = uuidv4()
    }

    var modifier_classes = this.state.modifier_classes
    var index = -1
    for (var i in modifier_classes) {
      if (modifier_classes[i].uuid === parent_modifier_class.uuid) {
        index = i
        break
      }
    }
    //we need to insert the child_modifier_classes after the parent_modifier_class, so loop through the list
    //and create an identical one, esxcept with the new child ones inserted directly after the parent
    var new_list = []
    for (var z in modifier_classes) {
      if (z < index) {
        new_list.push(modifier_classes[z])
      }
      // we found the parent modifier, so let's add all the child ones directly
      // after the parent
      else if (z === index) {
        new_list.push(modifier_classes[z])
        for (var j in child_modifier_classes) {
          new_list.push(child_modifier_classes[j])
        }
      } else if (z > index) {
        new_list.push(modifier_classes[z])
      }
    }
    this.setState({ modifier_classes: new_list },()=>this.lock=false)
  }

  // recursive function to remove displayed modifiers when something gets unchecked.
  // this takes a menu_item_id and deletes any modifier class that it is a parent of,
  // and does so recrusively. Note that this should really take a unique id
  // in case the rare event where there are two of the same menu items in the same
  // MenuItemExpanded

  // dont updateState is set when we we know insert modifier classes will be called after 
  // this. This avoids a jump in the screen
  removeModifierClasses(modifier, recursiveCall, dontUpdateState) {
    if(this.lock && !recursiveCall){
      setTimeout(()=>this.removeModifierClasses(modifier, false, dontUpdateState), 100) 
      return
    }
    this.lock = true
    //add all modifiers to the list that
    var i = this.state.modifier_classes.length
    while (i--) {
      var mc = this.state.modifier_classes[i]
      // if we remove a modifier class, make sure we recursively remove all of its children
      // as well
      if (mc["parent_uuid"] && mc["parent_uuid"] === modifier.uuid) {
        this.state.modifier_classes.splice(i, 1)
        for (var j in mc["modifiers"]) {
          var m = mc["modifiers"][j]
          if (m["item"]) {
            this.removeModifierClasses(m, true, dontUpdateState)
          }
        }
      }
    }
    // we only want to set the lock to false one time when this is called.
    if(!recursiveCall){
      if(!dontUpdateState){
        this.setState({ modifier_classes: this.state.modifier_classes }, 
          ()=>this.lock=false)
      } else{
        this.lock = false
      }
    }
  }

  invalidateCoupon() {
    this.setState({
      isCoupon: false,
    })
  }

  revalidateCoupon() {
    this.setState({
      isCoupon: true,
    })
  }

  imageSrc(){
    if(this.state.imageError) return
    if(!this.state.containerRef) return
    if(!this.props.item.image_url.includes(CDN)) {
      return this.props.item.image_url
    }
    let width = Math.ceil(this.state.containerRef.offsetWidth)
    let height = Math.ceil(width * 9/16)
    let cdn_url = this.props.item.image_url.replace(CDN, IMGCDN)
    cdn_url += `?size=${width}x${height}`
    return cdn_url
  }

  imageStyle(){
    if(!this.state.width) return
    
    let width = this.state.width
    let height = width * 9/16
    return {
      width:'100%',
      height:height+'px',
    }
  }


  menuItemContentStyle(){
    if(this.state.isMobile){
      let height = window.innerHeight - 124
      return {
        minHeight:height+'px'
      }
    }
  }

  showImage(){
    return this.props.item.image_url && !this.state.imageError
  }

  render() {
    let url = window.location.pathname
    if (this.state.redirect) {
      return <Redirect to={url.substr(0, url.indexOf("/category"))} />
    }
    var that = this
    var addToCart = {
      backgroundColor: this.props.rest.secondaryColor(),
    }
    var modifier_classes = this.state.modifier_classes
    let top_level_modifier_classes = []

    let is_valid = this.isValid()
    
    return (
      <div
        onClick={(e) => this.props.stopPropogation(e)}
        key={this.state.item.menu_item_id}
        style={{
          "--rest-primary": this.props.rest.hex_color_primary,
          "--rest-secondary": this.props.rest.hex_color_secondary,
        }}
      >
        <div
          className={classnames({"menuItemContent":!this.state.isMobile},
            {'menuItemContent-mobile':this.state.isMobile})}
          style={this.menuItemContentStyle()}
          ref={this.onRefChange}
        >
          {this.showImage() && (
            <div className='menu-item-image'>
              <div style={{display:'none'}}>{this.state.width}</div>
              <img
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src='https://cdn.hngr.co/placeholder.png'
                  this.setState({imageError:true})
                }}
                src={this.imageSrc()}
                style={this.imageStyle()}
              />
            </div>
          )}
          <div className={classnames(
            {'menu-item-expanded-container-image':this.showImage()},
            {'menu-item-expanded-container-no-image':!this.showImage()})}
          >
            <div 
              className='menu-item-title'
            >
              <Text 
                size='l' 
                weight='extrabold'
              >
                {this.props.item.name}
                {/* for freeza, which has couponModifierClassId , just always ignore this */}
                {this.state.isCoupon && !this.props.couponModifierClassId ? (
                  <div
                    style={{
                      fontSize: "14pt",
                      display: "inline-block",
                      color: "green",
                    }}
                  >
                    &nbsp;(Using Coupon)
                  </div>
                ) : null}
              </Text>
            </div>
            <div className='mb-4'>
              {this.props.item.description && (
                <div 
                  className={styles.description}
                  dangerouslySetInnerHTML={{__html:this.props.item.description}}
                >
                </div>
              )}
            </div>

            {modifier_classes.map((modifier_class, i) => {
              // if this is an edit, grab the modifiers for this modifier class
              let editModifiers = null
              let topLevelEditClass = true
              // okay so modifier_classes is a flat list. of the modifier classes to display.
              // the problem is that cart.modifier_classes is the tree. SO what is the solution here?
              // we need to find the spot in the three to grab the editModifiers from. OKAY. How the hell
              // do we do that? look at the else if

              let previous_top_level_modifier_class_id = null
              if(that.props.editItem){
                if(that.props.editItem.cart_modifier_classes[modifier_class.modifier_class_id]){
                  editModifiers = that.props.editItem.cart_modifier_classes[modifier_class.modifier_class_id].modifiers
                  if (top_level_modifier_classes.length){
                    previous_top_level_modifier_class_id =  top_level_modifier_classes[ top_level_modifier_classes.length - 1]
                  }
                  top_level_modifier_classes.push(modifier_class.modifier_class_id)
                } else if  (modifier_class.editModifierParent) {
                  editModifiers = modifier_class.editModifierParent.cart_modifier_classes[modifier_class.modifier_class_id].modifiers
                  topLevelEditClass = false
                }
              }
              return (
                <MenuItemModifierClass
                  initializeCartModifierClasses={this.initializeCartModifierClasses.bind(
                    this
                  )}
                  insertModifierClasses={this.insertModifierClasses.bind(this)}
                  parentComponent={this}
                  topLevelEditClass={topLevelEditClass}
                  previous_top_level_modifier_class_id={previous_top_level_modifier_class_id}
                  removeModifierClasses={this.removeModifierClasses.bind(this)}
                  parentModifierClassesCart={
                    modifier_class.parent_modifier_classes_cart
                  }
                  key={modifier_class.uuid}
                  index={i+1}
                  item={this.props.item}
                  editItem={this.props.editItem}
                  editModifiers={editModifiers}
                  modifierClass={modifier_class}
                  displaySubModifiers={this.displaySubModifiers.bind(this)}
                  couponModifierId={this.props.couponModifierId}
                  couponModifierClassId={this.props.couponModifierClassId}
                  invalidateCoupon={this.invalidateCoupon}
                  revalidateCoupon={this.revalidateCoupon}
                  topLevelModifierClasses={this.state.modifier_classes}
                  {...modifier_class}
                />
              )
            })}

            {this.props.rest.allowSpecialInstructions && (
              <div className='mt-8'>
                <Text weight='heavy'>SPECIAL INSTRUCTIONS</Text>
                <InputComponent
                  onChange={(e) =>
                    this.setState({ special_instructions: e.target.value })
                  }
                  
                  placeholder="e.g. allergies, spice level, requests*"
                  value={this.state.special_instructions}
                /> 
                <p className={styles.upcharge}>*Requests may result in upcharges</p>
              </div>
            )}
          </div>

        </div>

        <div
          className={
            this.state.browser !== "edge"
              ? "menuItemAddToCart"
              : "menuItemAddToCartEdge"
          }
        >
          <div className='menu-item-footer'>
            <MenuItemQuantity
              add={this.addQuantity.bind(this)}
              subtract={this.subtractQuantity.bind(this)}
              quantity={this.state.quantity}
            />

            <div
              className={classnames('menu-item-add-to-cart', 
                {'add-to-cart-enabled':is_valid},
                {'add-to-cart-disabled':!is_valid})}
              onClick={() => that.addToCart()}
            >
              <div>
                {this.props.editItem ? <span>UPDATE</span> : <span>ADD TO CART</span>}
                &nbsp;-&nbsp;
                {this.formatDisplayPrice(true)}  
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default withResizeDetector(withRouter( AnalyticsContextHOC(
    RestContextHOC(CartContextHOC(withErrorHandling(MenuItemExpanded))))))
