import React from 'react';
import HalfToppingModifier from './HalfToppingModifier';
import AnalyticsContextHOC from '../Contexts/AnalyticsContextHOC';
import withErrorHandling from "../Contexts/withErrorHandling/withErrorHandling";

class SelectionFormHalfToppings extends React.Component{

  constructor(props) {
    super(props)
    this.addModifier = this.addModifier.bind(this)
    this.removeModifier = this.removeModifier.bind(this)
  }

  addModifier(modifier, half_selection){
    this.props.analytics.info(`HalfSelectionChecked ${half_selection}`, modifier.name)
    // before you add a topping, remove any previous selections of this topping.
    // So if you previously selected "half pepperoni" then you select
    // "full pepperoni", we want to remove "half pepperoni" otherwise we will
    // end up with "full pepperoni" and "half pepperoni" in the cart
    this.removeModifier(modifier)
    modifier.half_selection = half_selection
    modifier['cart_modifier_classes'] = this.props.initializeCartModifierClasses(modifier.item)

    //before we add it, validate that we're not over the max selections
    var max_selections = this.props.modifierClass.maximum_num_selections
    var current_selections = this.props.parentModifierClassesCart[modifier.modifier_class_id]['modifiers'].length
    if(max_selections && current_selections + 1 > max_selections){
      this.props.analytics.info('HalfSelectionOverLimit')
      this.props.updateError(
        true,
        `You can only choose ${max_selections} option${max_selections > 1 ? 's' : ''}`
      );
      return false
    }

    this.props.parentModifierClassesCart[modifier.modifier_class_id]['modifiers'].push(modifier)
    this.props.displaySubModifiers(modifier, this.props.modifierClass, this.props.parentModifierClassesCart)
    return true
  }

  removeModifier(modifier, half_selection){
    this.props.updateError(false)
    this.props.parentModifierClassesCart[modifier.modifier_class_id]['modifiers'].forEach(function(item, index, object){
      if(item.modifier_id === modifier.modifier_id){
        object.splice(index, 1)
      }
    })
    this.props.removeModifierClasses(modifier)
  }

  render() {
    return (
      <div>
        {this.props.modifiers.map(modifier => (
          <HalfToppingModifier
            modifier={modifier}
            modifierClass={this.props.modifierClass}
            key={modifier.modifier_id}
            modifierId={modifier.modifier_id}
            addModifier={this.addModifier}
            removeModifier={this.removeModifier}
            editModifiers={this.props.editModifiers}
          />
        ))}
      </div>
  );
  }


};

export default withErrorHandling(AnalyticsContextHOC(SelectionFormHalfToppings));
