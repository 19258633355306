import React, { Component } from "react"

import EntryPointHOC from "../Contexts/EntryPointHOC"
import RestContextHOC from "../Contexts/RestContextHOC"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import CartContextHOC from "../Contexts/CartContextHOC"
import UserContextHOC from "../Contexts/UserContextHOC"
import SignupModalContextHOC from "../Contexts/SignupModalContextHOC"
import * as EmailValidator from 'email-validator';
import Modal from "../Modal/Modal"
import "../css/LoginSignup.css"
import "../css/AnonPasswordReset.css"

const Subdomain = require("../helpers/subdomain")
const Api = require("../helpers/api.js")

class AnonymousPasswordReset extends Component {
  constructor(props) {
    super(props)

    // capture any sendTo url params
    const params = new URLSearchParams(window.location.search)
    const reset_id = params.get('reset_id')
    const code = params.get('code')
    const email = params.get('email')
    let codeOK = false
    if (code) { codeOK = true; } 

    this.state = {
      done: false,
      error: false,
      loading: false,
      email: null,
      reset_id: reset_id,
      password: null,
      password2: null,
      errorMessage: '',
      code: code,
      codeOK: codeOK
    }
    this.handleEsc = this.handleEsc.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleEmailSubmission = this.handleEmailSubmission.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.emailAddressSubmitted = this.emailAddressSubmitted.bind(this)
    this.completeAndLogin = this.completeAndLogin.bind(this)
    this.handleCompleteEvent = this.handleCompleteEvent.bind(this)
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEsc, false)
    this.props.analytics.info("AnonymousPasswordResetPage")
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEsc, false)
  }

  validateEmail(email) {
    return EmailValidator.validate(email)
  }

  handleClickOutside() {
    this.handleClose()
  }

  handleCodeSubmission() {  
    let code = this.state.code
    this.props.analytics.info('AnonymousPasswordSendingCode', code)
    if (code.length !== 6) {
      let msg = 'Invalid code. Must be 6 digits'
      this.setState({error: msg})
      return
    }
    let payload = {email: this.state.email,
      code: this.state.code,
      reset_id: this.state.reset_id,
      subdomain: Subdomain.getSubdomain()}
    Api.callApi('anon_password_code_verify',
      (resp) => { 
        if (resp.success === 1) { this.setState({error: null, codeOK: true}) } 
        if (resp.success === 0) { this.setState({error: resp.message}) }
      },
      (resp) => {
        let err = 'Unknown Error'
        if (resp.message) { err = resp.message }
        this.setState({error: err})},
      payload
    )
  }

  handleCompleteEvent() {
    this.props.analytics.info('AnonymousPasswordResetSuccess')
    const params = new URLSearchParams(window.location.search)
    let pathname = '/menu'
    const userPhone = this.props.user.user.phone_number_e164
    if (params.get('sendTo') === 'checkout' && !userPhone) {
      pathname = '/menu/phone_verify?sendTo=checkout'
    } else if (params.get('sendTo') === 'checkout') {
      pathname = '/checkout'
    }
    this.props.history.replace({pathname: pathname})

  }

  completeAndLogin(resp) {
    if (resp.success !== 1) {
      if (resp.message) { this.setState({error: resp.message}) }
      return
    }

    if (resp.success === 1) {
      this.props.user.loginUser(resp.userdata, resp.hngr_token)
      this.props.cart.loginUser(resp.userdata)
      this.setState({done: true, error: false})
    }
  }

  handleEmailSubmission() {
    this.props.analytics.info('AnonymousPasswordResetSubmitButton')
    if (!this.validateEmail(this.state.email)) {
      let msg = "Invalid email address"
      this.setState({error: msg})
      return
    }

    let payload = {email: this.state.email,
      subdomain: Subdomain.getSubdomain()}

    // send the API request, success means close modal, set user tokens
    // and move back to original page
    Api.callApi('anon_password_reset',
      this.emailAddressSubmitted,
      (resp) => {
        let err = 'Unknown Error'
        if (resp.message) { err = resp.message }
        this.setState({error: err})},
      payload
    )
  }

  handlePasswordChange() {
    this.props.analytics.info('AnonymousPasswordResetSendingPasswords')
    if (this.state.password !== this.state.password2) {
      this.setState({error: "Passwords don't match"})
      return
    }
    if (!this.state.password || this.state.password.length < 4) {
      this.setState({error: "Password too short. Must be at least 4 characters"})
      return
    }
    let payload = {email: this.state.email,
      code: this.state.code,
      password: this.state.password,
      reset_id: this.state.reset_id,
      subdomain: Subdomain.getSubdomain()}
    Api.callApi('anon_password_set_password',
      this.completeAndLogin,
      (resp) => {
        let err = 'Unknown Error'
        if (resp.message) { err = resp.message }
        this.setState({error: err})},
      payload
    )


  }

  emailAddressSubmitted(resp) {
    if (resp.success == 0) {
      let err = 'Unknown Error'
      if (resp.message) { err = resp.message }
      this.setState({error: err})
      return
    }
    this.setState({reset_id: resp.reset_id})

  }

  handleEsc(e) {
    if (e.keyCode === 27) {
      e.preventDefault()
      this.handleClose()
      return
    }
  }

  handleClose() {
    this.props.analytics.info('AnonymousPasswordResetClose')
    let pathname = window.location.pathname.replace("/anon_password_reset","")
    this.props.history.replace({pathname: pathname})
  }

  componentDidUpdate(prevProps) {
    if (this.props.rest.name && this.state.loading === true) {
      this.setState({loading: false})
    }
  }

  render() {
      return (
        <Modal close={this.handleClickOutside}>
        <div className='anonPasswordReset'>
          { !this.state.reset_id && !this.state.rest_id && (
          <div>
          <h3>Reset Password</h3>

          <div className='words'>We will email you a code to reset your password</div>

          <div className='label'>Email: </div>
          <input type='text' 
            value={this.state.email}
            onChange={(e)=>{this.setState({email: e.target.value.trim()})}}
            autocomplete="email"
            autofocus="true"/>

          {this.state.error && (
            <div className="modalError">{this.state.error}</div>
          )}

          <button
            style={{background: this.props.rest.hex_color_primary}}
            onClick={e => this.handleEmailSubmission()}>Send Reset Code</button>
          </div>)}
    
        { !this.state.codeOK && this.state.reset_id && (
          <div>
          <h3>Enter Code</h3>
          <div className='words'>We sent a code to reset your password at:
</div>
          <h3>{this.state.email}</h3>
          <div className='words'>Please enter the code we sent or click the link in your email</div>

          <div className='label'>6-Digit Code:</div>
          <input type='text' 
            autofocus="true"
            maxlength="6"
            value={this.state.code}
            onChange={(e)=>{this.setState({code: e.target.value.trim()})}}/>

          {this.state.error && (
            <div className="modalError">{this.state.error}</div>
          )}

          <button
            style={{background: this.props.rest.hex_color_primary}}
            onClick={e => this.handleCodeSubmission()}>NEXT</button>
          </div>
        )}

        { this.state.codeOK && !this.state.done && (
          <div>
          <h3>Change Password</h3>

          <div className='label'>New Password:</div>
          <input type='password' 
            value={this.state.password}
            onChange={(e)=>{this.setState({password: e.target.value.trim()})}}
            autocomplete="new-password"/>

          <div className='label'>Confirm New Password:</div>
          <input type='password' 
            value={this.state.password2}
            onChange={(e)=>{this.setState({password2: e.target.value.trim()})}}
            autocomplete="new-password"/>

          {this.state.error && (
            <div className="modalError">{this.state.error}</div>
          )}

          <button
            style={{background: this.props.rest.hex_color_primary}}
            onClick={e => this.handlePasswordChange()}>Next</button>
          </div>
        )}

        { this.state.done && (
          <div>
          <h3>Password Updated!</h3>
          <div className='words'>You have successfully updated your password and you are now logged in.</div>

          <img className='happylogo' 
            src='https://cdn.hngr.co/foodmoji_dinner1.svg' />
          <div className='words'>Thank you for ordering with us!</div>

          <button
            style={{background: this.props.rest.hex_color_primary}}
            onClick={e => this.handleCompleteEvent()}>Continue Ordering</button>
          </div>)
        }

        </div>
        </Modal>)
  }

}

export default RestContextHOC(SignupModalContextHOC(
  AnalyticsContextHOC(
    CartContextHOC(
      EntryPointHOC(UserContextHOC(
        UserContextHOC(RestContextHOC(AnonymousPasswordReset))))))))
