import React, { Component } from 'react';
import '../css/OrderReceipt.css'
import LoadingIndicator from '../Loading/LoadingIndicator'
const Api = require('../helpers/api');


class OrderReceipt extends Component {

  constructor(props){
    super(props);
    this.state = {
      receipt: null,
      containerHeight:null
    }
    this.containerRef = React.createRef()
    this.getRawReceipt()
  }

  componentDidMount(){
    this.getHeight()
  }

  componentDidUpdate(){
    this.getHeight()
  }

  getRawReceipt() {
    let that  = this
    Api.callApi(
        'order_receipt',
        (blob) => {
                 var reader = new FileReader();
                 reader.readAsDataURL(blob);
                 reader.onloadend = function() {
                   let base64data = reader.result;
                   that.setState({receipt:base64data})
                 }
              },
        (err)=>console.log('ERROR:', err),
        null,
        null,
        [this.props.diner_id, this.props.hash_id]
    );
  }

  getHeight(){
    //console.log("ref", this.containerRef.current)
    //console.log("ref-height", this.containerRef.current.offsetHeight)
    if(
      this.containerRef.current && 
      this.containerRef.current.offsetHeight !== this.state.containerHeight
    ){
      let height = this.containerRef.current.offsetHeight
      this.setState({containerHeight:height})
      //console.log("disco cisco", height)
      if(this.props.onHeightUpdate){
        //console.log("disco33", height)
        this.props.onHeightUpdate(height)
      } else{
        console.log("props", this.props)
      }
    }
  }

  render() {
    return (
      <div 
        className={this.props.containerClass ? this.props.containerClass : 'orderReceiptContainer'}
        style={this.props.centerReceipt ? {textAlign:'center'} : null}
        ref={this.containerRef}
      >
        { this.state.receipt ? (
          <img 
            className={this.props.receiptClass ? this.props.receiptClass : 'orderReceiptFrame' }
            src={this.state.receipt} 
            alt='receipt for order: {this.props.orderID}'
          /> ) : <LoadingIndicator noBG={!this.props.loadingBackground}/>
        }
      </div>
    )
  }
}

export default OrderReceipt;
