import React from "react";
import withRouterAndRef from "../withRouterAndRef";
import * as Sentry from "@sentry/browser";
import "./ErrorBoundary.css";
import "../css/Global.css";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    window.error = this;

    this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    Sentry.configureScope((scope) => {
      scope.setExtra("error", error);
      scope.setExtra("errorInfo", errorInfo);
      Sentry.captureMessage(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="page-container error-boundary">
          <h1 style={{ textAlign: "center" }}>
            Uh oh, looks like something broke.
          </h1>

          <img
            src="https://cdn.hngr.co/supperclub/nakedburger.png"
            alt="burger"
          />

          <div
            className="bold error-boundary-home"
            onClick={() => window.location.href ='/'}
          >
            <div>Try Again</div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouterAndRef(ErrorBoundary);
