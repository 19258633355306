const getSubdomain = function () {
  const fulldomain = window.location.host
  const subdomain = fulldomain.split(".")[0].toLowerCase()
  const domain = fulldomain.split(".")[1].toLowerCase()

  if (subdomain.indexOf("-locations") > -1) {
    const i = subdomain.indexOf("-locations")
    return subdomain.slice(0, i)
  }

  /* if its a hngr property, return the subdomain name
     otherwise return the full domain name, this is to
     keep the backend API happy */
  if (domain === "hngr" || domain.indexOf("localhost") > -1 ||domain === 'hngr0') {
    return subdomain
  } else {
    return fulldomain
  }
}

module.exports = {
  getSubdomain: getSubdomain,
}
