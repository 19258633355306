import React, { Component } from 'react';
import SignupModalContextHOC from './Contexts/SignupModalContextHOC'
import UserContext from './Contexts/UserContextHOC'
import AnalyticsContextHOC from './Contexts/AnalyticsContextHOC'
import CartContext from './Contexts/CartContextHOC'

class OauthUniversal extends Component {

  constructor(props){
    super(props)
    this.authenticate()
  }

  authenticate(){
    this.props.analytics.debug("UniversalOauthURL", window.location)
    const params = new URLSearchParams(window.location.search)
    this.props.analytics.info("UniversalOauthSuccess", JSON.stringify(params))

    // data is attachd as a GET param to this page, which is 
    // base64 encoded. decode, load the json and use it to
    // force the user login
    const data = JSON.parse(atob(params.get('p')))
    this.props.user.loginUser(data.userdata, data.hngr_token)
    this.props.cart.loginUser(data.userdata)

    if (data.clickstream_id) {
      this.props.analytics.setSessionId(data.clickstream_id)
    }

    if (data.sendto === 'checkout') {
      // if no phone number and we're sending to checkout
      // then go and get the phone number, otherwise just
      // try to send to checkout directly
      // note that if they come from apple, they could have 
      // NO name and NO email
      // https://github.com/blpt-hngr/foodcoin/issues/3290
      // in which case send them to moreinfo
      if (data.userdata.first_name == null ||
          data.userdata.last_name === null) {
        let pathName = '/menu/moreinfo?sendTo=checkout'
        this.props.history.replace({pathname: pathName})
      } else if (data.userdata.email === null || 
          data.userdata.email.includes('RELAY@hngr.co')) {
        let pathName = '/menu/email_missing?sendTo=checkout'
        this.props.history.replace({pathname: pathName})
      } else if (data.userdata.phone_number_e164 === null) {
        let pathName = '/menu/phone_verify?sendTo=checkout'
        this.props.history.replace({pathname: pathName})
      } else {
        this.props.history.replace({pathname: '/checkout'})
      }

    } else {
      this.props.history.replace({pathname: '/menu/'})
    }
  }
  
  render() {
    return (
      <div> 
        Redirecting... 
      </div>
    )
  }
}

export default AnalyticsContextHOC(
  CartContext(UserContext(SignupModalContextHOC(OauthUniversal))))
