import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import localforage from "localforage"
import AnalyticsContextHOC from "./AnalyticsContextHOC"
import UserContextHOC from "./UserContextHOC"
import RestContextHOC from "./RestContextHOC"
import withErrorHandling from "./withErrorHandling/withErrorHandling"
import SignupModalContextHOC from "./SignupModalContextHOC"
import Cart from "../SharedComponents/cart"
import * as Sentry from "@sentry/browser"
import * as solanaWeb3 from "@solana/web3.js"
import * as splToken from "@solana/spl-token"
import {
  Cluster,
  clusterApiUrl,
  Connection,
  PublicKey,
  Keypair,
} from "@solana/web3.js"
import { Connection as MPConnection, programs } from "@metaplex/js"
//const { metadata: { Metadata } } = programs;

const api = require("../helpers/api")
const Timestamp = require("../helpers/timestamps")
const USDC_ADDRESS = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
const FOODCOIN_ADDRESS = "B7vkWNFo2aYcYddtwR8fvDwY1Uc54wHfdTXULjrX7KeL"
const SOL_PRICE = 80
const LAMPORTS_PER_SOL = 1000000000
const TOKEN_PROGRAM_ID = "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
const ASSOCIATED_TOKEN_PROGRAM_ID =
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
const METADATA_PROGRAM_ID = "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
const METADATA_PREFIX = "metadata"
const subdomain = require("../helpers/subdomain")
const KIOSK_CART_TIMEOUT = 90000
//const { Metaplex } = require("@metaplex-foundation/js")

export const CartContext = React.createContext()

class CartProvider extends Component {
  constructor(props) {
    super(props)
    this.empty_finance_array = {
      deliveryFee: 0,
      promo: 0,
      tax: 0,
      tip: 0,
      total: 0,
      subtotal: 0.0,
      productTotal: 0.0,
      taxableSubtotal: 0,
      taxRate: 0.0,
      tipSelected: false,
    }

    this.state = {
      cart: () => this.cartObjectParsed(),
      walletConnected: false,
      wallet: null,
      createdAt: null,
      utensils: true,
      splitTip: true,
      solBalance: null,
      usdcBalance: null,
      foodcoinBalance: null,
      enoughSol: (solPrice) => this.enoughSol(solPrice),
      enoughUSDC: () => this.enoughUSDC(),
      enoughFoodcoin: () => this.enoughFoodcoin(),
      enoughFoodcoinAndUSDC: () => this.enoughFoodcoinAndUSDC(),
      enoughFoodcoinAndSol: (price) => this.enoughFoodcoinAndSol(price),
      items: [],
      pickupOrDeliveryLoaded: false,
      couponFailReason: "",
      couponKeycode: "",
      clearCouponFail: () =>
        this.setState({ couponFailReason: "", couponKeycode: "" }),
      couponItem: null,
      couponCartConditionItem: null,
      setCouponKeycode: (keycode) => this.setState({ couponKeycode: keycode }),
      isFinanceLoaded: () => this.isFinanceLoaded(),
      isCheckerPageComplete: () => this.isCheckerPageComplete(),
      checkerPageIsSaved: false,
      editCheckerPage: () => this.editCheckerPage(),
      saveCheckerPage: () => this.saveCheckerPage(),
      loginUser: (user) => this.loginUser(user),
      isPickupOrDeliveryLoaded: () => this.isPickupOrDeliveryLoaded(), // tip page
      calculateTipFromPercent: (percent) =>
        this.calculateTipFromPercent(percent),
      finance: this.empty_finance_array,
      addToCart: (item, qty) => this.addToCart(item, qty),
      removeItem: (item) => this.removeItem(item),
      editItem: (item, origItem, qty) => this.editItem(item, origItem, qty),
      setReorderItems: (items) => this.setReorderItems(items),
      removeCoupon: () => this.removeCoupon(),
      setRestId: (rest_id) => this.setRestId(rest_id),
      getAcceptedCoins: () => this.getAcceptedCoins(),
      setPickup: () => this.setPickup(),
      setDelivery: () => this.setDelivery(),
      unsetPickupDelivery: () => this.setState({ pickup_or_delivery: null }),
      setDeliveryNew: () => this.setDeliveryNew(),
      setTip: (tipAmount) => this.setTip(tipAmount),
      setSplitTip: (val) => this.setState({ splitTip: val }),
      setTaxRate: (rate) => this.setTaxRate(rate),
      setUtensils: (val) => this.setState({ utensils: val }),
      producttotal: () => this.calculateProductTotal(),
      onWalletConnected: (publicKey) => this.onWalletConnected(publicKey),
      deliveryFeeAfterPromo: () => this.deliveryFeeAfterPromo(),
      subtotal: () => this.calculateSubTotal(),
      resetTip: () => this.resetTip(),
      promoAmount: () => this.promoAmount(),
      isSomeFormOfPaymentSelected: () => this.isSomeFormOfPaymentSelected(),
      setDeliveryZone: (dz, callback) => this.setDeliveryZone(dz, callback),
      invokeAsyncCallToVerify: () => this.invokeAsyncCallToVerify(),
      invokeOrderPlacement: (dinerUUID, onSuccess, onError) =>
        this.invokeOrderPlacement(dinerUUID, onSuccess, onError),
      inputCoupon: (keycode, callback, dinerUUID, fromVerify, returnItem) =>
        this.inputCoupon(keycode, callback, dinerUUID, fromVerify, returnItem),
      setBrowser: (browser) => this.setBrowser(browser),
      browser: null,
      setInitialAddress: (address) => this.setInitialAddress(address),
      setConfirmedAddress: (address) => this.setConfirmedAddress(address),
      stillAcceptingOrders: (callback) => this.stillAcceptingOrders(callback),
      queueUpEmptyCart: () => this.setState({ emptyCartWhenRestIdIsSet: true }),
      emptyCart: () => this.emptyCart(),
      minimumIsMet: () => this.minimumIsMet(),
      rest_id: null,
      pickup_or_delivery: null,
      delivery_zone: null,
      initial_address: null, //this is the address they enter on the the initial address page
      //we save it to prepopulate the address input later in the checkout process
      confirmed_address: null,
      setCustomPaymentNumber: (val) =>
        this.setState({ customPaymentNumber: val }),
      setCustomPaymentName: (val) => this.setState({ customPaymentName: val }),
      stripe_token: null,
      showPaymentError: false,
      paymentError: null,
      setPaymentError: (val, err) =>
        this.setState({ showPaymentError: val, paymentError: err }),
      cardErrorReason: null,
      setCardErrorReason: (err) => this.setState({ cardErrorReason: err }),
      addCardScreenOpen: false,
      setAddCardScreenOpen: () => this.setState({ addCardScreenOpen: true }),
      setAddCardScreenClosed: () => this.setState({ addCardScreenOpen: false }),
      savedCard: null,
      selectPayment: (type, value, cardDetails) =>
        this.selectPayment(type, value, cardDetails),
      selectPaymentNew: (type, value, cardDetails) =>
        this.selectPaymentNew(type, value, cardDetails),
      unselectPayment: () => this.unselectPayment(),
      applePay: false,
      saveCard: false,
      cash: false,
      bitcoin: false,
      qr: false,
      sol: false,
      solanaTokens: [],
      setSolanaTokens: (tokens) => this.setState({ solanaTokens: tokens }),
      customPayment: false,
      shouldSaveCard: (val) => this.setState({ saveCard: val }),
      cardDetails: null,
      coupon: null,
      specialInstructions: "",
      setSpecialInstructions: (inst) => this.setSpecialInstructions(inst),
      tableNumber: null,
      setTableNumber: (num) => this.setTableNumber(num),
      taxRate: null,
      setTaxExempt: (taxExempt) => {
        this.setState({ taxExempt: taxExempt }, () => this.recalculateFinance())
      },
      taxExempt: false,
      user: null,
      checkoutReady: false,
      addressNotSelected: false,
      updateAddressError: (addressNotSelected) =>
        this.updateAddressError(addressNotSelected),
      updateCheckoutState: (state) => this.updateCheckoutState(state),
      setUTMs: (utms) => this.setUTMs(utms),
      setVersion: (version) => this.setVersion(version),
      setSessionId: (sess_id) => this.setSessionId(sess_id),
      recalculateFinance: () => this.recalculateFinance(false, false),
      shouldGetStripeToken: false,
      getStripeTokenCallback: null,
      getStripeTokenFailureCallback: null,
      triggerGetStripeToken: (callback, failureCallback) => {
        this.triggerGetStripeToken(callback, failureCallback)
      },
      unTriggerGetStripeToken: () => this.unTriggerGetStripeToken(),
      setStripeToken: (stripe_token, cardDetails, callback) =>
        this.setStripeToken(stripe_token, cardDetails, callback),
      setFutureOrderTime: (ts) => this.setFutureOrderTime(ts),
      futureOrderTime: null,
      futureOrderTimeLoaded: false,
      futureOrderTimeString: () => this.futureOrderTimeString(),
      updatedAt: null,
      is_reorder: false,
      forceOpenCart: false,
      forceScrollBottom: false,
      setForceScrollBottom: (val) => this.setState({ forceScrollBottom: val }),
      isTimeSelected: () => this.isTimeSelected(),
      setSolanaTxn: (txn) => this.setSolanaTxn(txn),
      foundNFTs: [],
      unsetFoundNFTs: () => this.setState({ foundNFTs: [], nftOwner: false }),
      nftOwner: null,
      nftName: null,
      nftNumber: null,
      nftAddress: null,
      nftAlreadyRedeemed: null,
      paymentMethods: null,
      setSupperclubWallet: (keypair) => this.setSupperclubWallet(keypair),
      calculateSupperClubPayment: () => this.calculateSupperClubPayment(),
      setWallet: (wallet) => this.setState({ wallet: wallet }),
      getCoinBalances: () => this.getCoinBalances(),
      paymentMethod: null,
      paymentMethodFees: null,
      incentives: [],
      supperclubDisplay: [],
      getIncentiveAmount: (type) => this.getIncentiveAmount(type),
      checkKioskTimeout: () => this.checkKioskTimeout(),
      setKioskDinerDetails: (details) => this.setKioskDinerDetails(details),
      kioskDinerDetails: {},
      kioskPaymentIntentId: null,
      setKioskPaymentIntentId: (id) =>
        this.setState({ kioskPaymentIntentId: id }),
      calculatePaymentMethodSavings: (amount) =>
        this.calculatePaymentMethodSavings(amount),
    }
    this.lastUpdated = Date.now()
  }

  componentDidUpdate(prevProps, prevState) {
    // if tax delivery fees updates, we need to recalculate finance.
    // https://github.com/blpt-hngr/foodcoin/issues/316e
    if (this.props.rest.taxDeliveryFees !== prevProps.rest.taxDeliveryFees) {
      this.recalculateFinance()
    }

    if (this.state.futureOrderTime !== prevState.futureOrderTime) {
      this.props.rest.refreshBlackouts(this.state.futureOrderTime)
    }

    this.lastUpdated = Date.now()
  }

  // take the future order time and display it as a string....
  // for example "Today, ASAP" or "Wednesday, 3:00pm"
  futureOrderTimeString() {
    if (!this.state.futureOrderTime) {
      if (this.props.rest.allow_asap_orders) {
        return "Today, ASAP"
      } else {
        return "Select A Time"
      }
    }
    // let's parse out the day of week and time
    let dow = Timestamp.getDayOfWeekFull(this.state.futureOrderTime)
    let time = Timestamp.getTimeOfDay(this.state.futureOrderTime)
    let mm = Timestamp.getMonthInt(this.state.futureOrderTime)
    let dd = Timestamp.getDayOfMonth(this.state.futureOrderTime)

    return `${dow} ${mm}/${dd}, ${time}`
  }

  isCheckerPageComplete() {
    if (this.isPickupOrDeliveryLoaded()) {
      if (
        this.state.pickup_or_delivery === "delivery" &&
        this.state.initial_address
      ) {
        return true
      }
      if (this.state.pickup_or_delivery === "pickup") {
        return true
      }
    }
    return false
  }

  saveCheckerPage() {
    this.setState({
      checkerPageIsSaved: true,
    })
    this.props.analytics.info("CheckerPageComplete")
  }

  editCheckerPage() {
    this.setState({
      checkerPageIsSaved: false,
    })
    let path = this.props.location.pathname.split("/")
    this.props.history.push(path[0] + "/menu/start")
  }

  triggerGetStripeToken(callback, failureCallback) {
    this.setState({
      shouldGetStripeToken: true,
      getStripeTokenCallback: callback,
      getStripeTokenFailureCallback: failureCallback,
    })
  }

  unTriggerGetStripeToken() {
    this.setState({ shouldGetStripeToken: false })
  }

  isFinanceLoaded() {
    return !(this.state.finance.total === 0)
  }

  // DEPRECATED: don't use this. Use this.state.pickupOrDeliveryLoaded
  isPickupOrDeliveryLoaded() {
    return !(this.state.pickup_or_delivery === null)
  }

  //to empty the cart, we blow up the items, the finance and the confirmed address.
  //there's probably other stuff we wanna blow up too.... TODO later...
  emptyCart() {
    //this.setState({kioskDinerDetails:{})

    this.setState({ kioskDinerdetails: {} })

    this.setState(
      {
        items: [],
      },
      () => this.syncStorage()
    )

    this.setState(
      {
        delivery_zone: null,
      },
      () => this.saveDeliveryZone()
    )

    this.setState({
      couponFailReason: "",
      couponKeycode: "",
    })

    this.setState(
      {
        finance: this.empty_finance_array,
      },
      () => this.savefinance()
    )

    this.setState(
      {
        solanaTxn: null,
      },
      () => this.saveSolanaTxn()
    )

    this.setState(
      {
        confirmed_address: null,
      },
      () => this.saveConfirmedAddress()
    )

    this.setState(
      {
        initial_address: null,
      },
      () => this.saveInitialAddress()
    )

    this.setState(
      {
        coupon: null,
      },
      () => this.saveCoupon()
    )

    this.setState(
      {
        incentives: [],
      },
      () => this.saveIncentives()
    )

    this.setState(
      {
        user: null,
      },
      () => this.saveUser()
    )

    this.setState(
      {
        futureOrderTime: null,
      },
      () => this.saveFutureOrderTime()
    )

    this.setState({
      couponItem: null,
      couponFailReason: null,
      couponCartConditionItem: null,
      stripe_token: null,
      savedCard: null,
      solanaTxn: null,
      incentives: [],
    })

    this.setState({ pickup_or_delivery: null }, () =>
      this.savePickupOrDelivery()
    )

    this.setState({ createdAt: null }, () => this.saveCreatedAt())
  }

  unselectPayment() {
    this.setState({
      savedCard: null,
      applePay: false,
      cardDetails: null,
      cash: false,
      bitcoin: false,
      sol: false,
      customPayment: false,
    })
  }

  selectPayment(type, value, cardDetails) {
    if (type === "card") {
      this.setState({
        savedCard: value,
        applePay: false,
        cardDetails: cardDetails,
        cash: false,
      })
    } else if (type === "applePay") {
      this.setState({
        savedCard: null,
        applePay: true,
        cardDetails: cardDetails,
        cash: false,
      })
    } else if (type === "cash") {
      this.setState({
        savedCard: null,
        applePay: false,
        cardDetails: null,
        cash: true,
        stripe_token: null,
      })
    } else if (!type) {
      this.setState({
        cash: false,
        savedCard: null,
        applePay: false,
        cardDetails: null,
      })
    }
  }

  selectPaymentNew(type, value, cardDetails, skipRecalculate) {
    if (type === "card") {
      this.setState(
        {
          savedCard: value,
          applePay: false,
          bitcoin: false,
          sol: false,
          foodcoin: false,
          cardDetails: cardDetails,
          cash: false,
          customPayment: false,
          paymentMethod: type,
        },
        () => {
          // i don't even know why recalculate here.... but this is to fix
          // a race condition when refreshing checkout and finance gets saved
          // to 0 before the cart items load
          if (skipRecalculate !== true) {
            this.recalculateFinance()
          }
        }
      )
    } else if (type === "newCard") {
      this.setState(
        {
          savedCard: null,
          applePay: false,
          cardDetails: cardDetails,
          cash: false,
          bitcoin: false,
          sol: false,
          foodcoin: false,
          customPayment: false,
          paymentMethod: type,
        },
        () => this.recalculateFinance()
      )
    } else if (type === "applePay") {
      this.setState(
        {
          savedCard: null,
          applePay: true,
          cardDetails: cardDetails,
          cash: false,
          bitcoin: false,
          sol: false,
          foodcoin: false,
          customPayment: false,
          paymentMethod: type,
        },
        () => this.recalculateFinance()
      )
    } else if (type === "cash") {
      this.setState(
        {
          savedCard: null,
          applePay: false,
          cardDetails: null,
          cash: true,
          stripe_token: null,
          bitcoin: false,
          sol: false,
          foodcoin: false,
          customPayment: false,
          paymentMethod: type,
        },
        () => this.resetTip()
      )
    } else if (type === "custom") {
      this.setState(
        {
          savedCard: null,
          applePay: false,
          cardDetails: null,
          cash: false,
          stripe_token: null,
          bitcoin: false,
          sol: false,
          foodcoin: false,
          customPayment: true,
          paymentMethod: type,
        },
        () => this.recalculateFinance()
      )
    } else if (type === "bitcoin") {
      this.setState(
        {
          savedCard: null,
          applePay: false,
          cardDetails: null,
          cash: false,
          stripe_token: null,
          customPayment: false,
          bitcoin: true,
          foodcoin: false,
          sol: false,
          paymentMethod: type,
        },
        () => this.recalculateFinance()
      )
    } else if (type === "sol") {
      this.setState(
        {
          savedCard: null,
          applePay: false,
          cardDetails: null,
          cash: false,
          stripe_token: null,
          customPayment: false,
          bitcoin: false,
          sol: true,
          foodcoin: false,
          qr: value,
          paymentMethod: type,
        },
        () => this.recalculateFinance()
      )
    } else if (type === "foodcoin") {
      this.setState(
        {
          savedCard: null,
          applePay: false,
          cardDetails: null,
          cash: false,
          stripe_token: null,
          customPayment: false,
          bitcoin: false,
          sol: false,
          foodcoin: true,
          qr: value,
          paymentMethod: type,
        },
        () => this.recalculateFinance()
      )
    } else if (!type) {
      this.setState(
        {
          cash: false,
          savedCard: null,
          applePay: false,
          cardDetails: null,
          bitcoin: false,
          sol: false,
          foodcoin: false,
          customPayment: false,
          paymentMethod: null,
        },
        () => this.recalculateFinance()
      )
    }
  }

  isSomeFormOfPaymentSelected() {
    if (
      this.state.cash === false &&
      this.state.savedCard === null &&
      this.state.applePay === false &&
      this.state.cardDetails === null &&
      this.state.customPayment === false
    ) {
      return false
    } else {
      return true
    }
  }

  setStripeToken(stripe_token, cardDetails, callback) {
    this.setState(
      { stripe_token: stripe_token, cardDetails: cardDetails },
      () => {
        callback()
      }
    )
  }

  async saveTaxRate() {
    await localforage
      .setItem(this.getLocalForageKeyTaxRate(), this.state.taxRate)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  setFutureOrderTime(timestamp) {
    // make zero an int, not string on all browsers
    if (timestamp === "0") timestamp = 0
    if (!this.state.createdAt && timestamp) this.setCreatedAt()
    let date = new Date(timestamp * 1000)
    date = date.toString()
    this.props.analytics.debug("SettingOrderAhead", date)
    this.setState(
      { futureOrderTime: timestamp, futureOrderTimeLoaded: true },
      () => {
        this.saveFutureOrderTime()
      }
    )
  }

  // helper functions for setting browser and utms
  setBrowser(browser) {
    this.setState({ browser: browser })
  }
  setSpecialInstructions(inst) {
    this.setState({ specialInstructions: inst })
  }
  setUTMs(utms) {
    this.setState({ utms: utms })
  }
  setVersion(version) {
    this.setState({ version: version })
  }
  setSessionId(sess_id) {
    this.setState({ sessionId: sess_id })
  }

  //address should be {address:address, lat:lat, lng:lng}
  setInitialAddress(address) {
    this.setState({ initial_address: address }, () => this.saveInitialAddress())
  }

  setTableNumber = (num) => {
    this.setState({ tableNumber: num })
  }

  //address should be {address:address, lat:lat, lng:lng}
  setConfirmedAddress(address) {
    this.setState({ confirmed_address: address }, () =>
      this.saveConfirmedAddress()
    )
  }

  isTimeSelected = () => {
    if (this.state.futureOrderTime === 0) {
      return true
    }

    return !!this.state.futureOrderTime
  }

  setDelivery = () => {
    if (!this.state.createdAt) this.setCreatedAt()

    this.setState(
      {
        pickup_or_delivery: "delivery",
      },
      () => this.invokeAsyncCallToVerify()
    )
  }

  setDeliveryNew = () => {
    if (!this.state.createdAt) this.setCreatedAt()

    if (this.state.cash && !this.props.rest.allowCashDelivery) {
      this.selectPaymentNew(null)
    }

    this.setState(
      {
        pickup_or_delivery: "delivery",
      },
      () => {
        this.savePickupOrDelivery()
        this.saveDeliveryZone()
        this.invokeAsyncCallToVerify()
      }
    )
  }

  setPickup() {
    if (!this.state.createdAt) this.setCreatedAt()
    // if we are set to cash and pickup doesn't allow cash,
    // then we need to set payment to null
    if (this.state.cash && !this.props.rest.allowCashPickup) {
      this.selectPaymentNew(null)
    }

    this.setState(
      {
        pickup_or_delivery: "pickup",
        initial_address: null,
        delivery_zone: null,
      },
      () => {
        this.savePickupOrDelivery()
        this.saveDeliveryZone()
        this.invokeAsyncCallToVerify()
        this.recalculateFinance(true)
      }
    )
    this.setConfirmedAddress(null)
    this.setInitialAddress(null)
    this.resetTip(0)
  }

  setDeliveryZone(delivery_zone, callback) {
    var state = {
      pickup_or_delivery: "delivery",
      delivery_zone: delivery_zone,
    }

    // when called from the confirm address page, we pass a callback that checks if the new delivery zone
    // meets the minimum order amount
    if (!callback) {
      this.setState(state, () => {
        this.savePickupOrDelivery()
        this.saveDeliveryZone()
        this.recalculateFinance(true)
      })
    } else {
      this.setState(state, () => {
        this.savePickupOrDelivery()
        this.saveDeliveryZone()
        this.recalculateFinance(true, callback)
      })
    }
  }

  async savePickupOrDelivery() {
    this.props.analytics.debug(
      "SavingPickupOrDelivery",
      this.state.pickup_or_delivery
    )
    let key = this.getLocalForageKeyPickupOrDelivery()
    this.props.analytics.debug("SavingPickupOrDeliveryKey", key)
    let that = this
    await localforage
      .setItem(key, this.state.pickup_or_delivery)
      .then(function (value) {
        that.props.analytics.debug("SavedPickupOrDelivery", value)
      })
      .catch(function (err) {
        that.props.analytics.debug("SavingPickupOrDeliveryError", err)
        console.error(err)
      })
  }

  async saveDeliveryZone() {
    await localforage
      .setItem(this.getLocalForageKeyDeliveryZone(), this.state.delivery_zone)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  async saveInitialAddress() {
    await localforage
      .setItem(this.getLocalForageKeyAddress(), this.state.initial_address)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  async savefinance() {
    //alert('saving finance')
    await localforage
      .setItem(this.getLocalForageKeyfinance(), this.state.finance)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  async saveCoupon() {
    await localforage
      .setItem(this.getLocalForageKeyCoupon(), this.state.coupon)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  async saveIncentives() {
    await localforage
      .setItem(this.getLocalForageKeyIncentives(), this.state.incentives)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  async saveSolanaTxn() {
    await localforage
      .setItem(this.getLocalForageKeySolanaTxn(), this.state.solanaTxn)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  async saveConfirmedAddress() {
    // don't save the delivery instructions across refreshes
    let address = JSON.parse(JSON.stringify(this.state.confirmed_address))
    if (address) {
      address["specialInstructions"] = null
    }
    await localforage
      .setItem(this.getLocalForageKeyConfirmedAddress(), address)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  async saveUser() {
    await localforage
      .setItem(this.getLocalForageKeyUser(), this.state.user)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  async saveFutureOrderTime() {
    await localforage
      .setItem(
        this.getLocalForageKeyFutureOrderTime(),
        this.state.futureOrderTime
      )
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  async saveCreatedAt() {
    await localforage
      .setItem(this.getLocalForageKeyCreatedAt(), this.state.createdAt)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  setRestId(rest_id) {
    let callback
    if (this.state.emptyCartWhenRestIdIsSet) {
      callback = () => {
        this.emptyCart()
        this.getAcceptedCoins()
      }
    } else {
      callback = () => {
        this.loadCartFromStorage()
        this.getAcceptedCoins()
      }
    }

    this.setState(
      {
        rest_id: rest_id,
        items: [],
      },
      callback
    )
  }

  getAcceptedCoins() {
    const handleResponse = (resp) => {
      this.setState(
        {
          acceptedCoins: resp.tokens,
        },
        this.getCoinBalances.bind(this)
      )
    }

    api.callApi(
      "accepted_tokens",
      handleResponse.bind(this),
      (err) => {
        console.log("error getting accepted coins", err)
      },
      { rest_uuid: this.state.rest_id }
    )
  }

  setTaxRate(rate) {
    this.setState(
      {
        taxRate: rate,
      },
      () => this.saveTaxRate()
    )
  }

  getLocalForageKeyCreatedAt() {
    var key = this.state.rest_id + "_created_at"
    return key
  }

  getLocalForageKeyFutureOrderTime() {
    var key = this.state.rest_id + "_future_order_time"
    return key
  }

  getLocalForageKeyTaxRate() {
    var key = this.state.rest_id + "_tax_rate"
    return key
  }

  getLocalForageKeyAddress() {
    //the local forage key is rest_id+'_cart'
    var key = this.state.rest_id + "_cart_address"
    return key
  }

  getLocalForageKeyConfirmedAddress() {
    //the local forage key is rest_id+'_cart'
    var key = this.state.rest_id + "_confirmed_cart_address"
    return key
  }

  getLocalForageKeyPickupOrDelivery() {
    //the local forage key is rest_id+'_cart'
    var key = this.state.rest_id + "_cart_pickup_or_delivery"
    return key
  }

  getLocalForageKeyDeliveryZone() {
    //the local forage key is rest_id+'_cart'
    var key = this.state.rest_id + "_delivery_zone"
    return key
  }

  getLocalForageKeyfinance() {
    var key = this.state.rest_id + "_finance"
    return key
  }

  getLocalForageKeyCoupon() {
    var key = this.state.rest_id + "_coupon"
    return key
  }

  getLocalForageKeyIncentives() {
    var key = this.state.rest_id + "_incentives"
    return key
  }

  getLocalForageKeySolanaTxn() {
    var key = this.state.rest_id + "_solana_txn"
    return key
  }

  getLocalForageKeyUser() {
    var key = this.state.rest_id + "_user"
    return key
  }

  getLocalForageKey() {
    //the local forage key is rest_id+'_cart'
    var key = this.state.rest_id + "_cart"
    return key
  }

  getLocalForageKeyUpdatedAt() {
    //the local forage key is rest_id+'_cart'
    var key = this.state.rest_id + "_updated"
    return key
  }

  loadCartFromStorage() {
    let that = this
    localforage
      .getItem(this.getLocalForageKeyUpdatedAt())
      .then(function (value) {
        let updatedAt
        if (that.state.updatedAt) {
          updatedAt = that.state.updatedAt
        } else {
          updatedAt = value
          that.setState({ updatedAt: updatedAt })
        }

        if (true || !updatedAt) {
          that.loadCartFromStorageForReal()
        } else {
          let now = Date.now()
          let seconds = (now - updatedAt) / 1000
          //if(seconds < 60 * 60 * 24){
          if (seconds < 60 * 60 * 24) {
            that.loadCartFromStorageForReal()
          } else {
            this.props.analytics.debug("CartExpiredAndCleared", seconds / 60)
          }
        }
      })
      .catch(function (err) {
        console.error(err)
      })
  }

  //if the cart is empty, try to populate it from localforage
  loadCartFromStorageForReal() {
    var that = this
    if (this.state.items.length === 0) {
      //load the cart items
      this.props.analytics.debug("LoadingCartFromStorage")
      localforage
        .getItem(this.getLocalForageKey())
        .then(function (value) {
          if (value !== null && value.length > 0) {
            let items = value
            let non_expired_items = []
            let ITEM_TIMEOUT = 1000 * 60 * 60 * 24 // 24 hrs
            let now = Date.now()
            for (var i in items) {
              let item = JSON.parse(JSON.stringify(items[i]))
              if (!item.added_at) {
                /*
                Sentry.configureScope((scope) => {
                  scope.setExtra("clickstream-session-id", window.CLICKSTREAM_SESSION_ID)
                  Sentry.captureMessage("Trying to load a super old cart.")
                })
                */
                non_expired_items.push(item)
                that.props.analytics.info(
                  "CartNoExpirationDAte",
                  JSON.stringify(item)
                )
              } else if (now - item.added_at < ITEM_TIMEOUT) {
                non_expired_items.push(item)
              } else {
                that.props.analytics.info(
                  "CartExpiredItem",
                  JSON.stringify(item)
                )
              }
            }
            that.setState({ items: non_expired_items }, () =>
              that.recalculateFinance()
            )
          }
        })
        .catch(function (err) {
          console.error(err)
        })
      //load if this order was for pickup or delivery
      let pickupDeliveryKey = this.getLocalForageKeyPickupOrDelivery()
      that.props.analytics.debug(
        "PickupOrDeliveryAboutToLoadWithKey",
        pickupDeliveryKey
      )
      localforage
        .getItem(pickupDeliveryKey)
        .then(function (value) {
          if (value !== null && that.state.pickup_or_delivery === null) {
            that.props.analytics.debug("PickupOrDeliveryLoadedNotNull", value)
            that.setState({
              pickup_or_delivery: value,
              pickupOrDeliveryLoaded: true,
            })
          } else {
            that.props.analytics.debug("PickupOrDeliveryLoadedIsNull", value)
            that.props.analytics.debug(
              "PickupOrDeliveryLoadedIsNullStateIs",
              that.state.pickup_or_delivery
            )
            that.setState({ pickupOrDeliveryLoaded: true })
          }
        })
        .catch(function (err) {
          that.props.analytics.debug("ErrorLoadingPickupOrDelivery", err)
          console.error(err)
        })

      //load the delivery zone
      localforage
        .getItem(this.getLocalForageKeyDeliveryZone())
        .then(function (value) {
          if (value !== null && that.state.delivery_zone === null) {
            that.setState({ delivery_zone: value }, () =>
              that.recalculateFinance()
            )
          }
        })
        .catch(function (err) {
          console.error(err)
        })

      //load the saved address
      localforage
        .getItem(this.getLocalForageKeyAddress())
        .then(function (value) {
          if (value !== null) {
            that.setState({ initial_address: value })
          }
        })
        .catch(function (err) {
          console.error(err)
        })

      // load any finance data on the order
      localforage
        .getItem(this.getLocalForageKeyfinance())
        .then(function (value) {
          if (value !== null && !that.state.finance.total) {
            that.setState({ finance: value })
          }
        })
        .catch(function (err) {
          console.error(err)
        })

      localforage
        .getItem(this.getLocalForageKeyIncentives())
        .then(function (value) {
          if (value !== null) {
            that.setState({ incentives: value }, () => {})
          }
        })
        .catch(function (err) {
          console.error(err)
        })

      localforage
        .getItem(this.getLocalForageKeyCoupon())
        .then(function (value) {
          if (value !== null) {
            that.setState({ coupon: value }, () => {
              window.coupon_loaded = true
              setTimeout(() => that.invokeAsyncCallToVerify(), 700)
            })
          }
        })
        .catch(function (err) {
          console.error(err)
        })

      localforage
        .getItem(this.getLocalForageKeySolanaTxn())
        .then(function (value) {
          if (value !== null) {
            that.setState({ solanaTxn: value })
          }
        })
        .catch(function (err) {
          console.error(err)
        })

      localforage
        .getItem(this.getLocalForageKeyTaxRate())
        .then(function (value) {
          if (value !== null) {
            that.setState({ taxRate: value })
          }
        })
        .catch(function (err) {
          console.error(err)
        })

      //load the confirmed address
      localforage
        .getItem(this.getLocalForageKeyConfirmedAddress())
        .then(function (value) {
          if (value !== null) {
            that.setState({ confirmed_address: value })
          }
        })
        .catch(function (err) {
          console.error(err)
        })

      //load user
      localforage
        .getItem(this.getLocalForageKeyUser())
        .then(function (value) {
          if (value !== null) {
            that.setState({ user: value })
          }
        })
        .catch(function (err) {
          console.error(err)
        })

      // load the future ordering time
      localforage
        .getItem(this.getLocalForageKeyFutureOrderTime())
        .then(function (value) {
          if (value !== null) {
            that.setState({
              futureOrderTime: value,
              futureOrderTimeLoaded: true,
            })
          } else {
            that.setState({ futureOrderTimeLoaded: true })
          }
        })
        .catch(function (err) {
          console.error(err)
        })

      // load created at
      localforage
        .getItem(this.getLocalForageKeyCreatedAt())
        .then(function (value) {
          if (value !== null) {
            that.setState({
              createdAt: value,
            })
          }
        })
        .catch(function (err) {
          console.error(err)
        })
    }
  }

  //everytime we add/remove from the cart, sync the new cart with storage
  async syncStorage(item) {
    await localforage
      .setItem(this.getLocalForageKey(), this.state.items)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  //checks to see if delivery zone minimum is met. Returns true for pickup orders
  minimumIsMet() {
    if (this.state.pickup_or_delivery === "pickup") {
      return true
    }

    if (this.state.pickup_or_delivery === null) {
      return true
    }

    if (this.state.delivery_zone === null) {
      return false
    }

    //if minimum isnt set, assume its 0
    if (this.state.delivery_zone.delivery_minimum === null) {
      return true
    }

    // we don't want to include free delivery coupons in the minimum
    // calculation
    let subtotal = this.state.subtotal()
    if (
      this.state.coupon &&
      this.state.coupon.coupon.action.indexOf("free_delivery") > -1 &&
      this.state.finance.promo
    ) {
      subtotal = subtotal + this.state.finance.deliveryFee
    }

    if (
      this.state.pickup_or_delivery === "delivery" &&
      subtotal >= this.state.delivery_zone.delivery_minimum
    ) {
      return true
    } else if (
      this.state.pickup_or_delivery === "delivery" &&
      this.state.supperclubWallet &&
      this.state.finance.productTotal >=
        this.state.delivery_zone.delivery_minimum
    ) {
      return true
    }
    return false
  }

  /**  anytime we update a field that could result 
       This assumes that state.items is already set
  **/
  recalculateFinance(callVerify, callback) {
    let finance = this.state.finance
    let tip = finance["tip"]
    let deliveryFee = 0
    let deliveryServiceFee = 0
    let deliverySmallOrderFee = 0
    let deliverySmallOrderFeeCeiling = 0

    if (
      this.state.delivery_zone &&
      this.state.pickup_or_delivery === "delivery"
    ) {
      let dz = this.state.delivery_zone
      if (dz.delivery_fee) {
        deliveryFee = dz.delivery_fee
        if (
          this.state.coupon &&
          this.state.coupon.coupon.action.indexOf("free_delivery") > -1
        ) {
          deliveryFee = 0
        } else {
          console.log("deliv fee not zero")
        }
      }

      if (dz.delivery_service_fee) {
        deliveryServiceFee = dz.delivery_service_fee
      }
      if (dz.delivery_small_order_fee_ceiling) {
        deliverySmallOrderFeeCeiling = dz.delivery_small_order_fee_ceiling
      }
    }
    let prodtotal = this.calculateProductTotal()

    this.calculateSupperClubPromo()

    // Sum up all promotional incentives
    if (this.state.incentives) {
      this.state.finance.promo = this.state.incentives.reduce(
        (total, inc) => total + inc.amount,
        0
      )
    }

    let subtotal = this.calculateSubTotal()
    let tax = this.calculateTax()
    // don't include free_delivery promos for  delivery small order fee
    let deliverySmallOrderTotal = subtotal
    if (
      this.state.coupon &&
      this.state.coupon.coupon.action.indexOf("free_delivery") > -1 &&
      this.state.finance.promo
    ) {
      deliverySmallOrderTotal = prodtotal
    }

    if (
      this.state.pickup_or_delivery === "delivery" &&
      this.state.delivery_zone &&
      this.state.delivery_zone.delivery_small_order_fee &&
      deliverySmallOrderFeeCeiling > deliverySmallOrderTotal
    ) {
      deliverySmallOrderFee = this.state.delivery_zone.delivery_small_order_fee
    }

    let deliveryFees = deliveryFee + deliveryServiceFee + deliverySmallOrderFee

    let paymentMethodFees = this.calculatePaymentMethodFees(subtotal)

    finance["deliveryFee"] = deliveryFee
    finance["deliveryServiceFee"] = deliveryServiceFee
    finance["deliverySmallOrderFee"] = deliverySmallOrderFee
    finance["deliveryFees"] = deliveryFees
    finance["paymentMethodFees"] = paymentMethodFees
    finance["productTotal"] = prodtotal
    finance["subtotal"] = subtotal
    finance["tax"] = tax
    finance["total"] = subtotal + tax + deliveryFees + tip + paymentMethodFees

    this.props.analytics.info("RecalculateFinance", JSON.stringify(finance))

    this.setState({ finance: finance }, () => {
      this.savefinance()
      this.calculateSupperClubPayment()

      if (callVerify) {
        this.invokeAsyncCallToVerify()
      }
      if (callback) callback()
    })
  }

  addIncentive(incentive) {
    return this.state.incentives
      .filter((inc) => {
        return inc.name != incentive.name && inc.id != incentive.id
      })
      .concat(incentive)
  }

  removeIncentive(incentive) {
    return this.state.incentives.filter((inc) => {
      return inc.name != incentive.name && inc.id != incentive.id
    })
  }

  getIncentiveAmount(type) {
    return this.state.incentives.reduce((total, inc) => {
      return inc.name == type ? total + inc.amount : total
    }, 0)
  }

  calculatePaymentMethodFees(amount) {
    let feeTotal = 0
    let selectedMethod = this.state.paymentMethod
    let fees = null

    if (this.props.rest.paymentMethodFees) {
      if (selectedMethod == "card" || selectedMethod == "newCard") {
        let cardDetails = this.state.cardDetails
        if (cardDetails.funding == "credit") {
          fees = this.props.rest.paymentMethodFees.filter((fee) => {
            if (
              fee.method == "credit" &&
              fee.type == "surcharge" &&
              (!fee.brand || fee.brand == cardDetails.brand)
            )
              return true
            else return false
          })

          feeTotal = fees.reduce((total, fee) => {
            let addFee = 0
            if (fee.flat > 0) {
              addFee += fee.flat
            }
            if (fee.percent > 0) {
              addFee += Math.round((fee.percent / 100) * amount)
            }
            return total + addFee
          }, feeTotal)
        } else if (cardDetails.funding === "debit") {
          fees = this.props.rest.paymentMethodFees.filter((fee) => {
            if (fee.method === "debit") return true
            else return false
          })
          feeTotal = fees.reduce((total, fee) => {
            let addFee = 0
            if (fee.flat > 0) {
              addFee += fee.flat
            }
            if (fee.percent > 0) {
              addFee += Math.round((fee.percent / 100) * amount)
            }

            if (fee.type === "discount") {
              addFee *= -1
            }
            return total + addFee
          }, feeTotal)
        }
      } else if (selectedMethod == "applePay") {
          fees = this.props.rest.paymentMethodFees.filter((fee) => {
            if (fee.method === "applePay") return true
            else return false
          })

          feeTotal = fees.reduce((total, fee) => {
            let addFee = 0
            if (fee.flat > 0) {
              addFee += fee.flat
            }
            if (fee.percent > 0) {
              addFee += Math.round((fee.percent / 100) * amount)
            }

            if (fee.type === "discount") {
              addFee *= -1
            }
            return total + addFee
          }, feeTotal)
      }
    }

    this.setState({
      paymentMethodFees: fees,
    })

    return feeTotal
  }

  calculatePaymentMethodSavings(amount) {
    let feeTotal = 0
    let fees = null

    if (this.props.rest.paymentMethodFees) {
      fees = this.props.rest.paymentMethodFees.filter((fee) => {
        if (fee.method == "credit" && fee.type == "surcharge") return true
        else return false
      })

      feeTotal = fees.reduce((total, fee) => {
        let addFee = 0
        if (fee.flat > 0) {
          addFee += fee.flat
        }
        if (fee.percent > 0) {
          addFee += Math.round((fee.percent / 100) * amount)
        }
        return total + addFee
      }, feeTotal)
    }

    return feeTotal
  }

  calculateSupperClubPromo() {
    let promoCoinApplied = 0

    if (this.state.supperclubWallet && this.state.supperclubBalances) {
      let remainingBalance = this.state.finance.productTotal
      let promos = []
      let incentives = []
      let payments = []
      let supperclubApplied = 0
      let promoCoinApplied = 0
      let display = []

      // Apply promo coins first
      this.state.supperclubBalances.forEach((coin) => {
        if (
          remainingBalance > 0 &&
          coin.balance > 0 &&
          coin.is_promo_coin === true
        ) {
          let coinApplied = 0
          if (coin.balance > remainingBalance) {
            coinApplied = remainingBalance
          } else {
            coinApplied = coin.balance
          }
          remainingBalance = remainingBalance - coinApplied
          // Check for existing rollup coin
          let rollupCoin = display.find((c) => c.rollup == true)
          if (rollupCoin && coin.rollup == true) {
            rollupCoin.amount += coinApplied
          } else {
            display.push({
              name: coin.name,
              amount: coinApplied,
              image: coin.image,
              symbol: coin.symbol,
              rollup: coin.rollup,
            })
          }
          promos.push({
            key: coin.symbol,
            mint: coin.mint,
            name: coin.name,
            desc: "Supper Club Wallet Coin",
            amount: coinApplied,
            balance: coin.balance,
            logo: coin.image,
            is_promo_coin: coin.is_promo_coin,
            rollup: coin.rollup,
          })
          incentives.push({
            name: "supperclubPromo",
            id: coin.mint,
            type: "discount",
            amount: coinApplied,
            desc: coin.name,
            taxable: false,
          })
          promoCoinApplied += coinApplied
        }
      })

      incentives = this.state.incentives
        .filter((inc) => inc.name != "supperclubPromo")
        .concat(incentives)

      this.state.incentives = incentives

      let walletState = {
        supperclubPromos: promos,
        supperclubPromoApplied: promoCoinApplied,
        supperclubDisplay: display,
      }

      this.setState(walletState)

      return walletState
    }
    return {}
  }

  calculateSupperClubPayment() {
    if (this.state.supperclubWallet && this.state.supperclubBalances) {
      let remainingBalance = this.state.finance.total
      let display = this.state.supperclubDisplay
      let payments = []
      let supperclubApplied = 0
      let promoCoinApplied = 0

      // Loop through available balances and apply to total in order
      this.state.supperclubBalances.forEach((coin) => {
        if (
          remainingBalance > 0 &&
          coin.balance > 0 &&
          coin.is_promo_coin !== true
        ) {
          let coinApplied = 0
          if (coin.balance > remainingBalance) {
            coinApplied = remainingBalance
          } else {
            coinApplied = coin.balance
          }
          remainingBalance = remainingBalance - coinApplied
          // Check for existing rollup coin
          let rollupCoin = display.find((c) => c.rollup == true)
          if (rollupCoin && coin.rollup == true) {
            rollupCoin.amount += coinApplied
          } else {
            display.push({
              name: coin.name,
              amount: coinApplied,
              image: coin.image,
              symbol: coin.symbol,
              rollup: coin.rollup,
            })
          }
          payments.push({
            key: coin.symbol,
            mint: coin.mint,
            name: coin.name,
            desc: "Supper Club Wallet Coin",
            amount: coinApplied,
            balance: coin.balance,
            logo: coin.image,
            is_promo_coin: coin.is_promo_coin,
          })
          supperclubApplied += coinApplied
        }
      })

      let walletState = {
        supperclubPayments: payments,
        remainingBalance: remainingBalance,
        supperclubApplied: supperclubApplied,
        supperclubDisplay: display,
      }

      this.setState(walletState)

      return walletState
    }
    return {}
  }

  loginUser(user) {
    this.props.analytics.info("LoginSuccess")
    this.setState({ user: user }, () => {
      this.saveUser()
      setTimeout(() => {
        if (window.coupon_loaded) {
          this.invokeAsyncCallToVerify()
        }
      }, 1500)
    })
  }

  /*
    item has cart_modifier_ids listing all the items that were edited
    - we want to remove all those items from state.items, then add in the new ones
    - we want to add in the new ones at the same index where the first one was removed
  */
  editItem(item, origItem, qty) {
    item["edited_at"] = Date.now()
    // get the index where we will end up adding in the new items
    let index
    let cart_item_ids = origItem.cart_item_ids
    for (var i in this.state.items) {
      if (cart_item_ids.indexOf(this.state.items[i].cart_item_id) > -1) {
        index = i
        break
      }
    }

    //remove all the to-be-updated items (they really just get recreated)
    let indexesToRemove = []
    for (var i in this.state.items) {
      if (cart_item_ids.indexOf(this.state.items[i].cart_item_id) > -1) {
        indexesToRemove.push(i)
      }
    }
    let cart_items = this.state.items
    for (var k = indexesToRemove.length - 1; k >= 0; k--) {
      cart_items.splice(indexesToRemove[k], 1)
    }

    //create new items at the index
    let new_items = []
    for (var i = 0; i < qty; i++) {
      new_items.push(item)
    }
    //splice these into the cart at the specificed indx
    cart_items.splice.apply(cart_items, [index, 0].concat(new_items))
    this.setState({ items: cart_items, is_reorder: false }, () => {
      this.syncStorage()
      this.recalculateFinance(true)
    })
  }

  /* addToCart - add items to cart, if QTY is set add item multiple times */
  addToCart(item, qty) {
    item["added_at"] = Date.now()
    let items = this.state.items
    if (!this.state.createdAt && items.length === 0) {
      this.setCreatedAt()
    }
    for (var i = 0; i < qty; i++) {
      let itemCopy = JSON.parse(JSON.stringify(item))
      itemCopy.cart_item_id = item.cart_item_id + i
      items.push(itemCopy)
    }
    this.setState({ items: items }, () => {
      this.syncStorage()
      this.recalculateFinance(true)
    })
    this.setUpdatedAt()
  }

  setUpdatedAt() {
    this.setState({ updatedAt: Date.now() }, () => {
      this.saveUpdatedAt()
    })
    var itemsContainer = document.getElementsByClassName("CartItemsWrapper")[0]
    if (itemsContainer) itemsContainer.scrollTop = itemsContainer.scrollHeight
  }

  async saveUpdatedAt() {
    await localforage
      .setItem(this.getLocalForageKeyUpdatedAt(), this.state.updatedAt)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  //this is called when there is an UNEXPECTED (i.e. 500) error to the /verify
  //endpoint
  verifyFail(err) {
    console.log(err)
  }

  // method to reset the tip to an unselected state. This is called when the
  // custom tip input box is cleared out and we want the TipSelectionRow
  // to reset the default tip amount
  resetTip() {
    var finance = this.state.finance
    finance["tip"] = 0
    finance["tipSelected"] = false
    this.setState({ finance: finance }, () => this.recalculateFinance())
  }

  /* setTip - method to update the finance object on the
     cart to set the tip. also invokes call to storage to save value*/
  setTip(tipAmount) {
    var finance = this.state.finance
    finance["tip"] = tipAmount
    finance["tipSelected"] = true
    this.setState({ finance: finance }, () => this.recalculateFinance())
  }

  /* updateCoupon */
  // called after verify
  updateCoupon(data) {
    console.log("update coupon", data)
    //let recheckCoupon = false;

    if (data.coupon_fail_reason) {
      this.setState({
        couponFailReason: data.coupon_fail_reason,
      })
    }

    //let finance = this.state.finance
    //finance["promo"] = data.promo_amount
    //this.setState({ finance: finance, coupon: data.coupon }, () => {
    let incentives = this.state.incentives
    if (data.coupon) {
      let amount = data.promo_amount
      if (data.coupon.coupon.action == "free_delivery") {
        amount = 0
      }
      incentives = this.addIncentive({
        name: `coupon`,
        id: data.coupon.coupon_id,
        type: "discount",
        amount: amount,
        desc: `Keycode: ${data.coupon.keycode.toUpperCase()}`,
        taxable: false,
      })
    }
    this.setState(
      {
        coupon: data.coupon,
        incentives: incentives,
      },
      () => {
        this.recalculateFinance()
        this.saveCoupon()
        this.saveIncentives()
        // if there is a keycode that isnt valid yet, then attempt to
        // to see if it is valid now. We do  this because we may of
        // just added an it em to the cart which triggers verify
        // which will trigger this code
        if (this.state.couponFailReason) {
          let dinerUUID = this.state.user ? this.state.user.uniq_uuid : null
          this.inputCoupon(this.state.couponKeycode, null, dinerUUID, true)
        }
      }
    )
  }

  /* invokeAsyncCallToVerify - invokes a call to the VERIFY api
     which should be a completely async api. pass the cart in
     its current context and output to console log for debugging */
  invokeAsyncCallToVerify() {
    let dinerUUID = this.props.user.user ? this.props.user.user.diner_id : null
    let verifyPayLoad = {
      items: this.state.items,
      address: this.state.initial_address,
      diner_id: dinerUUID,
      finance: this.state.finance,
      coupon: this.state.coupon,
      pickup_or_delivery: this.state.pickup_or_delivery,
      rest_id: this.state.rest_id,
      fulfill_order_at: this.state.futureOrderTime,
    }
    console.log("calling verify with payload", verifyPayLoad)
    api.callApi(
      "verify",
      this.updateCoupon.bind(this),
      this.verifyFail.bind(this),
      verifyPayLoad
    )
  }

  detectWallets() {
    return []
    let wallets = []
    if (window.solana && window.solana.isPhantom) {
      wallets.push("phantom")
    }

    if (window.solana && !window.solana.isPhantom) {
      if (window.solana.isSolflare) {
        wallets.push("solflare")
      } else {
        wallets.push("window.solana")
      }
    }

    if (window.solflare) {
      wallets.push("solflare")
    }

    if (window.ethereum) {
      if (window.ethereum.isMetaMask) {
        wallets.push("metamask")
      } else {
        wallets.push("window.ethereum")
      }
    }
    return wallets
  }

  enoughUSDC() {
    if (!this.state.usdcBalance) {
      return false
    }
    let bal = parseFloat(this.state.usdcBalance)
    bal = bal * 100
    console.log("usdc bal", bal)
    return bal >= this.state.finance.total
  }

  enoughFoodcoin() {
    if (!this.state.foodcoinBalance) {
      return false
    }
    let bal = parseFloat(this.state.foodcoinBalance)
    bal = bal * 100
    return bal >= this.state.finance.total
  }

  enoughFoodcoinAndUSDC() {
    if (!this.state.usdcBalance) return false
    if (!this.state.foodcoinBalance) return false
    let foodcoin = this.state.foodcoinBalance
    let usdc = this.state.usdcBalance
    let bal = (foodcoin + usdc) * 100
    return bal >= this.state.finance.total
  }

  enoughFoodcoinAndSol(solPrice) {
    if (!solPrice) {
      return false
    }
    if (!this.state.solBalance) {
      return false
    }
    if (!this.state.foodcoinBalance) {
      return false
    }
    let foodcoin = this.state.foodcoinBalance * 100
    let sol = parseFloat(this.state.solBalance)
    // convert from SOL to dollars
    let cents = sol * solPrice
    let bal = foodcoin + cents
    return bal >= this.state.finance.total
  }

  enoughSol(solPrice) {
    if (!solPrice) return null
    if (!this.state.solBalance) return false
    let sol = parseFloat(this.state.solBalance)
    // convert from SOL to dollars
    let cents = sol * solPrice
    return cents >= this.state.finance.total
  }

  cartObject() {
    // fudge the finance array for free delivery
    if (
      this.state.coupon &&
      this.state.coupon.coupon &&
      this.state.coupon.coupon.action.indexOf("free_delivery") > -1 &&
      this.state.pickup_or_delivery === "delivery"
    ) {
      this.state.finance["promo_without_free_delivery"] =
        this.state.finance.promo - this.state.finance.deliveryFee
      this.state.finance["free_delivery"] = true
    }

    let obj = {
      items: this.state.items,
      card: this.state.savedCard,
      solana: this.state.sol,
      solana_tokens: this.state.solanaTokens,
      solana_txn: this.state.solanaTxn,
      sol_price_uuid: window.sol_price_uuid,
      cash: this.state.cash,
      bitcoin: this.state.bitcoin,
      save_card: this.state.saveCard,
      cardDetails: this.state.cardDetails,
      coupon: this.state.coupon,
      special_instructions: this.state.specialInstructions,
      stripe_token: this.state.stripe_token,
      subtotal: this.state.subtotal(),
      finance: this.state.finance,
      address: this.state.confirmed_address,
      browser: this.state.browser,
      utms: this.state.utms,
      ham_id: this.props.analytics.hamId(),
      app_version: this.state.version,
      clickstream_session_id: this.state.sessionId,
      pickup_or_delivery: this.props.rest.isLocked()
        ? "dine in"
        : this.state.pickup_or_delivery,
      diner_id: this.props.user.user ? this.props.user.user.diner_id : null,
      rest_id: this.state.rest_id,
      fulfill_order_at: this.state.futureOrderTime,
      is_reorder: this.state.is_reorder,
      custom_payment: this.state.customPayment,
      custom_payment_name: this.state.customPaymentName,
      custom_payment_number: this.state.customPaymentNumber,
      utensils: this.state.utensils,
      split_tip: this.state.splitTip,
      cart_created_at: this.state.createdAt,
      crypto_wallets_detected: this.detectWallets(),
      supperclub_applied: this.state.supperclubApplied,
      supperclub_promo_applied: this.state.supperclubPromoApplied,
      supperclub_payments: this.state.supperclubPayments,
      supperclub_promos: this.state.supperclubPromos,
      remaining_balance: this.state.remainingBalance,
      incentives: this.state.incentives,
      tax_exempt: this.state.taxExempt,
      kiosk_diner: this.state.kioskDinerDetails,
      kiosk_payment_intent_id: this.state.kioskPaymentIntentId,
    }

    if (this.state.tableNumber) {
      obj.special_instructions =
        this.state.tableNumber + "-" + obj.special_instructions
    }

    if (this.state.wallet) {
      obj.wallet = this.state.wallet.toString()
    }

    return obj
  }

  cartObjectParsed() {
    return new Cart(this.cartObject())
  }

  /* invokeOrderPlacement - invokes the order placement call
     and invokes the CALLBACK function upon success */
  invokeOrderPlacement(
    dinerUUID,
    onSuccessCallback,
    onErrorCallback,
    fromRetry
  ) {
    if (!this.state.pickup_or_delivery) {
      Sentry.configureScope((scope) => {
        Sentry.captureMessage("Pickup or delivery not set at checkout.")
      })
      this.props.analytics.info("TriedToPlaceOrderWithoutPickupOrDeliverySet")
      alert("Please choose pickup or delivery for your order")
      this.props.history.replace({
        pathname: "/menu/start",
      })
      return
    }

    if (this.state.finance.total === 0 && !fromRetry) {
      Sentry.configureScope((scope) => {
        scope.setExtra("taxrate", this.state.taxRate)
        scope.setExtra("clickstream-session-id", window.CLICKSTREAM_SESSION_ID)
        Sentry.captureMessage("Finance not set at checkout. No big deal.")
      })
      this.recalculateFinance(false, () => {
        this.invokeOrderPlacement(
          dinerUUID,
          onSuccessCallback,
          onErrorCallback,
          true
        )
      })
      return
    }
    var orderPayLoad = this.cartObject()
    this.props.analytics.info(
      "PlacingOrderWithCart",
      JSON.stringify(orderPayLoad)
    )
    api.callApi("placement", onSuccessCallback, onErrorCallback, orderPayLoad)
  }

  itemCount() {
    return this.state.items.length()
  }

  calculateTipFromPercent(percent) {
    return parseInt(this.state.producttotal() * percent, 10)
  }

  calculateSubTotal() {
    let prodtotal = this.calculateProductTotal()
    /*
      This IF statement was added by scott on 6/25/2019 while working on
      the updateCheckerPage branch. It was to solve a NaN in the cart.
      This bug does not exist on master, so this is a mystery and may
      be hiding a real bug somewhere else.
    */
    if (this.promoAmount()) {
      return prodtotal - this.promoAmount()
    } else {
      return prodtotal
    }
  }

  calculateProductTotal() {
    var prodtotal = 0
    for (var i in this.state.items) {
      var item = this.state.items[i]
      if (item.price) {
        prodtotal = prodtotal + item.price
      }
    }
    return Math.round(prodtotal)
  }

  isTaxFree() {
    if (this.state.customPayment) return true
    return false
  }

  calculateTax() {
    if (this.state.taxExempt) return 0
    let old_cart = false
    for (var i in this.state.items) {
      var item = this.state.items[i]
      if (!item.tax && item.tax !== 0) {
        old_cart = true
      }
    }
    if (old_cart) return this.calculateTaxOld()
    else return this.calculateTaxNew()
  }

  calculateTaxOld() {
    this.props.analytics.info("CalculateTaxOld")
    if (this.isTaxFree()) return 0
    let taxableSubtotal = 0
    for (var i in this.state.items) {
      var item = this.state.items[i]
      if (item.price && item.is_taxable !== false) {
        taxableSubtotal += item.price
      }
    }
    taxableSubtotal = Math.round(taxableSubtotal)
    if (this.state.finance.promo) {
      taxableSubtotal = taxableSubtotal - this.state.finance.promo
    }
    if (!this.state.taxRate) {
      Sentry.configureScope((scope) => {
        scope.setExtra("taxrate", this.state.taxRate)
        scope.setExtra("clickstream-session-id", window.CLICKSTREAM_SESSION_ID)
        Sentry.captureMessage("Invalid Tax Rate")
      })
    }

    let tax = Math.round(taxableSubtotal * this.state.taxRate)
    return tax
  }

  calculateTaxNew() {
    this.props.analytics.info("CalculateTaxNew")
    if (this.isTaxFree()) return 0
    let tax = 0
    for (var i in this.state.items) {
      var item = this.state.items[i]
      if (item.tax) {
        tax += item.tax
      }
    }
    let promo_amount = 0
    if (this.state.finance.promo) {
      promo_amount = this.promoAmount() * this.state.taxRate
    }
    let delivery_amount = 0
    if (this.props.rest.taxDeliveryFees && this.state.finance.deliveryFees) {
      delivery_amount = this.state.finance.deliveryFees * this.state.taxRate
    }
    return Math.max(
      Math.round(tax - promo_amount + delivery_amount + 0.0001),
      0
    )
  }

  // item is a quantity item
  removeItem(item) {
    // loop through all the items and find the one
    // with the matching id and remove it
    let items = this.state.items
    let indexesToRemove = []
    for (var i in items) {
      for (let j in item.cart_item_ids) {
        if (item.cart_item_ids[j] === items[i].cart_item_id) {
          indexesToRemove.push(i)
        }
      }
    }

    // we need to make indexesToREmove unique because at this point it
    // contains duplicates due to the nested for loops.
    // https://github.com/blpt-hngr/foodcoin/issues/3262
    let no_dupes = []
    for (var i in indexesToRemove) {
      if (no_dupes.indexOf(indexesToRemove[i]) === -1) {
        no_dupes.push(indexesToRemove[i])
      }
    }
    indexesToRemove = no_dupes

    for (var k = indexesToRemove.length - 1; k >= 0; k--) {
      items.splice(indexesToRemove[k], 1)
    }
    this.setState({ items: items, is_reorder: false }, () => {
      this.syncStorage()
      this.recalculateFinance(true)
    })
  }

  removeCoupon() {
    this.props.analytics.info("RemoveCoupon")
    let coupon_id = this.state.coupon.coupon_id
    let incentives = this.removeIncentive({
      name: "coupon",
      id: coupon_id,
    })
    this.setState(
      {
        coupon: null,
        couponKeycode: "",
        couponFailReason: "",
        incentives: incentives,
      },
      (coupon_id) => {
        this.saveCoupon()
        this.saveIncentives()
        this.invokeAsyncCallToVerify()
      }
    )
  }

  //is the restaurant still accepting orders?
  //takes a callback which will be passed info on wether or not the rest or deliv zone
  //is still accepting orders
  stillAcceptingOrders(callback) {
    var params = {}
    if (this.state.pickup_or_delivery === "delivery") {
      params["delivery_zone_id"] = this.state.delivery_zone.id
    } else {
      params["rest_id"] = this.state.rest_id
    }
    api.callApi(
      "accepting_orders",
      callback,
      () => {
        this.props.updateError(true, "Unknown error", true, {
          zIndex: "1000",
          position: "fixed",
          top: "0",
          width: "99vw",
        })
      },
      params
    )
  }

  updateCheckoutState(state) {
    this.setState({ checkoutReady: state })
  }

  updateAddressError(addressNotSelected) {
    this.setState({ addressNotSelected })
  }

  updateCartAfterCoupon(response, openItem) {
    if (response.item && openItem) {
      let menu_item_id = response.item.menu_item_id
      console.log("menu item id", menu_item_id)
      console.log("menu items", this.props.rest.menu.menu_items)
      let item = this.props.rest.menu.menu_items[menu_item_id]
      let path = `/menu/${menu_item_id}`

      // if a menu_item is currently open, we want to replace the current path in history
      let menuItemCurrentlyOpen =
        this.props.location.pathname.indexOf("category") + "category".length ===
        this.props.location.pathname.length
      if (
        menuItemCurrentlyOpen &&
        this.props.location.pathname.indexOf("category") !== -1
      ) {
        this.props.analytics.info("AutomaticallyPoppingUpCouponItem", item.name)
        this.props.history.replace({
          pathname: path,
          state: {
            item: item,
            modifier_id: response.item.modifier_id,
            isCoupon: true,
            closeCart: true,
          },
        })
      } else {
        this.props.analytics.info("AutomaticallyPoppingUpCouponItem", item.name)
        this.props.history.push({
          pathname: path,
          state: {
            item: item,
            modifier_id: response.item.modifier_id,
            isCoupon: true,
            closeCart: true,
          },
        })
      }
    }

    //this.setState({couponFailReason:null, couponFailKeycode:null})
    if (response.promo_amount) {
      this.props.analytics.info("CouponSuccess", response.promo_amount)
      //let finance = this.state.finance
      //finance["promo"] = response.promo_amount

      if (response.promo_amount) {
        this.setState({ couponFailReason: null })
      }

      let amount = response.promo_amount
      if (response.coupon.coupon.action == "free_delivery") {
        amount = 0
      }

      this.setState(
        {
          coupon: response.coupon,
          couponCartConditionItem: response.cart_condition_item,
          incentives: this.addIncentive({
            name: `coupon`,
            id: response.coupon.coupon_id,
            type: "discount",
            amount: amount,
            desc: `Keycode: ${response.coupon.keycode.toUpperCase()}`,
            taxable: false,
          }),
        },
        () => {
          this.saveCoupon()
          this.recalculateFinance()
        }
      )
    } else {
      if (response.reason === "Must sign up for rewards") {
        this.closeCart = true
        let path = this.props.location.pathname.split("/")
        this.props.history.push({
          pathname: path[0] + path[1] + "/rewards",
          state: { closeCart: true },
        })
      }
      this.setState({
        couponFailReason: response.reason,
        couponCartConditionItem: response.cart_condition_item,
        couponItem: response.item,
        coupon: null,
        forceOpenCart: response.item ? true : false,
      })
    }

    if (this.inputCouponCallback) {
      this.inputCouponCallback(response)
    }
  }

  inputCoupon(keycode, callback, dinerUUID, calledFromVerify, openItemModal) {
    this.setState({ couponKeycode: keycode })
    let cart = {
      items: this.state.items,
      subtotal: this.state.subtotal(),
      finance: this.state.finance,
      address: this.state.confirmed_address,
      pickup_or_delivery: this.state.pickup_or_delivery,
      rest_id: this.state.rest_id,
      diner_id: this.props.user.user ? this.props.user.user.uniq_uuid : null,
      fulfill_order_at: this.state.futureOrderTime,
    }
    let payload = {
      cart: cart,
      keycode: keycode,
      diner_id: this.props.user.user ? this.props.user.user.uniq_uuid : null,
    }

    this.inputCouponCallback = callback
    api.callApi(
      "coupon_input",
      this.updateCartAfterCoupon.bind(this),
      (resp) =>
        this.props.updateError(true, "Error validating coupon", true, {
          zIndex: "1000",
          position: "fixed",
          top: "0",
          width: "99vw",
        }),
      payload,
      null,
      null,
      openItemModal
    )
  }

  setReorderItems(items) {
    this.setState({ items: items, is_reorder: true }, () => {
      this.syncStorage()
      this.recalculateFinance(true)
    })
  }

  setCreatedAt() {
    api.callApi(
      "time",
      (data) => {
        this.setState({ createdAt: data.time }, () => this.saveCreatedAt())
      },
      () => {}
    )
  }

  setSolanaTxn(txn) {
    this.setState({ solanaTxn: txn }, () => {
      this.saveSolanaTxn()
    })
  }

  // this hacky function subtracts the delivery total from the promo
  promoAmount() {
    if (this.state.supperclubWallet && this.state.finance.promo) {
      return this.state.finance.promo
    }
    if (!this.state.coupon) return null
    if (!this.state.finance.promo) return null

    if (this.state.coupon.coupon.action.indexOf("free_delivery") > -1) {
      return 0
    } else {
      return this.state.finance.promo
    }
  }

  deliveryFeeAfterPromo() {
    if (!this.state.finance.deliveryFee) return 0
    if (
      this.state.finance.promo &&
      this.state.coupon &&
      this.state.coupon.coupon.action.indexOf("free_delivery") > -1
    ) {
      return 0
    }
    return this.state.finance.deliveryFee
  }

  async checkForOwner(publicKey) {
    console.log("check for owner....")
    let connection
    let accounts
    let success = false
    try {
      connection = new solanaWeb3.Connection(
        "https://shy-icy-water.solana-mainnet.quiknode.pro/b2e2d1cb7ef4ff5db6dd353035e14739a5904ffb/",
        "confirmed"
      )
      // see if they have any matching NFTs
      accounts = await connection.getParsedProgramAccounts(
        new solanaWeb3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
        {
          filters: [
            {
              dataSize: 165, // number of bytes
            },
            {
              memcmp: {
                offset: 32, // number of bytes
                bytes: publicKey.toString(), //MY_WALLET_ADDRESS, // base58 encoded string
              },
            },
          ],
        }
      )
      success = true
    } catch (err) {
      console.log("EXCEPTIOPN ERROR", err)
      connection = new solanaWeb3.Connection(
        "https://nd-135-906-691.p2pify.com/58f40b25ff76a90476de9023bd163e25",
        "confirmed"
      )
      // see if they have any matching NFTs
      accounts = await connection.getParsedProgramAccounts(
        new solanaWeb3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
        {
          filters: [
            {
              dataSize: 165, // number of bytes
            },
            {
              memcmp: {
                offset: 32, // number of bytes
                bytes: publicKey.toString(), //MY_WALLET_ADDRESS, // base58 encoded string
              },
            },
          ],
        }
      )
      success = true
    } finally {
      if (!success) {
        setTimeout(() => this.checkForOwner(publicKey), 3000)
        return
      }
      if (!accounts || !accounts.length) {
        setTimeout(() => this.checkForOwner(publicKey), 10000)
        return
      }
    }

    let mints = []
    for (let i in accounts) {
      let amt =
        accounts[i].account.data["parsed"]["info"]["tokenAmount"]["uiAmount"]
      if (amt === 1) {
        mints.push(accounts[i].account.data["parsed"]["info"]["mint"])
      }
    }

    let rest_subdomain = subdomain.getSubdomain()
    let payload = {
      mints: mints,
      subdomain: rest_subdomain,
    }

    console.log("payload", payload)

    // hit up the API to see if we have any matching ones
    api.callApi(
      "check-for-nft",
      (resp) => {
        if (resp.owner) {
          window.scrollTo(0, 0)

          api.callApi(
            "post-to-nft-slack",
            (resp) => {
              console.log(resp)
            },
            (error) => {
              console.log("error posting top slack", error)
            },
            {
              msg:
                "[ordering site - someone connected wallet and fount nft] - " +
                publicKey.toString() +
                " - " +
                window.CLICKSTREAM_SESSION_ID,
            }
          )

          this.setState(
            {
              nftAlreadyRedeemed: resp.redeemed,
              nftOwner: true,
              nftName: resp.name,
              nftNumber: resp.number,
              nftAddress: resp.nft_address,
            },
            () => {
              if (this.state.coupon && !this.state.coupon.is_nft_coupon) {
                this.removeCoupon()
              }
            }
          )
        }
      },
      (err) => {
        console.log("error checking nft ownership", err)
      },
      payload
    )
  }

  async onWalletConnected(publicKey) {
    let rest_subdomain = subdomain.getSubdomain()
    this.checkForOwner(publicKey)

    var connection = new solanaWeb3.Connection(
      process.env.REACT_APP_SOLANA_RPC_ENDPOINT,
      "confirmed"
    )
    let account = await connection.getAccountInfo(publicKey)
    let lamports = 0
    let sol = 0
    if (account) {
      lamports = account.lamports
      sol = lamports / 1000000000
    }

    // get USDC balance...... to get this we need to get the
    let associatedDestinationTokenAddr =
      await splToken.getAssociatedTokenAddress(
        new PublicKey(USDC_ADDRESS),
        publicKey,
        false,
        new PublicKey(TOKEN_PROGRAM_ID),
        new PublicKey(ASSOCIATED_TOKEN_PROGRAM_ID)
      )

    let usdcBalance = 0
    try {
      let usdcAccount = await connection.getTokenAccountBalance(
        associatedDestinationTokenAddr
      )
      if (usdcAccount && usdcAccount.value) {
        usdcBalance = usdcAccount.value.uiAmount
      }
    } catch (err) {
      console.log("error geting usdc balance", err)
    }

    let foodcoinBalance = 0

    let associatedDestinationFoodcoinTokenAddr =
      await splToken.getAssociatedTokenAddress(
        new PublicKey(FOODCOIN_ADDRESS),
        publicKey,
        false,
        new PublicKey(TOKEN_PROGRAM_ID),
        new PublicKey(ASSOCIATED_TOKEN_PROGRAM_ID)
      )

    try {
      let foodcoinAccount = await connection.getTokenAccountBalance(
        associatedDestinationFoodcoinTokenAddr
      )
      if (foodcoinAccount && foodcoinAccount.value) {
        foodcoinBalance = foodcoinAccount.value.uiAmount
      }
    } catch (err) {
      console.log("error geting foodcoin balance", err)
    }

    this.setState({
      solBalance: sol,
      usdcBalance: usdcBalance,
      foodcoinBalance: foodcoinBalance,
      walletConnected: true,
      wallet: publicKey,
    })
  }

  async setSupperclubWallet(keypair) {
    this.setState({
      supperclubWallet: {
        keypair: keypair,
      },
    })
  }

  async getTokenMeta(coinAddress) {
    let connection = new Connection(window.RPC)
    console.log("Metaplex-GTM", Metaplex)

    return "hi"
  }

  async getBalance(coin, keypair) {
    let connection = new Connection(window.RPC)

    console.log("coin:", coin)
    let balance = 0

    let assocTokenAddress = await splToken.getAssociatedTokenAddress(
      new PublicKey(coin.mint),
      keypair.publicKey,
      false,
      new PublicKey(TOKEN_PROGRAM_ID),
      new PublicKey(ASSOCIATED_TOKEN_PROGRAM_ID)
    )
    console.log("Associated Address", assocTokenAddress)
    try {
      let account = await connection.getTokenAccountBalance(assocTokenAddress)
      balance = account.value.amount
      console.log("Account Balance", account.value.amount)
    } catch (err) {
      console.log("error geting foodcoin balance", err)
    }

    return balance
  }

  async getCoinBalances() {
    if (this.state.supperclubWallet) {
      let connection = new Connection(window.RPC)
      let keypair = this.state.supperclubWallet.keypair
      let balances = []

      // Loop through accepted coins to get balances & meta
      let balance, coin
      for (var i = 0; i < this.state.acceptedCoins.length; i++) {
        coin = this.state.acceptedCoins[i]
        balance = await this.getBalance(coin, keypair)

        balances.push({
          mint: coin.mint,
          balance: parseInt(balance),
          is_promo_coin: coin.is_promo_coin,
          name: coin.name,
          image: coin.image,
          symbol: coin.symbol,
          rollup: coin.rollup,
        })
      }

      this.setState(
        {
          supperclubBalances: balances,
        },
        this.recalculateFinance
      )
      console.log("balances", balances)
    }
  }

  async setKioskDinerDetails(details) {
    let dinerDetails = Object.assign({}, this.state.kioskDinerDetails, details)

    this.setState({
      kioskDinerDetails: dinerDetails,
    })
  }

  checkKioskTimeout() {
    if (window.KIOSK_MODE && this.lastUpdated) {
      if (this.state.items.length > 0) {
        if (Date.now() - this.lastUpdated > KIOSK_CART_TIMEOUT) {
          let currentURL = window.location.pathname

          this.props.history.push({
            pathname: currentURL + "/abandoned",
          })
        }
      } else {
        this.lastUpdated = Date.now()
      }
    }
  }

  render() {
    return (
      <CartContext.Provider value={this.state}>
        {this.props.children}
      </CartContext.Provider>
    )
  }
}

export default withErrorHandling(
  withRouter(
    SignupModalContextHOC(
      RestContextHOC(UserContextHOC(AnalyticsContextHOC(CartProvider)))
    )
  )
)
