import React from "react"
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC"
import withErrorHandling from "../../Contexts/withErrorHandling/withErrorHandling"
import "../../css/MenuItem.css"
import { withRouter } from "react-router-dom"
import greybox from "./greybox.png"

const CDN = "https://cdn.hngr.co"
const IMGCDN = "https://imgcdn.hngr.co"

class MenuItemImg extends React.Component {
  /* when mounting the menu image first render a grey box that is 
     whatever size we want it to be. then we will replace the image
     once it is mounted and we know what size image to look for 
     some notes on this task are here: 
      https://github.com/blpt-hngr/foodcoin/issues/1180 */
  constructor(props) {
    super(props)
    this.state = {
      image_url: props.image_url,
      ready_image_url: greybox,
      name: props.name,
      ref: React.createRef(),
      className: props.className,
      isUpdating: false,
      height: null,
      width: null,
    }
    this.shouldUpdateSize = this.shouldUpdateSize.bind(this)
  }

  /* shouldUpdateSize - look at the current size and if the
     new size is different than what is set, then change the image,
     note that if the device has a pixel depth > 1 then we need
     to request an image that is larger size (ex: iPhone) */
  shouldUpdateSize() {
    if (this.state.isUpdating === true) {
      return
    }

    // fix for cases when the ref doesnt yet exist but it is
    // trying to render the image. doesn't seem like a deal killer
    // see: https://github.com/blpt-hngr/foodcoin/issues/2297
    if (!this.state.ref.current) {
      return
    }
    if (!this.state.ref.current.height) {
      return
    }

    this.setState({ isUpdating: true })
    let pixelDensity = window.devicePixelRatio
    if (!pixelDensity) {
      pixelDensity = 1
    }
    const h = this.state.ref.current.height * pixelDensity
    const w = this.state.ref.current.width * pixelDensity
    if (this.state.width !== w || this.state.height !== h) {
      this.setState({ width: w, height: h })
      const new_image_url = this.state.image_url.replace(CDN, IMGCDN)
      const includes_cdn = this.state.image_url.includes(CDN)
      if (w > 10 && this.state.image_url && includes_cdn) {
        const params = "?size=" + w + "x" + h
        this.setState({
          ready_image_url: new_image_url + params,
          isUpdating: false,
        })
      } else {
        this.setState({
          ready_image_url: this.state.image_url,
          isUpdating: false,
        })
      }
    }
  }

  componentDidMount() {
    this.shouldUpdateSize()
  }
  
  componentDidUpdate() {
    if (!this.state.isUpdating) {
      setTimeout(()=>this.shouldUpdateSize(), 100)
    }
  }
  
  render() {
    return (
      <img
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src='https://cdn.hngr.co/placeholder.png'
          this.props.onError()
        }}
        className={this.state.className}
        style={{...this.props.style}}
        src={this.state.ready_image_url}
        ref={this.state.ref}
        alt={this.state.name}
      />
    )
  }
}

export default withRouter(AnalyticsContextHOC(withErrorHandling(MenuItemImg)))
