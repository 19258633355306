export default async function registerCustomSW() {
  if ("serviceWorker" in navigator) {
    const SW_URL = `${process.env.PUBLIC_URL}/custom-service-worker.js`;
    const registration = await navigator.serviceWorker.register(SW_URL, {
      scope: "/"
    });
    registration.onupdatefound = () => {
      const OLD_SW_URL = `${process.env.PUBLIC_URL}/service-worker.js`;

      // this is to unregister all the old service workers
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(reg => {
          if (reg.active) {
            if (reg.active.scriptURL === OLD_SW_URL) {
              reg.unregister();
            }
          }
        });
      });
    };
  }
}
