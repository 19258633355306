import React, { Component } from "react"
import RestContextHOC from "./Contexts/RestContextHOC"
import CartContextHOC from "./Contexts/CartContextHOC"
import { withRouter } from "react-router-dom"

class MenuByUUID extends Component {
  constructor(props) {
    super(props)
    this.props.history.replace({
      pathname: '/'
    })

    /*
    let menu_uuid = this.props.match.params.menu_uuid
    this.props.rest.setSandboxedMenu(menu_uuid, () => {
      this.props.cart.queueUpEmptyCart()
      this.props.history.replace({
        pathname: "/menu",
        state: { fromId: true },
      })
    })*/

  }

  render() {
    return <div>Loading...</div>
  }
}

export default withRouter(RestContextHOC(CartContextHOC(MenuByUUID)))
