import React from "react"
const formatPrice = require("../../helpers/formatPrice")

const CheckoutBottom = props => {
  if (props.disable_edits) {
    return
  }
  if (!props.cart.minimumIsMet() || (props.cart.finance.subtotal === 0 && !props.cart.finance.promo)) {
    var minimum = 0
    var money_needed = 0

    if (props.cart.delivery_zone) {
      minimum = props.cart.delivery_zone.delivery_minimum
      money_needed = minimum - props.cart.subtotal()
    }

    return (
      <div style={{textAlign:'center'}}>
        {minimum > 0 ? (
          <div style={styles.deliveryMinimum}>
            The delivery minimum is {formatPrice.formatPrice(minimum)}. 
            <br></br>
            Please add {formatPrice.formatPrice(money_needed)} to your order.
          </div>
        ) : (
          <button
            id="noHover"
            className={
              props.rest.use_intercom
                ? "hngrButton CartCheckout"
                : "hngrButton CartCheckout NoIntercom"
            }
          >
            CHECKOUT
          </button>
        )}
      </div>
    )
  } else {
    return (
      <button
        style={{ background: props.rest.secondaryColor() }}
        className={
          props.rest.use_intercom
            ? "hngrButton CartCheckout"
            : "hngrButton CartCheckout NoIntercom"
        }
        onClick={() => props.placeOrder()}
      >
        {formatPrice.formatPrice(props.cart.finance.subtotal)} - CHECKOUT
      </button>
    )
  }
}

var styles = {
  subtotal: {
    margin: "0px 10px",
    float: "right",
    width: "100%"
  },
  delivery: {
    float: "right",
    width: "100%",
    margin: "0px 10px 0px 10px"
  },
  coupon: {
    float: "right",
    width: "100%",
    margin: "10px 10px 0px 10px"
  },
  subtotalText: {
    fontWeight: "700",
    fontSize: "18px",
    color: "black",
    display: "inline-block",
    float: "left",
    marginLeft: "25px"
  },
  deliveryText: {
    fontWeight: "500",
    fontSize: "14pt",
    color: "#ccc",
    display: "inline-block",
    float: "left",
    marginLeft: "25px"
  },
  subtotalValue: {
    fontWeight: "700",
    fontSize: "18px",
    color: "green",
    float: "right"
  },
  couponValue: {
    fontWeight: "400",
    fontSize: "14pt",
    color: "#ea5151",
    float: "right"
  },
  deliveryMinimum: {
    position: "absolute",
    bottom: "16px",
    marginTop: "5px",
    marginRight: '30px',
    marginLeft: '10px',
    fontSize: "14px",
    fontWeight: "500",
    color: "#777",
    textAlign: "center",
    zIndex: "998",
    width: '90%'
  }
}
export default CheckoutBottom
