import React, { Component } from 'react'
import AppPage from '../AppPage'
import Loading from '../Loading/Loading'
import { Link } from 'react-router-dom'
import UserContextHOC from '../Contexts/UserContextHOC'
import RestContextHOC from '../Contexts/RestContextHOC'
import EntryPointHOC from "../Contexts/EntryPointHOC"
import CartContextHOC from '../Contexts/CartContextHOC'
import AnalyticsContextHOC from '../Contexts/AnalyticsContextHOC'
import SignupModalContextHOC from '../Contexts/SignupModalContextHOC'
import PaymentMethod from "../DeprecatedCheckout/PaymentCheckout/PaymentMethod";
import './Account.css'
const api = require('../helpers/api')

class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: this.props.user.user ? this.props.user.user.email : '',
      edit_email: false,
      loading_cards: true,
      cards: []
    }
  }

  componentDidMount(){
    this.loadSavedCards()
    this.props.analytics.pageview('/account')
  }

  componentDidUpdate(prevProps){
    if (this.props.user.user && !prevProps.user.user){
      this.loadSavedCards()
      this.setState({email: this.props.user.user.email})
    }
  }

  invokePasswordChange() {
    if (window.location.href.indexOf('password_reset') === -1){
      let url = this.props.location.pathname.split('/')
      let pathname = url[0] + '/' + url[1] + '/password_reset'
      this.props.history.push({
        pathname: pathname,
        state:{
          ...this.props.location.state
        }
      })
    }
  }

  invokeNameChange() {
    if (window.location.href.indexOf('set_name') === -1){
      let url = this.props.location.pathname.split('/')
      let pathname = url[0] + '/' + url[1] + '/set_name'
      this.props.history.push({
        pathname: pathname,
        state:{
          ...this.props.location.state
        }
      })
    }
  }

  invokePhoneVerifier() {
    if (window.location.href.indexOf('phone_verify') === -1){
      let url = this.props.location.pathname.split('/')
      let pathname = url[0] + '/' + url[1] + '/phone_verify'
      this.props.history.push({
        pathname: pathname,
        state:{
          ...this.props.location.state
        }
      })
    }
  }

  loadSavedCards() {
    if (this.props.user.user) {
      api.callApi(
        "saved_cards",
        res => this.handleLoadCardsSuccess(res),
        err => this.handleLoadCardsFailure(err),
        null,
        null,
        this.props.user.user.uniq_uuid
      );
    }
  }

  deleteSavedCard(card) {
    if (window.confirm(`Are you sure you want to delete your saved card ending in ${card.last4}?`)){
      this.props.analytics.info("DeletedSavedCard",card.last4)
      if (this.props.cart.savedCard && this.props.cart.savedCard.id === card.id) {
        this.props.cart.selectPayment(null);
      }
      this.setState({ loading_cards: true });
      api.callApi(
        "delete_card",
        () => this.loadSavedCards(),
        () => {
          this.setState({ loading_cards: false });
          alert('Failed to delete card')
        },
        {diner_id: this.props.user.user.uniq_uuid,card_id: card.id}
      )
    }
  }

  handleLoadCardsSuccess(res){
    this.setState({cards:res.cards,loading_cards:false})
  }

  handleLoadCardsFailure(err){
    console.log(err)
    this.setState({loading_cards:false})
  }

  renderPhone() {
    if (this.props.user.user.phone_number_e164) {
      return (<div style={{padding: '16px'}}>
        <h3>Your Phone Number</h3>
        <div>
          {this.props.user.user.phone_number_e164}

          <button className='hngrButton'
            style={{background: this.props.rest.hex_color_primary}}
            onClick={()=>this.invokePhoneVerifier()}>
            Change</button>

        </div>
      </div>)
    }
  }

  renderEmail() {
    if (this.state.edit_email){
      return(
        <div className="user-input-wrp">
          <br/>
          <input value={this.props.user.user.email} 
            type="text" className="inputText" required/>
          <span className="floating-label">Your email address</span>
          <button className='hngrButton' 
            style={{background: this.props.rest.hex_color_primary}} 
            onClick={()=>this.setState({edit_email: false})}>
            SAVE</button>
        </div>
      )
    } else {
      return(
        <div style={{padding: '16px'}}>
          <h3>Your email address</h3>
          <div>{this.state.email}</div>
        </div>
      )
    }
  }

  renderName() {
     return (<div style={{padding: '16px'}}>
       <h3>Your Name</h3>
       <div>{this.props.user.user.first_name} {this.props.user.user.last_name}
          <button className='hngrButton'
            style={{background: this.props.rest.hex_color_primary}}
            onClick={()=>this.invokeNameChange()}>
            Change</button>
       </div>
     </div>)
  }

  renderPassword() {

    if (this.props.user.user.auth_method === 'OAUTH') {
      return (
        <div style={{padding: '16px'}}>
          <h3>Your Password</h3>
          You currently login via <a>{this.props.user.user.auth_platform}</a>. To change your password please visit {this.props.user.user.auth_platform}.
        </div>)
    } else {
      return (
      <div style={{padding: '16px'}}>
        <h3>Your password </h3>
        <div style={{display:'inline-block', marginRight: '32px'}}>********</div>
        <button className='hngrButton' 
          style={{background:this.props.rest.hex_color_primary}}
          onClick={()=>this.invokePasswordChange()}>EDIT</button>
      </div>)
    }
  }

  renderSavedCards(){
    let cards = this.state.cards.map(card => (
      <PaymentMethod
        key={card.id}
        card={card}
        brand={card.brand}
        onDelete={e => this.deleteSavedCard(card)}
      />
    ))
    if (cards.length === 0) cards = <div>You have no saved cards.</div>
    return(
      <div style={{padding: '16px'}}>
        <h3>Your Saved Cards</h3>
        {this.state.loading_cards ? <Loading/> : cards}
      </div>
    )
  }

  render() {
    if (!this.props.user.user){
      return <AppPage {...this.props}><Loading/></AppPage>
    }
    return (
      <AppPage {...this.props}>
        <div className='Account'>
          <Link to='/menu'>
            <button className='hngrButton BackToMenuButton' style={{background: this.props.rest.hex_color_secondary}}>
              Back to Menu
            </button>
          </Link>
          <h1 style={{padding:'0px 16px'}}>Account</h1>
          {this.renderName()}
          {this.renderPhone()}
          {this.renderEmail()}
          {this.renderPassword()}
          {this.renderSavedCards()}
        </div>
      </AppPage>
    )
  }
}

export default AnalyticsContextHOC(EntryPointHOC(UserContextHOC(SignupModalContextHOC(RestContextHOC(CartContextHOC(Account))))))
