import React from 'react';
import { useWallet } from '@solana/wallet-adapter-react'

const withWalletHOC = (Component) => {
  return (props) => {
    const {publicKey, signMessage, sendTransaction} = useWallet();
    return (
      <Component
        sendTransaction={sendTransaction} 
        publicKey={publicKey} 
        signMessage={signMessage}
        {...props} 
      />
    )
  };
};

export default withWalletHOC
