import React from "react";
import SelectionFormRadioButtons from "../Modifiers/SelectionFormRadioButtons";
import SelectionFormCheckboxes from "../Modifiers/SelectionFormCheckboxes";
import SelectionFormQuantityInputs from "../sparkles/SelectionFormQuantityInputs/SelectionFormQuantityInputs.jsx";
import SelectionFormHalfToppings from "../Modifiers/SelectionFormHalfToppings";
import RestContextHOC from "../Contexts/RestContextHOC";
import withErrorHandling from "../Contexts/withErrorHandling/withErrorHandling";
import { ItemRequiredTag } from "./ItemRequiredTag";
import styles from "./MenuItemExpanded/MenuItemExpanded.module.scss";
import { v4 as uuidv4 } from 'uuid';
import {Text} from '../atoms'

const formatPrice = require("../helpers/formatPrice");

class MenuItemModifierClass extends React.Component {
  constructor(props) {
    super(props);
    this.removeModifierClasses = this.removeModifierClasses.bind(this)
    this.state = {ready:false}
  }

  componentDidMount(){
    this.initModifiers()
  }

  /**
   * Take the modifiers that are passed into props and add alter them:
   * - fill out missing item data
   * - add uniq uuid
   * - sort them by row order
   */
  initModifiers() {
    // i commented out the line below to fix a bug found by kevin where two-level
    // deep submodifiers weren't being hidden. I'm not sure the motiviation for the
    // deep copy originally......... hopefully I'm not reintroducing an old bug
    // - scott 1/23/2019
    //let modifiers = JSON.parse(JSON.stringify(this.props.modifiers));
    let modifiers = this.props.modifiers;
    for (let i in modifiers) {
      modifiers[i]["uuid"] = uuidv4()
    }
    modifiers = this.addMoreFieldsToModifierObj(modifiers);
    function sort_modifiers(a, b) {
      if (a.row_order < b.row_order) return -1;
      else if (a.row_order > b.row_order) return 1;
      else if (a.created_at < b.created_at) return -1;
      else if (a.created_at > b.created_at) return 1;
      return 0;
    }
    modifiers = modifiers.sort(sort_modifiers);
    // eslint-disable-next-line
    this.setState({modifiers: modifiers, ready:true})
  }


  removeModifierClasses(modifier, dontUpdateState){
    this.props.removeModifierClasses(modifier, false, dontUpdateState)
    let modifiersRemoved = false
    for(var i in modifier.cart_modifier_classes){
      if(modifier.cart_modifier_classes[i].modifiers.length){
        modifier.cart_modifier_classes[i].modifiers.forEach(mod => {
          if(!mod.is_default){
            modifiersRemoved = true
          }
        })
      }
    }

    if(modifiersRemoved){
      this.props.updateError(
        true, 
        'Any customizations on this item have been reset. Please reapply your choices.'
      )
    }

  }

  /**
   * when we're passing around modifiers, the "item" section needs to be
   * filled up from the lookup table in the rest context. This is done so that the
   * backend api doesn't need to duplicate a bunch of data
   */
  addMoreFieldsToModifierObj(modifiers) {
    for (var i in modifiers) {
      var modifier = modifiers[i];
      //if it's an item modifier as opposed to a string
      if (modifier.menu_item_id) {
        modifier["item"] = this.props.rest.menu.menu_items[
          modifier.menu_item_id
        ]
        modifier["name"] = modifier["item"].name
      }
      //if it's a string modifier
      else {
        modifier["name"] = modifier.modifier_value;
      }
      modifier["display_price"] = this.modifierPrice(modifier)
    }
    return modifiers;
  }

  /* helper function which returns modifier name */
  modifierName(modifier) {
    modifier = this.getModifier(modifier);
    if (modifier.item) {
      return modifier.item.name;
    } else {
      return modifier.modifier_value;
    }
  }

  // NOTE: there are special cases down the line, for example the half
  // modifiers have their own set of logic for displaying a half_override
  // price
  modifierPrice(modifier) {
    var priceValue
    if (modifier.price_override) {
      priceValue = modifier.price_override
    } else if (modifier.item && this.props.price_override !== null) {
      priceValue = this.props.price_override
    } else if (modifier.item && this.props.price_override == null) {
      priceValue = modifier.item.price
    } else {
      priceValue = 0.0
    }
    if (priceValue >= 0){
      return "+" + formatPrice.formatPrice(priceValue);
    } else {
      return formatPrice.formatPrice(priceValue);
    }
  }

  modifierDescription(modifier) {
    modifier = this.getModifier(modifier);
    if (modifier.item) {
      return modifier.item.description;
    }
  }

  /**
   * helper function which figures out which type of form elements
   * to show to the user (radio, checkboxes, pizzaform, quantity, etc)
   * and returns the proper component
   */
  getSelectionOption() {
    var maximum_num_selections = this.props.maximum_num_selections;
    var minimum_num_selections = this.props.minimum_num_selections;
    var allow_quantity_input = this.props.allow_quantity_input;
    var allow_half_modifiers = this.props.allow_half_modifiers;

    // this set of props will be passed to whatever SelectionForm type
    // we end up rendering
    let selectionFormProps = {
      initializeCartModifierClasses: this.props.initializeCartModifierClasses,
      parentModifierClassesCart: this.props.parentModifierClassesCart,
      displaySubModifiers: this.props.displaySubModifiers,
      removeModifierClasses: this.removeModifierClasses,
      modifierClass: this.props.modifierClass,
      modifiers: this.state.modifiers,
      couponModifierId: this.props.couponModifierId,
      couponModifierClassId: this.props.couponModifierClassId,
      revalidateCoupon: this.props.revalidateCoupon,
      invalidateCoupon: this.props.invalidateCoupon,
      editModifiers: this.props.editModifiers
    };

    if (minimum_num_selections > 0 && !this.state.isRequired) {
      this.setState({ isRequired: true });
    }

    if (allow_quantity_input) {
      return <SelectionFormQuantityInputs {...selectionFormProps} />;
    } else if (allow_half_modifiers === true) {
      return <SelectionFormHalfToppings {...selectionFormProps} />;
    } else if (
      maximum_num_selections > 1 ||
      !maximum_num_selections ||
      minimum_num_selections === 0
    ) {
      return <SelectionFormCheckboxes {...selectionFormProps} />;
    } else if (this.props.modifierClass.maximum_num_selections === 1) {
      return <SelectionFormRadioButtons {...selectionFormProps} />;
    } else {
      return "TEXT";
    }
  }

  render() {
    if(!this.state.modifiers) return null
    const containerClass = this.state.isRequired ? styles.requiredModifierContainer : styles.modifierContainer
    return (
      <div className={containerClass}>
        <div className={styles.modifier}>
          <Text weight='heavy'>{this.props.display_text.toUpperCase()}</Text>
          {this.state.isRequired ? (
            <ItemRequiredTag 
              quantityInput={this.props.allow_quantity_input}
              numSelectionsNeeded={this.props.minimum_num_selections} 
              numSelected={this.props.parentModifierClassesCart[this.props.modifierClass.modifier_class_id]['modifiers'].length}
            /> 
            ) : (
            <div className={styles.optional}>Optional</div>
         ) }
        </div>
        <div className={styles.options}>{this.getSelectionOption()}</div>
      </div>
    );
  }
}

export default withErrorHandling(RestContextHOC(MenuItemModifierClass))
