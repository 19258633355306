export const Error = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="56" rx="10" fill="#FBB5B3" />
    <g clipPath="url(#clip0_1376_6319)">
      <path
        d="M28.0002 17.5C28.6658 17.5 29.2799 17.8516 29.6174 18.4281L39.7424 35.6781C40.0846 36.2594 40.0846 36.9766 39.7518 37.5578C39.419 38.1391 38.7955 38.5 38.1252 38.5H17.8752C17.2049 38.5 16.5815 38.1391 16.2487 37.5578C15.9158 36.9766 15.9205 36.2547 16.258 35.6781L26.383 18.4281C26.7205 17.8516 27.3346 17.5 28.0002 17.5ZM28.0002 23.5C27.3768 23.5 26.8752 24.0016 26.8752 24.625V29.875C26.8752 30.4984 27.3768 31 28.0002 31C28.6237 31 29.1252 30.4984 29.1252 29.875V24.625C29.1252 24.0016 28.6237 23.5 28.0002 23.5ZM29.5002 34C29.5002 33.6022 29.3422 33.2206 29.0609 32.9393C28.7796 32.658 28.398 32.5 28.0002 32.5C27.6024 32.5 27.2209 32.658 26.9396 32.9393C26.6583 33.2206 26.5002 33.6022 26.5002 34C26.5002 34.3978 26.6583 34.7794 26.9396 35.0607C27.2209 35.342 27.6024 35.5 28.0002 35.5C28.398 35.5 28.7796 35.342 29.0609 35.0607C29.3422 34.7794 29.5002 34.3978 29.5002 34Z"
        fill="#D14848"
      />
    </g>
    <defs>
      <clipPath id="clip0_1376_6319">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(16 16)"
        />
      </clipPath>
    </defs>
  </svg>
)
