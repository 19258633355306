import React from "react";
import "./SelectionFormQuantityInputs.scss";
import QuantityInputModifier from "../../Modifiers/QuantityInputModifier";
import withErrorHandling from "../../Contexts/withErrorHandling/withErrorHandling";

class SelectionFormQuantityInputs extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      total: 0,
      overLimit: false
    };
  }

  // when a checkbox is selected we need to validate it and see if we're over the maximum number
  // of selections
  onChange(modifier, quantity) {
    if (!this.checkForOverMaximum(modifier, quantity)) {
      this.setState({ overLimit: true });
      // return false;
    }
    this.addModifier(modifier, quantity);
    return true;
  }

  // to check if we're over the maximum, we need to see if the new quantity typed in is greater
  // than maximum_num_selections, however we have to remember to not count any of this particular
  // modifier that are already in the list
  checkForOverMaximum(modifier, quantity) {
    var max_selections = this.props.modifierClass.maximum_num_selections;
    // find the number of modifiers in the list that are NOT this specific one
    var total = 0;
    for (var i in this.props.parentModifierClassesCart[
      modifier.modifier_class_id
    ]["modifiers"]) {
      if (
        this.props.parentModifierClassesCart[modifier.modifier_class_id][
          "modifiers"
        ][i].modifier_id !== modifier.modifier_id
      ) {
        total += 1;
      }
    }

    let overLimit = quantity + total > max_selections;
    this.setState({ overLimit });
    this.props.updateError(
      overLimit,
      `Too many items selected! You can only pick up to ${
        this.props.modifierClass.maximum_num_selections
      }.`,
      false,
      {},
      true
    );

    if (quantity + total > max_selections) {
      return false;
    } else {
      return true;
    }
  }

  // to add a modifier, just add it to the parent modifier classes cart list
  addModifier(modifier, quantity) {
    //remove all other instances of this modifier in the list
    //move backwards in the list so splicing doesnt break the loop
    var modifier_classes = this.props.parentModifierClassesCart;
    var idx = modifier_classes[modifier.modifier_class_id]["modifiers"].length;
    while (idx--) {
      if (
        modifier_classes[modifier.modifier_class_id]["modifiers"][idx]
          .modifier_id === modifier.modifier_id
      ) {
        modifier_classes[modifier.modifier_class_id]["modifiers"].splice(
          idx,
          1
        );
      }
    }

    modifier[
      "cart_modifier_classes"
    ] = this.props.initializeCartModifierClasses(modifier.item);
    for (var i = 0; i < quantity; i++) {
      this.props.parentModifierClassesCart[modifier.modifier_class_id][
        "modifiers"
      ].push(modifier);
    }
    this.props.displaySubModifiers(
      modifier,
      this.props.modifierClass,
      this.props.parentModifierClassesCart
    );
    this.setState({
      total: this.props.parentModifierClassesCart[modifier.modifier_class_id]
        .length
    });
  }

  render() {
    const { maximum_num_selections } = this.props.modifierClass;
    return (
      <div>
        {/* {this.state.overLimit && (
          <div className="quantity-inputs__overflow">
            Too many items!
            {maximum_num_selections &&
              `You can only select ${maximum_num_selections}.`}
          </div>
        )} */}
        {this.props.modifiers.map(modifier => (
          <QuantityInputModifier
            modifier={modifier}
            key={modifier.modifier_id}
            modifierId={modifier.modifier_id}
            onChange={this.onChange}
            maxNum={maximum_num_selections}
            overLimit={this.state.overLimit}
            editModifiers={this.props.editModifiers}
          />
        ))}
      </div>
    );
  }
}

export default withErrorHandling(SelectionFormQuantityInputs);
