import React, { Component } from "react"
import ArrowWithCircle from "./ArrowWithCircle"
import classnames from "classnames"
import "./RestaurantCard.css"

const Hours = require("../helpers/hours")

class RestaurantCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isClicked:false
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.isClicked !== this.state.isClicked){
      this.setState({isClicked:this.props.isClicked})
    }
  }

  goToRest(){
    let domain = process.env.REACT_APP_HNGR_DOMAIN
    let subdomain = this.props.rest.subdomain
    let pickupOrDelivery = this.props.pickupOrDelivery
    let url
    if(pickupOrDelivery){
       url = `https://${subdomain}.${domain}?order_type=${pickupOrDelivery}`
    } else {
       url = `https://${subdomain}.${domain}`
    }
    window.location.href = url
  }


  onClick(){
    if(this.props.onClick){
      this.props.onClick()
    }
  }

  isOpenToday(){
    if(this.props.rest.hours && this.props.rest.hours[this.props.pickupOrDelivery]){
      let hours = this.props.rest.hours[this.props.pickupOrDelivery]
      let first_pass = false
      for(let i in hours){
        if(!first_pass){
          if(hours[i].length) return true
          first_pass = true
        }
      }
    }
  }

  render() {
    return (
      <div 
        className={classnames('restaurant-card', {'restaurant-card-highlighted':this.state.isClicked})} 
        onClick={()=>this.onClick()}
      >
        <div className='restaurant-card-title'>
          {this.props.rest.secondary_name}
        </div>
        <div className='restaurant-card-address'>
          {this.props.rest.address1} <br/>{this.props.rest.city}, {this.props.rest.state} {this.props.rest.zipcode}
        </div>
        
        {this.props.page !== 'all-locations' && (
          <div className='restaurant-card-bottom-row'>
            <div className='restaurant-card-hours-container'>
              {this.isOpenToday() === true && (
                <div className='restaurant-card-open-today'>
                  Open Today
                </div>
              )}
              <div className='restaurant-card-hours'>
                {Hours.todaysHoursFromSet(this.props.rest.hours[this.props.pickupOrDelivery], this.props.isBefore4am).map((hours, idx) => (
                  <div key={idx}>
                    {hours}
                  </div>
                ))}
              </div>
            </div>
            
            <div className='restaurant-card-order-button' onClick={()=>this.goToRest()}>
              <div style={{whiteSpace:'nowrap'}}>Order {this.props.pickupOrDelivery}</div> 
            </div>
          </div>
        )}

        {this.props.page === 'all-locations' && (
          <div style={{display:'flex', marginTop:'8px'}}>
            <div className='restaurant-card-order-button' onClick={()=>this.goToRest()}>
              <div>Order Online</div> 
            </div>
          </div>
        )}

      </div>
    )
  }

}

export default RestaurantCard 
