import React from "react"
import { Link } from "react-router-dom"
import RestContextHOC from "./Contexts/RestContextHOC"
import UserContextHOC from "./Contexts/UserContextHOC"
import CartContextHOC from "./Contexts/CartContextHOC"
import AnalyticsContextHOC from "./Contexts/AnalyticsContextHOC"
import SignupModalContextHOC from "./Contexts/SignupModalContextHOC"
import { withRouter } from "react-router-dom"
import "./css/Sidebar.css"
const Subdomain = require("./helpers/subdomain")

class Sidebar extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      exiting: false
    }
  }

  componentDidMount(){
    this.props.analytics.info('SideBarOpened')
  }

  renderProfile() {
    if (this.signedIn()) {
      return (
        <div className='SidebarProfile'>
          <img className='SidebarProfileImg' src='https://cdn.hngr.co/Overstuffed%20Sandwich.png' alt="prof_img" />
          <div className='SidebarProfileName'>{this.props.user.user.first_name} {this.props.user.user.last_name}</div>
        </div>
      );
    } else return null
  }

  logIn() {
    this.props.analytics.info("Login", "Sidebar")
    this.props.analytics.info('SideBarLoginClick')
    this.props.closeSidebar()
    this.props.signupModal.openLogin()
  }

  signUp() {
    this.props.analytics.info('SideBarSignupClick')
    this.props.analytics.info("Signup", "Sidebar")
    this.props.closeSidebar()
    let path = this.props.location.pathname.split()
    this.props.history.push(
      {pathname: path[0] + '/signup', search:window.location.search}
    )
    //this.props.signupModal.openSignup()
  }

  signOut() {
    if (window.confirm('Are you sure you want to sign out?')) {
      this.props.analytics.info("Signout", "Sidebar");
      this.props.cart.emptyCart();
      this.props.user.signOut();
      this.props.history.push({
        pathname: "/"
      });
    }
  }

  openRewards(){
    this.props.analytics.info('SideBarRewardsClick')
    this.props.closeSidebar()
    let path = this.props.location.pathname.split()
    this.props.history.push(
      {pathname: path[0] + '/rewards', search:window.location.search} 
    )
  }

  openContact(){
    this.props.analytics.info('SideBarContactClick')
    this.props.closeSidebar()
    let path = this.props.location.pathname.split()
    this.props.history.push(
      {pathname: path[0] + '/contact', search:window.location.search}
    )
  }

  renderSigninOutLink() {
    if (this.signedIn()) {
      return (
        <>
          <li style={{ cursor: "pointer" }}>
            <Link to='/account'>Account</Link>
          </li>
          <li style={{ cursor: "pointer" }} onClick={() => this.signOut()}>
            Signout
          </li>
        </>
      );
    } else {
      return (
        <span>
          <li style={{ cursor: "pointer" }}>
            <div onClick={() => this.signUp()}>Sign Up</div>
          </li>
          <li style={{ cursor: "pointer" }} onClick={() => this.logIn()}>
            Sign In
          </li>
        </span>
      );
    }
  }

  renderRecentOrders(){
    if (this.signedIn()){
      return(
        <li>
          <Link to="/orders" 
            onClick={() => this.props.analytics.info('SideBarOrderHistoryClick')}
          >Orders</Link>
        </li>
      )
    }
  }

  renderRewards(){
    if (this.signedIn() && this.isRewardsRestaurant()){
      return(
        <li style={{ cursor: "pointer" }} onClick={()=>this.openRewards()}>
            Rewards
          </li>
      )
    }
  }

  isRewardsRestaurant() {
    if (this.props.rest.rewards) {
      return true
    } else{
      return false
    }
  }

  signedIn() {
    if (this.props.user.user) {
      return true
    } else {
      return false
    }
  }

  closeSidebar(){
    this.props.analytics.info('SideBarClose')
    this.setState({exiting:true},()=>{
      window.setTimeout(()=>{
        this.props.closeSidebar()
      },200)
    })
  }

  render() {
    return (
      <div className='SidebarBackground' onClick={()=>this.closeSidebar()}>
        <div className='Sidebar' id={this.state.exiting ? 'SidebarExit' : ''}>
          {this.renderProfile()}
          <img src='/close.png' className='SidebarClose' onClick={()=>this.closeSidebar()} alt='close'/>
          <ul>
            <li>
              <a href={`/menu`}>
                Menu
              </a>
            </li>
            <li style={{ cursor: "pointer" }} onClick={()=>this.openContact()}>
              More Info
            </li>
            {this.renderRewards()}
            {this.renderRecentOrders()}
            {this.renderSigninOutLink()}
          </ul>
          <div className='SidebarVersion'>
            version {this.props.analytics.versionId()}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(
  AnalyticsContextHOC(
    SignupModalContextHOC(
      UserContextHOC(
        CartContextHOC(
          RestContextHOC(
              Sidebar
          )
        )
      )
    )
  )
);
