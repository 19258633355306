import React, { Component } from "react"
import classnames from "classnames"
import GoogleMapsJSLoaderContextHOC from "./Contexts/GoogleMapsJSLoaderContextHOC"
import RestContextHOC from "./Contexts/RestContextHOC"
import extractAddressComponents from "./helpers/googlePlaces"
import AnalyticsContextHOC from "./Contexts/AnalyticsContextHOC"
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete"
import "./css/GooglePlacesAutocomplete.css"
import "./css/CheckerPage.css"

class GooglePlacesAutocomplete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formattedAddress: this.props.formattedAddress
        ? this.props.formattedAddress
        : "",
      address: this.props.address ? this.props.address : {},
    }
    // from checkout
    if(this.props.initialFormattedAddress){
      this.state['formattedAddress'] = this.props.initialFormattedAddress
    }
    this.updateParent = this.updateParent.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.address &&
      this.state.formattedAddress == null &&
      prevProps.formattedAddress !== this.state.address
    ) {
      this.setState({ formattedAddress: prevProps.formattedAddress }, this.updateParent)
    }
    if (!prevProps.address && this.props.address) {
      this.setState({ address: this.props.address })
    }
    if (prevProps.formattedAddress && !this.props.formattedAddress) {
      this.setState({ formattedAddress: "" }, this.updateParent)
    }
  }

  updateParent(){
    if(this.props.updateParent){
      this.props.updateParent(this.state.formattedAddress)
    }
  }

  handleChange = (address) => {
    if (address.length < this.state.formattedAddress.length) {
      this.props.handleAddressBackspace()
    }
    this.props.analytics.info("TypingInAddress", address)
    if (!this.props.addressNotSelected && this.props.updateAddressError) {
      if (this.props.updateAddressError) {
        this.props.updateAddressError(true)
      }
    }
    this.setState({ formattedAddress: address }, this.updateParent)
    if(this.props.onChange){
      this.props.onChange(address)
    }
  }

  handleSelect = (address, place_id) => {
    if(!place_id){
      if(this.props.parent){
        this.props.parent.setState({borderRedOnDateSelect:true})
      }
      return 
    }
    if(this.props.parent){
      this.props.parent.setState({addressSelected:true})
    }

    this.props.analytics.info("SelectedAddress", address)
    this.setState({ address: address })
    var that = this
    geocodeByPlaceId(place_id)
      .then((results) => {
        that.props.analytics.debug("GooglePlacesJSON", JSON.stringify(results))
        that.formattedAddress = results[0].formatted_address
        that.addressComponents = extractAddressComponents(
          results[0].address_components,
          address,
          place_id
        )
        return getLatLng(results[0])
      })
      .then((latLng) => {
        let addressObj = {
          formattedAddress: that.formattedAddress,
          ...latLng,
          ...that.addressComponents,
          place_id: place_id,
        }
        that.props.onSelect(addressObj)
        that.setState({ formattedAddress: that.formattedAddress }, this.updateParent)
        if (this.props.updateAddressError) {
          this.props.updateAddressError(false)
        }
      })
      .catch((error) => {
        console.error("Error", error)
        this.setState({address: null})
      })
  }

  renderNoAddressMessage(suggestions, loading) {
    if (
      suggestions.length === 0 &&
      this.state.formattedAddress &&
      !this.state.address &&
      !loading
    ) {
      return (
        <div className="NoAddressMessage">
          Address not found! Try again and select an address from the dropdown.
          We'll get your apartment number during checkout.
        </div>
      )
    }
  }

  searchOptions() {
    if (!this.props.rest) return {}
    if (!this.props.rest.deliveryBoundingBox) return {}
    if (!this.props.rest.deliveryBoundingBox.sw) return {}

    let box = this.props.rest.deliveryBoundingBox
    var bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(box.sw.lat, box.sw.lng),
      new window.google.maps.LatLng(box.ne.lat, box.ne.lng)
    )
    const searchOptions = {
      bounds: bounds,
    }
    return searchOptions
  }

  addressInputStyle(){
    if (this.props.smallWidth){
      return (
        {
          padding: "10px 15px",
          borderRadius: "15px",
          width: "calc(100%)",
          display: "inline-block",
          height: "45px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          border: "0px",
          fontSize: "16px",
          boxSizing: "border-box",
          overflow: "hidden",
        }
      )
    }
    else{
      return(
        styles.addressInput
      )
    }
  }

  render() {
    if (!this.props.GoogleMapsJSLoader.google_maps_loaded) {
      return <span />
    }
    return (
      <div
        className={classnames("autocompleteContainer", {
          hidden: this.props.isHidden,
        })}
      >
        <div className="autocompleteWrapper">
          <PlacesAutocomplete
            style={styles.container}
            value={this.state.formattedAddress}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            searchOptions={this.searchOptions()}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="location-search-container">
                <input
                  style={this.addressInputStyle()}
                  {...getInputProps({
                    autocomplete:'new-password',
                    placeholder: "Enter street address...",
                    className: "location-search-input",
                    onFocus: ()=>{if(this.props.onFocus) this.props.onFocus()},
                    onBlur: ()=>{if(this.props.onBlur) this.props.onBlur()},
                  })}
                />
                <div
                  className="autocomplete-dropdown-container"
                  style={{
                    position: "absolute",
                    // padding: "10px 15px",
                    borderRadius: "0px 0px 15px 15px",
                    width: "calc(100% - 0px)",
                    border: "1px solid #eee",
                    borderTop: "0",
                    top: "42px",
                    left: "-1px",
                  }}
                >
                  {loading && <div>Loading...</div>}
                  {this.renderNoAddressMessage(suggestions, loading)}
                  {suggestions.length > 0 &&
                    suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item"
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#6BADF4",
                            color: "#fff",
                            cursor: "pointer",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            overflowX: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: "12pt",
                            fontWeight: "700",
                            textAlign: "left",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            overflowX: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textAlign: "left",
                          }
                      return (
                        <div
                          className={className}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      )
                    })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      </div>
    )
  }
}
const styles = {
  addressReturn: {
    height: "50px",
    backgroundColor: "white",
    width: "94%",
    marginLeft: "3%",
    marginRight: "3%",
    borderRadius: "10px",
    boxShadow: "1px 1px 1px .5px lightgray",
    marginTop: "20px",
    marginBottom: "10px",
  },
  spacer: {
    height: "10px",
    display: "block",
  },
  addressInput: {
    padding: "10px 15px",
    borderRadius: "15px",
    width: "calc(100% - 40px)",
    display: "inline-block",
    height: "45px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    border: "0px",
    fontSize: "16px",
    boxSizing: "border-box",
    overflow: "hidden",
  },
  goButton: {
    backgroundColor: "green",
    display: "inline-block",
    border: "0px",
    color: "white",
    height: "50px",
    borderRadius: "10px",
    fontWeight: "300",
    fontSize: "12pt",
    zIndex: "980",
    paddingLeft: "20px",
    paddingRight: "20px",
    cursor: "pointer",
    boxShadow: "1px 1px 10px 4px lightgray",
    marginTop: "20px",
    width: "350px",
  },
  errorReturn: {
    position: "fixed",
    top: "300px",
    backgroundColor: "white",
    width: "80%",
    boxShadow: "1px 1px 10px .5px lightgray",
    zIndex: "990",
    textAlign: "center",
    borderRadius: "10px",
    padding: "15px",
    marginLeft: "6%",
  },
  padding: {
    marginBottom: "20px",
    marginTop: "10px",
    marginLeft: "3%",
    width: "94%",
    marginRight: "3%",
  },
  background: {
    backgroundColor: "whitesmoke",
    width: "100%",
    height: "100%",
    position: "fixed",
    opacity: ".9",
    marginTop: "-280px",
    zIndex: "900",
    textAlign: "center",
  },
  inlineBlock: {
    display: "inline-block",
  },
  container: {
    display: "inline-block",
    textAlign: "center",
  },
}
export default RestContextHOC(
  AnalyticsContextHOC(GoogleMapsJSLoaderContextHOC(GooglePlacesAutocomplete))
)
