import React from "react"
import classnames from "classnames"
import "./buttonwrapper.scss"

const ButtonWrapper = ({
  children,
  withBorder,
  onClick,
  propagate,
  className,
  as,
  ...rest
}) => {
  const handleClick = (e) => {
    e.stopPropagation()
    onClick(e)
  }

  const Element = as
  return (
    <Element
      onClick={handleClick}
      style={{ zIndex: 1 }}
      className={classnames("button-wrapper__container", {
        "button-wrapper__container--border": withBorder,
        "button-wrapper--propagate": propagate,
        [className]: className,
      })}
      {...rest}
    >
      {children}
    </Element>
  )
}

ButtonWrapper.defaultProps = {
  onClick: () => {},
  as: "button",
}

export default ButtonWrapper
