import React, { Component } from 'react';
import SignupModalContextHOC from './Contexts/SignupModalContextHOC'
import UserContext from './Contexts/UserContextHOC'
import AnalyticsContextHOC from './Contexts/AnalyticsContextHOC'
import CartContext from './Contexts/CartContextHOC'

class OauthFailure extends Component {

  componentDidMount() {
    this.props.history.replace({pathname: '/menu/auth'})
  }

  render() {
    return (
      <div> 
        Redirecting... 
      </div>
    )
  }
}

export default AnalyticsContextHOC(
  CartContext(UserContext(SignupModalContextHOC(OauthFailure))))
