import React, { Component } from "react"
import UserContextHOC from "../Contexts/UserContextHOC"
import CartContextHOC from "../Contexts/CartContextHOC"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import SignupModalContextHOC from "../Contexts/SignupModalContextHOC"
import { withRouter } from "react-router-dom"

class ThirdPartyLogin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sendTo: props.sendTo,
      error: false
    }
    this.sendOffSitetoLogin = this.sendOffSitetoLogin.bind(this)
    this.sendToCryptoLogin = this.sendToCryptoLogin.bind(this)
  }


  // OAUTH: this code will pop up a wallet request 
  // and then get your address/publickey
  // sign a message and then send you offsite to our
  // api with the message, where we verify and then
  // send you back
  sendToCryptoLogin(platform) {
    this.props.analytics.info("CryptoWalletLoginInit", platform)

  }

  // OAUTH: Important Stuff!
  // this sends the user to the backend route that will
  // generate the oauth url and send the user out to that 
  // url to authenticate 
  sendOffSitetoLogin(platform) {
    this.props.analytics.info("SendOffSitetoOauth", platform)

    // construct a payload to send as get params
    let getParams = {'platform': platform,
      'subdomain': encodeURIComponent(window.location.origin)}

    // grab session_id and utms from the analytics context so that
    // they get passed thru
    // https://github.com/blpt-hngr/foodcoin/issues/3155
    let clickstream_session_id = this.props.analytics.session_id
    getParams['clickstream_id'] = clickstream_session_id
    let utms = this.props.analytics.utms()
    if (utms && utms.utm_source) { getParams['utm_source'] = utms.utm_source }
    if (utms && utms.utm_medium) { getParams['utm_medium'] = utms.utm_medium }
    if (utms && utms.utm_campaign) { getParams['utm_campaign'] = utms.utm_campaign }

    // handle sendTo (for sendin to checkout)
    if (this.props.sendTo) { getParams['sendto'] = this.props.sendTo }

    // handle promo opt ins
    getParams['PromoOptIn'] = this.props.PromoOptIn

    // log payload to clickstream
    this.props.analytics.info("SendOffSitetoOauthReady", 
      JSON.stringify(getParams))

    // construct the URL + get params and send the user out 
    // hopefully they will return to the OauthUniversal component
    let url = new URL(window.API_HOST + '/api/login/v2/oauth')
    url.search = new URLSearchParams(getParams)
    let urlString = url.toString()
    window.location.href = urlString
  }

  render() {
    if (this.props.type === "google") {
      return (
        <div 
          onClick={() => this.sendOffSitetoLogin('google')}
          className="googleButton oauthButton">
          <div className="oauthFiller" />
          <div className="oauthContainer" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'flex-start', marginLeft:'10px'}}>
          <img
            src="/google.png"
            className="oauthLogo"
            alt="Google Login Button"
           />
           <div className='buttonText'>Sign in with Google</div>
         </div>
         <div className="oauthFiller" />
         </div>
        )

    } else if (this.props.type === "solana") {
      return (<div
        onClick={() => this.sendToCryptoLogin('solana')}
        className="solanaButton oauthButton">
        <div className="oauthFiller" />
        <div className="oauthContainer" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'flex-start', marginLeft:'30px'}}>
        <img
          src="/solana.png"
          className="oauthLogo"
          alt="Solana Login Button"
         />
       <div className='buttonText'>Sign in with Solana</div>
       </div>
       <div className="oauthFiller" />

       </div>
      )

    } else if (this.props.type === "facebook") {
      return (
      <div 
        onClick={() => this.sendOffSitetoLogin('facebook')}
        className="facebookButton oauthButton">
        <div className="oauthFiller" />
        <div className="oauthContainer" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'flex-start', marginLeft:'30px'}}>
        <img
          src="/fb.png"
          className="oauthLogo"
          alt="Facebook Login Button"
         />
       <div className='buttonText'>Sign in with Facebook</div>
       </div>
       <div className="oauthFiller" />

       </div>
      )
    } else if (this.props.type === 'apple') {
      return (
      <div
        onClick={() => this.sendOffSitetoLogin('apple')}
        className="oauthButton appleButton">
        <div className="oauthFiller" />
        <div className="oauthContainer" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'flex-start', marginLeft:'5px'}}>
        <img
          src="/apple.svg"
          alt="Apple Login Button"
         />
       <div className='buttonText'>Sign in with Apple</div>
       </div>
       <div className="oauthFiller" />
       </div>
      )
    }
  }
}

export default withRouter(
  AnalyticsContextHOC(
    CartContextHOC(SignupModalContextHOC(UserContextHOC(ThirdPartyLogin)))
  )
)
