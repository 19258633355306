import React from "react";
import styles from "./Errors.module.scss";
import { ReactComponent as ErrorIcon } from "./error.svg";
import { ReactComponent as ModalIcon } from "../../helpers/ModalIcon.svg";
const withErrorHandling = WrappedComponent => {
  class withErrorHandling extends React.PureComponent {
    constructor(props) {
      super(props);
      this.error = React.createRef();
      this.state = {
        hasError: false,
        errorMsg: "",
        customStyles: { color: "red" },
        onCloseCallback: null
      };

      this.errorElement = {};
    }

    updateError = (
      hasError,
      errorMsg,
      noBorder,
      customStyles,
      stopScroll,
      customComponent,
      onCloseCallback
    ) => {
      this.setState(
        {
          customComponent: customComponent ? customComponent : null,
          hasError: hasError,
          errorMsg: errorMsg,
          noBorder,
          customStyles: customStyles,
          onCloseCallback: onCloseCallback
        },
      );
      if(hasError === false && this.state.onCloseCallback){
        this.state.onCloseCallback()
      }
    };

    render() {
      const {
        hasError,
        errorMsg,
        noBorder,
        customStyles,
        customComponent
      } = this.state;

      return (
        <>
          {hasError && (
            <div
              className={
                noBorder ? styles.errorContainerNoBorder : styles.errorContainer
              }
              style={customStyles && { ...customStyles }}
              ref={this.error}
            >
              <ErrorIcon />
              {errorMsg && <div className={styles.error}>{errorMsg}</div>}
              {customComponent}
              <button
                style={{
                  marginLeft: "auto",
                  background: "none",
                  border: "none",
                  marginRight: '15px',
                }}
                onClick={() => this.updateError(false)}
              >
                <ModalIcon />
              </button>
            </div>
          )}
          <WrappedComponent updateError={this.updateError} {...this.props}>
            {this.props.children}
          </WrappedComponent>
        </>
      );
    }
  }
  return withErrorHandling;
};

export default withErrorHandling;
