import React from "react";
import withErrorHandling from "../Contexts/withErrorHandling/withErrorHandling";
import AnalyticsContextHOC from '../Contexts/AnalyticsContextHOC';
import CheckboxModifier from "./CheckboxModifier";

class SelectionFormCheckboxes extends React.Component {
  constructor(props) {
    super(props);
    let modifiers = [] 
    let foundWhippedCream = false
    // only add whipped cream once. Bug with chowly and FAT LFO 2/2/2222
    for(let m in this.props.modifiers){
      let mod = this.props.modifiers[m]
      if(mod.item){
        if(mod.item.name == 'No Whipped Cream'){
          if(!foundWhippedCream){
            modifiers.push(mod)
          }
          foundWhippedCream = true
        } else{
          modifiers.push(mod)
        }
      } else{
        modifiers.push(mod)
      }
    }
    this.state = {
      modifiers:modifiers
    }

    this.onChange = this.onChange.bind(this);
    this.num_selected = 0;
  }

  // when a checkbox is selected we need to validate it and see if we're over the maximum number
  // of selections
  onChange(modifier, checked, editModifier) {
    if (checked) {
      this.props.analytics.info("CheckboxModifierCheck", modifier.name)
      var max_selections = this.props.modifierClass.maximum_num_selections;
      if (max_selections && this.num_selected + 1 > max_selections) {
        // alert(`You can only select ${max_selections} options`)

        this.props.updateError(
          true,
          `You can only select ${max_selections} ${max_selections === 1 ? 'option' : 'options'}`
        );
        return false;
      } else {
        this.num_selected += 1;
        this.addModifier(modifier, editModifier);
        return true;
      }
    }
    //if we uncheck a box, remove the modifier
    else {
      this.props.analytics.info("CheckboxModifierUnchecked", modifier.name)
      this.num_selected -= 1;
      this.removeModifier(modifier);
      return true;
    }
  }

  // to add a modifier, just add it to the parent modifier classes cart list
  addModifier(modifier, editModifier) {
    modifier[
      "cart_modifier_classes"
    ] = this.props.initializeCartModifierClasses(modifier.item)
    //a checkbox just adds to list, so just append to the list
    this.props.parentModifierClassesCart[modifier.modifier_class_id]['modifiers'].push(
      modifier
    )
    this.props.displaySubModifiers(
      modifier,
      this.props.modifierClass,
      this.props.parentModifierClassesCart,
      editModifier
    );
  }

  // to remove a modifier, remove it from the parent class cart list
  removeModifier(modifier) {
    //remove it from the list
    this.props.updateError(false)
    this.props.parentModifierClassesCart[modifier.modifier_class_id]['modifiers'].forEach(
      function(item, index, object) {
        if (item.modifier_id === modifier.modifier_id) {
          object.splice(index, 1)
        }
      }
    );

    //hide any of its submodifiers if this is an item (as opposed to a string)
    if (modifier.item) {
      this.props.removeModifierClasses(modifier)
    }
  }

  render() {
    return (
      <div>
        {this.state.modifiers.map(modifier => (
          <CheckboxModifier
            editModifiers={this.props.editModifiers}
            modifier={modifier}
            key={modifier.modifier_id}
            modifierId={modifier.modifier_id}
            onChange={this.onChange}
          />
        ))}
      </div>
    );
  }
}

export default AnalyticsContextHOC(
  withErrorHandling(SelectionFormCheckboxes)
)
