import React, { Component } from "react"
import ReCAPTCHA from "react-google-recaptcha";

import EntryPointHOC from "../Contexts/EntryPointHOC"
import RestContextHOC from "../Contexts/RestContextHOC"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import ThirdPartyLogin from './ThirdPartyLogin'
import CartContextHOC from "../Contexts/CartContextHOC"
import UserContextHOC from "../Contexts/UserContextHOC"
import SignupModalContextHOC from "../Contexts/SignupModalContextHOC"
import Checkbox from "../sparkles/Checkbox/Checkbox"
import Modal from "../Modal/Modal"
import "../css/LoginSignup.css"
import "../css/EmailRegistration.css"
import * as EmailValidator from 'email-validator';

const Subdomain = require("../helpers/subdomain")
const Api = require("../helpers/api.js")

class EmailRegistration extends Component {
  constructor(props) {
    super(props)

    let PromoOptIn = null
    if (this.props.rest.collect_extra_signup_data) {
      PromoOptIn = true
    }

    this.state = {
      authMethod: 'PASSWORD',
      email: null,
      first: null,
      last: null,
      password: null,
      suggestOauth: null,
      PromoOptIn: PromoOptIn,
      reCaptcha: null,
      error: false,
      loading: false,
      errorMessage: ''
    }
    this.handleEsc = this.handleEsc.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
    this.handleSubmission = this.handleSubmission.bind(this)
    this.registrationSuccess = this.registrationSuccess.bind(this)
    this.registrationRejected = this.registrationRejected.bind(this)
    this.sendToLogin = this.sendToLogin.bind(this)
    this.handleReCap = this.handleReCap.bind(this)
  }

  validateEmail(email) {
    return EmailValidator.validate(email)
  }

  handleReCap(r) {
    this.setState({reCaptcha: r})
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEsc, false)
    this.props.analytics.info("EmailRegistrationPage")
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEsc, false)
  }

  handleClickOutside() {
    this.handleClose()
  }

  // registration with backend has been successfull
  // so invoke logins everywhere and then close the modal
  registrationSuccess(data) {
    this.props.analytics.info('EmailRegistrationSuccess', JSON.stringify(data))
    this.props.user.loginUser(data.userdata, data.hngr_token)
    this.props.cart.loginUser(data.userdata)

    const params = new URLSearchParams(window.location.search)
    let pathname = '/menu'
    const userPhone = data.userdata.phone_number_e164
    if (params.get('sendTo') === 'checkout' && !userPhone) {
      pathname = '/menu/phone_verify?sendTo=checkout'
    } else if (params.get('sendTo') === 'checkout') {
      pathname = '/checkout'
    }
    this.props.history.replace({pathname: pathname})

  }

  handleSubmission() {
    this.props.analytics.info('EmailRegistrationSubmitButton')
    if (!this.validateEmail(this.state.email)) {
      let msg = "Invalid email address"
      this.setState({error: msg})
      return
    }
    if (!this.state.password || this.state.password.length < 4) {
      this.setState({error: "Password too short. Must be at least 4 characters"})
      return
    }
    if (!this.state.first || this.state.first.length === 0) {
      this.setState({error: "Please provide a first name"})
      return
    }
    if (!this.state.last || this.state.last.length === 0) {
      this.setState({error: "Please provide a last name"})
      return
    }
    if (!this.state.reCaptcha) {
      this.setState({error: 
        'Please check the button above to prove you are not a robot'})
      return
    }


    // disable the button so it can't be clicked again
    // lots of users tap this in rapid succession
    // see: https://github.com/blpt-hngr/foodcoin/issues/3793
    this.setState({loading: true})

    let payload = {first: this.state.first,
      last: this.state.last,
      email: this.state.email,
      password: this.state.password,
      subdomain: Subdomain.getSubdomain(),
      PromoOptIn: this.state.PromoOptIn,
      reCaptcha: this.state.reCaptcha,
      restaurant_id: this.props.rest.rest_id
    }

    // send the API request, success means close modal, set user tokens
    // and move back to original page
    Api.callApi('email_register',
      this.registrationSuccess,
      this.registrationRejected,
      payload
    )
  }

  // invoke when the backend refuses to accept an email registration
  registrationRejected(resp) {
    this.props.analytics.info('EmailRegistrationRejected',
      JSON.stringify(resp))
    this.setState({error: resp.message, 
      loading: false,
      suggestOauth: resp.platform})
  }

  handleEsc(e) {
    if (e.keyCode === 27) {
      e.preventDefault()
      this.handleClose()
      return
    }
  }

  handleClose() {
    this.props.analytics.info('EmailRegistrationClose')
    let lastPath = this.props.location.pathname.replace("/email_register","")
    this.props.history.push({
      pathname: lastPath,
      search: window.location.search
    })
  }


  componentDidUpdate(prevProps) {
    if (this.props.rest.name && this.state.loading === true) {
      this.setState({loading: false})
    }
  }

  sendToLogin() { 
    let pathname = '/menu/email_login'
    const params = new URLSearchParams(window.location.search)
    if (params.get('sendTo')) {
      pathname += '?sendTo=' + params.get('sendTo')
    }
    this.props.history.replace({pathname: pathname})
  }

  render() {
      return (
        <Modal close={this.handleClickOutside}>

        {!this.state.suggestOauth && (
        <div className='emailRegistration'>
          <div>
          <h3>Create Your Account</h3>

          <div class='words'>
            Already have an account? <a className='regularHotlink'
              onClick={() => this.sendToLogin()}>Login</a>
          </div>

          <div className='label'>First Name:</div>
          <input 
            value={this.state.first}
            type='text'
            onChange={(e)=>{this.setState({first: e.target.value.trim()})}}
            autocomplete="firstname" />

          <div className='label'>Last Name:</div>
          <input 
            value={this.state.last}
            type='text'
            onChange={(e)=>{this.setState({last: e.target.value.trim()})}}
            autocomplete="lastname"/>

          <div className='label'>Email:</div>
          <input 
            value={this.state.email}
            type='email'
            onChange={(e)=>{this.setState({email: e.target.value.trim()})}}
            autocomplete="username email"/>

          <div className='label'>Password:</div>
          <input type='password' 
            value={this.state.password}
            onChange={(e)=>{this.setState({password: e.target.value.trim()})}}
            autocomplete="new-password"/>

          {this.props.rest.require_captcha_for_emails && (
          <div className='promoBox'>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={this.handleReCap}
          />
          </div>
          )}

          {this.props.rest.collect_extra_signup_data && (
            <div className='promoBox'>
              <div onClick={()=>{this.setState({
                PromoOptIn: !this.state.PromoOptIn})}}>
                <Checkbox checked={this.state.PromoOptIn} />
              </div>
              <div style={{paddingLeft:'20px', textAlign:'left'}}>
                Send me emails about promotions, deals and rewards
              </div>
            </div>
          )}


          {this.state.error && (
            <div className="modalError">{this.state.error}</div>
          )}

          <button
            disabled={this.state.loading}
            style={{background: this.props.rest.hex_color_primary}}
            onClick={e => this.handleSubmission()}>Next</button>
          </div>
          </div>)}

          {this.state.suggestOauth && (
          <div className='emailRegistration'>
            <div>
            <h3>Login to Continue</h3>
            <div className='words'>
              You previously created an account with the following email address
            </div>
            <h3>{this.state.email}</h3>
            <div className='words'>
              Please Log in to Continue
            </div>

            {this.state.suggestOauth === 'GOOGLE' && (
              <ThirdPartyLogin
                type='google'
                title={'Log in'}
                sendTo={this.props.sendTo}
                setStage={this.props.setStage}
                PromoOptIn={this.state.PromoOptIn}
                isLogin={true}
              />
            )}

            {this.state.suggestOauth === 'EMAIL' && (
              <ThirdPartyLogin
                type='google'
                title={'Log in'}
                sendTo={this.props.sendTo}
                setStage={this.props.setStage}
                PromoOptIn={this.state.PromoOptIn}
                isLogin={true}
              />
            )}

            {this.state.suggestOauth === 'FACEBOOK' && (
              <ThirdPartyLogin
                type='facebook'
                title={'Log in'}
                sendTo={this.props.sendTo}
                PromoOptIn={this.state.PromoOptIn}
                isLogin={true}
              />
            )}
          </div>
          </div>
        )}

        </Modal>
        )
  }

}

export default RestContextHOC(SignupModalContextHOC(
  AnalyticsContextHOC(
    CartContextHOC(
      EntryPointHOC(UserContextHOC(
        UserContextHOC(RestContextHOC(EmailRegistration))))))))
