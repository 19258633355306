export const Thumb = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="56" rx="10" fill="#C5DFC7" />
    <g clip-path="url(#clip0_1376_6289)">
      <path
        d="M20.875 26.5H17.125C16.5037 26.5 16 27.0037 16 27.625V38.875C16 39.4963 16.5037 40 17.125 40H20.875C21.4963 40 22 39.4963 22 38.875V27.625C22 27.0037 21.4963 26.5 20.875 26.5ZM19 38.125C18.3787 38.125 17.875 37.6213 17.875 37C17.875 36.3787 18.3787 35.875 19 35.875C19.6213 35.875 20.125 36.3787 20.125 37C20.125 37.6213 19.6213 38.125 19 38.125ZM34 19.8181C34 21.8063 32.7827 22.9216 32.4401 24.25H37.2084C38.7739 24.25 39.9926 25.5506 40 26.9733C40.0039 27.8142 39.6462 28.7194 39.0887 29.2795L39.0836 29.2846C39.5447 30.3785 39.4697 31.9113 38.6473 33.0097C39.0542 34.2235 38.644 35.7146 37.8794 36.514C38.0808 37.3389 37.9846 38.0409 37.5912 38.6061C36.6345 39.9806 34.2633 40 32.2581 40L32.1247 40C29.8613 39.9992 28.0088 39.175 26.5203 38.5128C25.7724 38.1801 24.7944 37.7681 24.0523 37.7545C23.7458 37.7489 23.5 37.4987 23.5 37.1921V27.1716C23.5 27.0216 23.5601 26.8777 23.6668 26.7722C25.5237 24.9373 26.3222 22.9947 27.8441 21.4701C28.5381 20.7749 28.7905 19.7247 29.0344 18.7091C29.2429 17.8419 29.6789 16 30.625 16C31.75 16 34 16.375 34 19.8181Z"
        fill="#1A8035"
      />
    </g>
    <defs>
      <clipPath id="clip0_1376_6289">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(16 16)"
        />
      </clipPath>
    </defs>
  </svg>
)
