import React, { Component } from "react"
import localforage from "localforage"
import AnalyticsContextHOC from "./AnalyticsContextHOC"
import withErrorHandling from "./withErrorHandling/withErrorHandling"
import { areValuesEmpty } from "../helpers/object"
import LogRocket from "logrocket"

const api = require("../helpers/api")
const Subdomain = require("../helpers/subdomain")

export const RestContext = React.createContext()

// keys that wont be automatically backed up
const DONT_BACKUP = [
  "sandboxed_menu_uuid",
  "sandboxedMenuLoaded",
  "futureHours",
  "hours",
  "menu",
  "allowSplitTips",
  "alreadyCalculatedLogRocket",
  "logrocket_percentage",
  "allowCashPickup",
  "allowCashDelivery",
  "accepts_bitcoin",
  "allowSol",
  "has_nft_promo",
]

const FIELD_FROM_CONFIG = [
  "name",
  "secondary",
  "hex_color_primary",
  "hex_color_secondary",
  "log_url",
  "logo_color",
  "logo_white",
  "website_url",
  "require_captcha_for_emails",
]

class RestProvider extends Component {
  constructor(props) {
    super(props)
    this.gettingMenu = false
    this.alreadyCalculatedLogRocket = false

    this.restoreInjections = this.restoreInjections.bind(this)
    this.saveInjections = this.saveInjections.bind(this)
    this.handleFutureHoursResponse = this.handleFutureHoursResponse.bind(this)

    this.state = {
      allowCashPickup: null,
      allowCashDelivery: null,
      allowSpecialInstructions: null,
      rest_id: null,
      rest_loaded: false,
      name: null,
      futureHours: null,
      secondary_name: null,
      address: null,
      zipcode: null,
      logo_url: null,
      logo_color: null,
      logo_white: null,
      city: null,
      state: null,
      lat: null,
      lng: null,
      phone: null,
      phone_number_e164: null,
      hex_color_primary: null,
      hex_color_secondary: null,
      require_captcha_for_emails: true,
      facebook_pixel_id: null,
      gtag_conversion_id: null,
      website_url: null,
      default_pickup_time: null,
      default_delivery_time: null,
      allow_future_orders: true,
      has_nft_promo: null,
      autoconfirm: null,
      allow_asap_orders: null,
      allowPickupTips: false,
      allowSplitTips: false,
      offerNoUtensils: false,
      collect_extra_signup_data: false,
      phone_modal_opt_in_checkbox: null,
      phone_modal_text_blob: null,
      phone_modal_text_title: null,
      phone_modal_terms_url: null,
      phone_modal_privacy_url: null,
      allow_crypto_oauth: true,
      rewards: null,
      menu: null,
      menus: null,
      categoryHidden: [],
      categoryGreyedOut: [],
      itemsHidden: [],
      itemsGreyedOut: [],
      taxDeliveryFees: false,
      // this is the concat of the above two lists. its only used by
      // modifiers, who dont care about grey vs black
      itemBlackouts: [],
      menuStatus: "open",
      menuDisplayType: null,
      default_menu_id: null,
      futureHours: null,
      nextAvailableFutureTime: null,
      acceptsBitcoin: false,
      hours: null,
      deliveryBoundingBox: null,
      toggles: {},
      injections: {},
      setDeliveryBoundingBox: (bounds) =>
        this.setState({ deliveryBoundingBox: bounds }),
      primaryColor: () => this.primaryColor(),
      secondaryColor: () => this.secondaryColor(),
      setRestId: (rest_id, restoreData) => this.setRestId(rest_id, restoreData),
      setMenu: (menu, callback) => this.setMenu(menu, callback),
      setRewards: (rewards) => this.setRewards(rewards),
      setCategoryHidden: (cats) => this.setCategoryHidden(cats),
      setCategoryGreyedOut: (cats) => this.setCategoryGreyedOut(cats),
      setItemsHidden: (items) => this.setItemsHidden(items),
      setItemsGreyedOut: (items) => this.setItemsGreyedOut(items),
      setItemBlackouts: (items) => this.setItemBlackouts(items),
      setInfo: (info) => this.setInfo(info),
      setInjections: (inj) => this.setInjections(inj),
      getInjections: (context) => this.getInjections(context),
      setFont: (font) => this.setFont(font),
      restoreData: (rest_id) => this.restoreData(rest_id),
      restoreSandboxedMenu: () => this.restoreSandboxedMenu(),
      getToggleValue: (key) => this.getToggleValue(key),
      getHours: () => this.getHours(),
      getFutureHours: () => this.getFutureHours(),
      refreshBlackouts: (timestamp) => this.refreshBlackouts(timestamp),
      maybeGetMenu: () => this.maybeGetMenu(),
      sandboxed_menu_uuid: null,
      checkSandbox: () => this.checkSandbox(),
      sandboxedMenuLoaded: false,
      futureOrderTimesAvailable: () => this.futureOrderTimesAvailable(),
      setSandboxedMenu: (uuid, callback) => {
        this.setState(
          {
            sandboxed_menu_uuid: uuid,
            sandboxedMenuLoaded: true,
          },
          callback
        )
        this.backupSandboxedMenu(uuid)
      },
      lockMenu: () => this.lockMenu(),
      isLocked: () => this.isLocked(),
      checkIfFutureDeliveryAvailable: () =>
        this.checkIfFutureDeliveryAvailable(),
      checkIfFuturePickupAvailable: () => this.checkIfFuturePickupAvailable(),
      checkIfFutureAvailable: () => this.checkIfFutureAvailable(),
    }

    //set font if its in window.REST_CONFIG.restaurant
    if (
      window.REST_CONFIG &&
      window.REST_CONFIG.restaurant &&
      window.REST_CONFIG.restaurant.font_family &&
      window.REST_CONFIG.restaurant.font_url
    ) {
      this.setFont({
        font_family: window.REST_CONFIG.restaurant.font_family,
        font_url: window.REST_CONFIG.restaurant.font_url,
      })
    }

    // for fields comijng from the window config, set the state with those
    // If it's available in rest set that, otherweise set from brand
    for (var i in FIELD_FROM_CONFIG) {
      let field = FIELD_FROM_CONFIG[i]
      if (window.REST_CONFIG && window.REST_CONFIG.restaurant) {
        let val
        // grab from brand
        if (window.REST_CONFIG.brand) {
          val = window.REST_CONFIG.brand[field]
        }
        // if rest is set and non null, take it from rest
        if (
          window.REST_CONFIG.restaurant[field] !== undefined &&
          window.REST_CONFIG.restaurant[field] !== null
        ) {
          val = window.REST_CONFIG.brand[field]
        }
        if ((val !== undefined) & (val !== null)) {
          this.state[field] = val
        }
      }
    }
  }

  setFont(font) {
    if (!font.font_url || !font.font_family) return
    if (window.FONT) return
    window.FONT = true

    var link = document.createElement("link")
    link.setAttribute("rel", "stylesheet")
    link.setAttribute("type", "text/css")
    link.setAttribute("href", font.font_url)
    document.getElementsByTagName("head")[0].appendChild(link)

    var style = document.createElement("style")
    document.getElementsByTagName("head")[0].appendChild(style)
    let css = `
      * {
        font-family: '${font.font_family}', sans-serif !important;
      }   
    `
    style.type = "text/css"
    style.appendChild(document.createTextNode(css))
  }

  initLogRocket() {
    if (this.props.analytics.createdANewSession === null) {
      setTimeout(() => this.initLogRocket(), 100)
      return
    }
    // sneak the logrocket logic in here because we piggy back the
    // setting into this API call. Basically check if clickstream has
    // created a new session first. If it hasn't, then don't do any
    // logrocket stuff. This is to prevent the user from having no-logrocket
    // but then having yes-logrocket after a refresh.... we are using cs
    // to mantain the session
    if (
      !this.alreadyCalculatedLogRocket &&
      this.props.analytics.createdANewSession === true
    ) {
      this.alreadyCalculatedLogRocket = true
      if (this.state.logrocket_percentage) {
        let getRandomInt = (min, max) => {
          min = Math.ceil(min)
          max = Math.floor(max)
          return Math.floor(Math.random() * (max - min + 1)) + min
        }
        let num = getRandomInt(0, 99)
        if (num < this.state.logrocket_percentage) {
          if (process.env.REACT_APP_LOGROCKET) {
            this.props.analytics.setLogrocket()
            LogRocket.init(process.env.REACT_APP_LOGROCKET)
            LogRocket.getSessionURL((sessionURL) => {
              let params = {
                session_url: sessionURL,
                clickstream_session_id: window.CLICKSTREAM_SESSION_ID,
                rest_id: this.state.rest_id,
              }
              api.callApi(
                "logrocket_session",
                function () {},
                function () {},
                params
              )
            })
          }
        }
      }
    }
  }

  setInjections(injections) {
    this.setState({ injections: injections }, () => {
      this.saveInjections()
    })
  }

  async saveInjections() {
    let storageKey = this.getLocalForageKey() + ".injections"
    localforage.setItem(storageKey, this.state.injections)
  }

  async restoreInjections(rest_id) {
    let storageKey = this.getLocalForageKey(rest_id) + ".injections"
    let injections = await localforage
      .getItem(storageKey)
      .catch(function (err) {
        console.log(err)
      })
    this.setState({ injections: injections })
  }

  // fetch any category blackouts on this menu
  // note that this requires a time!
  refreshBlackouts(futureOrderTime) {
    // if we haven't loaded future hours yet, then if the rest is closed
    // we need to wait for the next available future order time
    // before passing a future order time to the blackout api calls
    // this was fixing a bug where fatburger was showing an empty menu
    // when they were closed and had timers but hadnt selected a
    // future order time yet
    if (!this.state.futureHoursLoaded && !futureOrderTime) {
      setTimeout(() => this.refreshBlackouts(futureOrderTime), 100)
      return
    }

    if (
      !futureOrderTime &&
      this.state.futureHours &&
      this.state.futureHours.open &&
      this.state.futureHours.open.delivery === false &&
      this.state.futureHours.open.pickup === false &&
      this.state.nextAvailableFutureTime
    ) {
      console.log(this.state.nextAvailableFutureTime)
      futureOrderTime = this.state.nextAvailableFutureTime
    }

    // never send 0 to the backend
    if (futureOrderTime == 0) futureOrderTime = null

    var subdomain = Subdomain.getSubdomain()

    let blackoutParams = { fulfill_at: futureOrderTime }
    // if the restaurant is closed and fulfill_at is null,
    // then fulfill_at should be the next_available_time

    api.callApi(
      "menu_category_blackouts",
      (resp) => {
        this.setCategoryHidden(resp.hidden)
        this.setCategoryGreyedOut(resp.greyed_out)
        this.props.analytics.info(
          "BlackedoutCategoriesLoaded",
          JSON.stringify(resp.categories)
        )
      },
      (err) => console.log(err),
      null,
      blackoutParams,
      subdomain
    )

    api.callApi(
      "menu_item_blackouts",
      (resp) => {
        this.setItemsHidden(resp.hidden)
        this.setItemsGreyedOut(resp.greyed_out)
        console.log([...resp.hidden, ...resp.greyed_out])
        this.setItemBlackouts([...resp.hidden, ...resp.greyed_out])
        this.props.analytics.info(
          "BlackedoutItemsLoaded",
          JSON.stringify(resp.items)
        )
      },
      (err) => console.log(err),
      null,
      blackoutParams,
      subdomain
    )
  }

  getInjections(context) {
    if (!this.state.injections) {
      return []
    }
    let injections = this.state.injections[context]
    if (injections === null) {
      return
    }
    let returnList = []
    for (let i in injections) {
      let injection = atob(injections[i])
      returnList.push(injection)
    }
    return returnList
  }

  setInfo(info) {
    var that = this
    this.setState(
      {
        rest_id: info.rest_id,
        menuDisplayType: info.menu_display_type,
        name: info.name ? info.name : "Restaurant not available",
        secondary_name: info.secondary_name,
        require_captcha_for_emails: info.require_captcha_for_emails,
        phone: info.phone,
        phone_number_e164: info.phone_number_e164,
        menus: info.menus,
        allow_future_orders: info.allow_future_orders,
        allow_asap_orders: info.allow_asap_orders,
        autoconfirm: info.autoconfirm_seconds,
        toggles: info.toggles,
        default_menu_id: info.default_menu_id,
        website_url: info.website_url,
        facebook_pixel_id: info.facebook_pixel_id,
        gtag_conversion_id: info.gtag_conversion_id,
        address: info.address ? info.address : "Address not available",
        zipcode: info.zipcode,
        city: info.city ? info.city : "We're sorry",
        state: info.state ? info.state : "come back soon!",
        lat: info.lat,
        lng: info.lng,
        hex_color_primary: info.hex_color_primary
          ? info.hex_color_primary
          : "#ea5151",
        hex_color_secondary: info.hex_color_secondary
          ? info.hex_color_secondary
          : "#4dbbe2",
        logo_url: info.logo_url ? info.logo_url : "/hngr-180.png",
        logo_color: info.logo_color,
        logo_white: info.logo_white,
        phone_modal_opt_in_checkbox: info.phone_modal_opt_in_checkbox,
        phone_modal_text_blob: info.phone_modal_text_blob,
        phone_modal_text_title: info.phone_modal_text_title,
        phone_modal_terms_url: info.phone_modal_terms_url,
        phone_modal_privacy_url: info.phone_modal_privacy_url,
        allow_crypto_oauth: info.allow_crypto_oauth,
        allowCashPickup: info.allow_cash_pickup,
        allowCashDelivery: info.allow_cash_delivery,
        allowSOL: info.allow_sol,
        allowSplitTips: info.allow_split_tips,
        use_intercom: info.use_intercom,
        default_delivery_time: info.default_delivery_time,
        default_pickup_time: info.default_pickup_time,
        collect_extra_signup_data: info.collect_extra_signup_data,
        rest_loaded: true,
        allowSpecialInstructions: info.allow_special_instructions,
        allowPickupTips: info.allow_pickup_tips,
        twitter_url: info.twitter_url,
        facebook_url: info.facebook_url,
        insta_url: info.insta_url,
        taxDeliveryFees: info.tax_delivery_fees,
        has_nft_promo: info.has_nft_promo,
        offerNoUtensils: info.offer_no_utensils,
        logrocket_percentage: info.logrocket_percentage,
        default_delivery_time: info.default_delivery_time,
        acceptsBitcoin: info.accepts_bitcoin,
        paymentMethodFees: info.payment_method_fees,
      },
      () => {
        that.backupData()
        that.initLogRocket()
      }
    )
  }

  setRestId(rest_id, restoreData) {
    var that = this
    if (restoreData) {
      this.setState({ rest_id: rest_id }, that.restoreData(rest_id))
    } else {
      this.setState({ rest_id: rest_id })
    }
  }

  // getToggleValue - returns the value of a feature toggle
  // that gets passed from the backend
  getToggleValue(toggle_id) {
    if (toggle_id in this.state.toggles) {
      return this.state.toggles[toggle_id]
    } else {
      return false
    }
  }

  setRewards(rewards) {
    this.setState({ rewards: rewards })
  }

  setMenu(menu, callback) {
    let obj = {
      menu_id: menu.menu_id,
      menu_type: menu.menu_type,
    }
    this.props.analytics.info("SettingMenuInRestContext", JSON.stringify(obj))
    this.setState({ menu: menu }, () => {
      if (callback) callback()
    })
  }

  setCategoryHidden(cats) {
    this.setState({ categoryHidden: cats })
  }

  setCategoryGreyedOut(cats) {
    this.setState({ categoryGreyedOut: cats })
  }

  setItemsHidden(items) {
    this.setState({ itemsHidden: items })
  }

  setItemBlackouts(items) {
    this.setState({ itemBlackouts: items })
  }

  setItemsGreyedOut(items) {
    this.setState({ itemsGreyedOut: items })
  }

  maybeGetMenu() {
    if (this.gettingMenu) return
    if (this.state.menu) return
    if (this.state.sandboxed_menu_uuid) return
    const menu_load_start = Date.now()
    this.gettingMenu = true
    var subdomain = Subdomain.getSubdomain()
    this.props.analytics.info("AboutToStartLoadingMenu", window.DEBUG_ID)
    api.callApi(
      "menu",
      (res) => {
        this.gettingMenu = false
        this.setMenu(res)
        const menu_load_finish = Date.now()
        const menu_load_time = (menu_load_finish - menu_load_start) / 1000
        this.props.analytics.info("MainMenuLoadTime", menu_load_time)
      },
      (err) => console.log(err),
      null,
      null,
      subdomain
    )
  }

  getHours() {
    var subdomain = Subdomain.getSubdomain()
    this.setState({ api_host: window.API_HOST })
    const hours_start = new Date()
    api.callApi(
      "rest_hours",
      (res) => {
        this.hoursSuccess(res, hours_start)
      },
      (err) => this.onHoursFail(err),
      null,
      null,
      subdomain
    )
  }

  logAsapAvailability(data) {
    if (this.props.analytics.isOpenEventHasFired) return

    if (this.state.allow_asap_orders === null) {
      setTimeout(() => this.logAsapAvailability(data), 1000)
      return
    }

    if (data) {
      let msg = ""
      if (this.state.allow_asap_orders) {
        if (data.pickup.open && data.delivery.open) {
          msg = "pickup_and_delivery"
        } else if (data.pickup.open && !data.delivery.open) {
          msg = "pickup"
        } else if (!data.pickup.open && data.delivery.open) {
          msg = "delivery"
        } else {
          msg = "closed"
        }
      } else {
        msg = "closed"
      }
      if (!this.props.analytics.isOpeneventHasFired) {
        this.props.analytics.isOpenEventHasFired = true
        this.props.analytics.send("asap_availability", msg)
      }
    }
  }

  hoursSuccess(data, hours_start) {
    const hours_finish = new Date()
    this.props.analytics.info(
      "HoursLoaded",
      (hours_finish - hours_start) / 1000
    )
    this.setState({ hours: data })
    this.logAsapAvailability(data)
  }

  onHoursFail(err) {
    if (this.state.api_host !== process.env.REACT_APP_FOODCOIN_API_FAILOVER) {
      window.API_HOST = process.env.REACT_APP_FOODCOIN_API_FAILOVER
      this.getHours()
    } else {
      this.props.updateError(
        true,

        "Looks like a slow connection is holding back our ordering platform. Try to refresh, or call the restaurant to place your order!",
        true,
        { zIndex: "1000", position: "fixed", top: "0", width: "99vw" }
      )
      this.props.analytics.error("HoursFailure", JSON.stringify(err))
    }
  }

  getLocalForageKey(rest_id = null) {
    if (rest_id !== null) {
      return "rest" + rest_id
    }
    return "rest" + this.state.rest_id
  }

  isFunction(functionToCheck) {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    )
  }

  async backupData() {
    if (!this.state.rest_id) {
      console.log("Cant save rest_context if rest_id is not set")
      return
    }

    //when we wantto save the state, we can't serialize the fucntions, so we gotta create
    //a new object that is a clone of the state, but with the functions stripped out
    var to_be_saved = {}
    for (var key in this.state) {
      var value = this.state[key]
      if (!this.isFunction(value) && DONT_BACKUP.indexOf(key) === -1) {
        to_be_saved[key] = value
      }
    }

    await localforage
      .setItem(this.getLocalForageKey(), to_be_saved)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  async backupSandboxedMenu(menu_uuid) {
    let key = "sandboxed_menu_uuid"
    let item = {
      menu_uuid: menu_uuid,
      timestamp: new Date().getTime(),
    }
    await localforage
      .setItem(key, item)
      .then(function (value) {})
      .catch(function (err) {
        console.error(err)
      })
  }

  restoreData(rest_id) {
    if (!rest_id) {
      console.log("Attempted to restore data with a bad rest_id")
      return
    }

    var that = this
    localforage
      .getItem("rest" + rest_id)
      .then(function (former_state) {
        for (var key in former_state) {
          var value = former_state[key]
          if (value != null) {
            if (key === "menu") {
              continue
            }
            that.state[key] = value
          }
        }
        that.setState({ cachebust: Math.random() })
      })
      .catch(function (err) {
        console.error(err)
      })

    this.restoreInjections(rest_id)
    this.restoreSandoxedMenu()
  }

  restoreSandboxedMenu() {
    let that = this
    localforage
      .getItem("sandboxed_menu_uuid")
      .then(async function (sandboxed_menu) {
        if (!sandboxed_menu) {
          that.setState({ sandboxedMenuLoaded: true })
          return
        }
        let seconds = (new Date().getTime() - sandboxed_menu.timestamp) / 1000
        // three hour timeout
        if (seconds < 60 * 180 && false) {
          that.setState({
            sandboxed_menu_uuid: sandboxed_menu.menu_uuid,
            sandboxedMenuLoaded: true,
          })
        } else {
          that.setState({ sandboxedMenuLoaded: true })
          await localforage.setItem("menu_sanboxed", false)
        }
      })
      .catch(function (err) {
        that.setState({ sandboxedMenuLoaded: true })
        console.error(err)
      })
  }

  removeSandboxMenu() {
    if (process.env.NODE_ENV === "development") {
      localforage.removeItem("sandboxed_menu_uuid", () => {
        console.log("successfully removed")
        this.setState({ sandboxed_menu_uuid: null })
      })
    }
  }

  secondaryColor() {
    if (this.state.hex_color_secondary) {
      return this.state.hex_color_secondary
    } else {
      return "gray"
    }
  }

  primaryColor() {
    if (this.state.hex_color_primary) {
      return this.state.hex_color_primary
    } else {
      return "#1e1e1e"
    }
  }

  /* If ordering on premise, lock down the following:
    - Change menu
    - Ability to change order type (pickup/delivery)
    - Coupon 
  */

  lockMenu = () => {
    this.setState({ menuStatus: "locked" }, async () => {
      await localforage.setItem("menu_sanboxed", true)
    })
  }

  isLocked = () => {
    return !!this.state.sandboxed_menu_uuid
  }

  checkSandbox = async () => {
    if (this.isLocked()) {
      return
    }

    const sb = await localforage.getItem("menu_sanboxed")
    if (sb) {
      this.lockMenu()
    }
  }

  logFutureHourAvailability() {
    if (this.props.analytics.isOpenFutureEventHasFired) {
      return
    }

    if (!this.state.rest_loaded) {
      setTimeout(() => this.logFutureHourAvailability(), 1000)
      return
    }

    let pickup = this.checkIfFuturePickupAvailable()
    let delivery = this.checkIfFutureDeliveryAvailable()
    let msg = ""
    if (pickup && delivery) {
      msg = "pickup_and_delivery"
    } else if (pickup) {
      msg = "pickup"
    } else if (delivery) {
      msg = "delivery"
    } else {
      msg = "closed"
    }
    this.props.analytics.isOpenFutureEventHasFired = true
    this.props.analytics.info("future_order_availability", msg)
  }

  handleFutureHoursResponse(hours) {
    this.setState(
      {
        futureHoursLoaded: true,
        futureHours: hours,
        nextAvailableFutureTime: hours.next_available,
      },
      () => {
        this.logFutureHourAvailability()
        window.TODAY_TS = hours.today
        window.TOMORROW_TS = hours.tomorrow
        let subdomain = Subdomain.getSubdomain()
      }
    )
  }

  getFutureHours() {
    let subdomain = Subdomain.getSubdomain()
    api.callApi(
      "future_hours",
      (hours) => this.handleFutureHoursResponse(hours),
      () => {
        console.log("Error getting future hours")
      },
      null,
      null,
      subdomain
    )
  }

  futureOrderTimesAvailable() {
    if (!this.state.futureHours) return false
    for (var i in this.state.futureHours["delivery"]) {
      if (this.state.futureHours["delivery"][i].length) return true
    }
    for (var i in this.state.futureHours["pickup"]) {
      if (this.state.futureHours["pickup"][i].length) return true
    }
    return false
  }

  checkIfFuturePickupAvailable() {
    return (
      this.state.allow_future_orders &&
      this.state.futureHours &&
      !areValuesEmpty(this.state.futureHours.pickup)
    )
  }

  checkIfFutureDeliveryAvailable() {
    return (
      this.state.allow_future_orders &&
      this.state.futureHours &&
      !areValuesEmpty(this.state.futureHours.delivery)
    )
  }

  checkIfFutureAvailable() {
    return (
      this.checkIfFutureDeliveryAvailable() ||
      this.checkIfFuturePickupAvailable()
    )
  }

  render() {
    window.removeSandboxMenu = () => this.removeSandboxMenu()
    return (
      <RestContext.Provider value={this.state}>
        {this.props.children}
      </RestContext.Provider>
    )
  }
}

export default withErrorHandling(AnalyticsContextHOC(RestProvider))
