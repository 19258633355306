import React from "react";
import CartContext from "../Contexts/CartContextHOC";
import RestContextHOC from "../Contexts/RestContextHOC";
import UserContextHOC from "../Contexts/UserContextHOC";
import AnalyticsContextHOC from '../Contexts/AnalyticsContextHOC'
import { withRouter } from 'react-router-dom';
import '../css/RecentOrder.css'

const Timestamp = require("../helpers/timestamps");

class OpenOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.order,
      orderTotal: (props.order.total / 100).toFixed(2)
    };
  }

  openTrackOrder(){
    if(this.state.order.is_bitcoin_order){
      let search = `?hash_id=${this.state.order.hash_id}&diner_id=${this.props.user.user.uniq_uuid}`
      this.props.history.push({
        pathname: 'thankyou',
        search:search
      }) 
    } else { 
      this.props.analytics.info('OpenedOrderHistoryModal', this.state.order.hash_id)
      let url = `/orders/track/?hash_id=${this.state.order.hash_id}&diner_id=${this.props.user.user.uniq_uuid}`
      this.props.history.push({
        pathname: url,
        state:{status_or_receipt:'status'}
      });
    }
  }

  formatOrderDay(){
    return Timestamp.getDayOfWeekFull(this.state.order.created_at)
  }


  formatOrderTime(){
    return Timestamp.getTimeOfDay(this.state.order.created_at)
  }

  render() {
    return(
  <span style={{textAlign:'center'}}>
    <div className='recentOrderContainer' onClick={()=>this.openTrackOrder()}>
      <div className='recentOrderTitle'>
        <b>{this.formatOrderDay()}, {this.formatOrderTime()}</b> : Order for <span style={{textTransform:'capitalize'}}>{this.state.order.delivery_or_pickup}</span>
      </div>
      <br/>
      <div className='recentOrderBody'>
        Order #<span className='recentOrderHeavyText'>{this.state.order.hash_id}</span> - ${this.state.orderTotal}
      </div>
    </div>
    {!this.props.noBorder && <div className='recentOrderDivider'></div>}
  </span>
    );
  }
}

export default withRouter(AnalyticsContextHOC(RestContextHOC(UserContextHOC(CartContext(OpenOrder)))));
