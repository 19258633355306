import React from "react";
import "./MenuItemQuantity.css"
import classnames from "classnames"

class MenuItemQuantity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: this.props.quantity
    }
    this.subtract = this.subtract.bind(this)
    this.add = this.add.bind(this)
  }

  componentDidUpdate() {
    if (this.props.quantity !== this.state.quantity) {
      this.setState({ quantity: this.props.quantity });
    }
  }

  add() {
    this.setState({
      quantity: this.state.quantity + 1
    });
    this.props.add();
  }

  subtract() {
    this.setState({
      quantity: Math.max(this.state.quantity - 1, 1)
    });
    this.props.subtract();
  }

  render(){
    return (
      <div className={classnames('quantityContainer')}>
        <div 
          onClick={this.subtract} 
          className={classnames('quantityMinus',
            {'quantityDisabled':this.state.quantity === 1})}
        >
          <Minus/>
        </div>
        <div className='quantityQuantity'>
          <div>{this.state.quantity}</div>
        </div>
        <div onClick={this.add} className='quantityPlus'>
          <Plus/>
        </div>
      </div>
    )
  }
}


const Plus = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
)

const Minus = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 10h24v4h-24z"/></svg>
)

export default MenuItemQuantity;
