import React, { useState } from "react"
import { Capacitor } from "@capacitor/core";
import ReactTooltip from 'react-tooltip';
import classnames from "classnames"
import RestContextHOC from "../../Contexts/RestContextHOC"
import SidebarMenu from "../../Sidebar"
import { Link } from "react-router-dom"
import WalletButton from "../../WalletButton"
import "./RestaurantHeader.css"
import { useWallet } from '@solana/wallet-adapter-react'

const Subdomain = require("../../helpers/subdomain")

const CRYPTO_SUBDOMAINS = ['seabasspizza', 'mayo', 'fatburger-revel-labsystem', 'irazu']

const RestaurantHeader = props => {
  const [expanded, setExpanded] = useState(false)
  const {publicKey} = useWallet();

  const title = props.rest.name + " | Order Online"
  const subdomain = Subdomain.getSubdomain()


  let walletFound = false
  if(window.solana || window.solflare){
    walletFound = true
  }
  let crypto = false
  if(walletFound && props.rest.has_nft_promo){
    crypto = true
  }

  ReactTooltip.rebuild()
  setTimeout(()=>ReactTooltip.rebuild(), 100)

  return (
    <>
      <div
        className="RestaurantHeader"
        style={{
          background: props.rest.hex_color_primary
            ? props.rest.hex_color_primary
            : "#ccc"
        }}
      >
        <ReactTooltip />
        {props.back && (
          <div onClick={props.back} className="RestaurantHeaderBackArrow">
            <svg fill='white' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
          </div>
        )}
        {!window.KIOSK_MODE && (
        <img
          className="RestaurantHeaderMenu"
          src="https://imgcdn.hngr.co/profile.png?size=100x100"
          alt="menu"
          onClick={() => {
            if(props.cart.supperclubWallet){
              window.location.href = process.env.REACT_APP_SUPPERCLUB_URL
            } else {
              setExpanded(!expanded)
            }
          }}
        />
        )}

        {props.rest.has_nft_promo && walletFound && (
          <div 
            className={classnames('header-wallet-button-container',
              {'solana-unconnected-header':!publicKey},
              {'solana-connected-header':publicKey})}
          >
            <div className="wallet-adapter-dropdown">
              <WalletButton />
            </div>
          </div>
        )}

        {props.rest.has_nft_promo && !walletFound && (
          <div 
            className='solana-tool-tip-thing'
             data-tip={`
                <div class='solana-tooltip'>
                  Your browser does not support Solana transactions. Please use Google Chrome  to access this feature.
                </div>
              `}
              data-html="true"
          
          >
            <div style={{marginRight:'8px'}}>Solana Users</div>
            <Info/>

          </div>

        )}

        <Link to="/menu">
          <img
            className="RestaurantHeaderLogo"
            src={props.rest.logo_url}
            alt="logo"
          />
        </Link>
        {expanded && <SidebarMenu closeSidebar={() => setExpanded(false)} />}
      </div>
      {(Capacitor.getPlatform() == 'android' || Capacitor.getPlatform() == 'ios') && 
          !window.KIOSK_MODE && (
        <div 
          className='back-to-supperclub'
          onClick={()=>{
            window.location.href = process.env.REACT_APP_SUPPERCLUB_URL
          }}
        >
          <Back />
          <div>Back To Supper Club</div>
        </div>
      )}
    </>
  )
}

export default RestContextHOC(RestaurantHeader)


const Info = props =>(
<svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0C5.37305 0 0 5.37498 0 12C0 18.6289 5.37305 24 12 24C18.627 24 24 18.6289 24 12C24 5.37498 18.627 0 12 0ZM12 5.32258C13.1224 5.32258 14.0323 6.23245 14.0323 7.35484C14.0323 8.47723 13.1224 9.3871 12 9.3871C10.8776 9.3871 9.96774 8.47723 9.96774 7.35484C9.96774 6.23245 10.8776 5.32258 12 5.32258ZM14.7097 17.6129C14.7097 17.9336 14.4497 18.1935 14.129 18.1935H9.87097C9.55031 18.1935 9.29032 17.9336 9.29032 17.6129V16.4516C9.29032 16.131 9.55031 15.871 9.87097 15.871H10.4516V12.7742H9.87097C9.55031 12.7742 9.29032 12.5142 9.29032 12.1935V11.0323C9.29032 10.7116 9.55031 10.4516 9.87097 10.4516H12.9677C13.2884 10.4516 13.5484 10.7116 13.5484 11.0323V15.871H14.129C14.4497 15.871 14.7097 16.131 14.7097 16.4516V17.6129Z" fill="white"/>
</svg>
)

const Back = props => (
  <svg {...props} width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_4896_34089)">
  <path d="M1.45724 4.18902L4.87955 0.628743C5.04454 0.457086 5.3122 0.457086 5.4772 0.628743L5.87639 1.04405C6.04121 1.21553 6.04139 1.49326 5.8771 1.6651L3.16479 4.49991L5.87692 7.3349C6.04139 7.50674 6.04104 7.78447 5.87622 7.95595L5.47702 8.37126C5.31203 8.54291 5.04437 8.54291 4.87937 8.37126L1.45724 4.8108C1.29225 4.63914 1.29225 4.36068 1.45724 4.18902Z" fill="#424242"/>
  </g>
  <defs>
  <clipPath id="clip0_4896_34089">
  <rect width="8" height="8" fill="white" transform="translate(0 0.5)"/>
  </clipPath>
  </defs>
  </svg>
)
