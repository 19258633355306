import React, { Component } from "react"
import classnames from "classnames"
import RestContextHOC from "../../Contexts/RestContextHOC"
import CartContextHOC from "../../Contexts/CartContextHOC"
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC"
import "./TimeSelect.css"
import { ButtonWrapper } from "../../atoms"
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg"
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg"
import { ReactComponent as OpenIcon } from "../../assets/icons/down-chevron.svg"
import { areValuesEmpty } from "../../helpers/object"

const api = require("../../helpers/api")
const Timestamp = require("../../helpers/timestamps")
const Subdomain = require("../../helpers/subdomain")

class TimeSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hours: null,
      hoursModified: false,
      date: "",
      time: null,
      availableTimestamps: [],
      pickupOrDelivery: this.props.delivery ? "delivery" : "pickup",
    }
    this.dateSelect = React.createRef()
    this.selectDate = this.selectDate.bind(this)
    window.t = this
  }

  componentDidMount() {
    if (this.props.rest.futureHours) {
      this.modifyHours()
    }

    //if we are coming from the cart update button , not the landing page
    if (!this.fromLanding()) {
      // if this is clicked from the cart, load the saved value
      // if it exists, otherwise initialize this
      if (this.props.cart.futureOrderTime) {
        this.loadValueFromCart()
      } else{
        this.setInitialDate()
      }
    }

    if(this.props.suggestedTime){
      this.loadValueFromCart()
    }

  }

  componentDidUpdate(prevProps, prevState) {
    //if future hours have changed, regenerate the drodown list
    if (
      JSON.stringify(prevProps.rest.futureHours) !==
      JSON.stringify(this.props.rest.futureHours)
    ) {
      this.modifyHours()
    }

    // load value from cart on a refresh
    if (
      !prevProps.cart.futureOrderTime && 
      this.props.cart.futureOrderTime &&
      !this.fromLanding()
    ) {
      this.loadValueFromCart()
    }

    // if ASAP is selected going into the checkout page
    if (
      this.fromLanding() &&
      this.props.cart.futureOrderTimeLoaded &&
      !this.props.cart.futureOrderTime &&
      this.state.hours &&
      !this.state.date
    ) {
      console.log("DidUpdateSettoAsap", this.state.date)
      this.setToASAP()
    }

    if(this.state.time != prevState.time){
      if(this.props.updateTime){
        this.props.updateTime(this.state.time)
      }
    }
  }

  fromLanding(){
    if(
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromLanding
    ) {
      return true
    } else{
      return false
    }
  }

  loadValueFromCart() {
    if (!this.props.rest.futureHours || !this.props.cart.futureOrderTimeLoaded) {
      console.log("loadvaluefromcart", "notready")
      setTimeout(() => this.loadValueFromCart(), 100)
      return
    }
    // we need to set the time to futureOrderTIme but we also need to extract
    // the day from it... Let's hack it by finding which key is <= the time but not
    // more than 24 hours less than
    let timestamp = this.props.cart.futureOrderTime
    if(this.props.suggestedTime) timestamp = this.props.suggestedTime
    
    // this is passed in from CheckerPage. It's basically saying, if we were on
    // pickup and changed the time, and now switched to delivery... try to use
    // the changed time from pickup... if it's valid for delivery. Otherwise
    //if(this.props.suggestedTime) timestamp = this.props.suggestedTime


    // verify the timestmap is still valid
    if (timestamp) {
      let valid = false
      for (let d in this.props.rest.futureHours[
        this.props.pickup_or_delivery
      ]) {
        for (let j in this.props.rest.futureHours[
          this.props.pickup_or_delivery
        ][d]) {
          let ts = this.props.rest.futureHours[this.props.pickup_or_delivery][
            d
          ][j]
          if (parseInt(ts) === parseInt(timestamp)) {
            valid = true
          }
        }
      }

      if (!valid) {
        //if we got here, that means that that cart.futureOrderTime
        //is a bad value
        this.props.analytics.warn(
          "BadFutureOrderTimeLoadedFromCart",
          this.props.cart.futureOrderTime
        )
        this.props.cart.setFutureOrderTime(null)
        // unset pickup and delivery so they're foced to go through
        // the checker again
        this.props.cart.unsetPickupDelivery()
        this.setState({ time: null }, ()=>this.props.updateTime())
        this.setInitialDate()
        return
      }
    }
    let secondsInADay = 60 * 60 * 24
    for (let ts in this.props.rest.futureHours[this.props.pickup_or_delivery]) {
      // sepcial hack for ASAP
      if (timestamp === 0) {
        this.setState({
          date: ts,
          time: timestamp,
        })
        return
      }

      if (timestamp >= ts && timestamp - secondsInADay < ts) {
        this.setState({
          date: ts,
          time: timestamp,
        })
        return
      }
    }
  }

  setToASAP() {
    let date = Object.keys(this.state.hours[this.props.pickup_or_delivery])[0]
    if(date){
      this.setState({
        date: date,
        time: 0,
      })
    }
  }

  setInitialDate() {
    /*
    if (
      this.props.cart.futureOrderTime &&
      !this.fromLanding()
    ){
      return
    }*/

    if (
      this.props.rest.futureHours &&
      areValuesEmpty(this.props.rest.futureHours[this.props.pickup_or_delivery])
    ) {
      return
    }

    if (this.props.rest.futureHours && this.props.pickup_or_delivery && this.state.hours) {
      if (this.props.rest.futureHours["open"][this.props.pickup_or_delivery]) {
        if (Object.keys(this.props.rest.futureHours[this.props.pickup_or_delivery]).length) {
          this.handleChangeDate(
            Object.keys(
              this.props.rest.futureHours[this.props.pickup_or_delivery]
            )[0],
            true
          )
        }
      } else {
        let date = "false"
        this.handleChangeDate(date, true)
      }
    } else {
      window.setTimeout(() => {
        this.setInitialDate()
      }, 100)
    }
  }

  modifyHours() {
    if (this.state.hoursModified) return
    let data = JSON.parse(JSON.stringify(this.props.rest.futureHours))
    // throw "ASAP' into the first row for pickup
    let firstDate = Object.keys(data["pickup"])[0]
    if (data["open"]["pickup"] && data["pickup"][firstDate] && this.props.rest.allow_asap_orders) {
      data["pickup"][firstDate].unshift(0)
    }

    // throw "ASAP' into the first row for delivery
    if (data["open"]["delivery"] && data["delivery"][firstDate] && this.props.rest.allow_asap_orders) {
      data["delivery"][firstDate].unshift(0)
    }

    this.setState({ hours: data, hoursModified: true }, () => {
      // if suggestedTime is set..... loadvaluefromcart gets called, which
      // will set the initial date
      if(this.fromLanding() && !this.props.suggestedTime){
        this.setInitialDate()
      }
    })
  }

  handleChangeDate(timestamp, dontUpdateTime) {
    console.log("handlechangedate", timestamp)
    console.log("handlechangedatedontupdatetime", dontUpdateTime)
    if(timestamp === "false"){
     this.setState({time: null, date:"false"}, ()=>this.props.updateTime())
     return
    }


    let time
    if(this.futureOrderingOnly()){
      time = "false" // select a time
    } else{
      time = this.state.hours[this.props.pickup_or_delivery][timestamp][0]
    }
    if (!dontUpdateTime) {
      this.setState({ date: timestamp, time: time })
      this.props.analytics.info("TimeSelectChangeDate", timestamp)
    } else {
      this.setState({ date: timestamp })
    }

  }

  handleChangeTime(timestamp) {
    this.props.analytics.info("TimeSelectChangeTime", timestamp)

    if (timestamp === "false") {
       //this.props.cart.setFutureOrderTime(null)
       this.setState({ time: null }, ()=>this.props.updateTime())
       return
    } 

    if (timestamp === "0") {
      this.setState({ time: timestamp })
      //this.props.cart.setFutureOrderTime(null)
    } else {
      this.setState({ time: timestamp })
      //this.props.cart.setFutureOrderTime(timestamp)
    }
  }

  futureOrderingOnly() {
    if (
      !this.state.hours["open"][this.props.pickup_or_delivery] ||
      !this.props.rest.allow_asap_orders
    ){
      return true
    } else {
      return false
    }
  }

  renderDates() {
    if (!this.props.rest.futureHours || !this.state.hours) {
      return <option>loading...</option>
    }

    let dates = []

    if(this.futureOrderingOnly()){
      dates.push(
        <option
          key="Select a Date"
          value={false}
          className="time-select__option"
        >
          Select a Date
        </option>
      )
    }

    let firstIteration = true
    for (let timestamp in this.props.rest.futureHours[
      this.props.pickup_or_delivery
    ]) {
      let mm = Timestamp.getMonthInt(timestamp)
      let dd = Timestamp.getDayOfMonth(timestamp)
      let dow = Timestamp.getDayOfWeekFull(timestamp)

      // so this block of code needs to not fire
      // Write 'Today' for today instead of 'DOW, dd/mm'
      if (
      
        firstIteration && 
        ((this.props.rest.futureHours["open"][this.props.pickup_or_delivery] && this.props.rest.allow_asap_orders)||
         (timestamp === this.props.rest.futureHours['today'] && this.props.rest.futureHours[this.props.pickup_or_delivery][timestamp] && this.props.rest.futureHours[this.props.pickup_or_delivery][timestamp].length))
      ){

        //if there is future ordering OR ASAP is available
        dates.push(
          <option
            key={timestamp}
            value={timestamp}
            className="time-select__option"
          >
            Today
          </option>
        )
      }
      
      if(timestamp !== this.props.rest.futureHours['today']){
        // don't show closed days
        if (
          this.props.rest.futureHours[this.props.pickup_or_delivery][timestamp]
            .length
        ) {
          dates.push(
            <option key={dd} value={timestamp}>
              {dow}, {mm}/{dd}
            </option>
          )
        }
      }
      firstIteration = false
    }
    return dates
  }

  renderTimes() {
    //render times if this.state.hours and this.state.date
    if (this.state.hours && this.state.date) {
      let date = this.state.date
      let hours = this.state.hours[this.props.pickup_or_delivery][date]
      // if the rest is closed, put "select a time" as the default option
      // to force them to make a selection
      let times = []

      if (this.futureOrderingOnly()) {
        times.push(
          <option 
            key="-1" value="false">
            Select A Time
          </option>
        )
      }

      for (let i in hours) {
        let timestamp = hours[i]

        if (timestamp !== 0) {
          times.push(
            <option key={timestamp} value={timestamp}>
              {Timestamp.getTimeOfDay(timestamp)}
            </option>
          )
        } else {
          times.push(
            <option key={timestamp} value={timestamp}>
              ASAP
            </option>
          )
        }
      }
      return times
    } else {
      return <option key={-99}>Loading..</option>
    }

  }

  selectDate = (e) => {
    e.stopPropagation()
    if (
      this.dateSelect &&
      this.dateSelect.current &&
      e.target !== this.dateSelect.current
    ) {
      this.dateSelect.current.click()
    }
  }

  render() {
    if (!this.props.rest.allow_future_orders) return null
    return !this.props.rest.futureHours ? null : (
      <div
        className={classnames("timeSelectContainer")}
      >
        <div className="time-select__partial-container">
          <ButtonWrapper
            as="span"
            className="selectdiv"
            onClick={this.selectDate}
            propagate
          >
            <select
              id="dateselect"
              ref={this.dateSelect}
              value={this.state.date}
              onChange={(e) => this.handleChangeDate(e.target.value)}
            >
              {this.renderDates()}
            </select>
            <OpenIcon className="bg-responsive-svg" onClick={this.selectDate} />
          </ButtonWrapper>
        </div>
        
        <div 
          className="time-select__partial-container"
          style={this.state.borderRedOnDateSelect && !this.state.time && !this.props.rest.hours.delivery.open && this.props.rest.allow_future_orders ? {borderColor:'red'} : null}
        >
          <ButtonWrapper
            as="span"
            className={classnames("selectdiv", {'timeselect-disabled':
              this.state.date === 'false'})}
            onClick={this.selectDate}
            propagate
          >
            <select
            className={classnames({'timeselect-disabled':
              this.state.date === 'false'})}
              value={this.state.time}
              onChange={(e) => this.handleChangeTime(e.target.value)}
            >
              {this.renderTimes()}
            </select>
            <OpenIcon className="bg-responsive-svg" onClick={this.selectDate} />
         </ButtonWrapper>
            {this.state.date === 'false' && (
              <div className='checkerpage-select-date-warning'>
                Select a date first
              </div>
            )}
 
        </div>
      </div>
    )
  }
}

export default AnalyticsContextHOC(CartContextHOC(RestContextHOC(TimeSelect)))
