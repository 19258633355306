import React, { Component } from "react"
import LoginInput from "./LoginInput"
import ThirdPartyLogin from './ThirdPartyLogin'
import ThirdPartySignup from "./ThirdPartySignup"
import RestContextHOC from "../Contexts/RestContextHOC"
import UserContextHOC from "../Contexts/UserContextHOC"
import CartContextHOC from "../Contexts/CartContextHOC"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import SignupModalContextHOC from "../Contexts/SignupModalContextHOC"
import Loading from "../Loading/Loading"
import Modal from "../Modal/Modal"
import Checkbox from "../sparkles/Checkbox/Checkbox"
import "../css/LoginSignup.css"
import { withRouter } from "react-router-dom"
const { detect } = require('detect-browser')

const browser = detect()
const Api = require("../helpers/api")
const LoginHelper = require("../helpers/login")
const Subdomain = require("../helpers/subdomain")

class Login extends Component {
  constructor(props) {
    super(props)

    let PromoOptIn = null
    if (this.props.rest.collect_extra_signup_data) {
      PromoOptIn = true
    }

    // we should be using this to determine the flow
    // ie are they coming from */login or */signup
    let flow = null
    if (window.location.pathname.indexOf('/auth') !== -1) {
      flow = 'signin'
    } else {
      flow = 'signup'
    }

    // browser ID for apple pay
    let isAppleOS = false
    if (browser && browser.os) {
      if (browser.os === 'iOS' || browser.os === 'Mac OS') {
        isAppleOS = true
      }
    }

    this.state = {
      stage: 0,
      email: "",
      password: "",
      flow: flow,
      isAppleOS: isAppleOS,
      primary_color: props.rest.hex_color_primary,
      secondary_color: props.rest.hex_color_secondary,
      loading: this.props.rest.name ? false : true,
      fromOauthRedirect: false,
      PromoOptIn: PromoOptIn
    }

    this.forgotPassword = this.forgotPassword.bind(this)
    this.handleEsc = this.handleEsc.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.sendToCreateAccount = this.sendToCreateAccount.bind(this)
  }

  componentDidMount() {
    this.props.analytics.debug("LoginPage")
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rest !== this.props.rest) {
      let PromoOptIn = false
      if (this.props.rest.collect_extra_signup_data) {
        PromoOptIn = true
      }
      this.setState({
        PromoOptIn: PromoOptIn,
        loading: false,
        primary_color: this.props.rest.hex_color_primary,
        secondary_color: this.props.rest_color_secondary,
      })
    }
  }

  // close current modal and open the password reset modal
  forgotPassword() {
    if (window.location.href.indexOf('anon_password_reset') === -1){
      let url = this.props.location.pathname.split('/')
      let pathname = url[0] + '/' + url[1] + '/anon_password_reset'
      pathname = pathname.replace("/auth", "")
      this.props.history.push({
        pathname: pathname,
        state:{
          ...this.props.location.state
        }
      })
    }
  }

  updateStage(stg) {
    let clickstream_text =
      stg === 0 ? "ClickedBackToLoginOptions" : "ClickedEmailLogin"
    this.props.analytics.info(clickstream_text)
    this.setState({ stage: stg })
  }

  renderStage() {
    return (
      <LoginInput
        flow={this.state.flow}
        data={this.state}
        updateStage={(stg) => this.updateStage(stg)}
        updateError={this.props.updateError}
      />
    )
  }

  renderSecondaryLink() {
      return (
        <div>
          <div className="secondaryLink" onClick={this.forgotPassword}>
            Forgot Password
          </div>
        </div>)
  }

  renderOAuth() {
    if (this.state.stage == 0) {

    return <div>
      {this.state.flow === 'signup' && (
        <div className='loginButtons'>
          {this.state.isAppleOS && 
        <ThirdPartySignup
          type='apple'
          flow={this.state.flow}
          sendTo={this.props.sendTo}
          PromoOptIn={this.state.PromoOptIn}
          isLogin={true}
          isSignup={this.props.isSignup}
        />
      }
      <ThirdPartySignup
        type='google'
        flow={this.state.flow}
        sendTo={this.props.sendTo}
        PromoOptIn={this.state.PromoOptIn}
        isLogin={true}
        isSignup={this.props.isSignup}
      />

      {!this.state.isAppleOS &&
          <ThirdPartySignup
            type='apple'
            flow={this.state.flow}
            sendTo={this.props.sendTo}
            PromoOptIn={this.state.PromoOptIn}
            isLogin={true}
            isSignup={this.props.isSignup}
          />
      }

      <ThirdPartySignup
        type='facebook'
        flow={this.state.flow}
        sendTo={this.props.sendTo}
        PromoOptIn={this.state.PromoOptIn}
        isLogin={true}
        isSignup={this.props.isSignup}
      />

      {window.solana && this.props.rest.allow_crypto_oauth && 
        <ThirdPartySignup
          type='solana'
          flow={this.state.flow}
          sendTo={this.props.sendTo}
          PromoOptIn={this.state.PromoOptIn}
          isLogin={true}
          isSignup={this.props.isSignup}
        />
      }

      <hr style={{border: '1px solid #eee', width: '40%'}}/>
        </div>
      )}

      {this.state.flow === 'signin' && (
        <div className='loginButtons'>
          {this.state.isAppleOS && 
        <ThirdPartyLogin
          type='apple'
          flow={this.state.flow}
          sendTo={this.props.sendTo}
          PromoOptIn={this.state.PromoOptIn}
          isLogin={true}
          isSignup={this.props.isSignup}
        />
      }
      <ThirdPartyLogin
        type='google'
        flow={this.state.flow}
        sendTo={this.props.sendTo}
        PromoOptIn={this.state.PromoOptIn}
        isLogin={true}
        isSignup={this.props.isSignup}
      />

      {!this.state.isAppleOS &&
          <ThirdPartyLogin
            type='apple'
            flow={this.state.flow}
            sendTo={this.props.sendTo}
            PromoOptIn={this.state.PromoOptIn}
            isLogin={true}
            isSignup={this.props.isSignup}
          />
      }

      <ThirdPartyLogin
        type='facebook'
        flow={this.state.flow}
        sendTo={this.props.sendTo}
        PromoOptIn={this.state.PromoOptIn}
        isLogin={true}
        isSignup={this.props.isSignup}
      />
      
      {window.solana && this.props.rest.allow_crypto_oauth && <ThirdPartyLogin
        type='solana'
        flow={this.state.flow}
        sendTo={this.props.sendTo}
        PromoOptIn={this.state.PromoOptIn}
        isLogin={true}
        isSignup={this.props.isSignup}
      />}

      <hr style={{border: '1px solid #eee', width: '40%'}}/>
        </div>
      )}
    </div>
      
    }
  }

  renderCloseButton() {
    return (
      <img
        alt="close"
        src="/close.png"
        className="closeButton"
        onClick={() => this.handleClose()}
      />
    )
  }

  sendToCreateAccount() {
    let pathname = '/menu/signup'
    const params = new URLSearchParams(window.location.search)
    if (params.get('sendTo')) {
      pathname += '?sendTo=' + params.get('sendTo')
    }
    this.props.history.replace({pathname: pathname})
  }

  // make sure we remove both signup and auth pathing
  handleClose() {
    this.props.analytics.info('EmailLoginClose')
    let lastPath = window.location.pathname.replace("/auth", "")
    lastPath = lastPath.replace("/signup", "")
    this.props.history.push({pathname: lastPath})
  }

  handleEsc(e) {
    if (e.keyCode === 27) {
      e.preventDefault()
      this.handleClose()
      return
    }
  }

  handleClickOutside() {
    this.handleClose()
  }

  render() {
    if (this.state.loading) {
      return (
        <Modal
          close={this.handleClickOutside}
          style={{ textAlign: "center", paddingTop: "24px" }}
        >
          <Loading />
        </Modal>
      )
    } else {
      return (
        <Modal
          close={this.handleClickOutside}
          style={{ textAlign: "center" }}
        >

          {this.state.flow === 'signin' && (
            <div className="header">Sign In</div>
          )}
          {this.state.flow === 'signup' && (
            <div className="header">Sign Up</div>
          )}
          <br />

          {this.state.flow == 'signin' && <div>
            Don't have an account? <a className='regularHotlink'
              onClick={() => this.sendToCreateAccount()}>Sign Up</a>
          </div>}
  
          {this.renderOAuth()}

          {this.renderStage()}

          {this.state.flow == 'signin' && this.renderSecondaryLink()}

          {this.state.stage === 0 && this.props.rest.collect_extra_signup_data && (
            <div className='inputContainer' style={{display:'flex', alignItems:'center', marginTop:'20px'}}>
              <div 
                onClick={()=>{
                  this.setState({PromoOptIn: !this.state.PromoOptIn})
                }}>
                <Checkbox 
                  checked={this.state.PromoOptIn}/>
              </div>
              <div style={{paddingLeft:'15px', textAlign:'left'}}>
                Send me emails about promotions, deals and rewards
              </div>
            </div>
          )}

          <div className="finePrintBottom" style={{marginTop: 20, marginBottom: 20}}>
            View our <a href={this.props.rest.phone_modal_terms_url}
            target="_blank"
            rel="noopener noreferrer">
            <u>Terms of Service</u>
          </a> and <a href={this.props.rest.phone_modal_privacy_url}
            target="_blank"
            rel="noopener noreferrer">
            <u>Privacy Policy</u>
          </a>
          </div>

        </Modal>
      )
    }
  }
}

export default withRouter(SignupModalContextHOC(
  AnalyticsContextHOC(CartContextHOC(UserContextHOC(RestContextHOC(Login))))
))
