import React from "react";
import "./QuantityInput.scss";
const QuantityInput = ({ onChange, value, overLimit }) => (
  <input
    onChange={e => onChange(e.target.value)}
    value={value}
    type="number"
    pattern="\d*"
    className={overLimit ? "quantity-input exceed" : "quantity-input"}
  />
);

export default QuantityInput;
