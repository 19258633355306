import React from "react";
import classnames from 'classnames'
import RestContextHOC from "../Contexts/RestContextHOC";
import BooleanCircle from "../sparkles/BooleanCircle/BooleanCircle.jsx";
import '../css/MenuItemModal.css'

class RadioModifier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      selectedKey: props.selectedKey,
      modifierId: props.modifierId,
      blackedOut:false
    };
    this.toggleHover = this.toggleHover.bind(this);
    this.click = this.click.bind(this);
  }


  // if theere is a coupon modifier selected, then ignore any other is-defaults
  componentDidMount() {
    this.checkIfBlackedOut()
    if(this.props.editModifiers){
      for(var i in this.props.editModifiers){
        if(this.props.editModifiers[i].modifier_id === this.props.modifier.modifier_id){
          this.click(null, this.props.editModifiers[i])
        }
      }
    }else if(this.props.couponModifierId){
      if( this.props.modifier.modifier_id === this.props.couponModifierId ){
        this.click()
      }
    } else{
      if (this.props.modifier.is_default ) {
        this.click()
      }
    }
  }


  // so we are gonna need one initialize for coming from EDIT 
  // and one for being a new item
  //..... we will also need to change addToCAa

  componentDidUpdate(prevProps){
    if(
      prevProps.rest.itemBlackouts.length !=
      this.props.rest.itemBlackouts.length
    ){
      this.checkIfBlackedOut()
    }
  }

  checkIfBlackedOut(){
    if(!this.props.modifier.item) return

    let menu_item_id = this.props.modifier.item.menu_item_id
    if(this.props.rest.itemBlackouts.indexOf(menu_item_id) > -1){
      this.setState({blackedOut:true})
    } else{
      this.setState({
        blackedOut:false
      })
    }
  }

  /* toggleHover :: toggles the HOVER state on the component
     so you can do things like change color, etc on hover */
  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }

  /* click :: function executes on click */
  click(e, editModifier) {
    this.props.onChange(this.state.modifierId, this.props.modifier, editModifier);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ selectedKey: props.selectedKey });
  }

  

  renderPrice(){
    if(this.props.modifier.display_price){
      var string_price = this.props.modifier.display_price
      var float_price = parseFloat(string_price.substring(2), 10)
      

      if(float_price > 0){
        return(
          <div
                className="priceSmall"
                style={{
                  ...styles.price,
                  fontWeight:
                    this.props.selectedKey === this.state.modifierId ? "600" : "400"
                }}
              >
                {this.props.modifier.display_price}
              </div>
          )
      }
    }
  }

  containerStyle(){
    let style
    if (this.state.hover){
      style = JSON.parse(JSON.stringify(styles.containerHover))
    } else {
      style = JSON.parse(JSON.stringify(styles.container))
    }
    if(this.state.blackedOut){
      style['pointerEvents'] = 'none'
      style['opacity'] = 0.5
    }
    return style
  }


  render() {
    const isSelected = this.state.selectedKey === this.state.modifierId
    return (
      <div
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        onClick={this.click}
        style={this.containerStyle()}
      >
        <div>
          <BooleanCircle
            filled={
              isSelected
            }
            fillColor={this.props.rest.hex_color_primary}
            diameter={40}
          />
        </div>
        <div className={classnames('modifier__name', {
          'modifier__name--selected': isSelected
        })}>{this.props.modifier.name}</div>
        {this.renderPrice()}
        
        <div />
      </div>
    );
  }
}

var styles = {
  container: {
    padding: "5px",
    display: "flex",
    alignItems: "center"
  },

  containerHover: {
    backgroundColor: "#eee",
    padding: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  },
  price: {
    flexGrow: "1",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "12px",
    fontWeight: "400",
    color: "#22252B"
  }
};

export default RestContextHOC(RadioModifier);
