import React, { Component } from "react"
import RestContextHOC from "../../Contexts/RestContextHOC"
import UserContextHOC from "../../Contexts/UserContextHOC"
import CartContextHOC from "../../Contexts/CartContextHOC"
import AnalyticsContextHOC from "../../Contexts/AnalyticsContextHOC"
const formatPrice = require("../../helpers/formatPrice")

import "./CreditCardFee.css"
import { Fee } from "./fee"
import { Stars } from "./stars"
import { Thumb } from "./thumb"
import { Alert } from "./alert"

class CreditCardFee extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let fee = this.props.fee

    return (
      <div className="element-row">
        <div className="payment-method-fee" style={{ width: "100%" }}>
          <Fee />
          <div>
            <div className="credit-card-fee-header">
              Save {formatPrice.formatPrice(fee)} by paying with cash
            </div>
            <div className="credit-card-fee-description">
              An additional fee of {formatPrice.formatPrice(fee)} applies when
              paying with a credit or debit card.
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const FeeNotification = ({ fee }) => (
  <div className="element-row">
    <div
      className="payment-method-fee payment-method-blue"
      style={{ width: "100%" }}
    >
      <Stars />
      <div>
        <div className="credit-card-fee-header">
          Save with debit card or cash
        </div>
        <div className="credit-card-fee-description">
          Pay with a debit card or cash instead of credit to save{" "}
          {formatPrice.formatPrice(fee)}
        </div>
      </div>
    </div>
  </div>
)

export const AvoidCardFees = () => (
  <div className="element-row">
    <div
      className="payment-method-fee payment-method-blue"
      style={{ width: "100%" }}
    >
      <Stars />
      <div>
        <div className="credit-card-fee-header">Save by paying with cash</div>
        <div className="credit-card-fee-description">
          Additional fees apply when paying with a credit or debit card
        </div>
      </div>
    </div>
  </div>
)

export const AvoidApplePayFees = () => (
  <div className="element-row">
    <div
      className="payment-method-fee payment-method-blue"
      style={{ width: "100%" }}
    >
      <Stars />
      <div>
        <div className="credit-card-fee-header">Save by paying with cash</div>
        <div className="credit-card-fee-description">
          Additional fees apply when paying with Apple Pay 
        </div>
      </div>
    </div>
  </div>
)

export const DebitSavings = ({ brand, fee }) => (
  <div className="element-row">
    <div
      className="payment-method-fee payment-method-green"
      style={{ width: "100%" }}
    >
      <Thumb />
      <div>
        <div className="credit-card-fee-header">
          Thank you for paying with debit
        </div>
        <div className="credit-card-fee-description">
          You saved {formatPrice.formatPrice(fee)} by paying with a {brand}{" "}
          debit card
        </div>
      </div>
    </div>
  </div>
)

export const ApplePayAlert = () => (
  <div className="element-row">
    <div
      className="payment-method-fee payment-method-yellow"
      style={{ width: "100%" }}
    >
      <Alert />
      <div>
        <div className="credit-card-fee-header">Apple/Google Pay</div>
        <div className="credit-card-fee-description">
          Earn a discount only if your account is linked to a debit card
        </div>
      </div>
    </div>
  </div>
)

export default RestContextHOC(
  AnalyticsContextHOC(CartContextHOC(UserContextHOC(CreditCardFee)))
)
