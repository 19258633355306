import React, { Component } from "react"
import AppPage from "./AppPage"
import MenuLoading from "./Menu/MenuLoading/MenuLoading"
import EntryPointHOC from "./Contexts/EntryPointHOC"
import CartContextHOC from "./Contexts/CartContextHOC"
import AnalyticsContextHOC from "./Contexts/AnalyticsContextHOC"
import { withRouter } from "react-router-dom"

class LandingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      restLoaded: false,
      cartLoaded: false,
      isLoaded: false,
      load_start: new Date(),
    }
    this.props.rest.getHours()
  }

  componentDidMount() {
    window.INITIAL_TS = Date.now()
    window.RECORDED_INITIAL_LOAD = false
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.restLoaded &&
      prevProps.entryPoint.rest === null &&
      this.props.entryPoint.rest !== null
    ) {
      this.setState({ restLoaded: true })
    }

    if (
      !this.state.cartLoaded &&
      this.props.cart.pickupOrDeliveryLoaded &&
      this.props.cart.futureOrderTimeLoaded
    ) {
      this.props.cart.setFutureOrderTime(null)
      this.setState({ cartLoaded: true })
    }

    if (
      this.state.cartLoaded &&
      this.state.restLoaded &&
      this.props.rest.hours &&
      this.props.rest.sandboxedMenuLoaded &&
      !this.state.isLoaded
    ) {
      this.setState({ isLoaded: true })
      const load_finish = Date.now()
      const load_time = (load_finish - this.state.load_start) / 1000
      this.props.analytics.info("LandingPageLoaded", load_time)

      // don't load the checker page if we're sandboxed in
      if (this.props.rest.isLocked()) {
        let path = `/menu`
        this.props.history.push({
          pathname: path,
        })
        return
      }

      if(window.KIOSK_MODE){
        this.props.cart.setPickup()
        this.props.history.replace("/menu")
        return
      } 

      let path = `/menu/start`

      // if we have a fb pixel, then initialize the pixel
      if (this.props.rest.facebook_pixel_id) {
        const pixelId = this.props.rest.facebook_pixel_id
        this.props.analytics.info("FacebookPixelInit", pixelId)
        this.props.analytics.setFacebookTrackingId(pixelId)
      }

      // if we have a GA conversion tracking ID, this will send a
      // specific conversion event that is passed into google tag mgr
      // see: https://github.com/blpt-hngr/foodcoin/issues/956
      if (this.props.rest.gtag_conversion_id) {
        const googleConversionID = this.props.rest.gtag_conversion_id
        this.props.analytics.info("GTagConversionInit", googleConversionID)
        this.props.analytics.setGoogleConversionId(googleConversionID)
      }

      // note: we pass the location.search (GET params) thru to the next
      // page. this helps in case we have stuff like UTMs or other params
      // in the URL we want to retain for logging, etc
      this.props.history.push({
        pathname: path,
        search: document.location.search,
        state: {
          fromLanding: true,
          pickup: this.props.rest.hours.pickup.open,
          delivery: this.props.rest.hours.delivery.open,
        },
      })
    }
  }

  render() {
    return (
      <AppPage dont_load_contexts={true} {...this.props}>
        <MenuLoading rest={this.props.rest} />
      </AppPage>
    )
  }
}

export default withRouter(
  AnalyticsContextHOC(CartContextHOC(EntryPointHOC(LandingPage)))
)
