import React from "react";
import styles from "./MenuCategoryMobile.module.scss";
import { ReactComponent as MenuDropdown } from "./MenuDropdown.svg";
import MenuSearch from "../MenuSearch/MenuSearch";
import { ReactComponent as ModalIcon } from "../../helpers/ModalIcon.svg";

const categorySorter = require("../../helpers/menuCategorySorter");

class MenuCategoryMobile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: props.selectedCategory ? props.selectedCategory : props.categories[Object.keys(this.props.categories)[0]],
      expanded: false,
      categoryHidden:this.props.rest.categoryHidden,
      categoryGreyedOut:this.props.rest.categoryGreyedOut,
    };
    this.list = [];
    this.toggleOpen = this.toggleOpen.bind(this);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);
  }

  componentDidUpdate(prevProps){
    if( 
      (JSON.stringify(prevProps.rest.categoryGreyedOut) !==  
      JSON.stringify(this.props.rest.categoryGreyedOut)) 
      ||  
      (JSON.stringify(prevProps.rest.categoryHidden) !==  
      JSON.stringify(this.props.rest.categoryHidden)) 
    ){  
      this.setState({
        categoryHidden:this.props.rest.categoryHidden,
        categoryGreyedOut:this.props.rest.categoryGreyedOut
      })
    }   
  }

  toggleOpen() {
    //update parent menu to return the grey background to focus on categories
    this.props.setExpanded();

    this.setState(
      prevState => ({ expanded: !prevState.expanded }),
      () => {
        this.props.setMobileExpanded(this.state.expanded);
        //animate the children to slide in

        if (this.list && this.state.expanded) {
          this.list.forEach((child, index) => {
            if (child) {
              const listLen = this.list.length;
              let { style } = child;

              const delay = `${index / (listLen * 2)}s`;
              style.opacity = "0";
              style.animation = "animateCategories 100ms linear";
              style.animationDelay = delay;
              style.animationFillMode = "forwards";
            }
          });
          // document.body.style.overflow = "hidden";
          // document.body.style.position = "relative";
          // document.documentElement.style.overflow = "hidden";
          // document.documentElement.style.position = "relative";
          // document.documentElement.style.height = "100%";
        }
        // else {
        //   document.body.style.overflow = "";
        //   document.body.style.position = "";
        //   document.documentElement.style.overflow = "";
        //   document.documentElement.style.position = "";
        //   document.documentElement.style.height = "";
        // }
      }
    );
  }

  handleCategoryClick(category, selectedIdx) {
    this.props.scrollToCategory(category, false, selectedIdx);

    this.setState({ selectedCategory: category });

    //close the dropdown
    this.toggleOpen();
  }

  renderCategoryHeader(){
    const { selectedCategory } = this.props;
    if(selectedCategory && selectedCategory.name){
      return (
        <>
        {selectedCategory && selectedCategory.image_url && <img src={selectedCategory.image_url} alt="category" className={styles.foodmoji}/>}
        <div>{selectedCategory && selectedCategory.name}</div>
        </>
      )
    } else {
      return <div>Select a Category</div>
    }
  }

  render() {
    let that = this
    const container = {
      marginRight: "24px",
      marginTop: "0px",
      marginBottom: "4px"
    };
    const { selectedCategory } = this.props;
    const { expanded } = this.state;
    return (
      <div id='Categories'>
        <div className={styles.header}>
          <div onClick={!expanded ? this.toggleOpen : undefined} className={styles.selected}>
            {expanded ? (
              <MenuSearch
                onSearchChange={this.props.onSearchChange}
                value={this.props.value}
                customStyle={{ container }}
              />
            ) : (
              this.renderCategoryHeader()
            )}
            <span
              className={expanded ? styles.dropIconExpanded : styles.dropIcon}
              onClick={
                this.state.expanded
                  ? this.props.value
                    ? this.props.clearSearch
                    : this.toggleOpen
                  : undefined
              }
            >
              {expanded && this.props.value ? (
                <ModalIcon />
              ) : (
                <>
                <MenuDropdown />
                {!expanded && <img alt='search' src='https://cdn.hngr.co/search-512.png' style={{height: '16px', opacity: '.3', marginLeft: "16px"}}/>}
                </>
              )}
            </span>
          </div>

          {expanded &&
            (!this.props.value && (
              <ul className={styles.list}>
                {categorySorter
                  .sortCategories(this.props.categories)
                  .map((category, index) => {
                    const selected =
                      category.menu_category_id === this.props.menu_category_id;
                    let blackoutClass = ''
                    if(that.state.categoryHidden.indexOf(category.menu_category_id) > -1){
                      blackoutClass = ' ' + styles.BlackedOutMobileCategory + ' '
                    } else if(that.state.categoryGreyedOut.indexOf(category.menu_category_id) > -1){
                      blackoutClass = ' ' + styles.GreyedOutMobileCategory + ' '
                    } 

                    return (
                      <li
                        onClick={() =>
                          this.handleCategoryClick(category, index)
                        }
                        className={
                          selected ? styles.category__selected + blackoutClass 
                            : styles.category + blackoutClass
                        }
                        key={category.menu_category_id}
                        ref={el => (this.list[index] = el)}
                      >
                        {category.name}
                      </li>
                    );
                  })}
              </ul>
            ))}
        </div>
      </div>
    );
  }
}

export default MenuCategoryMobile;
