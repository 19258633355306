import React, { Component } from 'react'
import UserContextHOC from './UserContextHOC'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel';
import localforage from 'localforage';
import * as Sentry from "@sentry/browser";
import LogRocket from 'logrocket';
import { v4 as uuidv4 } from 'uuid';
const api = require("../helpers/api")
const { detect } = require('detect-browser');

const browser = detect()
const SESSION_TIMEOUT = 5400000
const CLICKSTREAM_PRODUCT = 'HNGR_ONLINE_ORDERING'
const BRANDPAGE_PRODUCT = 'HNGR_BRAND_PAGE_LOCATIONS'

export const AnalyticsContext = React.createContext()

class AnalyticsProvider extends Component {

  constructor(props){
    super(props)

    let browserName = null
    let browserVersion = null
    let browserOS = null

    if (browser) {
      browserName = browser.name
      browserVersion = browser.version
      browserOS = browser.os
    }

    // this gets cleared on a refresh

    this.state = {
      send: (act, label) => this.send(act, label),
      info: (act, label) => this.info(act, label),
      conversion: (id, val) => this.conversion(id, val),
      debug: (act, label) => this.debug(act, label),
      warn: (act, label) => this.warn(act, label),
      error: (act, label) => this.error(act, label),
      sendHam: (eventName, payload) => this.sendHam(eventName, payload),
      pageview: (url) => this.pageview(url),
      browser: () => this.browser(),
      utms: () => this.utms(),
      versionId: () => this.versionId(),
      sessionId: () => this.sessionId(),
      setSessionId: (sess_id) => this.setSessionId(sess_id),
      setLogrocket: ()=> this.setLogrocket(),
      setFacebookTrackingId: (fbid) => this.setFacebookTrackingId(fbid),
      setGoogleConversionId: (ga_conv_id) => this.setGoogleConversionId(ga_conv_id),
      enableGoogleTag: () => this.enableGoogleTag(),
      fbTrackingID: null,
      logrocket: null,
      googleConversionID: null,
      gaTrackingID: props.gaTrackingID,
      hamId: () => this.hamId(),
      initialUrl: document.location.href,
      initialReferrer: document.referrer,
      subdomain: props.subdomain,
      browserOS: browserOS,
      browserName: browserName,
      session_id: null,
      diner_id: null,
      browserVersion: browserVersion,
      utm_source: null, utm_medium: null,
      utm_campaign: null,
      ham_id: null,
      version: props.version,
      viewportHeight: window.innerHeight,
      viewportWidth: window.innerWidth,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      createdANewSession:null,
      isOpenEventHasFired:false,
      isOpenFutureEventHasFired:false
    }
  
    /* support for google analytics tracking */
    if (props.gaTrackingID) { 
      ReactGA.initialize(props.gaTrackingID)
    }
    this.createOrLoadSession()
    this.setHamId()
  }

  /* this method sets the session token on this session
     along with the age of the session. after 30 mins, the session
     token gets cleared out and a new analytics session begins */
  async createOrLoadSession() {
    let sessId = this.state.session_id
    if (!sessId) {
      sessId = await localforage.getItem('sessionId')
    }
    let seen_at = await localforage.getItem('sessionLastSeenAt')
    if (seen_at === null || sessId === null) {
      seen_at = 0
    }
    let utm_source = await localforage.getItem('utm_source')
    let utm_medium = await localforage.getItem('utm_medium')
    let utm_campaign = await localforage.getItem('utm_campaign')
    let initial_referrer = await localforage.getItem('initial_referrer')
    let diner_id = await localforage.getItem('diner_id')

    // handle session timeouts, after a specific period
    // ignore anything we get back from local storage and create
    // a new session id and blow out any stored utms
    const current_timestamp = Date.now()
    const sess_age = current_timestamp - seen_at
    const onThankYou = (window.location.href.indexOf('thankyou') !== -1)
    const sessionExpired = (sess_age > SESSION_TIMEOUT)

    let createdANewSession = null

    // already a session and not expired is first
    // which means they're coming back from oauth
    // or refreshing the page or something
    if (sessId && !sessionExpired) {
      createdANewSession = false
    } else if (sessionExpired) {
      sessId = uuidv4()
      utm_source = null
      utm_medium = null
      utm_campaign = null
      initial_referrer = null
      createdANewSession = true
    } else {
      createdANewSession = false
      let logrocket  = await localforage.getItem('logrocket')
      // if logrocket holds the current session_id, then we want
      // to init logrocket
      if(
        logrocket && 
        sessId && 
        logrocket === sessId &&
        process.env.REACT_APP_LOGROCKET
      ){
        LogRocket.init(process.env.REACT_APP_LOGROCKET);
       }   
    }

    //TODO: WHEN CREATING A NEW SESSION WE NEED TO 
    // CLEAR LOGROCKET AND ALSO THINK OF RACE CONDITION
    // if we have utm parameters, then update and overwrite 
    // anything that we might have set. if null then dont touch
    const params = new URLSearchParams(document.location.search.substring(1))
    const p_utm_source = params.get("utm_source")
    const p_utm_medium = params.get("utm_medium")
    const p_utm_campaign = params.get("utm_campaign")
    if (p_utm_source) { utm_source = p_utm_source }
    if (p_utm_medium) { utm_medium = p_utm_medium }
    if (p_utm_campaign) { utm_campaign = p_utm_campaign }

    const new_ir = (initial_referrer) ? initial_referrer : this.state.initial_referrer
    await localforage.setItem('initial_referrer', new_ir)

    // if we have initial_referrer SET and DO NOT have any utms
    // and there ARE utms in the initial_referrer then set
    // those as our initial utms (see #339)
    if (new_ir &&
      new_ir.includes('utm_') &&
      utm_source === null) {
        const rpms = new URLSearchParams(new_ir)
        if (rpms.get("utm_source")) { utm_source = rpms.get("utm_source") }
        if (rpms.get("utm_campaign")) { utm_campaign = rpms.get("utm_campaign") }
        if (rpms.get("utm_medium")) { utm_medium = rpms.get("utm_medium") }
    }

    await localforage.setItem('sessionId', sessId)
    await localforage.setItem('sessionLastSeenAt', current_timestamp)
    await localforage.setItem('utm_source', utm_source)
    await localforage.setItem('utm_medium', utm_medium)
    await localforage.setItem('utm_campaign', utm_campaign)
    if (diner_id !== null) {
      await localforage.setItem('diner_id', diner_id)
    }

    // store this globally so that the api helper file can access it easily
    window.CLICKSTREAM_SESSION_ID = sessId
    window.APP_VERSION = this.versionId()
    LogRocket.track(sessId)

    var clickstream_url = `${process.env.REACT_APP_RDOT_LOCATION}/admin/clickstream/session/${sessId}`

    this.setState({session_id: sessId,
      utm_source: utm_source, 
      utm_medium: utm_medium,
      initial_referrer: new_ir,
      utm_campaign: utm_campaign,
      createdANewSession: createdANewSession})

    // send an event to note it is initialized
    const initInfo = {'sess_age': sess_age,
      'onThankYou': onThankYou,
      'url': new_ir,
      'sessionExpired': sessionExpired}
    this.debug('ClickstreamInitComplete', JSON.stringify(initInfo))

  }

  /* sets the ham id, which requires a request to the ham server
     which returns the API in the cookie back to the frontend. this 
     would normally not be visible because the ham server is on 
     another host */
  setHamId = () => {
    this.info("HAMInit")

    // only send event on creation of a new session
    if (this.state.createdANewSession === true) {
      this.sendHam('CLICKSTREAM_SESSION_START')
    }

  }

  // override the session id from outside
  setSessionId = (sessId) => {
    window.CLICKSTREAM_SESSION_ID = sessId
    this.setState({session_id: sessId})
  }

  hamId = () => {
    return this.state.ham_id
  }

  /* function that sends some HAM data, which is 
     user data to the backend to store in HAM */
  sendHam = (eventName, payload=null) => {
    const hamURI = process.env.REACT_APP_PIXEL_HOST + 'ham.json'
    const headers = {'Content-Type': 'application/json'}
    let params = {'product': 'HNGR_ORDERING',
      'clickstream_session_id': this.state.session_id,
      'event_name': eventName}
    if (payload !== null) {
      params['payload'] = btoa(JSON.stringify(payload))
    }
    var url = new URL(hamURI)
    url.search = new URLSearchParams(params).toString()
    fetch(url, {method: 'GET',
      credentials: 'include',
      headers: headers})
      .then(res => {
        res.json().then(json => {
          const pid = json.ham_id
          this.setState({ham_id: pid})
          this.info("HAMSuccess", pid)
        })
      })
      .catch(err => {
        console.log('ham err' + err)
        this.info("HAMError", err)
      })
  }


  browser = () => {
    return {browserOS: this.state.browserOS,
      browserName: this.state.browserName,
      browserVersion: this.state.browserVersion}
  }

  /* enable google tag manager */
  enableGoogleTag = () => {
    if (this.state.googleTagEnabled === false) {
      window.gtag = function() {
        window.dataLayer.push(arguments)
      }
      window.gtag('set', 'linker', {'accept_incoming': 'true'})
      this.setState({googleTagEnabled: true})
    }
  }

  /* sets the gtag conversion id which is something that
     gets fed back into the google ads system*/
  setGoogleConversionId = (id) => {
    if (id && window.dataLayer) {
      this.enableGoogleTag()
      this.setState({googleConversionID: id})
    }
  }

  /* sets the facebook tracking ID but doesnt actually
     make any outbound calls */
  setFacebookTrackingId = (fbTrackingID) => {
    if (fbTrackingID) {
      ReactPixel.init(fbTrackingID)
      this.setState({fbTrackingID: fbTrackingID})
    }
  }

  versionId = () => {
    return this.state.version
  }

  sessionId = () => {
    return this.state.session_id
  }

  setLogrocket = () => {
    localforage.setItem('logrocket', this.state.session_id)
  }

  utms = () => {
    return {utm_source: this.state.utm_source,
      utm_medium: this.state.utm_medium,
      utm_campaign: this.state.utm_campaign}
  }

  /* conversion method - this fires off any handlers we have specifically
     connected to a conversion event, such as google tag manager, etc */
  conversion = (orderId, valueAmount) => {  
    if (this.state.googleConversionID && window.gtag) {
      const gtag = window.gtag
      gtag('event', 'conversion', { 
        'send_to': this.state.googleConversionID,
        'transaction_id': orderId,
        'value': valueAmount.toString(),
        'currency': 'USD',
        'event_callback': () => {}
      })
    } else {
      console.log('gtag conversion: event not recorded')
    }
  } 

  /* send helper function, which aggregates all the analtyics providers
     and sends an event to all the destinations */
  send = async (action, label=null, level=null) => {
    let clabel = null
    if (label === null) {
      clabel = ""
    } else {
      clabel = String(label) // coerce to string here or GA fatals
    }

    // if we dont yet have a session id, retry again aggressvively
    if (this.state.session_id === null ) {
      setTimeout(this.send.bind(null, action, label, level), 100)
      window.NO_CS_SESSION_ALERT_COUNT += 1 
      // pick numbers to alert on. THis is to prevent infinte noise but to
      // also get a clear picture of whats up
      if (
        window.NO_CS_SESSION_ALERT_COUNT === 200 || 
        window.NO_CS_SESSION_ALERT_COUNT === 1000 ||  
        window.NO_CS_SESSION_ALERT_COUNT === 10000  
      ){
        if (window.location.href.indexOf('thankyou') === -1){
          Sentry.configureScope(scope => {
            scope.setExtra("Attempt Number", window.NO_CS_SESSION_ALERT_COUNT);
          });
        }
      }
      return
    }

    let payload = {category: this.state.subdomain, 
      action: action, label: clabel, level:level}

    // output logs if in testing mode
    if (process.env.REACT_APP_TESTING) {
      console.log(payload)
    }

    /* optionally load the event to google or facebook
       depending on whether GA or the pixel is installed */
    if (level !== 'debug') {
      if (this.state.gaTrackingID) {
        ReactGA.event(payload)
      }
      if (this.state.fbTrackingID) {
        ReactPixel.trackCustom(action, label)
      }
    }

    /* figure out which product this is for, for now
       putting in the -locations thing is a hack but 
       we can circle back on this later */
    let product = CLICKSTREAM_PRODUCT
    if (this.state.subdomain.indexOf("-locations") > -1) {
      product = BRANDPAGE_PRODUCT
    }

    let clickstreamPayload = {session_id: this.state.session_id,
      product: product,
      category: this.state.subdomain,
      url: window.location.href,
      version: this.state.version,
      user_agent: navigator.userAgent,
      label: clabel, 
      initial_url: this.state.initialUrl,
      initial_referrer: this.state.initialReferrer, 
      viewport_height: this.state.viewportHeight,
      viewport_width: this.state.viewportWidth,
      screen_height: this.state.screenHeight,
      screen_width: this.state.screenWidth,
      ham_id: this.state.ham_id,
      browser_name: this.state.browserName,
      browser_version: this.state.browserVersion,
      browser_os: this.state.browserOS,
      diner_id: this.props.user.user ? this.props.user.user.uniq_uuid : null,
      utm_source: this.state.utm_source,
      utm_medium: this.state.utm_medium,
      utm_campaign: this.state.utm_campaign,
      action: action,
      level:level}
    // assume we're in an iframe or some background process
    // if the viewport is == 0 and dont send events
    if (this.state.viewportHeight && this.state.viewportHeight > 0) {
      api.callApi('clickstream', function(){},
        function(){}, clickstreamPayload)
    }
    LogRocket.track("clickstream_action:"+action)

    // update the last seen at flag
    const current_timestamp = Date.now()
    await localforage.setItem('sessionLastSeenAt', current_timestamp)
  }


  debug = (action, label=null) => {
    this.send(action, label, 'debug')
  }

  info = (action, label=null) => {
    this.send(action, label, 'info')
  }

  warn = (action, label=null) => {
    this.send(action, label, 'warning')
  }

  error = (action, label=null) => {
    this.send(action, label, 'error')
  }


  /* pageview, fires off a different type of "HIT"
     which represents a "pageview" event, which is 
     treated differently in google analytics, and is
     needed in order to 1) build funnels and 2) stop
     google from complaining you dont have a pageview event */
  pageview = (url) => {
    // if we dont yet have a session id, retry again aggressively
    if (this.state.session_id === null) {
      setTimeout(this.pageview.bind(null, url), 100)
      return
    }

    if (this.props.gaTrackingID) { 
      ReactGA.pageview(url)
    }

    let clickstreamPayload = {session_id: this.state.session_id,
      product: CLICKSTREAM_PRODUCT,
      category: this.state.subdomain,
      url: window.location.href,
      user_agent: navigator.userAgent,
      version: this.state.version,
      label: url, 
      initial_url: this.state.initialUrl,
      initial_referrer: this.state.initialReferrer,
      diner_id: this.state.diner_id,
      ham_id: this.state.ham_id,
      browser_name: this.state.browserName,
      browser_version: this.state.browserVersion,
      browser_os: this.state.browserOS,
      viewport_height: this.state.viewportHeight,
      viewport_width: this.state.viewportWidth,
      screen_height: this.state.screenHeight,
      screen_width: this.state.screenWidth,
      utm_source: this.state.utm_source,
      utm_medium: this.state.utm_medium,
      utm_campaign: this.state.utm_campaign,
      action: 'pageview',
      level: 'info'}

    // assume we're in an iframe or some background process
    // if the viewport is == 0 and dont send events
    if (this.state.viewportHeight && this.state.viewportHeight > 0) {
      api.callApi('clickstream', function(){},
        function(){}, clickstreamPayload)
    }
  }

  /* note that we are slipping an img in here, this is 
     a hidden pixel that we have on other pages, as part 
     of an adtech thing */
  render() {
    return  (
      <AnalyticsContext.Provider value={this.state}>
        {this.props.children}
      </AnalyticsContext.Provider>
    )
  }
}

export default UserContextHOC(AnalyticsProvider);
