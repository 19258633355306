import React from "react"

const Address = ({ rest }) => {
  return (
    <div>
      <div className="brand-rest-location__body-text">{rest.address1}</div>
      <div className="brand-rest-location__body-text">
        {rest.city}, {rest.state} {rest.zip_code}
      </div>
    </div>
  )
}

Address.defaultProps = {
  rest: {
    address1: "",
    city: "",
    state: "",
    zip_code: ""
  }
}

export default Address
