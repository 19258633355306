import React from 'react';
import {SignupModalContext} from "./SignupModalContext";

export default function SignupModalContextHOC(Component){
    return function SignupModalContextComponent(props) {
        return (
            <SignupModalContext.Consumer>
                {
                    (context) => <Component signupModal={context} {...props} /> 
                }
            </SignupModalContext.Consumer>
        )
    };
}

