import React, { Component } from 'react';
import CartContextHOC from './CartContextHOC';
import RestContextHOC from './RestContextHOC';
import UserContextHOC from './UserContextHOC';
import AnalyticsContextHOC from "./AnalyticsContextHOC";
import * as Sentry from "@sentry/browser";
import { Capacitor } from "@capacitor/core";
import { Preferences } from '@capacitor/preferences';
import { Connection, PublicKey, Keypair } from '@solana/web3.js';
import {
  StripeTerminalPlugin,
  DiscoveryMethod,
  ConnectionStatus,
} from 'capacitor-stripe-terminal';

const api = require('../helpers/api');
const Subdomain = require('../helpers/subdomain');
const Bip39 = require('bip39')

/*
This HOC is used for components that are "entry points". An entry point is responsible for loading the rest info
and setting the rest_id of the cart, rest, and user context. It does this by looking at the subdomain and 
then making the appropriate api call to trade subdomain -> rest_id

*/
export default function EntryPointHOC(WrappedComponent) {
  var component = class extends Component {

    constructor(props){
      super(props)
      this.state = {
        rest: null
      }
      this.getRestInfoFromSubdomain = this.getRestInfoFromSubdomain.bind(this)
    }

    componentDidMount(){
      this.getRestInfoFromSubdomain()
      this.props.rest.restoreSandboxedMenu()
      this.props.rest.maybeGetMenu()
      this.props.rest.getFutureHours()
      this.props.rest.getHours()
      this.loadSupperClub()
      try{
        this.loadKioskMode()
      } catch (err){
        console.log("failed to load kiosk mode", err)
        Sentry.configureScope((scope) => {
          Sentry.captureMessage("Captured chunk error from loading kiosk mode")
        })
      }
    }

  loadSupperClub(){
    if(window.LOADED_SUPPERCLUB){
      console.log("already loaded supperclub")
      return
    }
    if (
      Capacitor.getPlatform() === "ios" ||
      Capacitor.getPlatform() === "android"
    ) {
      window.LOADED_SUPPERCLUB = true
      this.loadSupperClubDinerId()
      this.loadSupperClubWallet()
    } 
  }

  async loadKioskMode(){
    if(window.KIOSK_MODE){
      return
    }
    if (Capacitor.getPlatform() !== "android") return

    let { value: kiosk_diner_uuid } = await Preferences.get({ key: 'kiosk_diner_uuid' }); 
    let { value: stripe_terminal_id} = await Preferences.get({ key: 'stripeTerminal' }); 
    let { value: stripe_terminal_location_id} = await Preferences.get({ key: 'stripe_terminal_location_id' }); 
    this.stripe_terminal_id = stripe_terminal_id.replaceAll('"', '')
    this.stripe_terminal_location_id = stripe_terminal_location_id.replaceAll('"', '')
    if (
      Capacitor.getPlatform() === "android" &&
      kiosk_diner_uuid
    ) {
      window.KIOSK_MODE = true
      //setInterval(this.props.cart.checkKioskTimeout, 5000); 
      this.loadKioskDinerId(kiosk_diner_uuid)
      // initialize the terminal right away, so its ready when they're 
      // at checkout
      window.terminal = await StripeTerminalPlugin.create({
        fetchConnectionToken: async () => { 
          let url = `${process.env.REACT_APP_FOODCOIN_API_LOCATION}/stripe-pos/create-connection-token`
          const resp = await fetch(url, {
            method: 'POST'
          })
          const data = await resp.json()
          return data.secret
        },   
        onUnexpectedReaderDisconnect: () => { 
          // so on unexepected disconnect here, we want to try to reconnect,
          // so lets make a new function here
          this.tryToConnect()
          // handle reader disconnect
        }    
      }) 
    }
  }

  tryToConnect(){
    console.log("readers - inside try to connect, about to call discover")
    window.terminal
      .discoverReaders({
        simulated: false,
        discoveryMethod: DiscoveryMethod.BluetoothProximity
      })
      .subscribe(readers => {
        console.log("[ordering] readers subscribe got hit for discover")
        if (readers.length) {
          console.log("[ordering] readers.length inside context " + this.stripe_terminal_id)
          for(let i in readers){
            if(readers[i].serialNumber == this.stripe_terminal_id){
              if(!this.connected){
                const connectionConfig = {
                  locationId: this.stripe_terminal_location_id
                }
                window.terminal
                  .connectBluetoothReader(readers[i], connectionConfig)
                  .then(connectedReader => {
                    console.log("[ordering] Reader connected from context!")
                  })
              } 
            } 
          }
        } 
      })
  }


  async loadSupperClubWallet(){
    let { value: seed_phrase } = await Preferences.get({ key: 'seedPhrase' });
    if(seed_phrase) {
      //--> TODO: Confirm seed phrase belongs to diner

      let seed = Bip39.mnemonicToSeedSync(seed_phrase).slice(0, 32);
      let keypair = Keypair.fromSeed(seed);

      this.setState({
        seedPhrase:seed_phrase,
        keypair:keypair,
        publicKey:keypair.publicKey
      });

      this.props.cart.setSupperclubWallet(keypair);
    } 
  }


  async loadSupperClubDinerId(){
    let { value: diner_uuid } = await Preferences.get({ key: 'diner_uuid' }); 
    if(diner_uuid){
      api.callApi(
        "get-supperclub-diner",
        (resp) => {
          let userdata = resp.userdata
          this.props.user.loginUser(userdata, null);
          this.props.cart.loginUser(userdata);
        },  
        () => {alert('Failed to load diner!')}, 
        {diner_uuid:diner_uuid},
        null,
        null
      ) 
    }
  }


  async loadKioskDinerId(diner_uuid){
    if(diner_uuid){
      api.callApi(
        "get-supperclub-diner",
        (resp) => {
          let userdata = resp.userdata
          this.props.user.loginUser(userdata, null);
          this.props.cart.loginUser(userdata);
        },  
        () => {alert('Failed to load diner!')}, 
        {diner_uuid:JSON.parse(diner_uuid)},
        null,
        null
      ) 
    }
  }


  restInfoSuccess(data){
    //if we entered a bad subdomain
    if(!data.rest_id){
      this.setState({badSubdomain:true})
    }
    this.props.rest.setInfo(data)
    this.props.rest.setInjections(data.injections)
    this.props.user.setRestId(data.rest_id)
    if(!this.props.cart.rest_id){
      this.props.cart.setRestId(data.rest_id)
    }
    this.props.cart.setTaxRate(data.tax_rate)
    this.props.rest.setRewards(data.rewards)
    this.setState({
      rest: data,
      loading_rest: false
    })
  }

  // on BasicData Fail, attempt again with failover host
  // and if that fails, then barf out errors to the user 
  onBasicDataFail(err) {
    if (this.state.api_host !== process.env.REACT_APP_FOODCOIN_API_FAILOVER) {
      this.props.analytics.debug("UsingFailoverDomain")
      window.API_HOST = process.env.REACT_APP_FOODCOIN_API_FAILOVER
      this.getRestInfoFromSubdomain()
    } else {
      //Sentry.captureMessage("Unable to fetch basic info via API")
      //Sentry.captureException(err)
      this.props.analytics.info("EntryPointBasicInfoError")
    }
  }


  getRestInfoFromSubdomain(){
    var subdomain = Subdomain.getSubdomain()
    this.setState({api_host: window.API_HOST})
    api.callApi('rest_basic_info', this.restInfoSuccess.bind(this), 
      this.onBasicDataFail.bind(this), null, null, subdomain)

    api.callApi(
      'font', 
      (resp)=>this.props.rest.setFont(resp),
      ()=>{},
      null,
      null,
      subdomain
    )
    
    api.callApi(
      'delivery_bounds', 
      (data)=>this.props.rest.setDeliveryBoundingBox(data.bounds),
      ()=>{}, null, null, subdomain)
  }

    render(){
      if(this.state.badSubdomain) return <div> Bad subdomain </div>
      return <WrappedComponent entryPoint={this.state} {...this.props} />
    }
  }

  return AnalyticsContextHOC(UserContextHOC(RestContextHOC(CartContextHOC(component))) )
}

