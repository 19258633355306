import React, { useState, useRef, useEffect } from "react"
import MenuItemExpanded from "./MenuItemExpanded/MenuItemExpanded";
import RestContextHOC from "../Contexts/RestContextHOC"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC";
import { withRouter } from "react-router-dom";
import Modal from '../Modal/Modal'
import "../css/MenuItemModal.css";

const MenuItemModal = props => {
  // grab from state if exists, otherwise set to null
  const {item, 
    modifier_id,
    modifier_class_id, 
    isCoupon, 
    editItem
  } = props.location.state ? props.location.state : 
    { item:null, modifier_id:null, editItem:null, 
      modifier_class_id:null, isCoupon:null }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => document.removeEventListener("resize", handleResize, false)
  }, [])
  
  const [width, setWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  if (!item) {
    props.history.replace('/menu')
    return null
  }
  

  if (isCoupon) {
    props.analytics.info("OpeningItemModalForCoupon", modifier_id);
  }


  //go back to close the modal
  const CloseModal = () => {
    props.analytics.info("MenuItemModalClose", item.name);
    // document.body.style.overflow = "";
    // document.body.style.position = "";
    // document.documentElement.style.overflow = "";
    // document.documentElement.style.position = "";
    // document.documentElement.style.height = "";
    let url_id = '/' + item.menu_item_id
    let url = props.location.pathname.replace(url_id,'')
    props.history.push({
      pathname:url,
      state:{
        fromItemModal:true,
        current_menu: props.rest.menu.menu_id
      }
    })
  };

  const stopPropogation = e => {
    e.stopPropagation();
  };

  const modalStyle = () => {
    if(width > 920){
      return { 
        textAlign:'left', 
        padding:'0px 0px 0px 0px',
        background: '#FCFCFC',
      }
    } else{
      return { 
        textAlign:'left', 
        padding:'0px 0px 0px 0px', 
        background: '#FCFCFC',
        width:'100%',
        maxWidth:'100%',
        minHeight:'calc(100% - 60px)',
        height:'calc(100% - 60px)',
        borderRadius:'0px',
        position:'absolute',
        bottom:'0px'
      }

    }
  }

  return item ? (
    <Modal 
      close={CloseModal} 
      style={modalStyle()}
      stickyX={true}
    >
        <MenuItemExpanded
          key={item.menu_item_id}
          item={item}
          couponModifierId={modifier_id}
          couponModifierClassId={modifier_class_id}
          CloseModal={CloseModal}
          stopPropogation={e => stopPropogation(e)}
          editItem={editItem}
          isMobile={width < 900}
        />
      </Modal>
  ) : (
    props.history.goBack()
  );
};

export default withRouter(RestContextHOC(AnalyticsContextHOC(MenuItemModal)))
