import React from "react"
import classnames from "classnames"
import { RestaurantHeader } from "./Components/RestaurantHeader"
import Cart from "./Components/Cart/Cart"
import RestContextHOC from "./Contexts/RestContextHOC"
import UserContextHOC from "./Contexts/UserContextHOC"
import CartContextHOC from "./Contexts/CartContextHOC"
import SignupModalContextHOC from "./Contexts/SignupModalContextHOC"
import AnalyticsContextHOC from "./Contexts/AnalyticsContextHOC"
import Footer from "./Footer/Footer"
import { withRouter } from "react-router-dom"

class AppPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebar: false,
      width: window.innerWidth,
      isMobile: false,
      forceOpen: false
    }
  }

  componentDidUpdate(prevProps) {
    window.onpopstate = e => {
      this.props.analytics.info("BrowserBackButton", "AppPage component")
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange)
  }

  render() {
    return (
      <div>
        <RestaurantHeader {...this.props} />
        <div 
          style={this.props.fullpage ? {width:'100%', paddingBottom:'0px'} : {} }
          className={classnames("AppBody", {"AppBody-footer":this.props.footer})}
        >
          {this.props.children}
          {this.props.footer && <Footer className={this.props.footerClassName}/>}
        </div>
        {!this.props.hideCart && <Cart />}
      </div>
    )
  }
}

export default withRouter(
  AnalyticsContextHOC(
    SignupModalContextHOC(
      UserContextHOC(CartContextHOC(RestContextHOC(AppPage)))
    )
  )
)
