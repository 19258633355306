import React, { Component } from 'react';
import * as Sentry from "@sentry/browser";
import OrderReceipt from './OrderReceipt';
import RestContextHOC from "../Contexts/RestContextHOC";
import CartContextHOC from "../Contexts/CartContextHOC";
import UserContextHOC from "../Contexts/UserContextHOC";
import SignupModalContextHOC from '../Contexts/SignupModalContextHOC'
import AnalyticsContextHOC from '../Contexts/AnalyticsContextHOC';
import '../css/TrackOrder.css';
import LoadingIndicator from "../Loading/LoadingIndicator";
import withErrorHandling from "../Contexts/withErrorHandling/withErrorHandling";
import Modal from '../Modal/Modal'

const Api=require("../helpers/api");
const Timestamp=require("../helpers/timestamps");

class TrackOrder extends Component {
    constructor(props){
      const params = new URLSearchParams(window.location.search);
      const diner_id = params.get('diner_id');
      const hash_id = params.get('hash_id');
      super(props);
      this.state={
        menu: false,
        status_or_receipt: 'status',
        pickup_or_delivery: null,
        orderPlaced: true,
        orderConfirmed: null,
        orderDone: null,
        status: null,
        hash_id: hash_id,
        diner_id: diner_id,
        error: null,
      }
    }
    
    componentDidMount() {
      this.getStatus() 
      this.props.analytics.info('TrackOrderView')
      if (this.props.history.location.state){
        this.setState({status_or_receipt: this.props.history.location.state.status_or_receipt})
      }
    }

    componentDidUpdate() {
      if(this.state.menu !== this.props.rest.menu){
        if(this.props.rest.menu){
          this.setState({menu: this.props.rest.menu})
        }
      }
    }

    getStatus(){
      if(this.state.status === 'cancelled' || this.state.status === 'completed'){
        console.log("status already in terinal state")
        return
      }

      Api.callApi(
        'order_status',
        (res)=>this.handleStatusSuccess(res),
        (err)=>this.handleStatusFailure(err),
        {diner_id: this.state.diner_id,
        hash_id: this.state.hash_id}
      )

    }

    handleStatusSuccess(res){
      if (res.success === 0){
        this.handleStatusFailure(res)
        setTimeout(()=>this.getStatus(), 15000)
        return
      }
      this.setState({
        status: res.status,
        pickup_or_delivery: res.pickup_or_delivery,
        created_at: res.created_at,
        delivery_address: res.delivery_address,
        delivery_instructions: res.delivery_instructions,
        hash_id: res.hash_id}, ()=>this.updateStatus())
        setTimeout(()=>this.getStatus(), 15000)
    }

    handleStatusFailure(err){
      console.log('FAILURE RES', err)
      if (err.message){
        console.log('setting error')
        this.setState({error:err.message})
      }
    }

    updateStatus(){
      if(this.state.status === 'unconfirmed'){
        this.setState({orderPlaced: true})
      }
      else if(this.state.status === 'confirmed'){
        this.setState({orderPlaced: true,
                      orderConfirmed: true})
      }
      else if (this.state.status === 'out_for_delivery' || this.state.status === 'ready_for_pickup' || this.state.status === 'completed'){
        this.setState({orderPlaced: true,
                      orderConfirmed: true,
                      orderDone: true})
      }
    }
      
  switchView(view) {
    let v=view.charAt(0).toUpperCase() + view.slice(1);
    this.props.analytics.info(`TrackOrderPageView${v}`)
    this.setState({
        status_or_receipt: view
      })
    }

  getRestDirections(){
    this.props.analytics.debug('TrackOrderPageGetDirections')
  }
  
  updateSubBubble(status){
    if(this.state.status !== 'cancelled'){
      if (status === 'placed'){
          if (this.state.orderPlaced){
            return(
              <div  className='trackerSubBubble'>
                <div style={{background: this.props.rest.hex_color_primary, animationDelay: ".4s" }}  className='trackerBubbleFill'></div>
              </div>
            )
          }
          else{
            return(
              <div className='trackerSubBubble'></div>
            )
          }
        }
      else if (status === 'confirmed'){
          if (this.state.orderConfirmed){
            return(
              <div  className='trackerSubBubble'>
                <div style={{background: this.props.rest.hex_color_primary, animationDelay: "1s" }}  className='trackerBubbleFill'></div>
              </div>
            )
          }
          else{
            return(
              <div className='trackerSubBubble'></div>
            )
          }
        }
      else{
          if (this.state.orderDone){
            return(
              <div  className='trackerSubBubble'>
                <div style={{background: this.props.rest.hex_color_primary, animationDelay: "1.5s" }}  className='trackerBubbleFill'></div>
              </div>
            )
          }
          else{
            return(
              <div className='trackerSubBubble'></div>
            )
          }
        }
      }
    }

  updateTimeline(status){
    if(this.state.status !== 'cancelled'){
      if (status === 'placed'){
        if (this.state.orderPlaced){
          return(
            <div style={{background: this.props.rest.hex_color_primary, width: '16.25%', animation: 'Filler 1s'}} className='trackerTimelineSegment' ></div>
          )
        }
      }

      else if (status === 'confirmed'){
        if (this.state.orderConfirmed){
          return(
            <div style={{background: this.props.rest.hex_color_primary, width: '50%', animation: 'Filler 2s'}} className='trackerTimelineSegment' ></div>
          )
        }
      }
      else{
        if (this.state.orderDone){
          return(
            <div style={{background: this.props.rest.hex_color_primary, width: '100%', animation: 'Filler 3s'}} className='trackerTimelineSegment'></div>
          )
        }
      }
    }
  }

  renderStatusTab(){
    return(
      <div className='trackerToggleContent'>
        <div className='trackerLine'>
          <div className='trackerTimeline'>
            {this.updateTimeline('placed')}
            {this.updateTimeline('confirmed')}
            {this.updateTimeline('delivery')}
          </div>

          <div className='trackerBubbleContainer'>
            <div className='trackerBubble'>
                {this.updateSubBubble('placed')}
            </div>
            <div className='trackerBubbleCaption'>ORDER PLACED</div>
            
          </div>

          <div className='trackerBubbleContainer'>
            <div className='trackerBubble'>
                {this.updateSubBubble('confirmed')}
            </div>
            <div className='trackerBubbleCaption'>ORDER CONFIRMED</div>
             
          </div>

          <div className='trackerBubbleContainer'>
            <div className='trackerBubble'>
             {this.updateSubBubble('out')}
            </div>
            <div className='trackerBubbleCaption'>{this.renderFinalStatus()}</div>
            
          </div>
        </div>
        {this.renderAddress()}
      </div>
    )
  }

  renderReceipt(){
    return(
        <OrderReceipt hash_id={this.state.hash_id} diner_id={this.state.diner_id}/>
    )
  }

  renderAddress(){
    if (this.state.pickup_or_delivery === 'delivery' && this.state.delivery_address){
      return(
        <div className='trackerAddress'>
          <div className='trackerAddressTitle'>Delivery Address</div>
          <div className='trackerAddressLine'>{this.state.delivery_address}</div>
          {this.state.delivery_instructions && <div className='trackerAddressLine2'>"{this.state.delivery_instructions}"</div>}
        </div>
      )
    }
    else{
      let fullAddress=this.props.rest.name + ', ' + this.props.rest.address + ', ' + this.props.rest.city + ', ' + this.props.rest.state + ' ' + this.props.rest.zipcode
      let addressURL=encodeURIComponent(fullAddress.trim())
      
      return(
      <div className='trackerAddress'>
          <div className='trackerAddressTitle'>Pick Up From {this.props.rest.name}</div>
          <a onClick={()=>this.getRestDirections()} id='addressLink' href={`https://maps.google.com/maps/dir/?api=1&destination=${addressURL}`} target="_blank" rel="noopener noreferrer">
            <div className='trackerAddressLine'>
              {this.props.rest.address}, {this.props.rest.city}, {this.props.rest.state} {this.props.rest.zipcode}
            </div>
          </a>
      </div>
      )
    }
  }

  renderContent(){
    if (this.state.status_or_receipt === 'status'){
      return(
        this.renderStatusTab()
      )}
    else{
      return(
        this.renderReceipt()
      )
    }
  }

  selectStatusButtonClass(button){
      if (this.state.status_or_receipt === button){
        return 'trackerStatusButton trackerButtonBlue'
      }
      return 'trackerStatusButton trackerButtonWhite'
  }
  
  closeTrackOrder(){
    let path = this.props.location.pathname.split('/')
    this.props.history.push(path[0] + '/' + path[1])
  }
      
  renderOrderStatus(){
      if (this.state.status === 'cancelled'){
        return(
          <div>ORDER CANCELLED</div>
        )
      }
      if (this.state.status === 'completed'){
        return(
          <div>Completed.</div>
        )
      }
      if (this.state.orderDone){
          return(
              <div>{this.renderFinalStatus()}</div>
          )
      }
      else if(this.state.orderConfirmed){
          return(
              <div>Order Confirmed</div>
          )
      }
      else{
          return(
              <div>Order Placed</div>
          )
      }
  }

  renderFinalStatus(){
    if(this.state.pickup_or_delivery === 'delivery'){
      return(
        "Out for Delivery"
      )
    }
    else{
      return(
        "Ready for Pickup"
      )
    }
  }

  formatPhone(){
    let p=this.props.rest.phone.replace(/-/g,'')
    p='(' + p.substring(0,3) + ') ' + p.substring(3,6) + '-' + p.substring(6,10)
    return <a style={{color:'blue',cursor:'pointer', textTransform:'underline'}} href={`tel:${this.props.rest.phone}`}>{p}</a>
  }

  // Sun, 0/18/1970
  formatOrderDate(){
    let dt=this.state.created_at
    let dow=Timestamp.getDayOfWeek(dt)
    let mm=Timestamp.getMonthInt(dt);
    let dd=Timestamp.getDayOfMonth(dt);
    let yyyy=Timestamp.getFullYear(dt);
    return `${dow}, ${mm}/${dd}/${yyyy}`
  }

  formatOrderTime(){
    return Timestamp.getTimeOfDay(this.state.created_at)
  }

 reOrder(hash_id){
      this.props.analytics.info('ClickedReorder',`Order ID: ${hash_id}`)
      Api.callApi(
        'reorder',
        (resp)=>{
          if(resp.success){
            this.props.cart.setReorderItems(resp.items)
            this.props.analytics.warn('ReorderCart', hash_id)
            this.props.history.push('/')
          } else { 
            this.props.analytics.warn('InvalidCartForReorder', hash_id)
            this.props.updateError(
              true,
              "Some menu items have changed in this order. It is not eligible to be re-ordered.",
              true, {zIndex:'1000', position: 'fixed', top:'0', width:'99vw'}
            );

          }
        },
        ()=>{
            this.props.updateError(
              true,
              "Some menu items have changed in this order. It is not eligible to be re-ordered.",
              true, {zIndex:'1000', position: 'fixed', top:'0', width:'99vw'}
            );

 
        },
        {hash_id:hash_id,
        diner_id:this.state.diner_id}
      )
  }
  
  renderHeader(){
    if(this.state.status === 'cancelled'){
      if (this.props.rest.phone){
        return(
          <div className='trackerStatusTextWrapper'>
            <div className='trackerOrderNumber'>Order&nbsp;<div style ={{color: this.props.rest.hex_color_primary}} className='trackerHash'>{this.state.hash_id}</div>&nbsp;:</div>
            <div className='trackerStatus'>{this.renderOrderStatus()}</div>
            <div className='trackerContact'><b>Your refund is being processed</b></div>
            <div className='trackerContact'>Call {this.formatPhone()} with questions!</div>
            {this.renderReorder()}
          </div>
        )
      }
    }
    else{
      if (this.props.rest.phone){
        return(
          <div className='trackerStatusTextWrapper'>
            <div className='trackerOrderNumber'>Order&nbsp;<div style ={{color: this.props.rest.hex_color_primary}} className='trackerHash'>{this.state.hash_id}</div>&nbsp;:</div>
            <div className='trackerStatus'>{this.renderOrderStatus()}</div>
            <div className='trackerContact'>Received <b>{this.formatOrderDate()}</b> at <b>{this.formatOrderTime()}</b></div>
            <div className='trackerContact'>Call {this.formatPhone()} with questions!</div>
            {this.renderReorder()}
          </div>
        )
      }
    }
  }

  renderReorder(){
    if(this.state.pickup_or_delivery === 'dine in') return
    if (this.props.location.state && this.props.location.state.fromCheckout) return
    return(
      <button className='hngrButton trackerReorderButton' style={{background:this.props.rest.hex_color_secondary}} onClick={()=>this.reOrder(this.state.hash_id)}>REORDER</button>
    )
  }

  openLogin(){
    this.props.history.push(`/menu/auth`)
    this.props.signupModal.openLogin()
  }

  render () {
    if (this.state.created_at && this.props.rest){
      return (
        <Modal close={()=>this.closeTrackOrder()} style={{padding: '16px'}}>
          {this.renderHeader()}
            <div className='trackerToggleBoxWrapper'>
              {this.state.pickup_or_delivery !== 'dine in' ? (
                <div className='trackerToggle'>
                    <button onClick={()=>this.switchView('status')} className={this.selectStatusButtonClass('status')}>Status</button>
                    <button onClick={()=>this.switchView('receipt')} className={this.selectStatusButtonClass('receipt')}>Receipt</button>
                </div>
                ) : null }
              {this.renderContent()}
            </div>
        </Modal>
      )
    } if (this.state.error){
      return(
        <div className='trackerBackground'>
          <div className='trackerContainer' style={{height:'auto'}}>
            <img onClick={()=>this.closeTrackOrder()} className='trackerClose' src='/close.png' alt='close'/>
            <div className='trackerContentContainer'>
              <div className='trackerStatus'>Whoops!</div>
              <br/>
              <div>Looks like you are not currently able to view this order. Try logging in below and then viewing your Order History in the side menu.</div>
              <br/>
              <button onClick={()=>this.openLogin()} className='hngrButton' style={{background:this.props.rest.hex_color_secondary}}>LOGIN</button>
            </div>
          </div>
        </div>
      )
    } else {
      return(
        <div className='trackerBackground'>
          <div className='trackerContainer' style={{height: '80vh'}}>
            <img onClick={()=>this.closeTrackOrder()} className='trackerClose' src='/close.png' alt='close'/>
            <div className='trackerContentContainer'>
              <LoadingIndicator/>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default withErrorHandling(
  SignupModalContextHOC(
    AnalyticsContextHOC(
      UserContextHOC(
        CartContextHOC(
          RestContextHOC(TrackOrder)
        )
      )
    )
  )
);
