import React from "react"
import MenuSearch from "../MenuSearch/MenuSearch"
import RestContextHOC from "../../Contexts/RestContextHOC"
import "./MenuLoading.css"

const MenuLoading = props => {
  const renderEmptyItems = () => {
    const items = [...Array(8)].map(i => (
      <div key={i} className="MenuItem MenuItemEmpty">
        <div className="MenuItemHeaderEmpty" />
        <div className="MenuItemDescriptionEmpty" />
        <div className="MenuItemDescriptionEmpty" />
        <div className="MenuItemDescriptionEmpty" />
      </div>
    ))
    return items
  }

  const renderCategories = () => {
    if (window.innerWidth < 800) {
      return <div className="MenuCategoriesEmpty" />
    } else {
      return (
        <div className="MenuCategoriesDesktop" id="Categories">
          <MenuSearch placeholder="Loading menu search..." />
        </div>
      )
    }
  }

  return (
    <div className="MenuContainer" style={{ paddingTop: "60px" }}>
      {renderCategories()}
      <div className="MenuCategoryHeader" style={{ opacity: 0.4 }}>
        Loading Menu...
      </div>
      <div
        className="MenuCategoryUnderline"
        style={{ background: props.rest.hex_color_secondary, opacity: 0.4 }}
      />
      {renderEmptyItems()}
    </div>
  )
}

export default RestContextHOC(MenuLoading)
