import React from "react";
import RestContextHOC from "../Contexts/RestContextHOC";
import QuantityInput from "../sparkles/QuantityInput/QuantityInput.jsx";

class QuantityInputModifier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blackedOut:false,
      value: ""
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.checkIfBlackedOut()
    if(this.props.editModifiers){
      let count = 0
      for(var i in this.props.editModifiers){
        let em = this.props.editModifiers[i]
        if(em.menu_item_id === this.props.modifier.menu_item_id){
          count += 1
        }
      }
      if(count){
        this.setState({value:count})
        this.props.onChange(this.props.modifier, count)
      }
    }
  }

  componentDidUpdate(prevProps){
    if(
      prevProps.rest.itemBlackouts.length !=
      this.props.rest.itemBlackouts.length
    ){
      this.checkIfBlackedOut()
    }
  }

  checkIfBlackedOut(){
    if(!this.props.modifier.item) return

    let menu_item_id = this.props.modifier.item.menu_item_id
    if(this.props.rest.itemBlackouts.indexOf(menu_item_id) > -1){
      this.setState({blackedOut:true})
    } else{
      this.setState({
        blackedOut:false
      })
    }
  }



  onChange(value) {
    //if the number has been deleted, set the quantity to 0
    if (value.length === 0) {
      this.props.onChange(this.props.modifier, 0);
      this.setState({ value: "" });
      return;
    }

    //if the length of theinput is over the length of the maximum allowed, stop allowing entry
    if (value.length > this.props.maxNum.toString().length) {
      return;
    }

    //turn the string input into an int
    var quantity = parseInt(value.replace(/\D/g, ""));

    //if we can't convert to int, just return and don't let the value show in the input
    if (isNaN(quantity)) {
      return;
    }

    // if the inputted value would be over the limit, don't allow it

    this.props.onChange(this.props.modifier, quantity);
    // if (!isValidInput) {
    //   return;
    // }
    this.setState({ value: quantity });
  }

  formatPrice(price){
    return price === '+$0.00' ? null : price
  }

  containerStyle(){
    let style
    if (this.state.hover){
      style = JSON.parse(JSON.stringify(styles.containerHover))
    } else {
      style = JSON.parse(JSON.stringify(styles.container))
    }
    if(this.state.blackedOut){
      style['pointerEvents'] = 'none'
      style['opacity'] = 0.5
    }
    return style
  }


  render() {
    return (
      <div
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        onClick={this.click}
        style={this.containerStyle()}
      >
        <div>
          <QuantityInput
            value={this.state.value}
            onChange={this.onChange}
            overLimit={this.props.overLimit}
          />
        </div>
        <div style={styles.dishName}>{this.props.modifier.name}</div>
        <div style={styles.price}>{this.formatPrice(this.props.modifier.display_price)}</div>
        <div />
      </div>
    );
  }
}

var styles = {
  container: {
    padding: "5px",
    display: "flex",
    alignItems: "center"
  },

  containerHover: {
    backgroundColor: "#eee",
    padding: "5px",
    display: "flex",
    alignItems: "center"
  },
  dishName: {
    paddingLeft: "8px",
    paddingRight: "8px"
  },
  price: {
    flexGrow: "1",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgb(34, 37, 43)"
  }
};

export default RestContextHOC(QuantityInputModifier);
