import React, { Component } from "react"
import LogRocket from 'logrocket';
import withErrorHandling from "../Contexts/withErrorHandling/withErrorHandling"
import withRouterAndRef from "../withRouterAndRef"
import GooglePlacesSearch from '../GooglePlacesSearch'
import GoogleMapsJSLoaderContextHOC from "../Contexts/GoogleMapsJSLoaderContextHOC"
import extractAddressComponents from "../helpers/googlePlaces"
import Loading from "../Loading/Loading"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import { ReactComponent as Bitcoin} from './Bitcoin.svg'
import { ReactComponent as PickupSelected} from './PickupSelected.svg'
import { ReactComponent as DeliverySelected} from './DeliverySelected.svg'
import X from "./X"
import MarkerX from "./MarkerX"
import RestaurantCard from "./RestaurantCard"
import "./Search.css"
import classnames from "classnames"
const Api = require("../helpers/api")

class SearchPage extends Component {
  constructor(props) {
    super(props)
    const fulldomain = window.location.host
    this.subdomain = fulldomain.split(".")[0].split("-")[0]
    //this.subdomain= 'seabasspizza'
    //this.subdomain = 'eatbitcoinpizza'

    this.state = {
      brand:null,
      width:window.innerWidth,
      height:window.innerHeight,
      pickupOrDelivery:'pickup',
      isDesktop:window.innerWidth > 850, 
      results:null,
      staticMapRest:null,
      loading:false,
    }
    this.googleMapsInitialized = false
    this.mobileMapRef = React.createRef()
    this.markers = {}
    
    // get the mobile map width
    this.state.mobileMapWidth = window.innerWidth
    // height is 
    this.state.mobileMapHeight = parseInt(window.innerWidth / 2)
    window.search = this
  }

  componentWillMount(){
    this.addFonts()
  }

  componentDidMount(){
    this.getBitcoinPrice()
    this.getBrandInfo()
    window.addEventListener("resize", this.handleResize.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this))
  }

  componentDidUpdate(){
    /*
    if(this.mobileMapRef && this.mobileMapRef.current && this.state.mobileMapLoaded){
      setTimeout(()=>{
        if(this.mobileMapRef && this.mobileMapRef.current){
          this.mobileMapHeight = this.mobileMapRef.current.height
          this.mobileMapWidth = this.mobileMapRef.current.width
        }
      }, 1000)
    }*/
  }

  handleResize(){
    this.setState({
      height:window.innerHeight,
      width:window.innerWidth,
      mobileMapWidth:window.innerWidth,
      mobileMapHeight:parseInt(window.innerWidth / 2),
      isDesktop:window.innerWidth > 850, 
    }, ()=>this.forceUpdate())
  }

  addFonts(){
    if(window.addedBitcoinFonts) return

    let links =` 
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap" rel="stylesheet">
    `
    document.head.innerHTML = document.head.innerHTML + links
    window.addedBitcoinFonts = true
  }

  initLogrocket(percentage){
    let rando = Math.floor( Math.random() * 100 )
    if (percentage >= rando){
      LogRocket.init(process.env.REACT_APP_LOGROCKET);
    } 
  }

  getBrandInfo(){
   Api.callApi(
      'brand-info-v2',
      (data)=> {
        this.setState({
          brand: data.brand
        })
        this.initLogrocket(data.brand.logrocket_percentage)
      },
      (err)=>{
        alert('An unknown error occurred.')
      },
      {subdomain:this.subdomain}
    )
  }

  getBitcoinPrice(){
    fetch('https://api.coinbase.com/v2/prices/spot?currency=USD')
      .then(response => response.json())
      .then(data => {
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        let pizzaPrice = 20/data.data.amount
        pizzaPrice = pizzaPrice.toFixed(4)
        let price = formatter.format(data.data.amount)
        this.setState({btcPrice:price, pizzaPrice:pizzaPrice})
      })
      .catch(()=>{

      })
  }

  updateMap(){
    if(!this.state.isDesktop) return false
    if(this.state.results.length === 0) return
    if(!this.googleMapsInitialized){
      this.map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 8,
      });    
      this.googleMapsInitialized = true

      window.google.maps.event.addListener(this.map, "click", function(event) {
        if(window.infowindow){
          window.infowindow.close();
        }
      });
    }

    var bounds = new window.google.maps.LatLngBounds();
    var infowindow = new window.google.maps.InfoWindow();    

    for (let i in this.state.results){
      let r = this.state.results[i]

      var icon = {
        url:'https://cdn.hngr.co/bitcoinmarker.png',
        scaledSize: new window.google.maps.Size(75, 75), // size
      };

      var marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(r.latitude, r.longitude),
        map: this.map,
        icon:icon,
        rest:JSON.parse(JSON.stringify(r))
      });

      this.markers[r.subdomain] = marker

      //extend the bounds to include each marker's position
      bounds.extend(marker.position);

      let that = this
      window.google.maps.event.addListener(marker, 'click', (function(marker, i) {

        var domain = process.env.REACT_APP_HNGR_DOMAIN
        var subdomain = marker.rest.subdomain
        var pickupOrDelivery = that.state.pickupOrDelivery
        var  url = `https://${subdomain}.${domain}?order_type=${pickupOrDelivery}`

        return function() {
          if(window.infowindow) window.infowindow.close(null)
          infowindow.setContent(`
            <div style='font-family: Space Mono; font-size:18px; font-weight:bold; text-transform: uppercase'> 
              ${marker.rest.secondary_name} 
            </div>
            <div style='color:#9B9B9B; font-size:14px; font-family: Space Mono;'>
              ${marker.rest.address1}
            </div>
            <div style='color:#9B9B9B; font-size:14px; font-family: Space Mono;'>
              ${marker.rest.city}, ${marker.rest.state} ${marker.rest.zipcode}
            </div>
            <div style='color:#F89E32; font-weight:700; font-family:Space Mono'>
              <a href='${url}' target='_blank'>ORDER HERE</a>
            </div>

          `)
          infowindow.open(this.map, marker);
          window.infowindow = infowindow
        }
      })(marker, i));
    }

    var offset = 0.003;
    var center = bounds.getCenter();
    bounds.extend(new window.google.maps.LatLng(center.lat() + offset, center.lng() + offset));
    bounds.extend(new window.google.maps.LatLng(center.lat() - offset, center.lng() - offset));

    this.map.fitBounds(bounds)


  }

  search(){
    if(!this.state.addressObj) return

    let endpoint
    if(this.state.pickupOrDelivery === 'pickup'){
      endpoint = 'locations-v2-pickup-search'
    } else {
      endpoint = 'locations-v2-delivery-search'
    }
    this.markers = {}
    this.setState({loading:true})
    Api.callApi(
      endpoint,
      (data)=>{
        this.setState({
          results:data.rests, 
          is_before_4am:data.is_before_4am,
          loading:false
        },()=>this.updateMap())
      },
      (err)=>{
        this.setState({loading:false})
        alert('Unknown error searching for restaurants')
      },
      {lat:this.state.addressObj.lat, 
      lng:this.state.addressObj.lng,
      brand_id:this.state.brand.brand_id}
    )
  }

  updatePickupOrDelivery(val){
    this.setState({
      pickupOrDelivery:val, 
      staticMapRest:null,
      restClicked:null
    }, ()=>this.search())
  }

  getStaticMapUrl(){
    let url = `https://maps.googleapis.com/maps/api/staticmap?key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    // we need to get the pins
    // we need to get the center, we can do this using regular math
    let latSum = 0
    let lngSum = 0
    let results = this.state.results
    if(this.state.staticMapRest){
      results = [this.state.staticMapRest]
    }

    for(let i in results){
      let rest = results[i]
      latSum += rest.latitude
      lngSum += rest.longitude
    }
    let centerLat = latSum/results.length
    let centerLng = lngSum/results.length
    let center = `${centerLat},${centerLng}`
    url += '&center='+center
    url += `&size=${this.state.mobileMapWidth}x${this.state.mobileMapHeight}`
    let markers = '&markers=icon:https://cdn.hngr.co/bitcoinmarkersmall.png%7C'
    for(let i in results){
      let rest = results[i]
      let point = rest.latitude +','+rest.longitude 
      markers += point + '%7C'
    }
    url += markers
    console.log("bucks", url)
    return url
  }

  svgPickerStyle(){
    if(this.state.isDesktop) return
    if(!this.state.width) return
    let width = this.state.width - 60
    let height = parseInt(width/433.0 * 55)
    return {
      width:width + 'px',
      height:height + 'px'
    }
  }

  validEmail(){
    if(!this.state.email) return false
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    /*eslint-enable */
    return re.test(this.state.email)

  }

  submitEmail(){
    if(!this.validEmail(this.state.email)) return
    this.setState({emailSaving:true})
    Api.callApi(
      'locations-page-save-email',
      (data)=>{this.setState({emailSaving:false, emailSaved:true})},
      (error)=>{this.setState({emailSaving:false})},
      {email:this.state.email, brand_id:this.state.brand.brand_id}
    )
  }

  renderContent(){
    return (
      <div className='locations-search-container'>
        {(this.state.isDesktop === false && this.state.results !== null) == false && ( 
          <>
            <div 
              style={this.state.isDesktop ? {marginTop:'0px'} : {}}
              className='locations-search-title'
            >
              The Future of Pizza!
            </div>

            <div className='locations-search-subtitle'>
              Find a nearby location &amp; order Bitcoin Pizza to your door
            </div>

            <div className='locations-search-bitcoin-pizza-price-container'>
              
              <div className='locations-search-bitcoin-pizza-price-box '>
                <div className='border-tr'></div>
                <div className='border-tl'></div>
                <div className='border-bl'></div>
                <div className='border-br'></div>
                <div className='locations-search-bitcoin-circle'>
                  <Bitcoin />
                </div>
                <div className='locations-search-bitcoin-pizza-price-box-right-side'> 
                  <div className='locations-search-bitcoin-pizza-price-box-right-side-title'>
                    Bitcoin
                  </div>
                  <div className='locations-search-bitcoin-pizza-price-box-right-side-subtitle'>
                    {this.state.btcPrice}
                  </div>
                </div>
              </div>
              
              <div className='locations-search-bitcoin-pizza-price-box'>
                <div className='border-tr'></div>
                <div className='border-tl'></div>
                <div className='border-bl'></div>
                <div className='border-br'></div>
                <img className='locations-search-pizza-img' src='https://cdn.hngr.co/Pizza8bit.png'/>
                <div className='locations-search-bitcoin-pizza-price-box-right-side'> 
                  <div className='locations-search-bitcoin-pizza-price-box-right-side-title'>
                    Pizza
                  </div>
                  <div className='locations-search-bitcoin-pizza-price-box-right-side-subtitle' style={{alignItems:'center'}}>
                   {this.state.pizzaPrice} BTC
                  </div>
                </div>
              </div>

            </div> {/* locations-search-bitcoin-pizza-price-container */}
          </>
        )}

        <div 
          className='pickup-delivery-selected' 
          style={this.state.pickupOrDelivery === 'pickup' ? {position:'relative', left:'-3px'} : {}}
        >
          <div 
            className='pickup-delivery-selected-pickup-click'
            onClick={()=>this.updatePickupOrDelivery('pickup')}
          >
          </div>
          <div 
            className='pickup-delivery-selected-delivery-click'
            onClick={()=>this.updatePickupOrDelivery('delivery')}
          >
          </div>
          {this.state.pickupOrDelivery === 'pickup' && (
            <PickupSelected style={this.svgPickerStyle()}/>
          )}

          {this.state.pickupOrDelivery === 'delivery' && (
            <DeliverySelected style={this.svgPickerStyle()} />
          )}
        </div>

        <div 
          className='location-search-autocomplete-wrapper'
          style={(!this.state.isDesktop && this.state.results) ? {marginTop:'0px'}: {}}
        >
            <GooglePlacesSearch
              allowAnyPlace={this.state.pickupOrDelivery === 'pickup'}
              page="locations-page"
              closeIcon={<X/>}
              currentLocationStart={()=>this.setState({loading:true})}
              currentLocationEnd={()=>this.setState({loading:false})}
              ref={this.placesRef}
              selectPlace={(addressObj)=>{
                this.setState({addressObj:addressObj}, ()=>this.search())}
              }
              onShowSuggestions={()=>{
                if(this.bottom && this.bottom.current){
                  this.bottom.current.scrollIntoView({ behavior: "smooth" })
                }
              }}
              onAddressUpdate={(val)=>{
                this.setState({addressObj:val})
                if(this.placesRef && this.placesRef.current){
                  this.placesRef.current.setState({error:null})
                }
              }}
              onClear={()=>{
                this.setState({results:null})
              }}
              onVerifiedAddressUpdate={(val)=>{
                this.setState({verifiedAddress:val})
              }}
            />
        </div> {/* autocomplete wrapper */ }


        {this.state.loading === true && (
          <div>
            <Loading />
          </div>

        )}

        {this.state.results !== null && this.state.loading !== true && (
          <div className='locations-page-ressults-container'>
            {this.state.results.map(rest=>(
              <div style={{marginBottom:'25px'}}>
                <RestaurantCard 
                  onClick={()=>{
                    if(this.state.isDesktop){
                      this.map.panTo(this.markers[rest.subdomain].getPosition())
                      this.map.setZoom(16)
                    } else{
                      this.setState({staticMapRest:rest},()=>window.scrollTo(0,0))
                    }
                    this.setState({restClicked:rest.restaurant_id})
                  }}
                  isClicked={this.state.restClicked === rest.restaurant_id}
                  isBefore4am={this.state.is_before_4am}
                  rest={rest} 
                  key={rest.restaurant_id+this.state.pickupOrDelivery} 
                  pickupOrDelivery={this.state.pickupOrDelivery}
                />
              </div>
            ))}
          </div>
        )}

        {this.state.results !== null && this.state.results.length === 0 && this.state.loading !== true && (
          <div className='locations-page-no-results'>
            <MarkerX/> 
            <div className='locations-page-no-locations'>
              No locations near your address
            </div>

            {this.state.emailSaved !== true && this.state.emailSaving !== true && (
              <>
                <div className='locations-page-enter-email-container'>
                  <input 
                    value={this.state.email}
                    onChange={(e)=>this.setState({email:e.target.value})}
                    className='locations-page-enter-email'
                    placeholder='Enter your email &amp; stay updated'
                  />
                </div>
                <div 
                  className={classnames('locations-page-email-button',
                    {'locations-page-email-button-enabled':this.validEmail()})}
                  onClick={()=>this.submitEmail()}
                >
                  <div>Enter your email</div>
                </div>
              </>
            )}

            {this.state.emailSaving === true && (
              <Loading />
            )}

            {this.state.emailSaved === true && (
              <>
                <div className='locations-page-enter-email-container'>
                  <input 
                    className='locations-page-enter-email'
                    disabled={true}
                    placeholder='You are now registered for updates'
                  />
                </div>
                <div 
                  className={classnames('locations-page-email-button',
                    'locations-page-email-button-thank-you')}
                  onClick={()=>this.submitEmail()}
                >
                  <div>Thank You</div>
                </div>
              </>
            )}
          </div>
        )}

        {this.state.isDesktop && ( 
          <div 
            className='see-all-locations'
            onClick={()=>this.props.history.push({
              pathname:'/all-locations'
            })}
          >
            See All Locations
          </div>
        )}

      </div>
    )
  }


  getDesktopGoogleMapStyle(){
    if(this.state.results === null || this.state.results.length === 0){
      return {display:'none'}
    } else{
      return {
        position:'sticky'
      }
    }
  }

  getMobileGoogleMapStyle(){
    if(this.state.results === null || this.state.results.length === 0){
      return {display:'none'}
    } else{
      return {
        width:this.state.mobileMapWidth + 'px',
        height:this.state.mobileMapHeight + 'px',
      }
    }
  }

  getMobileMapImgStyle(){
    let style = {
      width:this.state.mobileMapWidth + 'px',
      height:parseInt(this.state.mobileMapHeight/1.5) + 'px',
    }
    // hide the map for short screens, but not when the no locations page is shown
    /*
    if(this.state.height <= 650 && !(this.state.results !== null && this.state.results.length === 0)){
      style['display'] = 'none'
    }*/
    return style
  }

  renderDesktop(){
    return (
      <div className='locations-page-search-desktop-container'>
        <div className='locations-search-desktop-header'>
          <img className='locations-search-desktop-header-img' src={this.state.brand.logo_url}/>
        </div>
        {this.renderContent()}
        {(this.state.results === null || this.state.results.length == 0) && (
          <img 
            className='locations-page-search-desktop-map' 
            src='https://cdn.hngr.co/8bitmap.gif'
          />
        )}
        <div id='map' 
          style={this.getDesktopGoogleMapStyle()}
          className='locations-page-search-desktop-map'
        >
        </div>

      </div>
    )
  }

  renderMobile(){
    let showBaseMap = false
    if(this.state.results === null || this.state.results.length == 0){
      showBaseMap = true
    }
    return (
      <div className='locations-page-mobile-container' style={{minWidth:this.state.width+'px', minHeight:this.state.height+'px'}}>
        <div className='locations-search-mobile-header'>
          <img className='locations-search-mobile-header-img' src={this.state.brand.logo_url}/>
        </div>
         {showBaseMap === true && (
          <img 
            ref={this.mobileMapRef}
            onLoad={()=>this.setState({mobileMapLoaded:true})}
            style={this.getMobileMapImgStyle()}
            className='locations-page-search-mobile-map' 
            src='https://cdn.hngr.co/8bitmap.gif'
          />
        )}
        {showBaseMap === false &&  (
          <img
            className='locations-page-search-mobile-static-map' 
            style={this.getMobileGoogleMapStyle()}
            src={this.getStaticMapUrl()}
          />
        )}
        {this.renderContent()}
        <div 
          className='see-all-locations'
          onClick={()=>this.props.history.push({
            pathname:'/all-locations'
          })}
        >
          See All Locations
        </div>
      </div>
    )
  }

  render() {
    if(!this.state.brand){
      return(
        <div className='bitcoin-loading-background'>
          <img src='https://cdn.hngr.co/bitcoin.gif' />
        </div>
      )
    }
    if(this.state.isDesktop) return this.renderDesktop()
    else return this.renderMobile()
  }
}

const BTC = props => (<svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.4308 13.4351V14.7868C2.4308 14.936 2.5519 15.0571 2.70112 15.0571H3.78244C3.85414 15.0571 3.92289 15.0286 3.97359 14.9779C4.02429 14.9272 4.05277 14.8585 4.05277 14.7868V13.4351H4.59343V14.7868C4.59343 14.936 4.71453 15.0571 4.86375 15.0571H5.94507C6.01677 15.0571 6.08552 15.0286 6.13622 14.9779C6.18692 14.9272 6.2154 14.8585 6.2154 14.7868V13.4351H6.30623C8.46021 13.4351 10 12.3181 10 10.3858C10 8.76168 8.91112 7.87392 7.63625 7.7474V7.65225C8.68512 7.39057 9.4561 6.59905 9.4561 5.28417C9.4561 3.6276 8.21259 2.62197 6.31488 2.62197H6.2154V1.27033C6.2154 1.19863 6.18692 1.12987 6.13622 1.07918C6.08552 1.02848 6.01677 1 5.94507 1H4.86375C4.79206 1 4.7233 1.02848 4.6726 1.07918C4.62191 1.12987 4.59343 1.19863 4.59343 1.27033V2.62197H3.97383V1.27033C3.97383 1.19863 3.94535 1.12987 3.89466 1.07918C3.84396 1.02848 3.7752 1 3.7035 1H2.70112C2.62943 1 2.56067 1.02848 2.50997 1.07918C2.45928 1.12987 2.4308 1.19863 2.4308 1.27033V2.62197L0.270329 2.63387C0.198633 2.63387 0.129874 2.66235 0.0791772 2.71304C0.0284808 2.76374 0 2.8325 0 2.9042V3.97362C0 4.12176 0.118945 4.24394 0.268166 4.24394L1.08456 4.23854C1.29871 4.23997 1.5036 4.32604 1.65452 4.47797C1.80544 4.62991 1.89014 4.83537 1.89014 5.04952V11.0022C1.89014 11.2172 1.8047 11.4235 1.65261 11.5756C1.50052 11.7277 1.29424 11.8131 1.07915 11.8131L0.270329 11.825C0.198633 11.825 0.129874 11.8535 0.0791772 11.9042C0.0284808 11.9549 0 12.0237 0 12.0954V13.1767C0 13.3259 0.121107 13.447 0.270329 13.447L2.4308 13.4351ZM3.97383 4.22989H5.83261C6.81228 4.22989 7.38754 4.76838 7.38754 5.64857C7.38754 6.5904 6.76579 7.12132 5.35792 7.12132H3.97383V4.22989V4.22989ZM3.97383 8.61029H5.96345C7.19291 8.61029 7.86224 9.23746 7.86224 10.2582C7.86224 11.2887 7.18534 11.8261 5.52876 11.8261H3.97383V8.61138V8.61029Z" fill="#FAFAFA"/>
</svg>)

export default GoogleMapsJSLoaderContextHOC(
  AnalyticsContextHOC(
    withRouterAndRef(SearchPage)
  )
)
