export const Fee = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="56" rx="10" fill="#DFDFDF" />
    <g clipPath="url(#clip0_1352_7079)">
      <path
        d="M28 16C21.4 16 16 21.4 16 28C16 34.6 21.4 40 28 40C34.6 40 40 34.6 40 28C40 21.4 34.6 16 28 16ZM24.196 22.06C25.372 22.06 26.32 23.008 26.32 24.196C26.32 25.372 25.372 26.32 24.196 26.32C23.008 26.32 22.06 25.372 22.06 24.196C22.06 23.008 23.008 22.06 24.196 22.06ZM31.864 34C30.688 34 29.74 33.04 29.74 31.864C29.74 30.688 30.688 29.74 31.864 29.74C33.04 29.74 34 30.688 34 31.864C34 33.04 33.04 34 31.864 34ZM23.8 34.036L22 32.236L32.236 22L34.036 23.8L23.8 34.036Z"
        fill="#919397"
      />
    </g>
    <defs>
      <clipPath id="clip0_1352_7079">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(16 16)"
        />
      </clipPath>
    </defs>
  </svg>
)
