import React, { Component } from "react"

import { withRouter } from "react-router-dom"
import RestContextHOC from "../Contexts/RestContextHOC"
import UserContextHOC from "../Contexts/UserContextHOC"
import AnalyticsContextHOC from "../Contexts/AnalyticsContextHOC"
import EntryPointHOC from "../Contexts/EntryPointHOC"
import SignupModalContextHOC from "../Contexts/SignupModalContextHOC"
import Checkbox from "../sparkles/Checkbox/Checkbox"
import Loading from "../Loading/Loading"
import Modal from "../Modal/Modal"
import "../css/LoginSignup.css"

const Api = require("../helpers/api.js")


class PhoneVerifier extends Component {
  constructor(props) {
    super(props)

    // capture any sendTo url params
    const params = new URLSearchParams(window.location.search)
    const sendTo = params.get('sendTo')

    this.state = {
      authUUID: null,
      authMethod: null,
      phone: "",
      code: "",
      loading: this.props.rest.name ? false : true,
      error: false,
      errorMessage: '',
      sendTo: sendTo,
      phone_modal_opt_in: false
    }
    this.handleEsc = this.handleEsc.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleSubmitCode = this.handleSubmitCode.bind(this)
    this.togglePhoneModalOptIN = this.togglePhoneModalOptIN.bind(this)
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEsc, false)
    this.props.analytics.info("PhoneVerifierPage")
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEsc, false)
  }

  componentDidUpdate(prevProps) {
    if (this.props.rest.name && this.state.loading === true) {
      this.setState({loading: false})
    }
  }

  togglePhoneModalOptIN() {
    this.setState({phone_modal_opt_in: !this.state.phone_modal_opt_in})
  }

  handleInputPhone(e=null) {
    if (e && e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      this.handleSubmitPhone(e)
      return
    }
    var cleaned = e.target.value.replace(/\D/g, "")
    if (cleaned.length > 10) {
      cleaned = cleaned.slice(0, 10)
    }
    if (cleaned.length === 10) {
      cleaned = cleaned.slice(0, 3) + "-" +
        cleaned.slice(3, 6) + "-" +
        cleaned.slice(6, cleaned.length)
    }
    this.setState({ phone: cleaned, error: false })
  }

  // this is invoked when a code is entered
  // note that it will autosubmit basically on len 4
  // but should respect the backspace key
  handleInputCode(e=null) {
    let inputCode = this.state.code
    if (e) {
      inputCode = e.target.value
    }
    inputCode = inputCode.replace(/\D/g, "")
    this.setState({ code: inputCode, error: false })
    if (e && e.keyCode === 8) { return } // backspace

    if (e && e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      this.handleSubmitCode()
      return
    }

    // submit button clicked but incomplete code
    if (!e && inputCode && inputCode.length !== 4) {
      let msg = "Code should be 4 digits. Texted to " + this.state.phone
      this.setState({error: true, errorMessage: msg})
      return
    }
    if (inputCode && inputCode.length === 4) {
      this.handleSubmitCode(inputCode)
      return
    } 
  }

  // handleSubmitCode the full code is typed in and 
  // read to go, handle and go
  handleSubmitCode(inputCode=null) {
    if (this.state.loading === true) { return }

    let code = this.state.code
    if (inputCode != null) {
      code = inputCode
    } 
    if (code.length !== 4) {
      this.setState({ error: true })
      return
    }
    this.setState({loading: true, error: false }, () => {
      this.props.analytics.info("SubmittingVerificationCode", code)
      let payload = {'auth_uuid': this.state.authUUID,
        'method': this.state.authMethod,
        'phone': this.state.phone,
        'modal_opt_in': this.state.phone_modal_opt_in,
        'diner_uuid': this.props.user.user.diner_id,
        'rest_uuid': this.props.rest.rest_id,
        'verification_code': code}

      Api.callApi(
        "phone_verification_end",
        resp => this.confirmationCodeCheckCallback(resp),
        err => {
          this.props.analytics.error("VerificationCodeApiFail",
            JSON.stringify(err))
          this.setState({
            loading: false,
            error: true,
            errorMessage: 'Unable to verify code'
          })
        },
        payload
      )
    })
  }


  handleSubmitPhone() {
    this.setState({loading: true })
    let phone = this.state.phone
    //REMOVE ALL PARENS, SPACES, DASHES, 1s or +1s
    /*eslint-disable */
    phone = phone.replace(/\-/g, "")
    phone = phone.replace(/\(/g, "")
    phone = phone.replace(/\)/g, "")
    phone = phone.replace(/\ /g, "")
    /*eslint-enable */
    if (phone.slice(0, 2) === "+1") {
      phone = phone.substring(2, phone.length)
    }
    if (phone.slice(0, 1) === "1") {
      phone = phone.substring(1, phone.length)
    }
    this.props.analytics.info("StartingPhoneVertification", phone)
    Api.callApi(
      "phone_verification_start",
      resp => this.startPhoneVerification(resp, phone),
      () => {
          this.setState({
            loading: false,
            error: true,
            errorMessage: 'Unable to verify code'
          })
      },
      {phone: phone, component:'PhoneVerifier'}
    )
  }

  // invoked when the API returns whether code
  // the user entered is the right one or not
  confirmationCodeCheckCallback(resp) {
    if (resp["success"] === 1) {
      this.setState({ loading: false })
      this.props.analytics.info("VerificationCodeSuccess",
        JSON.stringify(resp))

      // update the current user state with phone info
      // and then close this modal
      this.props.user.addVerifiedPhone(resp.phone_e164)

      // if we still dont have a name, force them to the more info
      // modal to collect their name, etc before pushing to checkout
      if (!this.props.user.user.first_name ||
          !this.props.user.user.last_name) {
        let pathName = '/menu/moreinfo?sendTo=checkout'
        this.props.history.replace({pathname: pathName})
      } else if (this.state.sendTo === 'checkout') {
        this.props.history.replace({pathname: '/checkout'})
      } else {
        this.handleClose()
      }

    } else {
      this.props.analytics.warn("VerificationCodeFail", JSON.stringify(resp))
      this.setState({
        loading: false,
        error: true,
        errorMessage: resp.message
      })
    }
  }

  validatePhone(num) {
    /*eslint-disable */
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    /*eslint-enable */
    return re.test(num)
  }

  startPhoneVerification(resp, phone) {
    this.props.analytics.debug(
      "StartingPhoneVerificationResponse",
      JSON.stringify(resp))
    if (resp["success"] === 1) {
      let authMethod = resp['method']
      let authUUID = resp['auth_uuid']
      let loadMsg = "Sending a code to " + this.state.phone

      /* when SMS is disabled, just permit thru */
      if (authMethod == 'SMS_VERIFICATION_DISABLED') {
        this.props.user.addVerifiedPhone(resp['phone_e164'])
        if (this.state.sendTo === 'checkout') {
          this.props.history.replace({pathname: '/checkout'})
        } else {
          this.handleClose()
        }
      }

      this.setState({ loading: false,
        loadingMessage: loadMsg,
        authUUID: authUUID,
        authMethod: authMethod })
    } else {
      this.setState({
        loading: false,
        error: true,
        errorMessage: resp["message"]
      })
    }
  }

  handleClose() {
    this.props.analytics.info('PhoneVerificationClose')
    // remove everything after the last slash in the url to go back to 
    // the previous page
    let currentURL = window.location.pathname
    let lastPath = currentURL.substring(0, currentURL.lastIndexOf("/"))
    this.props.history.push({
      pathname: lastPath,
      search: window.location.search
    })
  }

  handleEsc(e) {
    if (e.keyCode === 27) {
      e.preventDefault()
      this.handleClose()
      return
    }
  }

  handleClickOutside() {
    this.handleClose()
  }

  render() {
    if (this.state.loading) {
      return (
        <Modal close={this.handleClickOutside}
          style={{ textAlign: "center", paddingTop: "24px" }}>
          <Loading />
          {this.state.loadingMessage &&
            <div>{this.state.loadingMessage}</div>}

        </Modal>
      )
    } else {
      return (
        <Modal
          close={this.handleClickOutside}
          style={{ textAlign: "center", paddingTop: "24px" }}>
          {!this.state.authUUID && 
            <div>
            <div className="header">Confirm your phone number</div>
            <div className="inputContainer">
              <div className="inputLabel">CELL PHONE:</div>
              <div className="inputPhonePrefix">+1</div>
              <input
                autoFocus
                id="inputPhone"
                type="tel"
                className="inputBody"
                value={this.state.phone}
                onChange={e => this.handleInputPhone(e)}
                onKeyDown={e => this.handleInputPhone(e)}
              />
              <br />
                <button
                  className="AccountButton"
                  style={{ background: this.props.rest.hex_color_primary }}
                  onClick={e => this.handleSubmitPhone(e)}>NEXT</button>
            </div>
          </div>}

          {this.state.authUUID && 
           <div>
           <div className="header">Enter Code</div>
           <div>
            <input
              autoFocus
              value={this.state.code}
              className="codeInput"
              type="tel"
              onChange={e => this.handleInputCode(e)}
              onKeyDown={e => this.handleInputCode(e)}
            />
            <br />
              <button
                className="AccountButton"
                style={{ background: this.props.rest.hex_color_primary }}
                onClick={e => this.handleInputCode()}>SUBMIT
              </button>
            </div>
            </div>
          }
          {this.state.error && 
          <div className='errorText'>
            {this.state.errorMessage}
          </div>
          }

          {!this.props.rest.phone_modal_text_blob && <div className="finePrint">
          We will send you text messages to confirm your number and updates related to your order status, such as a delivery or pickup notification. We will also send you text messages to confirm your phone number. Message and data rates may apply.
          </div>
          }
          {this.props.rest.phone_modal_text_blob && this.props.rest.phone_modal_opt_in_checkbox &&
          <div className='finePrint'>
            {this.props.rest.phone_modal_text_title &&
              <div className='finePrintTitle'>
                {this.props.rest.phone_modal_text_title}
              </div>
            }
            <div className='finePrintCheckboxContainer'>
            <div className='finePrintCheckbox' onClick={this.togglePhoneModalOptIN}>
              <Checkbox checked={this.state.phone_modal_opt_in} />
            </div>
            <div className='finePrintWithCheckbox'>
              {this.props.rest.phone_modal_text_blob}
            </div>
            </div>
          </div>
          }

          {this.props.rest.phone_modal_text_blob && !this.props.rest.phone_modal_opt_in_checkbox &&
          <div className="finePrint">
            { this.props.rest.phone_modal_text_blob }
          </div>
          }

          <br />
          <div className="finePrintBottom">
            View our <a href={this.props.rest.phone_modal_terms_url}
            target="_blank"
            rel="noopener noreferrer">
            <u>Terms of Service</u>
          </a> and <a href={this.props.rest.phone_modal_privacy_url}
            target="_blank"
            rel="noopener noreferrer">
            <u>Privacy Policy</u>
          </a>
          </div>
        </Modal>
      )
    }
  }
}

export default withRouter(RestContextHOC(SignupModalContextHOC(
  AnalyticsContextHOC(
      UserContextHOC(
        EntryPointHOC(RestContextHOC(PhoneVerifier)))))))
