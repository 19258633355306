import React from "react";
import NoCardSelected from "./NoCardSelected.jsx";

const cardBrandToPfClass = {
  visa: "1.png",
  mastercard: "2.png",
  amex: "22.png",
  discover: "14.png"
};
const CDN_URL = "https://cdn.hngr.co/cards/";

const CardLogoImg = ({ cardName, color }) => {
  const fillColor = color || "#CED4DA";

  if (cardName && cardName.toLowerCase() in cardBrandToPfClass) {
    const cardSrc = CDN_URL + cardBrandToPfClass[cardName.toLowerCase()];
    return (
      <img
        src={cardSrc}
        alt="card logo"
        style={{ height: "17px", margin: "auto", marginRight: "16px" }}
      />
    );
  } else {
    return (
      <div style={{ marginRight: "16px", width: "auto" }}>
        <NoCardSelected color={fillColor} />
      </div>
    );
  }
};

export default CardLogoImg;
