import React from 'react';
import {RestContext} from "./RestContext";

export default function RestContextHOC(Component){
  return React.forwardRef((props, ref) => (
      <RestContext.Consumer>
          {
            (context) => <Component ref={ref} rest={context} {...props} /> 
          }
      </RestContext.Consumer>
  ))
}

