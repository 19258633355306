import React, { Component } from "react"
import {loadStripe} from '@stripe/stripe-js';
import { withRouter } from "react-router-dom"
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  ElementsConsumer,
  useStripe, 
  useElements, 
  CardElement } from '@stripe/react-stripe-js';

import  RestaurantHeader from "./RestaurantHeader"
import "./ContactlessPayment.css"
import CC from "./NoCardSelected"
import Modal from 'react-modal';
import Loading from '../Loading/Loading'

Modal.setAppElement('#root')

const api = require('../helpers/api')
const formatPrice = require("../helpers/formatPrice")

const INPUT_STYLE = {         
  base: {
    fontSize: '20px',
    paddingTop:'7px',
    textAlign:'left',
    fontFamily: 'sans-serif',
    color: '#424770',
    '::placeholder': {
        color: '#aab7c4',
        fontWeight:100,
        fontSize:'20px',
    }
  }
}

class CheckoutForm extends React.Component  {

  constructor(props){
    super(props)
    this.state = {
      zipcode:'',
      hash_id:this.props.match.params.hash_id,
      loaded:false,
      expanded:'credit_card',
      tip:null,
      tipAmounts:[200,300,400],
      tipModalOpen:false,
      customTip:null,
      loadingPay:false,
      thankyou:false,
      receiptSent:false,
      receiptSending:false,
      email:''
    }
    this.expandCreditCard = this.expandCreditCard.bind(this)
    this.payWithCard = this.payWithCard.bind(this)
    this.sendReceipt = this.sendReceipt.bind(this)
    this.getData()
    this.customTipInput = React.createRef()

  }

  getData(){
    api.callApi(
      "contactless-payment-retrieve",
      (data) => {
        let state = {amount:data.amount, rest:data.rest, loaded:true}
        if (data.paid){
          state['thankyou'] = true
          state['tip'] = data.tip
        }
        this.setState({...state})
      },
      (err) => {
        alert(err)
      },
      {hash_id:this.state.hash_id}
    );
  }
  
  
  pay(token){
    this.setState({loadingPay:true})
    api.callApi(
      "contactless-payment-pay",
      (data) => {
        if(!data.success){
          alert(data.error_message)
          this.setState({loadingPay:false})
        } else{
          this.setState({loadingPay:false, thankyou:true})
        }
      },
      () => {
        alert('Error')
      },
      {hash_id:this.state.hash_id, stripe_token:token, tip:this.state.tip}
    );
  }

  async payWithCard(event) {
    const {stripe, elements} = this.props
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card, {address_zip:this.state.zipcode});

    if (result.error) {
      alert(result.error.message);
    } else {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
      this.pay(result.token.id)
    }
  };

  expandCreditCard(){
    this.setState({expanded:'credit_card'})
  }

  
  calculateAmount(){
    if (this.state.tip){
      return this.state.amount + this.state.tip
    } else {
      return this.state.amount
    }
  }

  parseCustomTip(){
    let customTip = this.state.customTip
    if(customTip === 0){
      this.setState({tip:0, tipModalOpen:false, customTip:null})
      return
    } else if(customTip === null){
      this.setState({customTip:null})
      alert('Please enter a valid number')
      return
    }
    let tip = Math.round(parseFloat(this.state.customTip) * 100)
    this.setState({tip:tip, tipModalOpen:false, customTip:null})
  }

  filterInput(value) {
    //check to see if they entered a valid $ amount into the input
    if (value.length && isNaN(parseFloat(value))) {
      //this.selectDefaultTipAmount()
      return
    }

    //strip non-numerical characters
    value = value.replace(/[^0-9.]/g, "")

    this.setState({ typing: true })

    if (value.length) {
      if (parseFloat(value) < 0) {
        alert('Tip must be positive!')
        return
      }


      //don't allow multiple decimlas
      if ((value.match(/\./g) || []).length > 1) {
        return
      }

      //don't allow more than 2 numbers after the decimal
      if ((value.match(/\./g) || []).length === 1) {
        var idx = value.indexOf(".")
        if (idx + 4 <= value.length) {
          return
        }
      }

      //if they are typing in a period, then we don't want to parseFloat it
      var customTip = 0
      if (value.endsWith(".")) {
        customTip = value
      } else {
        //we need to set toFixed to be the amount after the decimal that's currently typed in
        //otherwise this code will make 3.40 into 3.4
        var period_idx = value.indexOf(".")
        if (period_idx > 0) {
          customTip = parseFloat(value).toFixed(value.length - 1 - period_idx)
        } else {
          customTip = parseFloat(value)
        }
      }

      this.setState({
        customTip: customTip
      })
    } else {
      this.setState({ customTip: null, typing: false })
    }
  }


  isEmailSet(){
    if(!this.state.email) return false
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    /*eslint-enable */
    return re.test(this.state.email);
  }
  
  sendReceipt(){
    this.setState({receiptSending:true})
    api.callApi(
      'contactless-payment-receipt',
      (data) => {
        this.setState({receiptSent:true, receiptSending:false})
      },
      (err) => {
        this.setState({receiptSending:false})
        alert('There was an error sending your receipt')
      },
      {hash_id:this.state.hash_id, email:this.state.email}
    );
  }

  render(){
    if(!this.state.loaded){
      return (
        <div>
          <div className='loading-div'>
            <Loading/>
          </div>
        </div>
      )
     
    }
    if(this.state.loadingPay){
      return (
        <div>
          <RestaurantHeader hideDropdown={true} rest={this.state.rest}/>
          <div className='loading-div'>
            <Loading/>
          </div>
        </div>
      )

    }

    if(this.state.thankyou){
      return (
        <div>
          <RestaurantHeader hideDropdown={true} rest={this.state.rest}/>
          <div className='thank-you-container'>
            <div className='thank-you-top-section grey-bar-bottom'>
              <div className='thank-you-for-supporting-us'>
                <div className='thank-you-for-supporting-us-top'>
                  Thank you for supporting us.
                </div>
                <div className='thank-you-for-supporting-us-bottom'>
                  Payment successful. <br/>
                  You're good to go!
                </div>
              </div>
              <div className='thank-you-checkmark-amount'>
                <div className='thank-you-checkmark'>
                    <svg style={{fill:'#F07A22'}} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
                </div>
                <div className='thank-you-amount'>
                  {formatPrice.formatPrice(this.calculateAmount())}
                </div>
              </div>
            </div>
            <div className='thank-you-social-media-section grey-bar-bottom'>
              <div className='thank-you-social-media-text'>
                Always order directly from our site. <br/> 
                {(this.state.rest.facebook_url || this.state.rest.inta_url) && (<span>
                Support and follow us on social media: </span>) }
              </div>
              <div className='thank-you-soecial-media-icons'>
                  {this.state.rest.facebook_url && (
                    <a target='_blank' href={this.state.rest.facebook_url}>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{fill:this.state.rest.hex_color_primary}} width="29" height="29" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/></svg>
                    </a>
                    )}

                  {this.state.rest.insta_url && (
                    <a target='_blank' href={this.state.rest.insta_url}>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{fill:this.state.rest.hex_color_primary}}  width="29" height="29" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                    </a>
                        )}
                      </div>
                    </div>
                  {this.state.rest.website_url && (
                    <div className='thank-you-order-section grey-bar-bottom'>
                      <div className='thank-you-order-text'>
                        Order pickup and delivery directly from our website:
                      </div>
                      <div className='thank-you-order-link'>
                        <a target='_blank' href={this.state.rest.website_url}>
                          {this.state.rest.website_url.replace('https://', '').replace('http://','')}
                        </a>
                      </div>
                    </div>
                  )}
              </div>

              {this.state.receiptSent ? (
                <div className='thank-you-receipt-sent-section'>
                  <div className='email-img'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/></svg>
                  </div>
                  <div>
                    <div className='thank-you-receipt-sent-top-text'>
                      We sent an email of your receipt to:
                    </div>
                    <div className='thank-you-receipt-sent-bottom-text'>
                      {this.state.email}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='thank-you-email-section'>

                  {this.state.receiptSending ? (
                    <div className='receipt-sending-loading'>
                      <Loading/>
                    </div>
                  ) : (
                  <div className='thank-you-email-section-top-text'>
                    Send your receipt &amp; sign up for rewards
                    <input 
                      value={this.state.email}
                      onChange={(e)=>this.setState({email:e.target.value})}
                      placeholder='Email address'
                    />
                    <div 
                      onClick={this.sendReceipt}
                      className={this.isEmailSet() ? 'thank-you-email-section-button email-button-enabled' : 'thank-you-email-section-button email-button-disabled'}
                    > 
                      Submit Email
                    </div>
                  </div>
                )}
                </div>
            )}

            </div>
          )
    }

    return (
      <div>
        <RestaurantHeader rest={this.state.rest} hideDropdown={true}/>
        <div className='thank-you-for-dining'>
          Thank you for dining with {this.state.rest.name}!
        </div>
        <div className='amount-container'>
          <div className='text-align-center'>
            <div className='your-total'>Your Total</div>
            <div className='amount'>
              {formatPrice.formatPrice(this.calculateAmount())}
            </div>
          </div>
        </div>
        <div className='payment-methods-container'>
          <PaymentMethod onSelect={this.expandCreditCard} />

        </div>
        
        {this.state.expanded === 'credit_card' && (
          <div className='card-section'>
            <form onSubmit={this.payWithCard}>
              <div className='elements-container'>
                <div className='element-row'>
                  <div style={{width:'100%'}}>
                    <div className='elements-header'>Card Number</div>
                    <CardNumberElement
                      options={{
                        style:INPUT_STYLE,
                        classes:{
                          base:'card-number-input'
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='element-row' >
                  <div style={{width:'50%'}}>
                    <div className='elements-header'>Expiration</div>
                    <CardExpiryElement 
                      options={{
                        style:INPUT_STYLE,
                        classes:{
                          base:'expiration-input'
                        }
                      }}
                    />
                  </div>
                  <div style={{width:'50%'}}>
                    <div className='elements-header'>Security Code</div>
                    <CardCvcElement 
                      options={{
                        style:INPUT_STYLE,
                        classes:{
                          base:'cvv-input'
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='element-row'>
                  <div style={{width:'50%'}}>
                    <div className='elements-header'>Zip Code</div>
                    <input 
                      style={{width:'90%'}}
                      className='StripeElement--empty zipcode'
                      type='number'
                      inputmode="decimal"
                      placeholder='Zip Code' 
                      value={this.state.zipcode}
                      onChange={e=>this.setState({zipcode:e.target.value.substring(0,5)})}
                    />
                  </div>
                </div>
              </div>
            </form> 
          </div>
        ) }
        
        <div className='tip-section'>
          <div className='tip-header'>Add a Tip</div>
          <div className='tip-container'>
            {this.state.tipAmounts.map(amount => (
              <div 
                className={amount === this.state.tip ? 'tip-outer tip-selected' : 'tip-outer'}
                onClick={()=>{
                  this.setState({tip:amount})
                }}
              >
                <div className='tip-inner'>
                  {formatPrice.formatPrice(amount)}
                </div>
              </div>
            ))}
            <div 
              className={
                this.state.tipAmounts.indexOf(this.state.tip) === -1 
                && this.state.tip !== null ? 'tip-outer tip-selected' :  'tip-outer'
              }
              onClick={()=>this.setState({tipModalOpen:true}, 
                ()=>setTimeout(()=>this.customTipInput.current.focus(), 100))}
            >
              <div className='tip-inner'>
                Custom
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.tipModalOpen}
          onAfterOpen={()=>{}}
          onRequestClose={()=>{this.setState({tipModalOpen:false})}}
          style={{content : {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            backgroundColor:'#FAFAFA',
            padding:'0px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }}}
          contentLabel="Example Modal"
        >
          <div className='custom-tip-modal'>
            <div className='custom-tip-modal-top'>
              <div>
                <div className='enter-tip-amount'>Enter Tip Amount</div>
                <div>
                 <input 
                    value={this.state.customTip} 
                    ref={this.customTipInput}
                    inputmode="decimal"
                    className='custom-tip-input' 
                    placeholder='$'
                    type='number'
                    onChange={(e)=>this.filterInput(e.target.value)}
                  /> 
                </div>
              </div>
            </div>
            <div className='custom-tip-modal-bottom'>
              <div onClick={()=>this.setState({tipModalOpen:false, customTip:null})}> Cancel </div>
              <div className='custom-tip-ok' onClick={()=>this.parseCustomTip()}> OK </div>
            </div>
          </div>
        </Modal>


        <div 
          className={this.state.zipcode.length === 5 
                     ? 'payment-footer payment-footer-enabled' 
                     : 'payment-footer payment-footer-disabled'} 
          onClick={this.payWithCard}
        >
          <div className='payment-footer-pay-with-card' >
              <div>
              Pay with card
            </div>
          </div>
          <div className='payment-footer-amount'>
            <div>
              {formatPrice.formatPrice(this.calculateAmount())}
            </div>
          </div>
        </div>

      </div>
    );
  }
}


class PaymentMethod extends React.Component {
  constructor(props){
    super(props)
    this.state={
      selected:true
    }
  }

  onSelect(){
    if(this.state.selected){
      this.props.onSelect()
    }
  }

  render(){
    let style = {}
    if(this.state.selected){
      style = {border:'3px solid orange'}
    }
    return (
      <div 
        className='payment-method-container'
        style={style}
        onClick={()=>{
          this.setState({selected:!this.state.selected}, ()=>this.onSelect())}
        }
      >
        <div>
          <CC/>
        </div>
        <div>Credit Card</div>
      </div>
    )
  }
  
}




const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const InjectedCheckoutForm = (props) => (
  <Elements stripe={stripePromise}>
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm {...props} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  </Elements>
);



export default withRouter(InjectedCheckoutForm)
